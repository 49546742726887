import React from 'react';
import styled from 'styled-components';
import { gifticon, pointicon, stampicon } from '../../../../assets/images';
import { useNavigate } from 'react-router-dom';

const StorePRS = ({
    kind
  , total
  , useTotal
  , storeData
  , earn
}) => {
  const navigate = useNavigate();
  const handleUsingPay = () => {
    if(kind === 'P'){
      navigate("/app/useinfo_store_point", {state: {storeData: storeData, earn: earn, useTotal:useTotal}, replace: true})
    } else if(kind === 'S'){
      navigate("/app/useinfo_store_stamp", {state: {storeData: storeData, earn: earn, useTotal:useTotal}, replace: true})
    } ;
  }


  return (
    <StorePRSWrap className={`kind${kind} earn${earn}`}>
      <KindSection>
        <h1 className={`kind${kind}`}>
          <span>
            {
              kind === 'P' ? <img src={pointicon} alt={kind} /> :
              kind === 'S' ? <img src={stampicon} alt={kind} /> :
                             <img src={gifticon } alt={kind} />
            }
          </span>
            {
              kind === 'P' ? '현금' :
              kind === 'S' ? '스탬프' :
                             '경품승점'
            }
        </h1>

        <ul>
          <li>
            <span>전체 누적</span> <p>{ Number(total).toLocaleString()}
            {kind === 'P' ? '원' : kind === 'S' ? '개' : '점'}</p>
          </li>
          <li>
            <span>전체 가용</span> <p>{ Number(useTotal).toLocaleString()}{kind === 'P' ? '원' : kind === 'S' ? '개' : '점'}</p>
          </li>
        </ul>
      </KindSection>

      <ThisStoreBonus>
          <h2 className={`kind${kind}`}>이 매장에서의 가용 보너스</h2>
          <div>
            <label className={`kind${kind}`}>
              <input 
              type='text' 
              name={kind} 
              value={storeData?.storeKind === kind ? Number(useTotal).toLocaleString() : 0} 
              readOnly />
            </label>
            <em className={`kind${kind}`}>{kind === 'P' ? '원' : kind === 'S' ? '개' : '점'}</em>
            <button  disabled={storeData?.storeKind !== kind || useTotal <= 0 } onClick={handleUsingPay}>사용</button>
          </div>
      </ThisStoreBonus>
    </StorePRSWrap>
  );
};

const StorePRSWrap = styled.div`
padding: 30px 0;
border-bottom: 1px solid #DFDFDF;

.kindP{
  color:#7B8531;

  input{
    color:#7B8531;
    background:#F2F3EA;
  }
}

.kindS{
  color:#EC635D;

  input{
    color:#EC635D;
    background:#FDEFEF;
  }
}

.kindR {
  color:#2860A7;

  input{
    color:#2860A7;
    background:#EAEFF6;
  }
}

// &.kindR.earnT, &.kindR.earnG {
//   display: none;
// }

`

const KindSection = styled.div`
display: flex;
justify-content: space-between;
align-items: start;
padding: 0 16px;


h1 {
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: bold;
  gap: 4px;
  font-size: 18px;

  span {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
    }
  }
}

ul {
 width: 200px;
  max-width: calc(100% - 130px);
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap:4px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#717171;

    span, p {
      color:#717171;
      font-size :14px;
    }

    &:last-child {
      color:#222;

      span, p {
        color:#222;
      }

      p{
        font-weight:bold;
      }
    }
  }
}
`

const ThisStoreBonus = styled.div`
width: calc(100% - 32px);
padding: 0 16px;
margin-top: 12px;

div{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

h2 {
  font-size: 14px;
  margin-bottom: 4px;
}

label {
  width: calc(100% - 84px);

  input {
    height: 28px;
    width: calc(100% - 8px);
    text-align:right;
    padding: 0 4px;
    border-radius: 4px;

    font-size: 16px;
    font-weight: bold;
  }
}

em {
  font-size: 14px;
  margin-left: 4px;
  min-width: 12px;
}

button {
  min-width: 60px;
  height: 28px;
  border-radius: 28px;
  background:#FE7A36;
  color:#fff;
  font-size: 14px;
  margin-left: 8px;

  &:disabled {
    background:#ddd
  }
}
`
export default StorePRS;