import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SubHeadHome = ({ children, SubBgColor, bottomLine }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundColor = scrollPosition > 10 ? SubBgColor : 'transparent';
  const navigator = useNavigate();
  
  return (
    /**
     * SubHeadHome 서브헤더 공통
     * - 뒤로가기 기능
     * - 서브타이틀은 있으면 추가됨.
     */

    <SubHeadHomeWrap style={{ backgroundColor }} data-bottomline={bottomLine}>
      <BackHistory onClick={()=>navigator('/app/main')}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.59717 9.76664C2.59717 9.37262 2.79772 9.00296 3.13538 8.77461L11.4554 3.1481C11.8995 2.84777 12.4949 2.84777 12.939 3.1481L21.259 8.77462C21.5966 9.00296 21.7972 9.37261 21.7972 9.76664V20.2969C21.7972 21.3054 20.9376 22.1228 19.8772 22.1228H16.0372C15.4849 22.1228 15.0372 21.6751 15.0372 21.1228V16.5838C15.0372 16.0315 14.5895 15.5838 14.0372 15.5838H10.3572C9.80488 15.5838 9.35717 16.0315 9.35717 16.5838V21.1228C9.35717 21.6751 8.90945 22.1228 8.35717 22.1228H4.51717C3.45678 22.1228 2.59717 21.3054 2.59717 20.2969V9.76664Z" stroke="#222222" strokeWidth="2"/>
        </svg>
      </BackHistory>
      

      {children && <h2>{children}</h2>}
    </SubHeadHomeWrap>
  );
};

const SubHeadHomeWrap = styled.div`
  width: 100%;
  height: 60px;
  z-index:99;

  position: fixed;
  top:0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: ${(props) => (props['data-bottomline'] ? '1px solid #ddd' : 'none')};

  // 뒤로가기 아이콘
  div {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -30px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #222;
  }
`;

const BackHistory = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

export default SubHeadHome;
