import React from 'react';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import styled from 'styled-components';
// import useModalWithBackButton from '../../../components/common/function/HistoryModal';
import RpsUseDetail from '../../../components/common/payuse/RpsUseDetail';

const OnlyRps = () => {
  const storedData = localStorage.getItem('pageState');
  const data = JSON.parse(storedData);

  const storeInfo     = data?.store;
  const pointInfo     = data?.point;
  const receiptInfo   = data?.receipt || data?.recipt;
  const type          = data?.type;
  const rpsInfo       = data?.rps;

  // const [isModal1Visible, toggleModal1] = useModalWithBackButton(); 

  const rpsScore      = Number(pointInfo?.rpsScore).toLocaleString();

  return (
    <div id="sub_common_wrap">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

      <section>
        <PointWrap>
          <RpsUseDetail
            // toggleModal       ={toggleModal1}
            earnType    ={storeInfo?.storeEarn}
            storeName   ={storeInfo?.storeName}
            rpsScore    ={rpsScore}
            userName    ={pointInfo?.userName}
            grade       ={pointInfo?.grade}
            pointInfo   ={pointInfo}
            type        ={type}
            receiptInfo ={receiptInfo}
            rpsInfo     ={rpsInfo}
            show        ={"use"}
          />
        </PointWrap>
      </section>
    </div>
  );
};

const PointWrap = styled.div`
width: calc(100% - 32px);
padding:32px 16px;
`
export default OnlyRps; 