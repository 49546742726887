import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SubHead      from '../../../components/TouchOll/SubHead';
import BottomGnb    from '../../../components/TouchOll/main/BottomGnb';
import BonusTotal   from '../../../components/TouchOll/usinginfo/BonusTotal';
import SearchInfo   from '../../../components/TouchOll/usinginfo/SearchInfo';
import OrderByList  from '../../../components/TouchOll/usinginfo/OrderByList';
import StoreList from '../../../components/TouchOll/main/StoreList';
import axios from 'axios';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import { formatDate } from '../../../components/common/function/FormatNumber';
import { top } from '../../../../assets/images';
import SkeletonUi from '../../../components/TouchOll/usinginfo/SkeletonUi';
import TestUsing from '../../../../assets/json/TestUsing.json'

const UseinfoPage = () => {
  /**
   * 마이 이용내역 페이지
   */
  const today = new Date();
  const [searchList, setSearchList] = useState({
    "sectors": "",
    "earn": "",
    "kind": "",
    "type": "",
    "sid": "",
    "storeName": "",
    "area": "",
    "sdate": formatDate(today),
    "edate": formatDate(today),
    "pageSize": 10,
    "offset": 0
  });

  const [dataResponse, setDataResponse] = useState({})
  const [useList, setUseList] = useState([])
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData]= useState(false);
  

  useEffect(() => {
    handleHeaderToken();
  }, [])

  // page 변경 감지에 따른 API호출
  useEffect(() => {
    fetchData();
    console.log("page", page);
  }, [page]);

  const fetchData = async () => {
    setSearchList( {
      ...searchList,
      offset: (page - 1) * searchList.pageSize
    });

    setIsLoading(true);
    try{
      const response = await axios.post('/api/myPageMngr/selectUseDtls', searchList, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log('이용내역 상세 데이터 ::', response?.data);
      // 기존 데이터와 새로 가져온 데이터를 합칩니다.
      setDataResponse(response?.data?.data?.message);
      
      // 더 이상 데이터가 없는 경우 처리
      if (response?.data?.data?.message?.listUseDetails?.length === 0) {
        setNoMoreData(true); // 더 이상 데이터가 없음을 나타내는 상태 변수
      } else if (page !== 1) {
        setUseList((prevData) => [...(prevData || []), ...response?.data?.data?.message?.listUseDetails]);
      } else {
        setUseList(response?.data?.data?.message?.listUseDetails);
      }
      
    } catch(error) {
      console.log("이용내역 오류 ::", error)
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    
  }

  useEffect(()=>{
    console.log("useList", useList)
  },[useList])

  // Intersection Observer 설정
  /**
   * 무한 로딩
   * handleObserver: 교차점이 발생했을 때 실행되는 콜백 함수.
   * @param {*} entries 교차점 정보를 담는 배열
   * isIntersecting: 교차점(intersection)이 발생한 요소의 상태
   * 교차점이 발생하면 page 1 증가
   */
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1, 
    });
    // 최하단 요소를 관찰 대상으로 지정함
    const observerTarget = document.getElementById("observer");
    // 관찰 시작
    if (observerTarget) {
      observer.observe(observerTarget);
    }
  }, []);


  // 가용 보너스 조회
  const [userPointTotal, setUserPointTotal] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get('/api/pointMngr/getPointInfo');
      
      setUserPointTotal(result.data.data.message.userPoint);
    };

    fetchData();
  }, []);

  console.log(dataResponse.listUseDetails);

  /**
   * 맨위 상단 이동하기
   */
  const MoveToTop = () => {
    window.scrollTo({ top:0, behavior: 'smooth'})
  } 

  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        MY 이용내역 후기 · 평가
      </SubHead>

      <section>
        {/* My 보너스 전체 */}
        <BonusTotal userPointTotal={userPointTotal} />

        {/* 검색 */}
        <SearchInfo 
        dataResponse    ={dataResponse}
        setDataResponse ={setDataResponse}
        searchList      ={searchList} 
        setSearchList   ={setSearchList}
        setIsLoading    ={setIsLoading}
        // fetchData       ={fetchData}
        setUseList      ={setUseList}
        setPage         ={setPage}
        />

        {/* 순서 정렬 */}
        <OrderByList />

        <StoreListWrap>
          {useList && 
            useList.map((item, index) => (
                <StoreList
                  key       ={index}
                  type      ={item?.type}
                  typeName  ={item?.typeName}
                  earn      ={item?.earn}
                  date      ={item?.payDate}
                  cash      ={item?.amount}
                  store     ={item?.storename}
                  starCount ={item?.storeAvg}
                  Icons     ={['hot']}
                  pointUse  ={item?.pointUse}
                  stampUse  ={item?.stampUse}
                  rpsUse    ={item?.rpsUse}
                  perCent   ={item?.pointRate}
                  stamp     ={item?.stamp}
                  rps       ={item?.rps}
                  pointSum  ={item?.sumpoint} 
                  kind      ={item?.kind}
                  approval  ={item?.approval} 
                  userId    ={item?.userId} 
                />
              ))
            }
          
          {!useList && 
          TestUsing.map((item, index) =>( 
            <StoreList
            key       ={index}
            type      ={item?.type}
            typeName  ={item?.typeName}
            earn      ={item?.earn}
            date      ={item?.payDate}
            cash      ={item?.amount}
            store     ={item?.storename}
            starCount ={item?.storeAvg}
            Icons     ={['hot', 'recom', 'top', 'celebs', 'new']}
            pointUse  ={item?.pointUse}
            stampUse  ={item?.stampUse}
            rpsUse    ={item?.rpsUse}
            perCent   ={item?.pointRate}
            stamp     ={item?.stamp}
            rps       ={item?.rps}
            pointSum  ={item?.sumpoint} 
            kind      ={item?.kind} 
            approval  ={item?.approval} 
            userId    ={item?.userId} 
            />
          ))
        }
          {
            isLoading && <SkeletonUi />
          }
          <div id="observer" style={{ height: "30px" }}></div>
        </StoreListWrap>
       
        <MoveTopBtn onClick={MoveToTop}><img src={top} alt='상단'/></MoveTopBtn>
      </section>

      <BottomGnb />
    </div>
  );
};

const StoreListWrap = styled.div`
min-height: 500px;
padding-bottom: 60px;
`

const MoveTopBtn = styled.button`
position: fixed;
bottom : 87px;
right: 16px;

width: 40px;
height: 40px;
border-radius:40px;

display:flex;
justify-content: center;
align-items: center;

`
export default UseinfoPage;