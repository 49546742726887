import React from 'react';
import styled from 'styled-components';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import StampUseDetail from '../../../components/common/payuse/StampUseDetail';
import PointUseDetail from '../../../components/common/payuse/PointUseDetail';
// import useModalWithBackButton from '../../../components/common/function/HistoryModal';

const OnlyDetail = () => {
  const storedData = localStorage.getItem('pageState');
  const data = JSON.parse(storedData);

  const storeInfo     = data?.store;
  const pointInfo     = data?.point;
  const receiptInfo   = data?.receipt || data?.recipt;
  const type          = data?.type;

  // const [isModal1Visible, toggleModal1] = useModalWithBackButton(); 

  const sttleStamp              = Number(pointInfo?.sttleStamp).toLocaleString();
  const sumCashBonusStamp       = Number(pointInfo?.sumCashBonusStamp).toLocaleString();
  const cancelSumCashBonusStamp = Number(pointInfo?.cancelSumCashBonusStamp).toLocaleString();

  const sttlePoint              = Number(pointInfo?.sttlePoint).toLocaleString();
  const sumCashBonusPoint       = Number(pointInfo?.sumCashBonusPoint).toLocaleString(); // 최종 포인트
  const cancelSumCashBonusPoint = Number(pointInfo?.cancelSumCashBonusPoint).toLocaleString();

  return (
    <div id="sub_common_wrap">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

      <section>
        <PointWrap>
          {
            pointInfo?.kind !== "C" && storeInfo?.stampUse === 'Y' ? 
            <StampUseDetail 
              // toggleModal       ={toggleModal1}
              earnType          ={storeInfo?.storeEarn}
              storeName         ={storeInfo?.storeName}
              sttleStamp        ={sttleStamp}
              userName          ={pointInfo?.userName}
              grade             ={pointInfo?.grade}
              sumCashBonusStamp ={type === "1" || type === "2" ? sumCashBonusStamp : cancelSumCashBonusStamp}
              pointInfo         ={pointInfo}
              type              ={type}
              receiptInfo       ={receiptInfo}
              kind              ={pointInfo?.kind}
              show              ={"use"}
            /> : 
            <PointUseDetail 
              // toggleModal       ={toggleModal1}
              earnType          ={storeInfo?.storeEarn}
              storeName         ={storeInfo?.storeName}
              sttleStamp        ={sttlePoint}
              userName          ={pointInfo?.userName}
              grade             ={pointInfo?.grade}
              sumCashBonusPoint ={type === "1" || type === "2" ? sumCashBonusPoint : cancelSumCashBonusPoint}
              pointInfo         ={pointInfo}
              type              ={type}
              receiptInfo       ={receiptInfo}
              kind              ={pointInfo?.kind}
              show              ={"use"}
            />
        }
        
        </PointWrap>
      </section>
    </div>
  );
};


const PointWrap = styled.div`
width: calc(100% - 32px);
padding:32px 16px;
`

export default OnlyDetail;