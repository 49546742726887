import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import BottomGnb from '../../../components/TouchOll/main/BottomGnb';
import styled from 'styled-components';
import MenuIconList from '../../../components/TouchOll/store/MenuIconList';
import StoreMapList from '../../../components/TouchOll/store/StoreMapList';
import { check } from 'prettier';
import KakaoMap from '../../../components/TouchOll/store/Map';
import StoreSearch from '../../../components/TouchOll/mypage/StoreSearch';
import { useLocation } from 'react-router';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import axios from 'axios';

const StorePage = () => {
  /**
   * 모바일 기기 확인 
   * isMobile : true (모바일 기기)
   */
  const [isMobile, setIsMobile]   = useState(false); 
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  // MapType 지도 타입 설정 탭
  const [mapType, setMapType] = useState(1);
  const handleMapTypeChange = (e) => {
    setMapType(parseInt(e.target.value));
  };

  const location = useLocation();

  const [test, setTest] = useState('onLocationChanged: json')
  useEffect(() => {
    /**
     * maptype (1: 내 위치) 일 때 
     * LocationManager 에 runLocator를 전송
     * 
     * 1이 아니거나 현재 페이지가 아니라면 dispose 전송
     */
    if(mapType === 1 && isMobile){
      // eslint-disable-next-line no-undef
      LocationManager.postMessage('runLocator');
    } else if (mapType === 2 && isMobile){
      // eslint-disable-next-line no-undef
      LocationManager.postMessage('dispose');
    }

    return () => {
      if(isMobile){
        // eslint-disable-next-line no-undef
        LocationManager.postMessage('dispose');
      }
    };
  }, [mapType, location]);



  window.onLocationChanged = (msg) => {
    setTest(msg)
    console.log('onLocationChanged ::',msg);
  }

  const [data, setData] = useState()
  const [paySearch, setPaySearch ] = useState({
    "pageSize": 10,
    "offset": 1,
    "orderBy": "datedesc",
    "sid ": "",
    "earn": "",
    "cityCode": "",
    "areaCode": "",
    "dongCode": "",
    "sectors": "",
    "storeGrp": "",
    "storeLevel": "",
    "storeName": "",
    "rating": "",    
    "kind": ""
  })

  useEffect(()=>{
    handleHeaderToken();
  },[])

  const fetchData = async () => {
    try{
      const response = await axios.post('/api/myPageMngr/listMyPay', paySearch, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      
      console.log("listMyPay 데이터: ",response?.data?.data?.message)
      setData(response?.data?.data?.message);

    } catch(error){
      console.log("flutter 전달 상세 오류 ::". error);
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

  const handleSearchPay = () => {
    fetchData();
  }
  
  const [xlat, setXlat] = useState(37.506320759000715);
  const [ylng, setYlng] = useState(127.05368251210247);

  useEffect(()=>{
    console.log("xlat", xlat);
    console.log("ylng", ylng);
  },[xlat, ylng])

  const [state, setState] = useState({
    // 지도의 초기 위치
    center: { lat: xlat, lng: ylng },
    // 지도 위치 변경시 panto를 이용할지에 대해서 정의
    isPanto: true,
  })
  
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        가맹점 찾기
      </SubHead>
      <section>
        <div id='wrap32'>
          <MapType>
            <li>
              <label>
                <input 
                type    ='radio' 
                name    ='map_location' 
                value   ={1} 
                checked ={mapType === 1} 
                onChange={handleMapTypeChange}
                /> 
                내 위치 중심
              </label>
            </li>

            <li>
              <label>
                <input 
                type    ='radio' 
                name    ='map_location' 
                value   ={2} 
                checked ={mapType === 2} 
                onChange={handleMapTypeChange}
                /> 
                일반 지도
              </label>
            </li>
          </MapType>

          <MenuIconList />
        </div>

        <MapContent>
          {/* {test} */}
          {/* 가맹점 찾기 검색 */}
          <StoreSearch 
          mapType        ={mapType} 
          paySearch      = {paySearch}
          setPaySearch   ={setPaySearch} 
          handleSearchPay={handleSearchPay} />

          {/* 카카오 맵 */}
          <KakaoMap xlat={xlat} ylng={ylng} state={state} />
        </MapContent>

        <StoreMapList 
          data    ={data} 
          setXlat ={setXlat} 
          setYlng ={setYlng}
          setState={setState}
        />
      </section>
      {/* <BottomGnb /> */}
    </div>
  );
};

const MapType = styled.ul`
display: flex;
justify-content: flex-start;
align-items: center;

margin-top: 12px;

li {
  width: 50%;
  height: 28px;

  border: 1px solid #099FC0;
  border-radius: 4px 0 0 4px;

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

input[type='radio'] {
  display:none;

  &:checked {
    background: #099FC0
  }
}

label{
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  color: #099FC0;

  &:has(input[type='radio']:checked){
    background: #099FC0;
    color:#fff;
  }
  
}
`

const MapContent = styled.div`
position: relative;
`

export default StorePage;