import React from 'react';
import ModalPopup from './ModalPopup';
import styled from 'styled-components';

const SystemYesNo = ({
    children
  , handleSystem
  , system
  , setSystem
}) => {
  return (
    <ModalPopup>
      <PopupContent>
        <SystemText>{children}</SystemText>
        <ul>
          <li><SystemBtn onClick={handleSystem}>Yes</SystemBtn></li>
          <li><SystemBtn onClick={() => setSystem(!system)}>No</SystemBtn></li>
        </ul>
      </PopupContent>
    </ModalPopup>
  );
};


const PopupContent = styled.div`
  width: 280px;
  height: auto;

  padding: 32px 16px 16px 16px;
  background:#fff;
  border-radius: 8px;

  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: center !important;
  gap : 0 !important;

  ul {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:last-child {
        em {color:#888888;}
      }
    }
  }
`

const SystemText = styled.p`
  margin-bottom:20px;
  font-size: 16px;
  color:#222;
  font-weight: 500 !important;

  line-height: 20px;

  em {
    color:#5D0EF5;
    font-weight:bold;
  }

  span {
    color:#FFC121;
    font-weight:bold;
  }
`

const SystemBtn = styled.em`
  font-size: 14px;
  color:#FE7A36;
  border-radius: 4px;
  width: 72px;
  height: 36px;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  cursor:pointer;
  
`

export default SystemYesNo;