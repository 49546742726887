import React from 'react';
import styled from 'styled-components';

const SwitchToggle = () => {
  return (
    <Switch>
      <input id="cb1" type="checkbox"/>
      <label htmlFor="cb1"></label>
    </Switch>
  );
};

const Switch = styled.div`
input {
	display: none;
  
	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + label {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}
  
	+ label {
		outline: 0;
		display: block;
		width: 40px;
		height: 20px;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}
    
		&:after {
			left: 0;
		}
    
		&:before {
			display: none;
		}

    background: #f0f0f0;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}
  
	&:checked + label:after {
		left: 50%;
	}

  &:checked + label {
		background: #FE7A36;
	}
}
}

`
export default SwitchToggle;