import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import StoreItem from '../usinginfo/StoreItem';
import styled from 'styled-components';
import { drag } from '../../../../assets/images';
import StoreFind from '../usinginfo/StoreFind';
import axios from 'axios';
import { handleHeaderToken } from '../../common/function/BearerToken';

const StoreMapList = ({
    data
  , setXlat
  , setYlng
  , setState
}) => {
  /**
   * 가맹점 찾기 조회
   */
  const [termOpen, setTermOpen] = useState(true);

  const handleXYmap = (xpo, ypo) => {
    setState(prev => {
      // 함수 내부에서 이전 상태값 참조
      return {
        ...prev,
        center: {lat: xpo, lng: ypo}
      }
    });
  }


 
  return (
    <>
    { !termOpen && <OpenBtn onClick={()=>setTermOpen(true)}>목록 열기</OpenBtn>}
    <SheetWrap
      detent="content-height"
      isOpen={termOpen}
      onClose={() => setTermOpen(false)}
      snapPoints={[0.65, 0.3]}
      initialSnap={1}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content disableDrag={true}>
        {
          data?.listMyPayStore &&
          data?.listMyPayStore.map((item) => (
          <StoreFind
            key       = {item.sid}
            earn      = {item.earn}
            store     = {item.storeName}
            storeImg  = {item.logo}
            starCount = {item.storeAvg}
            Icons     = {['hot']}
            pointUse  = {item.pointType === 'P' ? 'Y' : 'N'}
            stampUse  = {item.pointType === 'S' ? 'Y' : 'N'}
            rpsUse    = {item.rpsType === 'R' ? 'Y' : 'N'}
            perCent   = {item.point}
            stamp     = {item.stamp}
            rps       = {item.rps}
            city      ={item.city}
            gungu     ={item.gungu}
            dong      ={item.dong}
            sectorName={item.sectorName}
            phone     = {item.phone}
            xpoint    = {item.xpoint}
            ypoint    = {item.ypoint}
            onClick   = {() => handleXYmap(item.ypoint, item.xpoint)}
          />
          ))
        }
{/* 
          <StoreFind
            earn      = {'T'}
            store     = {"테스트 가맹점"}
            // storeImg  = {item.logo}
            starCount = {4.5}
            Icons     = {['hot']}
            pointUse  = {'Y'}
            stampUse  = {'N'}
            rpsUse    = {'Y'}
            perCent   = {3.5}
            stamp     = {3000}
            rps       = {3000}
            phone     = {'01026666666'}
            xpoint    = {125.880663748}
            ypoint    = {36.477896033}
            onClick   = {() => handleXYmap(36.48306, 126.8813)}
          />

          <StoreFind
            earn      = {'T'}
            store     = {"테스트 가맹점"}
            // storeImg  = {item.logo}
            starCount = {4.5}
            Icons     = {['hot']}
            pointUse  = {'Y'}
            stampUse  = {'N'}
            rpsUse    = {'Y'}
            perCent   = {3.5}
            stamp     = {3000}
            rps       = {3000}
            phone     = {'01026666666'}
            xpoint    = {37.483068}
            ypoint    = {126.8813}
            onClick   = {() => handleXYmap(37.48306, 126.8813)}
          /> */}

          
        </Sheet.Content>
      </Sheet.Container>
    </SheetWrap>
    </>
  );
};

const SheetWrap = styled(Sheet)`
z-index: 8 !important;

.react-modal-sheet-container {
  max-transform: translateY(440px);
}

.react-modal-sheet-content  {
  overflow: auto;

  &>div:first-child {
    padding-top:0;
  }
}
`

const OpenBtn = styled.button`
  position: fixed;
  bottom:20px;
  left : 50%;
  margin-left: -40px;
  background:#fff;
  // width: 80px;
  height:30px;
  padding: 10px 15px;
  // background:#06B3D9;
  z-index:10;
  border-radius: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

 
`

export default StoreMapList;