import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import BonusTotal from '../../../components/TouchOll/usinginfo/BonusTotal';
import SearchPayInfo from '../../../components/TouchOll/usinginfo/SearchPayInfo';
import OrderByList from '../../../components/TouchOll/usinginfo/OrderByList';
import StoreItem from '../../../components/TouchOll/usinginfo/StoreItem';
import axios from 'axios';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';

const UseinfoPay = () => {
  /**
   * 마이 보너스 조회 사용 페이지
   */
  const [data, setData] = useState()
  const [paySearch, setPaySearch ] = useState({
    "pageSize": 10,
    "offset": 1,
    "orderBy": "datedesc",
    "sid ": "",
    "earn": "",
    "cityCode": "",
    "areaCode": "",
    "dongCode": "",
    "sectors": "",
    "storeGrp": "",
    "storeLevel": "",
    "storeName": "",
    "rating": "",    
    "kind": ""
  })

  useEffect(()=>{
    handleHeaderToken();
  },[])

  const fetchData = async () => {
    try{
      const response = await axios.post('/api/myPageMngr/listMyPay', paySearch, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      
      console.log("listMyPay 데이터: ",response?.data?.data?.message)
      setData(response?.data?.data?.message);

    } catch(error){
      console.log("flutter 전달 상세 오류 ::". error);
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

  const handleSearchPay = () => {
    fetchData();
  }

  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
      My 보너스 사용・<em className='pay'>Pay</em>
      </SubHead>
      
      <section>
        {/* My 보너스 전체 */}
        <BonusTotal />

        {/* 보너스 조회 검색 */}
        <SearchPayInfo 
        handleSearchPay ={handleSearchPay}
        paySearch       ={paySearch}
        setPaySearch    ={setPaySearch} 
        />

        {/* 순서 정렬 */}
        <OrderByList />

        {
          data?.listMyPayStore &&
          data?.listMyPayStore.map((item, index) => (
            <StoreItem 
              key       = {item.sid}
              sid       = {item.sid}
              earn      = {item.earn}
              store     = {item.storeName}
              storeImg  = {item.logo}
              starCount = {item.storeAvg}
              Icons     = {['hot']}
              pointUse  ={item.pointType === 'P' ? 'Y' : 'N'}
              stampUse  ={item.pointType === 'S' ? 'Y' : 'N'}
              rpsUse    ={item.rpsType === 'R' ? 'Y' : 'N'}
              perCent   ={item.point}
              stamp     ={item.stamp}
              rps       ={item.rps}
              city      ={item.city}
              gungu     ={item.gungu}
              dong      ={item.dong}
              sectorName={item.sectorName}
              pavail    ={item.pavail}
              savail    ={item.savail}
              ravail    ={item.ravail}
            />
          ))
        }
     
      </section>
    </div>
  );
};

export default UseinfoPay;