import { useEffect } from "react";
import styled from "styled-components";
import { useBodyScrollLock } from "./function/BodyModalScroll";

const ModalPopup = ({ isOpen, setIsOpen, children }) => {
  /*
  * 배경에 딤(어둠)처리가 있는 모달 팝업
  * 
  */
  // const { lockScroll, openScroll } = useBodyScrollLock(); 
  // useEffect(()=>{
  //   if(!isOpen){
  //     lockScroll();
  //   } 
  // },[isOpen])

  const handleDimBGClick = () => {
    setIsOpen(false);
    // openScroll();
  }; 

  return (
    <ModalWrap>
      <Modal>
        {children}
      </Modal>

      <DimBG onClick={setIsOpen ? handleDimBGClick : undefined}></DimBG>
    </ModalWrap>
  );
};

const ModalWrap = styled.div`
  position: fixed;
  left:0;
  top:0;

  z-index: 999;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  z-index: 9999;
`

const DimBG = styled.div`
  width: 100vw;
  height: 100vh;

  position: relative;
  left:0;
  top:0;

  background: rgba(0,0,0,0.5);
  z-index: 999;

`

export default ModalPopup;

