import React, { useState } from 'react';
import styled from 'styled-components';

const MenuIconList = () => {
  const [checkedValues, setCheckedValues] = useState([]);

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter(item => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };
  
  return (
    <IconListMenu>
      <li>
        찾는 순서
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'hot'} 
          checked ={checkedValues.includes('hot')}
          onChange={() => handleCheckboxChange('hot')}
          />HOT
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'top'} 
          checked ={checkedValues.includes('top')}
          onChange={() => handleCheckboxChange('top')}
          />TOP
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'celab'} 
          checked ={checkedValues.includes('celab')}
          onChange={() => handleCheckboxChange('celab')}
          />셀럽
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'high'} 
          checked ={checkedValues.includes('high')}
          onChange={() => handleCheckboxChange('high')}
          />HIGH%
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'plus'} 
          checked ={checkedValues.includes('plus')}
          onChange={() => handleCheckboxChange('plus')}
          />PLUS%
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'recom'} 
          checked ={checkedValues.includes('recom')}
          onChange={() => handleCheckboxChange('recom')}
          />추천
        </label>
      </li>

      <li>
        <label>
          <input 
          type    ='checkbox'
          name    ='findList' 
          value   ={'new'} 
          checked ={checkedValues.includes('new')}
          onChange={() => handleCheckboxChange('new')}
          />NEW
        </label>
      </li>

    </IconListMenu>
  );
};

const IconListMenu = styled.ul`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 8px 0;

  li {
    width: calc(25% - 9px);
    height: 24px;

    display:flex;
    justify-content: center;
    align-items:center;

    border-radius: 30px;

    // font-weight:bold;
    color:#fff;

    input[type='checkbox']{
      display:none;
    }

    &:first-child {
      background:#7B8531;
    }

    label {
      width: 100%;
      height:100%;

      display:flex;
      justify-content: center;
      align-items:center;

      border-radius: 30px;
      // font-weight:bold;

      &:has(input[type='checkbox']:checked){
        background: #FF5F01;
        color:#fff;
      }
    }
  }
`

export default MenuIconList;