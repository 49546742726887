import React from 'react';
import Adimg from '../../../components/common/payuse/Adimg';
import styled from 'styled-components';
import Recipe from '../../../components/common/payuse/Recipe';
import BlankBtns from '../../../components/common/payuse/BlankBtns';
import TopImg from '../../../components/common/payuse/TopImg';
import PointUseScreen from '../../../components/common/payuse/PointUseScreen';
import { useLocation } from 'react-router';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import GameStanby from '../../../components/Biapp/GameStanby';
import RpsStand from '../../../components/common/payuse/RpsStand';
import PointStandScreen from '../../../components/common/payuse/PointStandScreen';

const PointTypeOne = () => {
  /**
   * 현금 적립 대기 페이지
   */
  const location = useLocation();  
  const data = location.state?.data || {};
  const point = location.state?.point;

  const storeInfo   = data?.store;
  const pointInfo   = data?.point;
  const receiptInfo = data?.receipt || data?.recipt;
  const useType     = data?.type;
  const kind        = data?.kind;

  console.log("받아온 데이터::",data)
  console.log("receiptInfo 데이터::",receiptInfo) 

  return (
    <div id="sub_common_wrap_f">
      <SubHeadHome  SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

    <section>
      <PointWrap>
      <PointResultWrap>
        {/* 터치올 공통 배너 */}
        <TopImg />
        
        {/* 포인트 상태 Type에 따른 화면 */}
        <PointStandScreen storeInfo={storeInfo} pointInfo={pointInfo} data={data} point={point} kind={kind}/>

        {/* 전자영수증 */}
        <Recipe receiptInfo={receiptInfo} storeInfo={storeInfo} useType={useType} />

        {/* 광고 */}   
        <Adimg />
      </PointResultWrap>
      
    </PointWrap>
    </section>
    </div>
    
  );
};

const PointWrap = styled.div`
background:#F5F5F5; 
padding:32px 0;
`

const PointResultWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
gap: 32px;

width: calc(100% - 32px);
padding: 0 16px;

`
export default PointTypeOne;