import React from 'react';
import styled from 'styled-components';

const PhoneNumBan = () => {
  return (
    <PhoneNumBanWrap>
      <div>
        <h1>폰 N0 - 처음 한번만 - 입력!</h1>
        <ul>
          <li>
            <span>· 진짜 - </span>현금이 통장 입금이 척!
          </li>
          <li>
            <span>· 한판 승부 - </span>가위바위보 경품이 척!
          </li>
          <li>
            <span>· 스탬프 - </span>무상! 기분이 짱!
          </li>
        </ul>
      </div>

    </PhoneNumBanWrap>
  );
};

const PhoneNumBanWrap = styled.div`
width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 20px;

  div {
    width: calc(100% - 32px);
    padding: 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
    
    h1 {
      font-size: 20px; 
      color:#fff;
      font-weight:700;
      background:#FE7A36;
      padding: 10px 12px;
      border-radius: 60px;
      text-align:center;
    }

    ul{
      margin-top: 27px;
      li {
      font-size: 20px; 
      color:#FE7A36;
      font-weight:700;
      line-height: 26px;

      span {
        color:#777;
      }
    }
    } 
  }

`
export default PhoneNumBan;