import React, { useState } from 'react';
import {
    arr_bottom_gray  
} from "../../../../assets/images";
import styled from 'styled-components';

const ToggleList = ({
  title
  , text
  , date
  , headtext
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <BoardListWrap className='boardList'>
      <BoardListTit className='boardtit' onClick={toggleContent}>
        <h2>{headtext ? <><em>[{headtext}]</em></> : ""} {title}</h2>
        <div>
          <em>{date}</em>
          <span>
            <img src={arr_bottom_gray} alt='더보기' />
          </span>
        </div>
      </BoardListTit>

      
        <BoardListContent className={`boardcon ${isContentVisible ? "open_li" : ""}`} >
          <div>
          <p>{text}</p>
        
          </div>
          </BoardListContent>
      
    </BoardListWrap>
  );
};

const BoardListWrap = styled.div`
  border-bottom: 1px solid #ddd;
`

const BoardListTit = styled.div`
  width: calc(100% - 32px);
  height: 60px;

  padding: 0 16px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor:pointer;

  h2 {
    font-size: 16px;
    em {color:#EB5757;}
    
  }

  div {
    display: flex;
    justify-content: end;
    align-items: center;

    em {
      font-size: 14px;
      color:#444;
    }

    span {
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
const BoardListContent = styled.div`
  max-height:0;
  overflow: hidden;
  transition: all .3s linear; 

  &.open_li {
    max-height: 100vh;
    transition: all .3s linear; 
  }

  div{
    padding: 0 16px 30px 16px;

     p {
      font-size: 14px;
      line-height: 20px;
     }
  }
`;

export default ToggleList;