import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import axios from 'axios';
import AutoTimer from '../../../components/TouchOll/join/AutoTimer';
import SystemModal from '../../../components/common/SystemModal';
import TelNumberInput from '../../../components/common/inputs/TelNumberInput';

const PwReissue = () => {
  /**
   * 정규 가입 | 임시 가입에 따른 비밀번호 재발급
   * 공통: 핸드폰 번호 > 인증번호 확인
   * 
   * 1. 정규가입 경우
   * - 확인 버튼 클릭하여 pwState가 true 이면 >> 이름, 생년월일 input 나타남
   * - 완료 버튼 클릭 하면 새 비밀번호 재발급 화면 이동
   * 
   * 2. 임시가입 경우 
   * - 확인 버튼 클릭시 이벤트 없음
   * - 완료 버튼 클릭 하면 (비밀번호, 이름, 생년월일) 화면 이동 
   * - (비밀번호, 이름, 생년월일) 화면에 완료 버튼 클릭시 가입 환영 페이지 이동
   * - 가입 환영 페이지 에서 확인 버튼 클릭 시 > 선택정보 작성 페이지
   *  
   */
  const [mobile, setMobile]         = useState('');     // 핸드폰 번호
  const [pwState, setPwState]       = useState(true);   // 인증번호 확인

  const [name, setName]             = useState('');     // 이름
  const [birthday, setBirthday]     = useState('');     // 생년월일
  
  const [popupTxt, setPopupTxt]     = useState('');     // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false);  // 시스템 팝업 

  const navigate = useNavigate();

  // 회원 유형 : 정규가입 회원 'Y' | 임시가입 회원 'N' 
  const [joinType, setJoinType] = useState('')

  useEffect(()=>{
    console.log("joinType", joinType)
  },[joinType])

  /** 
   * 비밀번호 재발급 완료 버튼
   * 조건: 아래 모두 true 표시 되어야 활성화
   */ 
  const [PassWordState, setPassWordState] = useState({
      // numberCheck : false  // 인증번호 확인
    nameCheck   : false  // 이름 유무 + 2글자 이상
    , birthCheck  : false  // 생년월일 패턴
  })


  

  /**
   * 이름 확인 체크
   * 조건: 2글자 이상 작성
   */
  const handleNameInput = (e) => {
    const nameValue     = e.target.value;
    setName(nameValue)

    if (nameValue.length >= 2) {
      setPassWordState(prevState => ({ ...prevState, nameCheck: true }));
      document.querySelector('#feedtxt2').classList.add('password-match'); 
    } 
    else {
      setPassWordState(prevState => ({ ...prevState, nameCheck: false }));
      document.querySelector('#feedtxt2').classList.remove('password-match'); 
    }
  };

  /**
   * 생년월일 확인 체크
   * 조건: 생년월일 정규식 확인
   */
  const handleBirthInput  = (e) => {
    let birthValue        = e.target.value;
    birthValue            = birthValue.replace(/\D/g, ''); // 숫자 이외의 문자 제거
    setBirthday(birthValue)

    if (birthValue.length > 8) {
      birthValue = birthValue.slice(0, 8); // 최대 길이를 8자
    }

    if (birthValue.length >= 6) {
      birthValue = birthValue.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3'); // 형식 변환
    }

    e.target.value = birthValue; // 입력 필드에 변환 값 설정
    

    // 유효성 확인
    if (birthValue.match(/(19|20)\d{2}\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])/)) {
      setPassWordState(prevState => ({ ...prevState, birthCheck: true }));
      document.getElementById('feedtxt3').classList.add('password-match'); 
    } 
    else {
      setPassWordState(prevState => ({ ...prevState, birthCheck: false }));
      document.getElementById('feedtxt3').classList.remove('password-match');
    }
  };
  
  /**
   * 정규 가입 회원 비밀번호 재발급 완료버튼
   */
  const handlePostUserPassWordRequest = () => {
    const userInfoPW = {
        mobile
      , name
      , birthday
      // , confirmNum
    }
    navigate("/app/pw_new_reissue"
    , { state: { userInfoPW }
    , replace: true });
  };

  /**
   * 임시 가입 회원 
   */
  const handleImsiPassword = () => {
    const imsiUserInfo = {
      mobile
    }
    navigate("/app/pw_tel_number"
    , { state: { mobile }
    , replace: true });
  }
  

  return (
    <div id="sub_common_wrap">
      {
        /**
         * 시스템 모달 팝업 창
         */
        system && (
          <SystemModal system={system} setSystem={setSystem}>
            {popupTxt}
          </SystemModal>
        )
      }

      <SubHead  SubBgColor={'#fff'} bottomLine={true}>
        비밀번호 재발급 & 정규가입
      </SubHead>

      <section>
        {/* 전화번호 인증 */}
        <TelNumberInput   
          mobile={mobile} 
          setMobile={setMobile}
          pwState={pwState}
          setPwState={setPwState}
          setPopupTxt={setPopupTxt}
          setSystem={setSystem}
          setJoinType={setJoinType}
        />

        {
          joinType === 'Y' &&
          <>
            <PassWordInputs>
              <div>
                <p>이름</p>
                <Feedtxt id="feedtxt2">
                  {
                    PassWordState.nameCheck ?
                      "입력 완료되었습니다."
                      : "이름은 2글자 이상 입력하세요."
                  }
                </Feedtxt>
                <input
                  type="text"
                  value={name}
                  placeholder="이름을 입력해주세요."
                  onChange={handleNameInput}
                />
              </div>
            </PassWordInputs>

            <PassWordInputs>
              <div>
                <p>생년월일</p>
                <Feedtxt id="feedtxt3">
                  {
                    PassWordState.birthCheck ?
                      "입력 완료되었습니다."
                      : "정확한 생년월일을 입력해주세요."
                  }
                </Feedtxt>
                <input
                  type="text"
                  placeholder="0000.00.00"
                  onChange={handleBirthInput}
                />
              </div>
            </PassWordInputs>
          </>
        }

        <BtnPassWord id="wrap32">
          <BtnBottom
            disabled={
              joinType === 'Y' ? 
              !(
                !pwState
                && PassWordState.nameCheck
                && PassWordState.birthCheck 
              )
              : pwState
            }
            onClick={joinType === 'Y' ? handlePostUserPassWordRequest : handleImsiPassword}>
            완료
          </BtnBottom>
        </BtnPassWord>
      </section>
    </div>
  );
};



const PassWordInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    background:#fff;
  }
`;

const Feedtxt = styled.p`
  font-size: 12px !important;
  margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const BtnPassWord = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  padding-bottom:40px;
`;



export default PwReissue;
