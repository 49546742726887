import React from 'react';
import styled from 'styled-components';
import { ediya, gifticon, mapicon, moor, no_store, p_icon, pointicon, stampicon, telicon, tooltip_tail } from '../../../../assets/images';
import { formatCurrency } from '../../common/function/FormatNumber';
import CommonIconList from '../../common/CommonIconList';
import Help from '../../common/Help';
import { useNavigate } from 'react-router';

const StoreItem = ({
    sid
  , earn
  , store
  , storeImg
  , starCount
  , Icons
  , pointUse
  , stampUse
  , rpsUse
  , perCent
  , stamp
  , rps
  , city
  , gungu
  , dong
  , sectorName
  , pavail
  , savail
  , ravail
}) => {
  /**
   * earn       : 유형
   * store      : 가게 이름
   * storeImg   : 스토어 이미지
   * starCount  : 별점 
   * Icons      : ['hot', 'recom', 'top', 'celebs', 'new'] 리스트 형태
   * pointUse   : 포인트 사용여부
   * stampUse   : 스탬프 사용여부
   * rpsUse     : 경품 사용여부
   * perCent    : 적립 % 
   * stamp      : 스탬프
   * rps        : 경품
   */

  const BASE_IMG_URL = "https://dev1.touchall.co.kr";
  
  const navigate = useNavigate();
  const handleUsing = () => {
    navigate("/app/useinfo_store", {state: {sid: sid}});
  }
  

  return (
    <StoreWrap>
      <StoreListWrap>
        <div>
          <div className='store_tit'>
            <h3>{store}</h3>
            <p className={`${starCount === 0 ? 'no_star_count' : ''}`}>
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.73438 12.8789L4.2793 8.13477L0.259766 5.22266H5.22266L6.75391 0.492188L8.29883 5.22266H13.2754L9.24219 8.13477L10.8008 12.8789L6.75391 9.95312L2.73438 12.8789Z" fill="#FF5F01"/>
              </svg>
              {starCount.toFixed(1)}
            </p>
          </div>

          {/* 가맹점 뱃지 */}
          <IconMap>
            <p><em><img src={mapicon} alt='지도' /></em>지도</p>
            <CommonIconList Icons={Icons} />
          </IconMap>
          

          <div className={`percent ${earn === "T" ? "type1" : earn === "G" ? "type2" : "type3"}`}>
            <em>{earn === "T" ? "통합" : earn === "G" ? "그룹" : "단독"}</em>
            
            {pointUse === 'Y' && <p className='pp'><img src={pointicon} alt='포인트' />{perCent.toFixed(1)}%</p>}
            {stampUse === 'Y' && <p className='ss'><img src={stampicon} alt='스탬프' />{formatCurrency(stamp)}당 1S</p>}
            {rpsUse === 'Y' && <p className='gg'><img src={gifticon} alt='경품' />{formatCurrency(rps)}당 1G</p>}
          </div>

          <p className='adress'>{city?.substr(0,2)} - {gungu} - {dong}  &nbsp;|&nbsp;  {sectorName}</p>

        </div>

        <RightBtn>
          <button><img src={moor} alt='매장상세' /></button>
          <div className='storeimg'>
            <img src={storeImg ? BASE_IMG_URL + storeImg : no_store} alt={store} />
          </div>
        </RightBtn>
      </StoreListWrap>

      <PayBtn>
        <div>
          <h3>이 매장에서의 가용 보너스<Help /></h3>
          <ul>
            { pointUse === 'Y' && <li className='pu'><img src={p_icon} alt='현금' />{Number(pavail).toLocaleString()}</li> }
            { stampUse === 'Y' && <li className='su'><img src={stampicon} alt='스탬프' />{Number(savail).toLocaleString()}</li>}
            { rpsUse === 'Y' && <li className='ru'><img src={gifticon} alt='경품' />{Number(ravail).toLocaleString()}</li>}
          </ul>
        </div>
        
        <button onClick={handleUsing}>
          사용·<em className='pay'>Pay</em>
        </button>
      </PayBtn>
    </StoreWrap>
  );
};


const StoreWrap = styled.div`
width: calc(100% - 32px);
padding: 32px 16px;
border-bottom:1px solid #ddd; 
`
const StoreListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .store_tit {
    display: flex;
    justify-content: start;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #222;
    }

    p {
      display: flex;
      justify-content: start;
      align-items: flex-start;
      margin-left: 4px;

      font-size: 14px;
      color:#FF5F01;

      &.no_star_count {
        svg {
          path {
            fill: #aaa;
          }
        }

        color:#aaa;
      }
    }
  }

  .percent {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
    margin-top: 8px;

    &.type1{
      em {
        background: #5D0EF5;
      }
    }

    &.type2{
      em {
        background: #8E3D82;
      }
    }

    &.type3{
      em {
        background: #7F7F7F;
      }
    }


    em {
      color: #fff;
      padding: 1px 2px;
      
      border-radius: 2px;
      font-size: 14px;
    }

    p {
      font-size: 12px;

      display: flex;
      justify-content: start;
      align-items: center;
      gap: 2px;

      img {
        width: 16px;
      }

      &.pp{ color: #7B8531; }
      &.ss{ color: #EC635D; }
      &.gg{ color: #2860A7; }
    }
  }

  .adress {
    color:#8e8e8e;
    font-size: 14px;
    margin-top: 8px;
  }

  
`

const RightBtn = styled.div`
display: flex;
justify-content: start;
align-items: start;

.storeimg {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  overflow:hidden;
  width: 90px;
  // min-height:115px;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

`

const PayBtn = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 12px;

&>div{
  background:#FFF7F2;
  border:1px solid #FFC9AA;
  border-radius: 8px;
  padding: 8px 12px;
  width: calc(100% - 120px);

  position:relative;

  &::after {
    width: 20px;
    height:20px;
    display:block;
    position:absolute;
    right: -10.5px;
    top: 50%;
    margin-top: -10px;
    content:"";

    background:url(${tooltip_tail}) right center no-repeat;
  }


  h3 {
    color:#FF5F01;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight:bold;
    margin-bottom: 8px;
    
    em {
      margin-left: 4px;
    }
  }

  ul {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    li {
      display: flex;
      justify-content: start;
      align-items: center;
      gap:4px;

      font-size: 14px;

      img {
        width: 16px;
      }

      &.pu {
        color:#626D0D;
      }

      &.su {
        color:#FC1F39;
      }

      &.ru {
        color:#2860A7;
      }
    }
  }
}

button {
  background:#FE7A36;
  border-radius: 40px;
  padding: 8px 8px;
  color:#fff;
  font-size: 14px;

  em {
    color:#fff;
  }
}
`

const IconMap = styled.div`
display: flex;
justify-content: start;
align-items: center;
gap:8px;

p {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;
  margin-top: 8px;

}
`
export default StoreItem;