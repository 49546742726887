import React from 'react';
import ModalPopup from '../../common/ModalPopup';
import styled from 'styled-components';
import {close_button} from '../../../../assets/images'

const BonusPill = ({toggleModal}) => {
  return (
    <ModalPopup>
      <PillWrap>
        <button onClick={toggleModal}><img src={close_button} alt='닫기' /> </button>
        <h1>누적 보너스란?</h1>
        <p>
          이용 가맹점으로부터<br/>
          이미 정산되어<br/><br/>
          바로 현금대용 Pay 또는<br/>
          통장 입금될 수 있는<br/><br/>
          <em>가용 보너스</em>와<br/>
          정산 시기 미도래분<br/>
          <em>미정산 보너스 합계</em>를 말함
        </p>
      </PillWrap>
    </ModalPopup>
  );
};

const PillWrap = styled.div`
  position: relative;
  width: 260px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  button {
    position: absolute;
    right: 16px;
    top: 16px;
    
    img{
      width: 24px;
    height: 24px;
    }
    
  }

  h1 {
    width: 100%;
    height:74px;

    line-height: 74px;
    padding: 0 16px;

    font-size: 20px;
    color: #fff;
    background: #555;
  }

  p {
    padding: 24px 16px;
    line-height: 24px;

    em{
      color: #EC635D;
    }
  }
`
export default BonusPill;