import React, { useState, useEffect } from "react";  
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { pointState, phoneNumberState } from "../../../recoil/recoilState";
import { useRecoilState, useRecoilValue } from 'recoil';
import api from "../../../api/api";

function PointSaveRequest({ props }) { 
	
  const [data, setData] = useState(null);
	const [error, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  console.log(searchParams);
  const point = searchParams?.get('point');
  const [processType, setProcessType] = useState(null);
  const [pointInfo, setPointInfo] = useRecoilState(pointState);  
  console.log("searchParams :: ", point);


  const navigate = useNavigate();
  
  useEffect(() => {
    const performProcess = async () => {
      try {
        // 포인트 정보 설정
        setPointInfo(searchParams?.get('point'));

        // requestPointSaveScreen API 호출
        const requestPointSaveScreenURL = '/api/pointMngr/requestPointSaveScreen';
        console.log("requestPointSaveScreen URL:", requestPointSaveScreenURL);

        const requestData = {
          point: searchParams?.get('point')
        };

        const response = await axios.post(requestPointSaveScreenURL, requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log("response data:", response?.data?.data);

        // 데이터 설정
        setData(response?.data?.data);

        // 처리 타입 설정
        const responseType = response?.data?.data?.type;
        setProcessType(responseType);
        console.log("processType:", responseType);

        

        // 타입에 따른 분기 처리
        if (responseType === 3 || responseType === '3'|| responseType === 4 || responseType === '4') {
          // pointMngr/requestPointSaveProcess API 호출 (취소)
          const cancelURL = `/api/pointMngr/requestPointSaveProcess`;
          console.log("cancelURL:", cancelURL);

          const cancelData = await axios.post(cancelURL, {
            point: searchParams?.get('point'),
            // mobile: ''
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          console.log("point_save_process cancelData:", cancelData?.data);

          // 취소 화면으로 이동
          navigate('/point_cancel', { state: { cancelData: cancelData?.data } , replace: true});
          
        } else if (response?.data?.data?.store?.rpsUse == "N" || response?.data?.data?.receipt?.stlmMethodCode === "9"){
          // point_save_request_screen으로 이동
          navigate('/point_save_result', { state: { data: response?.data?.data, gameResult: "" }, replace: true });
        } else {
          // point_save_request_screen으로 이동
          navigate('/point_save_request_screen', { state: { data: response?.data } , replace: true});
        }
      } catch (error) {
        // 에러 처리
        console.error('Error performing process:', error);
        setError(error);
      }
    };

    // performProcess 함수 실행
    performProcess();
  }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행

  
  return (
    <div style={{ backgroundColor: '#F5F5F5' }}>
      
    </div>
  );
}

export default PointSaveRequest;

