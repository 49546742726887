import { createBrowserHistory } from 'history';
import { useState, useEffect } from 'react';
import { useBodyScrollLock } from './BodyModalScroll';

const useModalWithBackButton = () => {
  /**
   * 뒤로가기를 했을 때 팝업 닫기 기능
   * ------------------------------------------------------------------
   * isModalVisible     : 팝업이 뜨는 여부 확인 (isModalVisible===true 일때 팝업 실행)
   * useBodyScrollLock  : 팝업이 떴을 때 배경 스크롤 방지 기능
   * history            : useHistory와 같은 기능
   * ------------------------------------------------------------------
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { lockScroll, openScroll } = useBodyScrollLock(); 
  const history = createBrowserHistory();

  useEffect(() => {
  
    if(isModalVisible){
      window.history.pushState(null, "", window.location.href);
    }

    const event = history.listen((listener) => {
      
      if (listener.action === "POP") {
        if (isModalVisible) {
          toggleModal();
        } 
      }
    });
    return event;
  }, [isModalVisible]);

  const toggleModal = () => {
    // setIsModalVisible((prevState) => !prevState);
    setIsModalVisible((prevState) => {
      if (!prevState) {
        lockScroll();
      } else {
        openScroll();
      }
      return !prevState;
    });
  };

  return [isModalVisible, toggleModal];
};

export default useModalWithBackButton;
