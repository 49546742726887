import React, { useState } from "react";
import styled from "styled-components";

import WebStampTit from "./common/WebStampTit";


function StampStanby({ data, rpsUse }) {
  // const storeInfo = data?.store;  
  // const pointInfo = data?.point; 

  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'>
        <WebStampTit data={data} state={0} />

        {
          rpsUse === 'Y' && <h6>가위바위보 미진행시 적립불가</h6>
        }
      </div>          
    </BonusSUWrap>
  );
}

const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
  padding: 0 16px;

  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

    h6 {
      color: #E95513;  
      font-weight: 500;
      margin-top: 5px;
      font-size: 18px;
      text-align:right;
    }
  }

  
`

export default StampStanby;
