import React from 'react';
import styled from "styled-components";
import {
  header
} from "../../../assets/images";

function Header() {
  return (    
    <BorderContainer>
      <HeaderImage src={header} />
    </BorderContainer>    
  );
}

const BorderContainer = styled.div`
  text-align:center;
`;

const HeaderImage = styled.img`
  width: 320px;
  height: 215px;
  margin-top: 20px;
  margin:0 auto;
`;

export default Header;
