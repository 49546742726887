import React from 'react';
import styled from 'styled-components';
import {
  checkon
  , checkoff
} from "../../../../assets/images";
import { useNavigate } from 'react-router';

const MyinfoBonus = ({userInfo}) => {
  const navigate = useNavigate();
  const handleBaseInfoEdit = (url) => {
    navigate(url);
  }

  return (
    <UserBaseWrap className='base'>
      <UserBaseTxt className='batxt'>
        <h3>보너스 사용</h3>
        <button onClick={() => handleBaseInfoEdit("/app/mypage_view_pay_edit")}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.93855 10.8078C9.21305 10.8078 10.2462 9.77458 10.2462 8.50008C10.2462 7.22557 9.21305 6.19238 7.93855 6.19238C6.66405 6.19238 5.63086 7.22557 5.63086 8.50008C5.63086 9.77458 6.66405 10.8078 7.93855 10.8078Z" stroke="#888888" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.7217 5.24962L14.1448 4.25038C13.8263 3.69827 13.1207 3.50962 12.5686 3.82808L12.2652 4.00346C11.1113 4.66923 9.669 3.83673 9.669 2.50462V2.15385C9.669 1.51635 9.15265 1 8.51515 1H7.3613C6.7238 1 6.20746 1.51635 6.20746 2.15385V2.50462C6.20746 3.83673 4.76515 4.66981 3.6113 4.00346L3.30784 3.82808C2.75573 3.50962 2.05015 3.69827 1.73169 4.25038L1.15477 5.24962C0.836304 5.80173 1.02496 6.50731 1.57707 6.82577L1.88053 7.00115C3.03438 7.6675 3.03438 9.3325 1.88053 9.99885L1.57707 10.1742C1.02496 10.4927 0.836304 11.1983 1.15477 11.7504L1.73169 12.7496C2.05015 13.3017 2.75573 13.4904 3.30784 13.1719L3.6113 12.9965C4.76515 12.3302 6.20746 13.1633 6.20746 14.4954V14.8462C6.20746 15.4837 6.7238 16 7.3613 16H8.51515C9.15265 16 9.669 15.4837 9.669 14.8462V14.4954C9.669 13.1633 11.1113 12.3302 12.2652 12.9965L12.5686 13.1719C13.1207 13.4904 13.8263 13.3017 14.1448 12.7496L14.7217 11.7504C15.0401 11.1983 14.8515 10.4927 14.2994 10.1742L13.9959 9.99885C12.8421 9.3325 12.8421 7.6675 13.9959 7.00115L14.2994 6.82577C14.8515 6.50731 15.0407 5.80173 14.7217 5.24962Z" stroke="#888888" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          수정
        </button>
      </UserBaseTxt>
      <ul>
        <li>
          <label>
            <input type='radio' name='bonus_use' />
            <em></em>
            <p>현금 대용 사용·결제 Pay로 사용</p>
          </label>
        </li>
        <li>
          <label>
            <input type='radio' name='bonus_use' />
            <em></em>
            <p>통장입금</p>
          </label>

          <span>{userInfo?.bankName} {userInfo?.bank} {userInfo?.holder}</span>
        </li>
      </ul>
    </UserBaseWrap>
  );
};

const UserBaseWrap = styled.div`
width: calc(100% - 32px);
padding: 36px 16px;
border-bottom: 1px solid #ddd;

ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 12px;

  margin-top: 24px;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    
    label{
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 4px;

      font-size: 16px;
    }
    

    input[type='radio'] {
      display: none;
    }
    
    /* 체크박스 커스텀 이미지 적용 */
    input[type='radio'] + em {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(${checkoff});
      background-size: cover;
    }
    
    /* 체크된 상태의 이미지 적용 */
    input[type='radio']:checked + em {
      background-image: url(${checkon});
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 14px;
      padding: 8px 12px;
      background:#f5f5f5;
      margin: 8px 0 0 20px
    }
  }
}
`

const UserBaseTxt = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

h3 {
  font-size: 18px;
  font-weight: 700;
}

button {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  color: #FE7A36;


  svg {
    path{
      stroke: #FE7A36;
    }
  }
}
`

export default MyinfoBonus;