import axios from 'axios';
import React, { useEffect } from 'react';

// 토큰을 설정하는 함수
const setAxiosToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("Usertokenkey");
    localStorage.removeItem("UserRefreshtokenkey");
  }
};

// 토큰을 헤더에 설정
export const handleHeaderToken = () => {
  const userToken = localStorage.getItem("Usertokenkey");
  setAxiosToken(userToken); 
}

// 로그아웃 시 토큰 제거
export const handleLogout = () => {
  setAxiosToken(null); 
};