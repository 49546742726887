import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { 
    arr_right_blue
  , arr_right_orange
  , bottom_arr_select
  , checkoff, checkon 
} from '../../../../assets/images';
import { useRecoilState } from 'recoil';
import { UserInfoResult } from '../../../../recoil/recoilState';
import BtnBottom from '../BtnBottom';
import axios from 'axios';
import SelectInput from '../usinginfo/SelectInput';

const JoinSelectInputs = () => {
  const [selectInfo, setSelectInfo] = useState({
      gender  : ''
    , cityCode: ''
    , areaCode: ''
    , dongCode: ''
    , email   : ''
  }) // 변경된 유저 정보

  /**
   * 유저정보 변경 로직
   * @param {*} key   : 변경할 key
   * @param {*} value : 변경할 value
   */
  const handleSelectChange = (key, value) => {
    setSelectInfo({
      ...selectInfo,
      [key]: value
    });
  };

  /**
   * 검색 조건 불러오기
   */
  const [searchData, setSearchData] = useState([]);
  const [codeList, setCodeList] = useState([]);

  const getCityAddress = async () => {
    const data = {
      "type" : "PAY"
    }
    try{
      const response = await axios.post('/api/codeMngr/getSearchCodeList', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('검색 pay ::' , response?.data?.data?.message);
      setSearchData(response?.data?.data?.message);

      /**
       * pcode 분류
       */
      const groupedData = response?.data?.data?.message.reduce((acc, item) => {
        if (!acc[item.pcode]) {
          acc[item.pcode] = [];
        }
        acc[item.pcode].push(item);
        return acc;
      }, {});

      console.log('groupedData', groupedData)
      setCodeList(groupedData);

    } catch (error){

    }
  }

  useEffect(()=>{
    getCityAddress();
  },[])

  const [detailArea, setDetailArea] = useState({}) // 구/근
  const [detailDong, setDetailDong] = useState({}) // 동/읍/리

  useEffect(()=>{
    console.log("detailArea", detailArea)
    console.log("detailDong", detailDong)
  },[detailArea, detailDong])

  
  useEffect(()=>{
    setSelectInfo((prevState) => ({
      ...prevState,
      areaCode: "",
      dongCode: ""
    }));
  },[selectInfo.cityCode])

  useEffect(()=>{
    setSelectInfo((prevState) => ({
      ...prevState,
      dongCode: ""
    }));
  },[selectInfo.areaCode])
  


  return (
    <InputsWrap>
      <h2>
        2. <strong>터치올</strong> 특별 학점 가산 회원 정보
      </h2>

      <ul>
        <li><img src={arr_right_blue} /> 메일 포함 3가지 등록</li>
        <li><img src={arr_right_orange} /> 가산점 가장 큼</li>
      </ul>

      <JoinInputs>
          <div>
            <p>성별</p>
            <ul> 
              <li>
                <label>
                  <input 
                    type='radio' 
                    name='gender' 
                    value='F' 
                    checked={selectInfo.gender === 'F'}  
                    onChange={(e)=>handleSelectChange('gender', e.target.value)} 
                  />
                  <em></em>
                  <p>여자</p>
                </label>
              </li>

              <li>
                <label>
                  <input 
                    type='radio' 
                    name='gender' 
                    value='M' 
                    checked={selectInfo.gender === 'M'} 
                    onChange={(e)=>handleSelectChange('gender', e.target.value)} 
                  />
                  <em></em>
                  <p>남자</p>
                </label>
              </li>
              </ul>
          </div>
        </JoinInputs>

        <JoinInputs>
          <div>
            <p>주요 소재지</p>
            
            <ol>
              <li>
                <SelectInput
                  setDetailArea   ={setDetailArea}
                  setDetailDong   ={setDetailDong}
                  handlesearchList={handleSelectChange}
                  selectName      ="시/도"
                  selectList      ={codeList?.AREA_CODE?.[0]?.listCode}
                />
              </li>

              <li>
                <SelectInput 
                  setDetailArea   ={setDetailArea}
                  setDetailDong   ={setDetailDong}
                  handlesearchList={handleSelectChange}
                  selectName      ="구/군"
                  selectList      ={detailArea && detailArea}
                  disabled        ={Object.keys(detailArea).length === 0}
                />
              </li>

              <li>
                <SelectInput 
                  setDetailArea   ={setDetailArea}
                  setDetailDong   ={setDetailDong}
                  handlesearchList={handleSelectChange}
                  selectName      ="동/읍/면"
                  selectList      ={detailDong && detailDong}
                  disabled        ={Object.keys(detailDong).length === 0}
                />
              </li>

              {/* <li><input 
                type='text' 
                name='loca4' 
                readOnly 
                placeholder='동/읍/
              면/리'/> 
                <em><img src={bottom_arr_select} /></em>
              </li> */}
            </ol>
          </div>
        </JoinInputs>

        <JoinInputs>
          <div>
            <p>이메일</p>
            <input 
              type='text' 
              name='email' 
              placeholder='이메일 주소'  
              value={selectInfo.email}
              onChange={(e)=>handleSelectChange('email', e.target.value)}
            />
          </div>
        </JoinInputs>

        <AgreeBtn>
          <BtnBottom>저장</BtnBottom>
        </AgreeBtn>
    </InputsWrap>
  );
};

const InputsWrap = styled.div`
width: calc(100% - 32px);
padding: 32px 16px;
background:#F6F6F6;

h2 {
  font-size: 20px;
  font-weight:bold;
  margin-bottom: 20px;

  strong {
    color:#FE7A36;
  }
}
&>ul {
  li {
    &:first-child {
      font-weight:bold;
      color:#0167FF;
    }

    margin-bottom: 12px;
    color:#FF5F01;


    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }
}
`

const JoinInputs = styled.div`
width: 100%;
margin-top: 32px;

&>div > p {
  font-size: 14px;
  color: #222;
  font-weight: 700;
  margin-bottom: 12px;
}

ul{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  input[type='radio'] {
    display: none;
  }

  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + em {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }

  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + em {
    background-image: url(${checkon});
  }

  li {
    width: 100%;
    height: 38px;

    background:#fff;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    padding:0 16px;

    label{
      height: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      font-size: 14px;

      &:has(input[type='radio']:checked ){
        background:#fff;

        p {
          color:#FE7A36;
          font-weight:bold;
          font-size: 14px;
        }
      }
    }
    
  }
}

input[name='email'] {
  width: calc(100% - 32px);
  height: 38px; 

  font-size: 14px;
  background:#fff;
  border: 1px solid #BDBDBD;
  padding: 0 16px;

  border-radius: 8px;
}

ol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  li {
    width: calc(33.33% - 6px);
    height: 16px;

    border-radius: 8px;
    background:#fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 0;

    input {
      font-size: 16px;
    }
  }
}
`

const AgreeBtn = styled.div`
  margin-top: 16px;

  width: 100%;
  padding: 20px 0 0px 0;

`;
export default JoinSelectInputs;