import React from "react";
import styled from "styled-components";

import {
  ad
} from "../../../assets/images";

function AppDownload() {

  const userAgent = navigator.userAgent.toLowerCase(); //userAgent 문자열 값 받아오기
  console.log("AppDownload Page  userAgent:: ", userAgent);
  
  if (userAgent.indexOf("android") > -1) {

    window.location.href = "https://toucholl-app.s3.ap-northeast-2.amazonaws.com/toucholl.apk";
  } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 || 
      userAgent.indexOf("ipod") > -1 
    ) {
      window.location.href = "https://testflight.apple.com/v1/app/6449457816?build=133086662";
  } else {    
    //아이폰, 안드로이드가 아닐 때 실행할 동작
  }

  return (
    <BorderContainer>
      <div>
        <WonText></WonText>
      </div>
    </BorderContainer>
  );
}

const BorderContainer = styled.div`  
  display: flex;
  justify-content: center;  
`;

const WonText = styled.div`    
  color: #707070;  
  font-weight: 600;
  margin-top: 5px;
  font-size: 16px;
  margin-left: 3px;
`;

export default AppDownload;
