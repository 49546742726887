import React, { useState } from 'react';
import styled from 'styled-components';
import { not_member, sgold, slash, stamp_logo } from '../../../../assets/images';

import { useNavigate } from 'react-router';
import EarnIcon from '../EarnIcon';
import StampUseDetail from './StampUseDetail';
import StampQPopup from '../../TouchOll/StampQPopup';
import useModalWithBackButton from '../function/HistoryModal';
import ModalPopup from '../ModalPopup';
import SystemPopup from '../SystemPopup';
import PSGicon from '../PSGicon';

const StampUseScreen = ({
  storeInfo
, pointInfo
, useType
, receiptInfo
, isApp
}) => {
  /**
   * storeInfo : 스토어 정보
   * pointInfo : 포인트 정보
   * type : 스탬프 적립 | 적립 취소 | 스탬프 사용 | 사용 취소
   */
  const earnType               = storeInfo?.storeEarn || 'T'; // earn 유형
  const storeName              = storeInfo?.storeName || "가맹점 이름"
  const sumCashBonusStamp      = Number(pointInfo?.sumCashBonusStamp).toLocaleString();
  const cancelSumCashBonusStamp = Number(pointInfo?.cancelSumCashBonusStamp).toLocaleString();
  const cancelSumStamp         = Number(pointInfo?.cancelSumStamp).toLocaleString();
  const sttleStamp             = Number(pointInfo?.sttleStamp).toLocaleString();
  const cancelSttleStamp       = Number(pointInfo?.cancelSttleStamp).toLocaleString();
  const cashBonusStamp         = Number(pointInfo?.cashBonusStamp).toLocaleString(); 
  const cancelCashBonusStamp   = Number(pointInfo?.cancelCashBonusStamp).toLocaleString(); 
  const cashGradeBonusStamp    = Number(pointInfo?.cashGradeBonusStamp).toLocaleString(); // 장학금
  const cancelCashGradeBonusStamp = Number(pointInfo?.cancelCashGradeBonusStamp).toLocaleString(); // 장학금 취소
  const sttleBounsStamp        = Number(pointInfo?.sttleStamp + pointInfo?.cashBonusStamp).toLocaleString();
  const cancelSttleBounsStamp  = Number(pointInfo?.cancelSttleStamp + pointInfo?.cancelCashBonusStamp).toLocaleString();

  const cancelUseStamp         = Number(pointInfo?.cancelUseStamp).toLocaleString(); // 장학금
  const useStamp               = Number(pointInfo?.useStamp).toLocaleString(); // 사용
  const grade                  = pointInfo?.grade
  const userName               = pointInfo?.userName
  const type                   = useType
  const kind                   = pointInfo?.kind  

  const navigate = useNavigate();

  const [isModal1Visible, toggleModal1] = useModalWithBackButton(); 

  const [joinPopup, setJoinPopup] = useState(false);
  const handleJoinPopup = () => {setJoinPopup(!joinPopup)}
  const handleAppDownload = () => {navigate('/app_download');};

  return (
    <>
    <StampWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>
      <h3 className='ptt'>
        터치올<em>-</em>
        <span>
        {
          kind === "S" ? "스탬프" : "수동 스탬프"
        }
        
        </span>
        <PSGicon type={'s'} />
        <StampQPopup />
      </h3>

      <h2 className={`type${type}`}>
        <p>
          {
          type === "1" ? "적립 완료" : 
          type === "2" ? "스탬프 사용은 적립금이 없습니다." : 
          type === "3" ? "적립 취소" : 
                         "스탬프 사용은 적립금이 없습니다." 
          }
        </p>

      </h2>
        
        {
          type === "1" || type === "3" ? (
            <PaySlash>
              <p className={`type${type}`}>
                <strong>
                  {type === "1" 
                    ? sttleBounsStamp
                    : cancelSttleBounsStamp}
                </strong>개
              </p>

              <ul className={`type${type}`}>
                <li>
                  <span>스탬프 <PSGicon type={'s'} /></span>
                  <p>
                    {type === "1" 
                      ? sttleStamp
                      : cancelSttleStamp}
                    개
                  </p>
                </li>
                <li>
                  <span>추가적립 <PSGicon type={'s'} /></span>
                  <p>
                    {type === "1"
                      ? cashBonusStamp
                      : cancelCashBonusStamp}
                    개
                  </p>
                </li>

              </ul>
            </PaySlash>
          ) : (
            <></>
          )
        }


        {
          type === "1" || type === "3" ? (
            <div className='grade_point'>
            <span>장학금 <img src={sgold} alt={kind} /></span>
            <p>{type === "1" ? cashGradeBonusStamp : cancelCashGradeBonusStamp}개</p>
          </div>
          ) : (
            <></>
          )
        }

        {
          type === "1" || type === "3" ? (
            <h2 className={`type${type} sum_point`}>
              <p>합계</p>
              <p>
                <img src={stamp_logo} />{" "}
                <em>
                  <strong>
                    {type === "1" ? sumCashBonusStamp : cancelSumCashBonusStamp}
                  </strong>{" "}
                  개
                </em>
              </p>
            </h2>
          ) : (
            <div className='margin32'></div>
          )
        }
      
        {
          userName === "고객" ?
            <p><img src={not_member} onClick={handleJoinPopup} /></p> :
            (type === "1" || type === "3") ?
              <p>
                <em>{userName}님</em> 은 현재
                <br />
                터치올 대학 <em>{grade}학년</em>으로
                <br />
                <br />
                장학금{" "}
                {type === "1" ? cashGradeBonusStamp : cancelSumCashBonusStamp}
                개{type === "1" ? " 더 추가됨" : " 더 취소됨"}
              </p>
              : ""

        }

      <ol>
      <li><button onClick={toggleModal1}>세부내역 확인</button></li>
        {/* {
          type === "1" || type === "3" ?  
          (<li><button onClick={toggleModal1}>세부내역 확인</button></li>)
          : ''
        } */}
        {
          isApp === "W" ? <></>
          : <li><button onClick={() => navigate('/app/useinfo', {replace: true})}>MY 이용내역 · 후기 평가</button></li>
        }
      </ol>
    </StampWrap>

    {
      isModal1Visible &&
      (
        <ModalPopup>
        <StampUseDetail  
          toggleModal       ={toggleModal1}
          earnType          ={earnType}
          storeName         ={storeName}
          sttleStamp        ={sttleStamp}
          userName          ={userName}
          grade             ={grade}
          sumCashBonusStamp ={type === "1" || type === "2" ? sumCashBonusStamp : cancelSumCashBonusStamp}
          pointInfo         ={pointInfo}
          type              ={type}
          receiptInfo       ={receiptInfo}
          kind              ={kind}
        />
        </ModalPopup>
      )
    }

    {/* 초간편 가입 권유 팝업 추가 */}
    { joinPopup &&
          <ModalPopup>
            <SystemPopup>
              <p>
                초간편 이용자에<br />
                가입하시겠습니까?
              </p>
              
              <PopupBtn>
                <li onClick={handleAppDownload}>
                  Yes
                </li>
                <li onClick={handleJoinPopup}>다음에</li>
              </PopupBtn>
            </SystemPopup>
          </ModalPopup>
        }
    </>
  );
};

const StampWrap = styled.div`
.type1, .type2{
  color: #FD4D61 !important;

  em, p, strong {
    color: #FD4D61 !important;
  }

}

.type3, .type4{
  color: #FB0E0E !important;

  em, p, strong {
    color: #FB0E0E !important;
  }

}

  width: calc(100% - 36px);
  padding: 16px;
  background: #fff;
  border: 2px solid #CBCBCB;
  border-radius: 12px;

  .margin32 {
    margin-bottom: 32px;
  }

  // 가게 상호
  h1{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  // 터치올 스탬프
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FE7A36;

    margin-top: 24px;

    display: flex;
    justify-content: start;
    align-items: center;
    gap:4px;

    em {
      color: #909090;
    }

    span {
      color: #FD4D61;

      display: flex;
      justify-content: start;
      align-items: center;
      

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }

  }

  // 입금 적립 상태
  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    // margin-bottom: 32px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-size: 30px;
    }

    p:last-child {
      display: flex;
      justify-content: start;
      align-items: center;
      
      img {
        margin-right:8px;
      }
    }
  }

  .sum_point {
    width: calc(100% - 32px);
    padding: 0 16px 8px 16px;
    margin-bottom: 32px;

    margin-top: 32px;

    border-bottom: 1px solid #707070;

    p {
      color:#707070 !important;
      font-size: 16px;
    }

  }

  .grade_point {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: calc(100% - 32px);
    padding: 0 16px;
    height: 36px;
    background:#EAC234;
    border-radius: 8px;
    margin-top: 16px;

    span, p {
      color:#fff;
      font-weight:bold;
    }
    span{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:4px;
    }

  }

  // 스탬프 사용, 취소시 
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;

    em {
      vertical-align: middle;
    }
  }

  // 현금, 장학금
  ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:stretch;
    gap: 4px;

    li{
      border-radius: 8px;
      border: 1px solid #7B8531;

      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
      padding:  8px 16px;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }

      span {
        display: flex;
        justify-content: start;
        align-items: center;
        color: #7B8531;
        font-weight: bold;
      }

      p{
        font-weight: bold;
      }
    }
    
  }

  // 000님 
  &>p {
    text-align: center;
    line-height: 19px;

    font-weight: bold;
    padding: 0 0 24px 0;

    em {
      color: #5D0EF5;
    }

    img {
      width: 100%;
    }
  }

  // 버튼
  ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    li {
      width: 100%;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: #9A65FF;
      border-radius: 4px;

      &:last-child{
        background: #2860A7;
      }

      button {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        width: 100%;
        height: 100%;
      }
    }
  }

  
`

const PaySlash = styled.div`

  background:url(${slash}) no-repeat;
  font-size: 14px;
  font-weight: bold;
  background-size: 100% 100%;
  padding: 0 8px 11px 8px;
  margin-top: 8px;

  strong {
    font-size: 30px;
  }

  &>p {
    height: 50px;
    line-height: 50px;
    text-align: right;
    // padding-right: 16px;
    margin-bottom: 8px;
  }

  // 현금, 장학금
  ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:stretch;
    gap: 4px;
  

    li{
      border-radius: 8px;
      border: 1px solid #7B8531;

      font-size: 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      line-height: 30px;

      padding:  0px 8px;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }

      span {
        display: flex;
        justify-content: start;
        align-items: center;
        color: #FD4D61;
        font-weight: bold;
      }

      p{
        font-weight: bold;
      }
    }
    
  }

`


const PopupBtn = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 30px;

  li {

    &:first-child {
      background:#FF5F01;
      color:#fff;
      font-weight: 700;
    }

    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background:#eee;
    border-radius: 8px;
  }

  
`
export default StampUseScreen;