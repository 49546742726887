import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const PopupModal = ({ state, setState, children }) => {

  useEffect(()=>{
    state ? window.history.pushState({ modal: true }) : window.history.back()
  },[state])

  const closeModal = () => {
    setState(false);
    window.history.back();
  } 

  return (
    <>
      {window.history.state?.modal && (
        <ModalWrap>
          <Content>
            {children}
          </Content>

          <DimBG onClick={closeModal}></DimBG>
        </ModalWrap>
      )}
    </>
  );
};

const ModalWrap = styled.div`
  position: fixed;
  left:0;
  top:0;

  z-index: 999;
`

const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  z-index: 9999;
`

const DimBG = styled.div`
  width: 100vw;
  height: 100vh;

  position: relative;
  left:0;
  top:0;

  background: rgba(0,0,0,0.5);
  z-index: 999;
`
export default PopupModal;