import React from 'react';
import styled from 'styled-components';
import { header } from '../../../../assets/images';

const TopImg = () => {
  return (
    <TopWrap>
      <img src={header} alt='터치올 배너' />
    </TopWrap>
  );
};

const TopWrap = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: start;

  img {
    width: 100%;
    height: auto;

    max-width: 360px;
  }
`

export default TopImg;