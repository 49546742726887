import React from 'react';
import styled from 'styled-components';
import { logo_Login } from '../../../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import BtnBottom from '../../../components/TouchOll/BtnBottom';

const JoinEnd = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userName = location.state.name;
  const userMobile = location.state.mobile;
  // const userName = '홍길동';

  return (
    <CenterLayout id="sub_common_wrap_f">
      <JoinEndIcon>
        <svg 
          xmlns     ="http://www.w3.org/2000/svg" 
          className ="svg-success" 
          viewBox   ="0 0 24 24"
        >
          <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
            <circle   className="success-circle-outline" cx="12" cy="12" r="11.5" />
            <circle   className="success-circle-fill"    cx="12" cy="12" r="11.5" />
            <polyline className="success-tick"           points="17,8.5 9.5,15.5 7,13" />

          </g>
        </svg>
      </JoinEndIcon>

      
      <WelcomeTxt>
        {userName}님
        <br /> <img src={logo_Login} alt='터치올' />
        <br /> 초간편 진짜 현금 보너스 
        <br /> & 전세계 최-초간편 Pay세상
        <br /> 가입을 환영합니다!
      </WelcomeTxt>

      <JoinEndBtn>
        <BtnBottom onClick={navigate('/app/join_select', {state: userMobile})}>
          확인
        </BtnBottom>
      </JoinEndBtn>
    </CenterLayout>
  );
};

const CenterLayout = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`
const JoinEndBtn = styled.div`
position: fixed;
bottom: 0;
width: calc(100% - 32px);
padding: 20px 16px 40px 16px;
`

const JoinEndIcon = styled.div`
.svg-success {
  display: inline-block;
  vertical-align: top;
  height: 100px;
  width: 100px;
  opacity: 1;
  overflow: visible;

  @keyframes success-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 31px;
      opacity: 1
    }
  }

  @keyframes success-circle-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 0px;
      opacity: 1
    }
  }

  @keyframes success-circle-fill {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .success-tick {
    fill: none;
    stroke-width: 1.5px;
    stroke: #FE7A36;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: success-tick 450ms ease 1200ms forwards;
    opacity: 0;
  }

  .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #FFF2EB;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: success-circle-outline 300ms ease-in-out 500ms forwards;
    opacity: 0;
  }

  .success-circle-fill {
    fill: #FFF2EB;
    stroke: none;
    opacity: 0;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
  }
`

const WelcomeTxt = styled.p`
font-size: 20px;
font-weight:700;
line-height: 28px;
text-align:center;
margin: 20px 0 150px 0;
color:#222;
opacity: 0;
animation: success-circle-fill 600ms ease-out 1100ms forwards;

img {
  width: auto;
  height: 22px;
  line-height: 28px;
  vertical-align: sub;
}

`

export default JoinEnd;