import React, { useState } from "react";
import styled from "styled-components";

import {
  close_button
  , stamp_description
  , stamp_logo
  , question_btn
} from "../../../assets/images";
import ModalPopup from "../common/ModalPopup";
import WebStampTit from "./common/WebStampTit";


function StampCancel({ data }) {

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleQuestionClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  const storeInfo = data?.store;
  const pointInfo = data?.point;

  return (

    <BonusSUWrap>
      <div className='cash_stamp_wrap'>
        <WebStampTit data={data} state={2} sumStamp={pointInfo?.cancelSumStamp} />
      

        {isPopupVisible && (
          <ModalPopup>
            <img
              src={stamp_description}
              style={{ width: '320px', height: '600px' }}
            />
            <CloseButton
              src={close_button}
              alt="Close"
              onClick={() => setPopupVisible(false)}
            />
          </ModalPopup>
        )}

      </div>
    </BonusSUWrap>

  );
}


const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
  padding: 0 16px;

  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

  }

  h1 {
    color: #2860a7;
    font-weight: 800;

    margin-right: 4px;
    vertical-align: middle;

    em {
      background-color: #5d0ef5;
      color: #ffffff;
      padding: 1px 5px 1px 5px;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  h3 {
    margin-top: 26px;

    font-size: 16px;
    font-weight: 700;
    color: #222;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2px;

    em {
      color: #FD4D61;

    }

    img {
      width: 20px;
      height: 20px;
    }

  }
`

const BonusSU = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

  }

  p {
    color: #FB0E0E;
    font-weight: 700;
    font-size: 14px;

    strong {
      color: #FB0E0E;
      font-weight: 700;
      font-size: 26px;
    }
  }
  
`


export default StampCancel;
