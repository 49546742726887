import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes  } from "react-router-dom";
import GlobalStyle from './views/components/common/GlobalStyle';

// 비앱 관련 링크
import PointSaveRequest       from './views/pages/save/PointSaveRequest';
import PointSaveRequestScreen from './views/pages/save/PointSaveRequestScreen';
import PointSaveResult        from './views/pages/result/PointSaveResult';
import AppDownload            from './views/pages/result/AppDownload';
import PointCancel            from './views/pages/cancel/PointCancel';
import PointSaveResultDetail  from './views/pages/result/PointSaveResultDetail';
import Home                   from './views/pages/home/home';

// App 관련 링크
import Login      from './views/pages/TouchOllAPp/Join/Login';
import JoinAgree  from './views/pages/TouchOllAPp/Join/JoinAgree';
import Join       from './views/pages/TouchOllAPp/Join/Join';
import JoinEnd    from './views/pages/TouchOllAPp/Join/JoinEnd';
import PwReissue from './views/pages/TouchOllAPp/Join/PwReissue';
import PwNewReissue from './views/pages/TouchOllAPp/Join/PwNewReissue';
import Notice from './views/pages/TouchOllAPp/board/Notice';
import QuestA from './views/pages/TouchOllAPp/board/QuestA';
import Event from './views/pages/TouchOllAPp/board/Event';
import MainPage from './views/pages/TouchOllAPp/Main/MainPage';
import StorePage from './views/pages/TouchOllAPp/store/StorePage';
import UseinfoPage from './views/pages/TouchOllAPp/usinginfo/UseinfoPage';
import MorePage from './views/pages/TouchOllAPp/more/MorePage';
import MyStoreInfo from './views/pages/storeAdmin/MyStoreInfo';
import MyInfoView from './views/pages/TouchOllAPp/mypage/MyInfoView';
import MyinfoEdit from './views/pages/TouchOllAPp/mypage/MyinfoEdit';
import MyinfoPayEdit from './views/pages/TouchOllAPp/mypage/MyinfoPayEdit';

import IconGuide from './views/pages/common/IconGuide';
import PointPayUse from './views/pages/common/payuse/PointPayUse';
import StampPayUse from './views/pages/common/payuse/StampPayUse';
import MyGrade from './views/pages/TouchOllAPp/mygrade/MyGrade';
import SettingPage from './views/pages/TouchOllAPp/more/SettingPage';
import PayUse from './views/pages/common/payuse/PayUse';
import UseinfoPay from './views/pages/TouchOllAPp/usinginfo/UseinfoPay';
import MyHot from './views/pages/TouchOllAPp/board/MyHot';
import ErrorPage from './views/pages/cancel/ErrorPage';
import UsingStore from './views/pages/TouchOllAPp/usinginfo/UsingStore';
import UsingPointStore from './views/pages/TouchOllAPp/usinginfo/UsingPointStore';
import UsingStampStore from './views/pages/TouchOllAPp/usinginfo/UsingStampStore';
import PayRequest from './views/pages/common/payuse/PayRequest';
import StampTypeOne from './views/pages/common/payuse/StampTypeOne';
import PointTypeOne from './views/pages/common/payuse/PointTypeOne';
import IosNfc from './views/components/common/function/IosNfc';
import OnlyReceipt from './views/pages/TouchOllAPp/usinginfo/OnlyReceipt';
import OnlyDetail from './views/pages/TouchOllAPp/usinginfo/OnlyDetail';
import OnlyRps from './views/pages/TouchOllAPp/usinginfo/OnlyRps';
import MyinfoSelectEdit from './views/pages/TouchOllAPp/mypage/MyinfoSelectEdit';
import ErrorNFC from './views/pages/common/ErrorNFC';
import JoinSelect from './views/pages/TouchOllAPp/Join/JoinSelect';
import PwTelNumber from './views/pages/TouchOllAPp/Join/PwTelNumber';
import OnlyType24 from './views/pages/TouchOllAPp/usinginfo/OnlyType24';
import axios from 'axios';
import NoMemberPage from './views/pages/common/NoMemberPage';
import StoreDetail from './views/pages/TouchOllAPp/store/StoreDetail';

function App() {
//  ios 일 경우에만 나타나게
const userAgent = navigator.userAgent.toLowerCase(); //userAgent 문자열 값 받아오기
const [iosNFC, setIosNFC] = useState(false);
useEffect(() => {
  if (
    userAgent.indexOf("iphone") > -1 ||
    userAgent.indexOf("ipad") > -1 ||
    userAgent.indexOf("ipod") > -1
  ) {
    setIosNFC(true);
  }
},[])

//  901번 오류 => refreshtoken(리프레쉬토큰)

/**
 * flutter에서 NFC 에러 관련 메세지를 서버에 저장
 * @param {*} msg : flutter 에러 메세지
 */
window.errorNFCMessage = (msg) => {
  const fetchError = async () => {
    const data = JSON.parse(msg);

    try{
      const response = await axios.post('/api/errMngr/saveErrorLog', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.error('Error errorNFCMessage data:', error);
    }
  }

  fetchError();
}


  return (
    <>
    <GlobalStyle />
    {/* {iosNFC && <IosNfc />} */}
    
    <Routes>
      {/* <Route path='/'                           element={<Home/>} /> */}
      {/* 웹(비앱) 관련 페이지 */}
      <Route path='/point_save_form'            element={<PointSaveRequest />} />
      <Route path='/point_save_request_screen'  element={<PointSaveRequestScreen />} />
      <Route path='/point_save_result'          element={<PointSaveResult />} />      
      <Route path='/point_save_result_detail'   element={<PointSaveResultDetail />} />
      <Route path='/app_download'               element={<AppDownload />} />
      <Route path='/point_cancel'               element={<PointCancel />} />
      <Route path='/error'                      element={<ErrorPage />} />

      {/* 앱 관련 페이지 */}
      <Route path='/app/main'                   element={<MainPage iosNFC={iosNFC} />} />
      <Route path='/'                           element={<Login />} />
      <Route path='/app/join_agree'             element={<JoinAgree />} />
      <Route path='/app/join'                   element={<Join />} />
      <Route path='/app/join_end'               element={<JoinEnd />} />
      <Route path='/app/join_select'            element={<JoinSelect />} />
      <Route path='/app/pw_reissue'             element={<PwReissue />} />
      <Route path='/app/pw_new_reissue'         element={<PwNewReissue />} />
      <Route path='/app/pw_tel_number'          element={<PwTelNumber />} />

      <Route path='/app/store'                  element={<StorePage />} />
      <Route path='/app/store_detail'           element={<StoreDetail />} />
      <Route path='/app/useinfo'                element={<UseinfoPage />} />
      <Route path='/app/useinfo_pay'            element={<UseinfoPay />} />
      <Route path='/app/useinfo_store'          element={<UsingStore iosNFC={iosNFC} />} />
      <Route path='/app/useinfo_store_point'    element={<UsingPointStore iosNFC={iosNFC} />} /> {/* 보너스 조회 사용 포인트 */}
      <Route path='/app/useinfo_store_stamp'    element={<UsingStampStore iosNFC={iosNFC} />} /> {/* 보너스 조회 사용 스탬프 */}

      <Route path='/app/more'                   element={<MorePage />} />
      <Route path='/app/more/setting'           element={<SettingPage />} />
      <Route path='/app/mypage_view'            element={<MyInfoView />} />
      <Route path='/app/mypage_view_edit'       element={<MyinfoEdit />} />
      <Route path='/app/mypage_view_pay_edit'   element={<MyinfoPayEdit />} />
      <Route path='/app/mypage_view_select_edit'element={<MyinfoSelectEdit />} />
      <Route path='/app/mygrade'                element={<MyGrade />} />

      <Route path='/app/board/notice'           element={<Notice />} />
      <Route path='/app/board/qa'               element={<QuestA />} />
      <Route path='/app/board/event'            element={<Event />} />
      <Route path='/app/board/myhot'             element={<MyHot />} />

      {/* 아이콘 가이드 페이지 */}
      <Route path='/app/icon_guide'             element={<IconGuide />} />

      {/* 디바이스 설정 변경 페이지 */}
      <Route path='/admin/mystore_info'         element={<MyStoreInfo iosNFC={iosNFC} />} />

      {/* 결제 관련 페이지 */}
      <Route path='/common/pay_result_request'  element={<PayRequest />} />
      <Route path='/common/pay_result'          element={<PayUse />} />
      <Route path='/common/point_result'        element={<PointPayUse />} />
      <Route path='/common/stamp_result'        element={<StampPayUse />} />
      <Route path='/common/stamp_type1'         element={<StampTypeOne />} />
      <Route path='/common/point_type1'         element={<PointTypeOne />} />

      {/* Only 가 붙은 페이지는 메인에서 이용내역의 영수증, 상세내역 등을 클릭했을 때 보이는 화면 */}
      <Route path='/app/receipt'                element={<OnlyReceipt />} />
      <Route path='/app/detail'                 element={<OnlyDetail />} />
      <Route path='/app/rpsdetail'              element={<OnlyRps />} />
      <Route path='/app/type24detail'           element={<OnlyType24 />} />

      {/* 플루터에서 전송하는 NFC에러를 받을 페이지 */}
      <Route path='/app/error_nfc'              element={<ErrorNFC />} />
      <Route path='/app/no_member'              element={<NoMemberPage />} />


    </Routes>
    </>
  );
}


export default App;