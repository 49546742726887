import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { logo, nfcimg } from '../../../assets/images';

const NFCpopup = ({setIsNFC}) => {
  /**
   * 모바일 기기 확인 
   * isMobile : true (모바일 기기)
   */
  const [isMobile, setIsMobile]   = useState(false); // 모바일 기기인지 확인
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  const handleNFCCancle = () => {
    if (isMobile){
      // eslint-disable-next-line no-undef
      cancleNFCBtn.postMessage("nfc=cancle");
    }

    setIsMobile(false);
  }

  return (
    <NfcPopup>
      <h2><img src={nfcimg} alt='nfc' /></h2>
      <p>
        스마트폰 <b>NFC</b> 기본모드 <br />
        확인 후 <img src={logo} alt='터치올' /> 단말에 <br />
        <strong>터치해주세요</strong>
      </p>

      <button onClick={handleNFCCancle}>취소</button>
    </NfcPopup>
  );
};

const NfcPopup = styled.div`
width: 90%;
min-width: 320px;

background:#fff;

padding: 28px 0 ;
border-radius: 12px;

h2 {
  width: 120px;

  img {
    width: 100%;
    height: auto;
  }
}

p{
  margin-top: 32px;
  font-size: 20px; 
  text-align:center;
  line-height: 28px;

  strong {
    color:#FE7A36;
    font-weight:bold;
  }

  b {
    font-weight:bold;
  }

  img {
    width: auto;
    height: 20px;
  }
}

button {
  margin-top: 28px;
  font-size: 16px;
  background:#ddd;
  width: 80px;
  height: 40px;

  border-radius: 8px;
  font-size: 16px;

}


display: flex;
justify-content: center;
align-items: center;
flex-direction:column;
`


export default NFCpopup;