import React from 'react';
import styled from "styled-components";
import { arr_gray_right } from '../../../../assets/images';

const BlankBtns = () => {
  const introUrl = "https://dev2.touchall.co.kr/intro";

  const handleEasyImageClick = () => {        
    window.open(introUrl + "/easy", "easy");
  }

  const handleCompareImageClick = () => {        
    window.open(introUrl + "/compare", "compare");
  }

  return (
    <BlackWrap>
      <li onClick={handleEasyImageClick} >
        타 적립 서비스와의 적립혜택 비교 
        <em><img src={arr_gray_right} alt="이동" /></em>
      </li>
      <li onClick={handleCompareImageClick}>
        터치올의 초간편성 비교 
        <em><img src={arr_gray_right} alt="이동" /></em>
      </li>
    </BlackWrap>
  );
};

const BlackWrap = styled.ul`  
  width: 100%;
  display: flex;
  flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 8px;

  li {
    width: calc(100% - 32px);
    height: 50px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

    font-size: 16px;
    font-weight: 700;
    color:#222;
    padding: 0 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default BlankBtns;