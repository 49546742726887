import React from 'react';
import ModalPopup from '../../common/ModalPopup';
import styled from 'styled-components';
import { close_button } from '../../../../assets/images';

const NoticePopup = ({isOpen, setIsOpen}) => {
  /**
   * 알림팝업 : 알립니다.
   */
  return (
    <ModalPopup>
      <Wrap>
        <button onClick={() => setIsOpen(!isOpen)}><img src={close_button} alt='닫기' /> </button>
        <h1>알립니다!</h1>
        <p>
          OOO님이 등록하신 폰 No - ID가,<br/>
          <em>다른 가입 폰 No와 중복입니다</em><br/><br/>

          과거, 폰 변경 후 No 미변경한<br/>
          분께 확인.변경 고지드렸으며<br/><br/>

          <span>회원님은 그와 무관하게<br/>
          별도 구분.관리되오니 계속 안심.<br/>
          이용하시면 됩니다.
          </span><br/><br/>

          <strong>참 조</strong><br/>
          정상 사용권 없는 폰 No 등록. 가입에<br/>
          따른 문제 발생 책임은 전적으로 <br/>
          당사자에 있습니다.

        </p>
      </Wrap>
    </ModalPopup>
  );
};

const Wrap = styled.div`
  position: relative;
  width: 260px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  button {
    position: absolute;
    right: 16px;
    top: 16px;
    
    img{
      width: 24px;
    height: 24px;
    }
    
  }

  h1 {
    width: 100%;
    height:74px;

    line-height: 74px;
    padding: 0 16px;

    font-size: 20px;
    color: #fff;
    background: #0157FF;
  }

  p {
    padding: 24px 16px;
    line-height: 24px;

    em{
      color: #FF5F01;
    }

    span{
      color: #042F8B;
    }

    strong {
      font-size: 20px;
      font-weight: bold;
      text-align:center;
      display: block;
    }
  }
`

export default NoticePopup;