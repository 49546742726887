import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import EventList from '../../../components/TouchOll/board/EventList';
import styled from 'styled-components';

const Event = () => {
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        이벤트
      </SubHead>

      <section>
        <EventListWrap>
          <EventList type={'N'} />
          <EventList type={'Y'} />
        </EventListWrap>
        
      </section>
    </div>
  );
};

const EventListWrap = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 20px;

  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: start;
  gap: 8px;
`
export default Event;