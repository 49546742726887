import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ex_icon } from '../../../../assets/images';

const BonusList = ({
    type
  , totalPoint
  , usingPoint
  , pay
  , noName
}) => {
  /**
   * type: point = 현금 | stemp = 스탬프 | gift = 경품
   * totalPoint : 누적
   * usingPoint : 가용
   * pay : 사용
   */

  const navigate = useNavigate();
  return (
    <BonusPoint type={type}> 
      {
        noName && <ExIcon><img src={ex_icon} alt='ex' /></ExIcon>
      }
      {type === 'point' && <h2><em>P</em> 현금</h2>}
      {type === 'stemp' && <h2><em>S</em> 스탬프</h2>}
      {type === 'gift' && <h2><em>G</em> 경품승점</h2>}

      <div>
        <ol>
          <li><span>누적</span>{totalPoint.toLocaleString()}</li>
          <li><span>가용</span>{usingPoint.toLocaleString()}</li>
        </ol>

        {type === 'point' && <button onClick={()=>navigate('/app/useinfo_pay')}>{pay}</button>}
        {type === 'stemp' && <button onClick={()=>navigate('/app/useinfo_pay')}>{pay}</button>}
        {type === 'gift'  && <button disabled="disabled" >{pay}</button>}

      </div>
    </BonusPoint>     
  );
};


const ExIcon = styled.div`
position: absolute;
left: 50%;
top: 0px;
margin-left: -55px;

img {
  width: 110px;

}
`

const BonusPoint = styled.div`
position: relative;

border-bottom: 1px solid #eee;
  width: calc(100% - 32px);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  h2 {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    font-weight: bold;
    color:${props => (props.type === 'point' ? '#7B8531' : props.type === 'stemp' ? '#EC635D' : '#2860A7')};

    em {
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;
      
      background-color: ${props => (props.type === 'point' ? '#7B8531' : props.type === 'stemp' ? '#EC635D' : '#2860A7')};
      border-radius: 24px;

      font-weight: 700;
      color: #fff;
      font-size: 20px;
    }
  }

  div {
    height: 76px;

    display: flex;
    justify-content: end;
    align-items: center;

    gap: 20px;

    ol {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      gap: 8px;

      li {
        width: 150px;

        text-align: right;
        font-size: 18px;
        color:${props => (props.type === 'point' ? '#7B8531' : props.type === 'stemp' ? '#EC635D' : '#2860A7')};
        font-weight: 700;
        
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          font-weight: 500;
        }

        &:first-child {
          font-weight: 500;
          font-size: 12px;
          color:#444;
        }
      }
    }

    button {
      font-size: 15px;
      font-weight: 700;
      width: 50px;
      height: 24px;
      background-color: ${props => (props.type === 'point' ? '#FE7A36' : props.type === 'stemp' ? '#FE7A36' : '#eee')};
      color: ${props => (props.type === 'point' ? '#fff' : props.type === 'stemp' ? '#fff' : '#999')};
      border-radius: 50px;
    }
  }
`

export default BonusList;