import React from 'react';
import styled from 'styled-components';

const LoadingScreen = () => {
  return (
    <LoadingWrap>
      <Loadingbar>
        <div>
          <svg version="1.1" id="L5" x="0px" y="0px" viewBox="0 0 60 60">
            <circle fill="#fff" stroke="none" cx="6" cy="30" r="6">
              <animateTransform 
                attributeName="transform" 
                dur="1s" 
                type="translate" 
                values="0 15 ; 0 -15; 0 15" 
                repeatCount="indefinite" 
                begin="0.1"/>
            </circle>
            <circle fill="#fff" stroke="none" cx="30" cy="30" r="6">
              <animateTransform 
                attributeName="transform" 
                dur="1s" 
                type="translate" 
                values="0 10 ; 0 -10; 0 10" 
                repeatCount="indefinite" 
                begin="0.2"/>
            </circle>
            <circle fill="#fff" stroke="none" cx="54" cy="30" r="6">
              <animateTransform 
                attributeName="transform" 
                dur="1s" 
                type="translate" 
                values="0 5 ; 0 -5; 0 5" 
                repeatCount="indefinite" 
                begin="0.3"/>
            </circle>
          </svg>
        </div>
        <p>자동 로그인 중...</p>
      </Loadingbar>
      
    </LoadingWrap>
  );
};

const LoadingWrap = styled.div`
width:100vw;
height: 100vh;
background:rgba(0,0,0,0.5);

position:fixed;
top:0;
left: 0;
z-index:999;
`

const Loadingbar = styled.div`
position:fixed;
bottom: 50%;
left: 50%;
text-align:center;
transform: translate(-50%, -50%);

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

div{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  color:#fff;
  font-size: 18px;
  font-weight:500;
  margin-top: 12px;
}
`
export default LoadingScreen;