import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StampUseScreen from '../../../components/common/payuse/StampUseScreen';
import TopImg from '../../../components/common/payuse/TopImg';
import BlankBtns from '../../../components/common/payuse/BlankBtns';
import Recipe from '../../../components/common/payuse/Recipe';
import Adimg from '../../../components/common/payuse/Adimg';
import { useLocation } from 'react-router';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import RpsResult from '../../../components/common/payuse/RpsResult';
import RpsResultList from '../../../components/common/payuse/RpsResultList';
import axios from 'axios';

const StampPayUse = () => {
  const [isLoading, setIsLoading] = useState(false); // 로딩 

  const location = useLocation();  
  const { data } = location.state || {};

  const approval  = data?.receipt?.aprvNo || data?.recipt?.aprvNo 
  const userId    = data?.point?.userId;
  const type      = data?.type;

  const [bonusData, setBounsData] = useState({})

  useEffect(()=>{
    const fetchData = async () => {
      try{
        setIsLoading(true);  // 로딩 시작

        const requestData = {
            approval  : approval
          , userId    : userId
          , type      : type
        }

        const response = await axios.post('/api/pointMngr/inQueryPointInfo', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        
        console.log("결제 조회되는 데이터: ",response?.data?.data?.message)
        setBounsData(response?.data?.data?.message);

      } catch(error){
        console.log("flutter 전달 상세 오류 ::". error);
      } finally {
        setIsLoading(false); // 로딩 끝
      }
    }

    fetchData()
  },[type])

  const isApp         = bonusData?.isApp;
  const storeInfo     = bonusData?.store;
  const pointInfo     = bonusData?.point;
  const receiptInfo   = bonusData?.receipt || bonusData?.recipt;
  const useType       = bonusData?.type;
  const rpsinfo       = bonusData?.rps;

  // console.log("받아온 데이터::",data)
  // console.log("receiptInfo 데이터::",receiptInfo)

  return (
    <div id="sub_common_wrap_f">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

      
      {isLoading ? ( // 로딩 중일 때
        <div>Loading...</div>
      ) : (
        <section>
          <StampWrap>
            <StampResultWrap className='wrr'>
              {/* 터치올 공통 배너 */}
              <TopImg />

              {/* 스탬프 상태 Type에 따른 화면 */}
              <StampUseScreen 
                storeInfo={storeInfo} 
                pointInfo={pointInfo} 
                useType={useType} 
                receiptInfo={receiptInfo}
                isApp={isApp}
              />

              {/* 경품증정 결과 */}
              {("1" === useType || "3" === useType) && 'N' !== storeInfo?.rpsUse && receiptInfo?.stlmMethodCode !== "9" && (
                <RPSResultWrap>
                  {useType === "1" && (
                    <RpsResult storeInfo={storeInfo} rpsinfo={rpsinfo} pointInfo={pointInfo} useType={useType} />
                  )}
                  <RpsResultList data={data} useType={useType} receiptInfo={receiptInfo} isApp={isApp} />
                </RPSResultWrap>
              )}
    

              {/* 터치올 홈페이지로 이동 */}
              <BlankBtns />

              {/* 전자영수증 */}
              {
                 receiptInfo?.stlmMethodCode !== "9" ? 
                 <Recipe stamp={"Y"} receiptInfo={receiptInfo} pointInfo={pointInfo} storeInfo={storeInfo} useType={useType} /> 
                 : <></>
              }
              

              {/* 광고 */}
              <Adimg />
            </StampResultWrap>

          </StampWrap>
        </section>
      )}
    </div>
  );
};

const RPSResultWrap = styled.div`
  width:calc(100% - 32px);
  padding: 20px 16px;
  background:#fff;
  border:2px solid #CBCBCB;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
`

const StampWrap = styled.div`
background:#F5F5F5; 
padding:32px 0;
`

const StampResultWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
gap: 32px;

width: calc(100% - 32px);
padding: 0 16px;

`

export default StampPayUse;