import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import {
  checkon
  , checkoff
} from "../../../../assets/images";
import axios from 'axios';
import BtnBottom from '../../../components/TouchOll/BtnBottom';

const MyinfoPayEdit = () => {
  return (
    <div id="sub_common_wrap">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        보너스 사용 수정
      </SubHead>
      <section>
        <InputCheck>
          <ul>
            <li>
              <label>
                <input type='radio' name='bonus_use' />
                <em></em>
                <p>현금 대용 사용·결제 Pay로 사용</p>
              </label>
            </li>
            <li>
              <label>
                <input type='radio' name='bonus_use' />
                <em></em>
                <p>통장입금</p>
              </label>

              <ol>
                <li><p>은행</p>   <input type='text' name='bank' placeholder='은행' /></li>
                <li><p>계좌번호</p><input type='number' name='account_num' placeholder='- 제외 입력'/></li>
                <li><p>예금주</p> <input type='text' name='bank_name' placeholder='예금주' /></li>
              </ol>
            </li>
          </ul>
          <h5>예금주는 가입자 실명 계좌여야 하며,<br />가족,지인 계좌 시 예금주의 동의 후 가능합니다.</h5>
        </InputCheck>
      
        <AgreeBtn>
          <BtnBottom>수정하기</BtnBottom>
        </AgreeBtn>
      </section>
    </div>
  );
};

const InputCheck =styled.div`
width: calc(100% - 32px);
padding: 0 16px;

ul{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 12px;

  margin-top: 24px;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    width: 100%;

    border-radius: 8px;
    
    label{
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 4px;

      font-size: 16px;

      padding: 12px 16px;
      background:#fff;
      border-radius: 8px;

      width: calc(100% - 32px);

      &:has(input[type='radio']:checked ){
        background:#fff;

        p {
          color:#FE7A36;
          font-weight:bold;
        }
      }

    }
    
    &:has(input[type='radio']:checked ){
      ol input {
        background:#fff;
      }
    }

    input[type='radio'] {
      display: none;
    }
    
    /* 체크박스 커스텀 이미지 적용 */
    input[type='radio'] + em {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(${checkoff});
      background-size: cover;
    }
    
    /* 체크된 상태의 이미지 적용 */
    input[type='radio']:checked + em {
      background-image: url(${checkon});
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 14px;
      padding: 8px 12px;
      background:#f5f5f5;
      margin: 8px 0 0 20px
    }
  }
}

ol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;

  width: 100%;

  margin-top: 12px;

  li {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    
    p {
      width: 100px;
      padding-left: 16px;
    }

    input {
      width: 100%;

      font-size: 16px;
      background:#e2e2e2;
      padding: 12px 16px;

      border-radius: 8px;
    }
  }
}

h5 {
  font-size: 14px;
  color: #444;
  line-height: 20px;
  margin-top: 16px;
}

`

const AgreeBtn = styled.div`
  margin-top: 16px;

  width: calc(100% - 32px);
  padding: 20px 16px 40px 16px;

`;

export default MyinfoPayEdit;