import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import AutoTimer from '../../../components/TouchOll/join/AutoTimer';
import axios from 'axios';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import { UserInfoResult } from '../../../../recoil/recoilState';
import { useRecoilState } from 'recoil';

const MyinfoEdit = () => {

  const [userInfo, setUserInfo] = useRecoilState(UserInfoResult);
  const [selectInfo, setSelectInfo] = useState({
    ...userInfo
  })
  
  const handleSelectChange = (key, value) => {
    setSelectInfo({
      ...selectInfo,
      [key]: value
    });
  };

  const [mobile, setMobile]         = useState('01012341234');   // 핸드폰 번호
  const [isBtnDis, setIsBtnDis]     = useState(true); // 인증번호 받기 비활성화
  const [confirmNum, setConfirmNum] = useState('');   // 인증번호 입력
  const [name, setName]             = useState('홍길동');   // 이름
  const [birthday, setBirthday]     = useState('19990101');   // 생년월일
  const [count, setCount]           = useState('');  // 3분 타이머 
  const [countOn, setCountOn]       = useState(true);  // 3분 타이머 
  const [popupTxt, setPopupTxt]     = useState(''); // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false); // 시스템 팝업
  
  const initialBirthday = '19990101';
  useEffect(() => {
    // 초기 생년월일 값을 형식에 맞게 변환하여 설정
    const formattedBirthday = initialBirthday.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
    setBirthday(formattedBirthday);
  }, []);

  /** 
   * 수정하기 버튼
   * 조건: 아래 모두 true 표시 되어야 활성화
   */ 
  const [joinState, setJoinState] = useState({
      numberCheck : false  // 인증번호 확인
    , nameCheck   : true  // 이름 유무 + 2글자 이상
    , birthCheck  : true  // 생년월일 패턴
  })

   /**
   * 이름 확인 체크
   * 조건: 2글자 이상 작성
   */
   const handleNameInput = (e) => {
    const nameValue     = e.target.value;
    setName(nameValue)

    if (nameValue.length >= 2) {
      setJoinState(prevState => ({ ...prevState, nameCheck: true }));
    } 
    else {
      setJoinState(prevState => ({ ...prevState, nameCheck: false }));
    }
  };

  /**
   * 생년월일 확인 체크
   * 조건: 생년월일 정규식 확인
   */
  const handleBirthInput = (e) => {
    let birthValue = e.target.value;
    birthValue = birthValue.replace(/\D/g, ''); // 숫자 이외의 문자 제거
  
    // 최대 길이를 8자로 제한
    if (birthValue.length > 8) {
      birthValue = birthValue.slice(0, 8);
    }
  
    // 생년월일 형식 변환 (00000000 -> 0000.00.00)
    if (birthValue.length >= 6) {
      birthValue = birthValue.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
    }
  
    // 입력 필드에 변환된 값 설정
    e.target.value = birthValue;
  
    // 상태 업데이트
    setBirthday(birthValue);
  
    // 유효성 확인
    if (birthValue.match(/(19|20)\d{2}\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])/)) {
      setJoinState(prevState => ({ ...prevState, birthCheck: true }));
    } else {
      setJoinState(prevState => ({ ...prevState, birthCheck: false }));
    }
  };
  

  /** 
   * 전화번호 유효성 검사
   * 결과 : 인증번호 받기 버튼 상태 변경
   */
  const handleMobileChange = (e) => {
    const pattern = new RegExp("^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$");

    setMobile(e.target.value);
    setIsBtnDis(!pattern.test(e.target.value)); 
  };

  /**
   * 인증번호 전송
   * after: 인증번호 input (#confirm_num_input) 보여짐
   */
  const handlePostNumRequest = async () => {
    try {
      await axios.post('/api/authUser/userCertFromMobile', {
        mobile: mobile
      });
      setCountOn(true);
      setCount(180);
      document.querySelector('#confirm_num_input').classList.add('confirm_input');
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };

  /**
   * 인증번호 확인 
   * 조건: data?result가 True 이면 인증번호 입력 input 사라짐
   * 결과: 전화번호 input 읽기 전용으로 변경되어 수정 불가.
   */
  const handleValidation = async () => {
    try {
      const response = await axios.post('/api/authUser/validationCert', {
          mobile  : mobile
        , certNum : confirmNum
      });

      if (response.data.data && response.data.data.result) {
        setCount('');
        setJoinState(prevState => ({ ...prevState, numberCheck: true }));
        document.querySelector('#confirm_num_input').classList.remove('confirm_input');
      } else {
        setPopupTxt('인증번호를 잘못 입력했습니다.');
        setSystem(!system);
      }
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };
  
  return (
    <div id="sub_common_wrap">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        회원 기본 정보 수정
      </SubHead>
      <section>

      <JoinInputs>
        <div>
          <p>이름</p>
          <input
            type        ="text"
            value       ={name}
            placeholder ="외국어도 가능"
            onChange    ={handleNameInput}
          />
        </div>
        <Feedtxt id="feedtxt2" className={joinState.nameCheck ? "password-match" :''}>
          {
            joinState.nameCheck ? 
            "해당 정보는 혜택 알림, 비밀번호 찾기에 이용됩니다." 
            : "이름은 2글자 이상 입력하세요."
          }
        </Feedtxt>
      </JoinInputs>

      <JoinInputs>
        <div>
          <p>생년월일</p>
          <input 
            type        ="text" 
            value       ={birthday}
            placeholder ="0000.00.00" 
            onChange    ={handleBirthInput}
          />
        </div>
        <Feedtxt id="feedtxt3"  className={joinState.birthCheck ? "password-match" :''}>
          {
            joinState.birthCheck ? 
            "해당 정보는 혜택 알림, 비밀번호 찾기에 이용됩니다." 
            : "정확한 생년월일을 입력해주세요."
          }
        </Feedtxt>
      </JoinInputs>

        <JoinInputs>
          <div>
            <p>전화번호</p>
            <div className="flex2">
              <input
                placeholder =" - 없이 전화번호 입력"
                value       ={mobile}
                onChange    ={handleMobileChange} // 입력 값 변경 시 핸들러 호출
                type        ="number"
                maxLength   ={11}
                onInput     ={e => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                              }}
                readOnly    ={joinState.numberCheck}
                pattern     ="^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$"
              />

              <button
                onClick ={handlePostNumRequest}
                disabled={isBtnDis}
                style   ={{ backgroundColor: isBtnDis ? '#aaa' : '#444' }}
              >
                인증번호 받기
              </button>
            </div>

            <div id="confirm_num_input">
              <div className="flex2">
                <input
                  placeholder ="인증번호 입력"
                  value       ={confirmNum}
                  onChange    ={(e) => setConfirmNum(e.target.value)}
                  type        ="number"
                />
                <CountTimer>
                  <AutoTimer
                    count     ={count}
                    setCount  ={setCount}
                    setCountOn={setCountOn}
                  />
                </CountTimer>

                <button
                  onClick ={countOn ? handleValidation : null}
                  disabled={!countOn}
                  style   ={{ backgroundColor: !countOn ? '#aaa' : '#444' }}
                >확인</button>
              </div>
            </div>

          </div>
          <Feedtxt id="feedtxt0"  className={joinState.numberCheck ? "password-match" :''}>
            {
              joinState.numberCheck ?
                "인증이 완료되었습니다."
                : "전화번호 인증이 필요합니다."
            }
          </Feedtxt>
        </JoinInputs>

        <AgreeBtn>
          <BtnBottom
            disabled={
              !(
                  joinState.numberCheck 
                && joinState.nameCheck 
                && joinState.birthCheck
              )
            }
          >수정하기</BtnBottom>
        </AgreeBtn>
      </section>
    </div>
  );
};

const JoinInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;

    padding: 0 16px;

    font-size: 16px;
  }
`;

const Feedtxt = styled.p`
  font-size: 14px !important;
  margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const CountTimer = styled.div`
  position:absolute;
  right: 135px;
  top:50%;
  margin-top: -7px;

  span {
    font-size: 14px;
    color:#aaa;
    
  }
`

const AgreeBtn = styled.div`
  margin-top: 16px;

  width: calc(100% - 32px);
  padding: 20px 16px 40px 16px;

`;

export default MyinfoEdit;