import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  rock_small
  , scissor_small
  , paper_small
  , rock_select
  , scissor_select
  , paper_select
} from "../../../assets/images";
import WebGameTit from "./common/WebGameTit";
import RpsResultList from "../common/payuse/RpsResultList";


function GameCancel({ data }) {
  const pointInfo   = data?.point;
  const useType     = data?.type;
  const receiptInfo = data?.recipt;
  const isApp       = data?.isAPP;

  console.log("GameResult pointInfo:", pointInfo);

  return (
    <>
      <BonusSUWrap>
      <RPSResultWrap>
        <div className='cash_stamp_wrap'>        
        <WebGameTit data={data} state={2} />

        <GrayBackGroundContainer>
          <GameSelectContainer>
            <GameShuffleImg
              src={pointInfo?.strRpsShow === 's' ? scissor_select : scissor_small}
            />
            <GameShuffleImg
              src={pointInfo?.strRpsShow === 'r' ? rock_select : rock_small}
            />
            <GameShuffleImg
              src={pointInfo?.strRpsShow === 'p' ? paper_select : paper_small}                
            />
          </GameSelectContainer>

          <DescriptionContainer>              
            <GameResultDescriptionContainer>
              <SaveStatus>경품승점 취소!</SaveStatus>        
              <CashAmount>{pointInfo?.cancelRpsScore}점</CashAmount>
            </GameResultDescriptionContainer>
          </DescriptionContainer>
        </GrayBackGroundContainer>
        </div>
        
          <RpsResultList  data={data}  useType={useType} receiptInfo={receiptInfo} isApp={isApp} />
        </RPSResultWrap>
      </BonusSUWrap>

      
      </>
  );
}

const RPSResultWrap = styled.div`
  width:calc(100% - 32px);
  padding: 20px 16px;
  background:#fff;
  border:2px solid #CBCBCB;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
`

const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
padding: 0 16px;

.cash_stamp_wrap {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
}
`

const GrayBackGroundContainer = styled.div`
  width: calc(100% - 32px); 
  background-color: #F6F6F6;
  border-radius: 10px;   
  padding: 30px 16px 26px 16px;
  margin-top: 12px;  
`;

const GameResultDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

`;

const SaveStatus = styled.div`      
  color: #FB0E0E;  
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  margin-left: 20px;
`;

const CashAmount = styled.div`
  color: #FB0E0E;
  font-weight: 600;
  font-size: 26px;
  margin-right: 20px;
`;

const DescriptionContainer = styled.div`
  display: flex;  
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const GameSelectContainer = styled.div`
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
`;

const GameShuffleImg = styled.img`
  width: 60px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
`;

export default GameCancel;
