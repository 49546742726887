import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import Comparison from '../../components/Biapp/Comparison';
import Receipt from '../../components/Biapp/Receipt';
import Ad from '../../components/Biapp/Ad';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { pointState } from '../../../recoil/recoilState';

import {
  app_download_button,
  app_download_description,
} from '../../../assets/images';
import PointUseScreen from '../../components/common/payuse/PointUseScreen';
import StampUseScreen from '../../components/common/payuse/StampUseScreen';
import RpsResultList from '../../components/common/payuse/RpsResultList';
import RpsResult from '../../components/common/payuse/RpsResult';
import useModalWithBackButton from '../../components/common/function/HistoryModal';

function PointSaveResultDetail() {
  // const { phoneNumber } = useParams(); // 라우터로부터 전달된 값
  // console.log("phoneNumber:", phoneNumber);
  const navigate = useNavigate();
  const location = useLocation();
  const { data, phoneNumber, gameResult } = location.state || {};
  const [detailData, setDetailData] = useState(null);
  const [memberValue, setMemberValue] = useState(null);
  const pointValue = useRecoilValue(pointState); // Recoil 상태 사용
  
  // requestPointSaveProcess 데이터 저장
  const [storeInfo  , setStoreInfo  ] = useState();
  const [pointInfo  , setPointInfo  ] = useState();
  const [receiptInfo, setReceiptInfo] = useState();
  const [useType    , setUseType    ] = useState();
  const [rpsInfo    , setRpsInfo    ] = useState();
  const [isApp      , setIsApp      ] = useState();

  // const receiptInfo = data?.receipt || data?.recipt;

  // const rpsinfo = data?.rps;
  // const [isBackClicked, setIsBackClicked] = useState(false);

  const handleEvent = () => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', location.href);
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', handleEvent);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isApprovedResult = await isApproved();
        const memberValue = isApprovedResult ? 'Y' : 'N';
        setMemberValue(memberValue);

        const requestData = {
          point: pointValue,
          mobile: phoneNumber
        };

        const response = await axios.post('/api/pointMngr/requestPointSaveProcess', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log('### 온전한 데이터 response : ', response?.data?.data?.message);
        setStoreInfo(response?.data?.data?.message?.store);
        setPointInfo(response?.data?.data?.message?.point);
        setReceiptInfo(response?.data?.data?.message?.receipt || response?.data?.data?.message?.recipt)
        setUseType  (response?.data?.data?.message?.type);
        setRpsInfo  (response?.data?.data?.message?.rps);
        setDetailData(response?.data?.data?.message); // detailData 업데이트
        setIsApp(response?.data?.data?.message?.isApp)

      } catch (error) {
        // navigate('/error'); // 에러 페이지로 이동

      }
    };

    console.log("## detailData:: ", detailData);
    const isApproved = async () => {
      try {
        const response = await axios.get(`/api/approved/${phoneNumber}`);
        console.log("## approved ::", response?.data?.data?.result)
        return response?.data?.data?.result;
      } catch (error) {
        // 에러 핸들링
      }
    };

    fetchData();
  }, []); // phoneNumber와 pointValue가 변경될 때마다 실행

  const handleAppDownloadButtonClick = () => {
    navigate('/app_download');
  };

  return (
    <div style={{ backgroundColor: '#F5F5F5' }}>
      <Header />
      <Wrap32>
        {
        storeInfo?.pointUse === 'Y' ? 
          <PointUseScreen 
            storeInfo={storeInfo} 
            pointInfo={pointInfo} 
            useType={useType} 
            receiptInfo={receiptInfo} 
            isApp={isApp}
          />
        : 
          <StampUseScreen 
            storeInfo={storeInfo} 
            pointInfo={pointInfo} 
            useType={useType} 
            receiptInfo={receiptInfo} 
            isApp={isApp}
          />
        }
      
      {/* 경품증정 결과 */}
      {("1" === useType || "3" === useType) && 'N' !== storeInfo?.rpsUse && receiptInfo?.stlmMethodCode !== "9" && (
        <RPSResultWrap>
          {useType === "1" && (
            <RpsResult storeInfo={storeInfo} rpsinfo={rpsInfo} pointInfo={pointInfo}  useType={useType} />
          )}
          <RpsResultList data={detailData}  useType={useType} receiptInfo={receiptInfo} isApp={isApp} />
        </RPSResultWrap>
      )}
     
      </Wrap32>

      <CenterAlignContainer>
        <OverlayContainer>
          <AppDownloadDescription src={app_download_description} />
          <AppDownloadButton
            src={app_download_button}
            onClick={handleAppDownloadButtonClick}
          />
        </OverlayContainer>
      </CenterAlignContainer>
      <Comparison />

      <Receipt data={data} />
      <Ad />
    </div>
  );
}

const RPSResultWrap = styled.div`
  width:calc(100% - 32px);
  padding: 20px 16px;
  background:#fff;
  border:2px solid #CBCBCB;
  border-radius: 12px;
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
`

const OverlayContainer = styled.div`
  position: relative;
`;

const AppDownloadButton = styled.img`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const CenterAlignContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AppDownloadDescription = styled.img`
  width: 320px;
`;

const Wrap32 = styled.div`
width: calc(100% - 32px);
padding : 0 16px;
margin-top: 20px;
`

export default PointSaveResultDetail;
