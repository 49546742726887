import React from 'react';
import styled from 'styled-components';

const BtnBottom = ({ onClick, disabled, children }) => {
  return (
    <BottomBtnOne onClick={onClick} disabled={disabled}>
      {children}
    </BottomBtnOne>
  );
};

const BottomBtnOne = styled.button`
  width: 100%;
  height: 50px;
  background: #ddd;
  color: #aaa;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;

  font-size: 16px;

  background-color: ${props => (props.disabled ? '#ddd' : '#FE7A36')};
  color: ${props => (props.disabled ? '#aaa' : '#fff')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  a {
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${props => (props.disabled ? '#aaa' : '#fff')};
  } 
  
  span {
    color: ${props => (props.disabled ? '#aaa' : '#fff')};
  }
`;

export default BtnBottom;
