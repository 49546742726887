import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import BottomGnb from '../../../components/TouchOll/main/BottomGnb';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import MoreList from '../../../components/TouchOll/more/MoreList';
import { more1, more2, more3, more4, more5, more6, more7, more8, more9 } from '../../../../assets/images';

const MorePage = () => {
  const navigate = useNavigate();

  const handleLoginUserToken = () => {
    localStorage.removeItem("Usertokenkey");
    navigate('/');
  }

  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        더보기
      </SubHead>
      <section>
        <MoreMenu>
          <MoreList link={"/"} target={'new'} 
            icon={more1} nav={'회사 소개'} />
          <MoreList link={"/"} target={'new'} 
            icon={more2} nav={'터치올 소개'} />
          <MoreList link={"/app/mypage_view"} 
            icon={more3} nav={'MY 정보조회, 수정'} />
          <MoreList link={"/app/mygrade"} 
            icon={more4} nav={'MY 학점, 학년'} />
          <MoreList link={"/"} 
            icon={more5} nav={'MY Hot 소식'} />
          <MoreList link={"/app/board/notice"} 
            icon={more6} nav={'공지사항'} />
          <MoreList link={"/app/board/event"} 
            icon={more7} nav={'이벤트'} />
          <MoreList link={"/app/board/qa"} 
            icon={more8} nav={'자주 묻는 질문'} />
          <MoreList link={"/app/more/setting"} 
            icon={more9} nav={'설정'} />
        </MoreMenu>
      </section>
      <BottomGnb />
    </div>
  );
};

const MoreMenu = styled.div`
padding: 20px 16px;
display: grid;
grid-template-columns: 1fr 1fr;
gap: 12px;
`

export default MorePage;