import React from 'react';
import styled from "styled-components";
import { logo, logo_toucholl } from '../../../../assets/images';
import PSGicon from '../PSGicon';

const RecipeBank = ({ 
  receiptInfo,
  pointInfo,
  storeInfo,
  // useType 
}) => {
  // APP 전자영수증
  // stamp : 스탬프 여부
  // storeInfo : 스토어 정보
  // useType : 타입

  console.log("receiptInfo:", receiptInfo);
  console.log("storeInfo 입금:", storeInfo);
  
  const supplyPrice = Number(receiptInfo?.supplyPrice).toLocaleString();
  const sttleStamp  = Number(pointInfo?.sttleStamp).toLocaleString(); // 사용
  const stlmPrice   = Number(receiptInfo?.stlmPrice).toLocaleString();
  const sttlePoint  = Number(pointInfo?.sttlePoint).toLocaleString();

  const useType = "5";

  return (
    <BorderContainer>
      <div className="recipe_wrap">
        <TypeCredit>
          <h3><img src={logo} alt='터치올' />현금<PSGicon type={'p'} /></h3>
          <h2>입금증</h2>
        </TypeCredit>
        
        {/* <p>{receiptInfo?.stlmDt}</p> */}
        {/* <h3>{storeInfo?.storeName}</h3> */}
        <h1>
          {stlmPrice}
          {storeInfo?.stampUse === "Y" && (useType === '2' || useType === '4') ? '개' : '원'}
        </h1>
        <ul>
          <li><span>보내는 이</span>터치올(주)</li>
          <li><span>받는 이</span>홍길동</li>
          <li><span>입금계좌</span>국민은행 000000-00-000000</li>
          <li><span>입금액</span>{stlmPrice}원</li>
          <li><span>입금 수수료</span>240원</li>
          <li><span>입금일시</span>{receiptInfo?.stlmDt}</li>
        </ul>
      </div>
    </BorderContainer>
  );
};


const TypeCredit = styled.div`
margin-bottom: 28px;
img {
  height: 22px;

  &:first-child {
    margin-right: 8px;
    height: 24px;
  }
}

h3 {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 2px;

  font-size: 22px;
  color:#7B8531 !important;
}

h2{
  margin-top: 12px;
}
`


const BorderContainer = styled.div`
  width: 100%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.06);
 
  .recipe_wrap{
    width: calc(100% - 32px);
    padding: 30px 16px;border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 10px; 
  }

  h2{
    color: #4D4D4D;
    font-weight: 600;
    font-size: 22px;
    text-align:center;
  }

  p{
    margin-top: 28px;
    color: #8E8E8E;
    font-weight: 500;
  }

  h3 {
    margin-top: 12px;
    color: #222222;
    font-weight: bold;
  }

  h1 {
    color: #222222;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    margin-top: 18px;
    padding-bottom: 8px;
  }

  ul {
    margin-top: 20px;

    li{
      display: flex;
      justify-content: space-between;
      align-content: center;

      color: #8E8E8E;
      font-weight: 500;
      font-size: 16px;

      line-height: 24px;

      span {
      color: #8E8E8E;  
      font-weight: 500;
      font-size: 16px;
    }
    } 
  }
`;


export default RecipeBank;