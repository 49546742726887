import React, { useState } from 'react';
import PointIcon from './PointIcon';
import styled from 'styled-components';
import BtnBottom from '../BtnBottom';
import SystemYesNo from '../../common/SystemYesNo';

const JoinTypeN = ({setJoinType}) => {
  const [imsipw, setImsipw] = useState('A123456!'); // 임시 비밀번호

  const [imsiPop1 , setImsiPop1]  = useState(false); // 임시 가입 확인
  const [imsiPop2 , setImsiPop2]  = useState(false); // 임시 비번 기록 확인
  const [imsiBtn1 , setImsiBtn1]  = useState(true) ; // 임시가입 신청 버튼
  const [imsiBtn2 , setImsiBtn2]  = useState(false); // 임시가입 완료 버튼

  const handleImsiPop1 = () => {
    setImsiPop1(!imsiPop1);
  }

  const handleImsiPop1Yes = () => {
    setImsiPop1(!imsiPop1);
    setImsiPop2(!imsiPop2);
  }

  const handleImsiPop2Yes = () => {
    setImsiPop2(!imsiPop2);
    setImsiBtn1(false);
    setImsiBtn2(true);
  }

  const handleJoinType = () => {
    setJoinType('Y')
  }

  return (
    <>
      {
        imsiPop1 && 
        <SystemYesNo handleSystem={handleImsiPop1Yes} system={imsiPop1} setSystem={setImsiPop1}>
          <em>정규 안심 가입</em>이 아닌 <span>임시가입</span> 신청을 한 것이 맞나요?
        </SystemYesNo>
      }

      {
        imsiPop2 && 
        <SystemYesNo handleSystem={handleImsiPop2Yes} system={imsiPop2} setSystem={setImsiPop2}>
          임시 비밀번호를 기록, 메모하셨나요?
        </SystemYesNo>
      }
      <ImsiWrap id='wrap32'>
        <h3>임시가입은</h3>
        <p>
          제공된 지정 ‘임시 비밀번호'만으로<br />
          즉시- 초간편 가입 이용 OK<em className='it_txt'>!</em><br />
        </p>

        <h4>다만, </h4>
        <ol>
          <li>- <strong>3일 내</strong> 본인 비번으로 변경 필<em className='it_txt'>!</em></li>
          <li>- <PointIcon /> 통장입금 <em className='pay'>Pay</em> <strong>불가<em className='it_txt'>!</em></strong></li>
          <li>- <strong>미 변경시</strong> 가입 축하 적립 & 경품 수령 <strong>불가<em className='it_txt'>!</em></strong></li>
        </ol>

        <button onClick={handleJoinType}>← 난 정규.안심 가입으로 할래</button>
      </ImsiWrap>

      <JoinInputs>
        <div>
          <p>임시비밀번호</p>
          <Feedtxt id="feedtxt3">
            * 비번 변경시 임시 비번 필수이니 따로 메모 필 
          </Feedtxt>

          <input
            type="text"
            value={imsipw}
            readOnly
          />
        </div>
      </JoinInputs>

      <BtnJoin id="wrap32">
        <BtnBottom
          disabled={!imsiBtn1}
          onClick={handleImsiPop1}>
          원 클릭 - 임시 가입 신청
        </BtnBottom>

        <BtnBottom
          disabled={!imsiBtn2}
          onClick={handleImsiPop1}>
          원 클릭 - 임시 가입 완료
        </BtnBottom>
      </BtnJoin>
    </>
  );
};

const ImsiWrap = styled.div`
  h3 {
    font-weight:bold;
    font-size: 18px;
    margin: 32px 0 12px 0;
  }

  p {
    line-height: 18px;
    font-size: 14px;
    color:#444;

    .it_txt {
      color:#444;
    }
  }

  h4 {
    font-weight:bold;
    color:#FE7A36;
    margin: 20px 0 8px 0;
  }

  ol {
    width: calc(100% - 32px);
    padding : 20px 16px;
    background:#FFE8DC;
    border:1px solid #FE7A36;
    border-radius: 8px;
    
    li {
      line-height: 20px;

      strong {
        color:#FE7A36;
        font-weight:bold;

        .it_txt{
          color:#FE7A36;
        }
      }

      img {
        width: 16px !important;
      }

      .it_txt, .pay {
        color:#444;
      }
    }
  }

  ul {
    li {
      color:#5D0EF5;
      font-weight: bold;
      line-height: 20px;
    }
  }

  button {
    width: 220px;
    height: 32px;

    border-radius: 8px;
    background:#8f20e7;
    
    color:#fff;
    font-weight:bold;
    font-size: 16px;

    margin-top: 20px;
  }
`

const JoinInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;

    padding: 0 16px;

    font-size: 16px;

    &::placeholder {
      color:#888;
    }
  }
`;

const Feedtxt = styled.p`
  font-size: 14px !important;
  // margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const BtnJoin = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;

  button:first-child {
    margin-bottom: 12px;
  }
`;

export default JoinTypeN;