import React, { useEffect, useState } from 'react';
import SubHead from '../../components/TouchOll/SubHead';
import SectionSelect from '../../components/storeAdmin/SectionSelect';
import AdminTit from '../../components/storeAdmin/AdminTit';
import styled from 'styled-components';
import {
  checkoff
  , checkon2,
  store
} from "../../../assets/images"
import ServiceSelect from '../../components/storeAdmin/ServiceSelect';
import BonusSpacial from '../../components/storeAdmin/BonusSpacial';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { DeviceInfoResult } from "../../../recoil/recoilState";
import VipFloatInput from '../../components/storeAdmin/VipFloatInput';
import SystemModal from '../../components/common/SystemModal';
import { handleHeaderToken } from '../../components/common/function/BearerToken';
import ModalPopup from '../../components/common/ModalPopup';
import NFCpopup from '../../components/common/NFCpopup';
import NewServiceSelect from '../../components/storeAdmin/NewServiceSelect';
import IosNfc from '../../components/common/function/IosNfc';
import LoadingBar from '../../components/common/LoadingBar';

const MyStoreInfo = ({iosNFC}) => {
  const location = useLocation();
  const storeid = location.state.sid; // sid 번호
  const [isNFC, setIsNFC] = useState(false);
  const [complete, setComplete] = useState(false);
  
  // Bearer Token 보내기
  useEffect(() => {
    handleHeaderToken();
  }, [])

  /**
   * 모바일 기기 확인 
   * isMobile : true (모바일 기기)
   */
  const [isMobile, setIsMobile]   = useState(false); // 모바일 기기인지 확인
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  /**
   * "getTelegramDevice" 통신 로직 순서 
   * getTelegramDevice  : NFC가 device_setting="전문" 형태로 보냄
   * keyValueParse      : ["device_setting", "전문"] 나눔
   * locationLink       : key 값에 따라 DeviceSetting 실행
   * DeviceSetting      : api 서버 통신.
   */
  const locationLink = (jsonKey, jsonValue) => {
    if(jsonKey === "device_setting"){
      // 설정변경 전문
      DeviceSetting(jsonValue)
    } else if(jsonKey === "device_NFC"){
      // NFC 팝업
      if(jsonValue === 'false'){
        setIsNFC(false);
      }
    }else if(jsonKey === "setting_complete"){
      // 설정 완료 popup
      if(jsonValue === 'true'){
        setComplete(true)
      }
    }
  }

  const DeviceSetting = async (jsonValue) => {
    try{
      const requestData={
        point : jsonValue
      }

      const response = await axios.put('/api/deviceMngr/setPointStngChgFromDvc', requestData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('DeviceSetting ::', response?.data);
      const telegramMsg = response?.data?.data?.message?.telegram;
      
      if (isMobile && telegramMsg){
        // eslint-disable-next-line no-undef
        getDeviceSettingTelegram.postMessage("device_setting="+telegramMsg);
        setIsNFC(true);
      }

    } catch(error){
      console.log("DeviceSetting 에러 ::". error);
    }
  }

  //eslint-disable-next-line no-undef
  window.getTelegramDeviceSetting = (msg) => {
    // setApproval(fluttermsg);
    const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, '');
    
    locationLink(key, value);
  }

  window.getTelegramDeviceNFC = (msg) => {
    // setApproval(fluttermsg);
    const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, '');
    
    locationLink(key, value);
  }

  

  const [storeInfo, setStoreInfo] = useRecoilState(DeviceInfoResult); // 첫 설정 정보
  
  const [bonusEdit, setBonusEdit] = useState({}); // 보너스 종류, 유형, 적립기준 관련
  const [vipBonus, setVipBonus]   = useState({}); // 보너스 우대 관련 
  const [sendTouch, setSendTouch] = useState({}); // 보너스 전송 유형, NFC 터치 시간

  const [systemTxt, setSystemTxt] = useState(""); //시스템 팝업 텍스트
  const [error, setError] = useState(false); // 시스템 팝업 오픈 여부
  const [errorType, setErrorType] = useState({
      bonuspoint    : false // 포인트 작성 여부
    , bonusType     : false // 하나라도 Y 가 있어야함.
    , bonusGroup    : true  // 그룹코드 여부
    , bonusSstemp   : false // 단독 기준금액 작성 여부
    , bonusSrps     : false // 단독 가위바위보 작성 여부

    , bonusvip      : false // 단골우대 포인트 작성 여부
    , amountpay     : false // 결제금액 작성 여부
    , amountpoint   : false // 결제금액 포인트 작성 여부
    , paytype       : false // 결제수단 선택 여부
    , paytypePoint  : false // 포인트 선택시 포인트 작성 여부
    , bonustime     : false // 지정시간 작성 여부
    , bonustimeEnd  : false // stime ~ etime 시간 유효 여부
    , bonustimePoint: false // 지정시간 포인트 작성 여부

    , sendtype      : false // 보너스 전송 방법 선택여부
    , nfctime       : false // 스마트폰 터치 대기시간 작성 여부
  });
  
  /**
   * recoil 첫 설정 정보 저장하기
   */
  const StoreInfoset = async () => {
    try {
      const response = await axios.post('/api/deviceMngr/getDvcStngInfo', {
        "sid": storeid
      });
      const result = response.data.data.message;
      
      console.log("현재 스토어정보", result);
      setStoreInfo({
          sid         :storeid
        , vat         :result.vat
        , transfer    :result.transfer
        , delay       :result.delay
        , author      :result.author
        , pointUse    :result.pointUse
        , stampUse    :result.stampUse
        , rpsUse      :result.rpsUse
        , remnant     :result.remnant
        , receipt     :result.receipt
        , leaflet     :result.leaflet
        , earn        :result.earn
        , point       :result.point
        , stamp       :result.stamp
        , rps         :result.rps
        , advancement :result.advancement
        , amount      :result.amount
        , amountRatio :result.amountRatio
        , method      :result.method
        , methodRatio :result.methodRatio
        , time        :result.time
        , stime       :result.stime
        , etime       :result.etime
        , applyDow    :result.applyDow
        , grpId       :result.grpId
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // console.log("storeid : ", storeid)
    StoreInfoset();

  }, [storeid, setStoreInfo]);

  
  /**
  * 보너스 종류, 유형, 적립기준
  */
  useEffect(() => {
    setBonusEdit({
        earn:storeInfo.earn
      , pointUse:storeInfo.pointUse
      , stampUse:storeInfo.stampUse
      , rpsUse:storeInfo.rpsUse
      , point:storeInfo.point
      , stamp:storeInfo.stamp
      , rps:storeInfo.rps 
      , grpId:storeInfo.grpId
    })
  }, [storeInfo]);

  /**
   * 보너스 우대
   */
  useEffect(() => {
    setVipBonus({
      advancement:storeInfo.advancement
    , amount :storeInfo.amount
    , amountRatio :storeInfo.amountRatio
    , method :storeInfo.method
    , methodRatio :storeInfo.methodRatio
    , time :storeInfo.time
    , stime :storeInfo.stime
    , etime :storeInfo.etime
    , applyDow :storeInfo.applyDow
    });
  }, [storeInfo])

  /**
   * 처음 설정 정보 저장하기
   * 보너스 전송 유형, NFC 시간 
   */
  useEffect(() => {
    setSendTouch({
      transfer :storeInfo.transfer
    , delay :storeInfo.delay
    })
  },[storeInfo])

  /**
   * 타입 변경 
   * @param {*} value : 값
   * @param {*} type : 타입
   * @returns 변경된 타입
   */
  const TypeChange = (value, type) => {
    let processedValue;
    switch (type) {
      case 'float':
        processedValue = parseFloat(value);
        break;
      case 'string':
        processedValue = String(value);
        break;
      case 'int':
        processedValue = parseInt(value);
        break;
      default:
        processedValue = value;
    }
    return processedValue;
  }

  /**
   * 값 업데이트
   * @param {*} name : key 이름
   * @param {*} value : 값
   * @param {*} type : 타입
   */
  const handleSendTouch = (name, value, type) => {
    const processedValue = value !== '' ? Number(value) : 0; // 빈 문자열인 경우 기본값 0으로 설정
    setSendTouch(prevState => ({
      ...prevState,
      [name]: TypeChange(processedValue, type)
    }));
  };

  /**
   * 오류가 없으면 true로 변경됨. 
   * false가 있으면 수정 버튼 클릭시 에러 메세지 뜸.
   * true가 되는 경우를 설정한 것.
   */
  useEffect(() => {
    setErrorType(prevState => ({
      ...prevState,
        bonuspoint    :  (bonusEdit.pointUse === 'Y' && bonusEdit.point > 0 ) || bonusEdit.pointUse !== 'Y'
      , bonusType     : bonusEdit.pointUse === 'Y' || bonusEdit.stampUse === 'Y' || bonusEdit.rpsUse === 'Y'
      , bonusGroup    : (bonusEdit.earn === 'G' && bonusEdit.grpId !== '') ||  (bonusEdit.earn !== 'G')
      , bonusSstemp   : (bonusEdit.earn === 'S' && bonusEdit.stamp > 0 && bonusEdit.stampUse === 'Y') ||  bonusEdit.earn !== 'S' || bonusEdit.stampUse !== 'Y'
      , bonusSrps     : (bonusEdit.earn === 'S' && bonusEdit.rps > 0 && bonusEdit.rpsUse === 'Y') ||  bonusEdit.earn !== 'S' || bonusEdit.rpsUse !== 'Y'

      , bonusvip      : vipBonus.advancement > 0
      , amountpay     : vipBonus.amount !== '' 
      , amountpoint   : vipBonus.amountRatio > 0
      , paytype       : vipBonus.method !== ''
      , paytypePoint  : (vipBonus.methodRatio !== '' && vipBonus.method === '2') || vipBonus.method !== '2'
      , bonustime     : vipBonus.stime !== '' && vipBonus.etime !== ''
      , bonustimeEnd  : parseInt(vipBonus.etime) - parseInt(vipBonus.stime) > 0 
      , bonustimePoint: vipBonus.time > 0

      , sendtype      : sendTouch.transfer !== ''
      , nfctime       : sendTouch.delay !== ''
    }));
  }, [bonusEdit, vipBonus, sendTouch]);

  const errorMessageMap = {
    bonuspoint: '포인트를 작성해주세요.'
    , bonusType: '보너스 타입을 설정해주세요.'
    , bonusGroup: '그룹코드가 없습니다.'
    , bonusSstemp: '스탬프 기준금액을 작성해주세요.'
    , bonusSrps: '가위바위보 기준금액을 작성해주세요.'
    , bonusvip: '단골우대 포인트를 설정해주세요.'
    , amountpay: '결제금액의 금액을 작성해주세요.'
    , amountpoint: '결제금액의 포인트를 설정해주세요.'
    , paytype: '결제수단을 선택해주세요.'
    , paytypePoint: '결제수단의 포인트를 설정해주세요.'
    , bonustime: '지정시간을 작성해주세요.'
    , bonustimeEnd: '지정시간이 유효하지 않습니다. (0시 ~ 24시)'
    , bonustimePoint: '지정시간의 포인트를 설정해주세요.'
    , sendtype: '보너스 전송 방법을 선택해주세요.'
    , nfctime: '스마트폰 터치 대기시간을 설정해주세요.'
  };


  const handleDeviceEdit = async () => {
    const errorKey = Object.keys(errorType).find(key => !errorType[key]);

    if (errorKey) {
      const errorMessage = errorMessageMap[errorKey];
      setError(true);
      setSystemTxt(errorMessage);
      return; 
    }

    // -------↓↓↓↓↓↓↓↓ 오류 미발생시 ↓↓↓↓↓↓↓↓--------- //
    /** 
     * 모두 True일 때, allErrorsTrue가 True 형태를 띰
     */
    const allErrorsTrue = Object.values(errorType).every(value => value);
    if (allErrorsTrue) {
      let conbi = { ...bonusEdit, ...vipBonus, ...sendTouch };
      console.log("conbi", conbi)

      if (conbi.pointUse === 'N') {
        conbi.point = 0;
      }
      else if (conbi.earn !== "S" || (conbi.earn !== "S" && conbi.stampUse === "N")) {
        conbi.stamp = 0;
      }
      else if (conbi.earn !== "S" || (conbi.earn !== "S" && conbi.rpsUse === "N")) {
        conbi.rps = 0;
      }
      else if (conbi.method !== "2") {
        conbi.methodRatio = 0;
      }

      const updatedAdvancement = { ...storeInfo };

      // conbi 객체의 각 키에 대해 순회하면서 상태 업데이트
      for (const key in conbi) {
        if (conbi.hasOwnProperty(key) && storeInfo.hasOwnProperty(key)) {
          updatedAdvancement[key] = conbi[key]; // storeInfo 상태의 해당 키 값을 conbi의 값으로 업데이트
        }
      }

      // Recoil 상태 갱신
      setStoreInfo(updatedAdvancement);


      try {
        const response = await axios.put('/api/deviceMngr/setPointStngChgFromServer', updatedAdvancement);
        console.log("변경 후 api 통신::: ", updatedAdvancement)
        const result = response.data.data.message;
        const telegram = response.data.data.message.telegram;
        console.log("result", result);
        console.log("telegram", telegram);

        if (isMobile && telegram) {
          // eslint-disable-next-line no-undef
          getSettingJeonmun.postMessage("device_setting="+telegram);
          setIsNFC(true);
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
        setSystemTxt('설정 변경 중 오류가 발생했습니다.');
      }
    }

  };

  /**
   * 설정 변경되었습니다. 팝업 [확인] 클릭시
   * 1. Loading 화면 보여주고
   * 2. 2초 후에 리로드 
   */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    setComplete(false);
    setLoading(true);
    
    setTimeout(() => {
      setLoading(false);
      window.location.reload();

    }, 2000);
  };

  return (
    <div id="sub_common_wrap">
      {loading && <LoadingBar />}
      {iosNFC && <IosNfc />}
      {complete &&
        <SystemModal 
          system        ={complete} 
          setSystem     ={setComplete}
          handleFunction={handleButtonClick}
        >
          설정이 변경되었습니다.
        </SystemModal>
      }

      {
        error && (
          <SystemModal system={error} setSystem={setError}>
            {systemTxt}
          </SystemModal>
        )
      }

      {
        isNFC && !iosNFC && (
          <ModalPopup>
            <NFCpopup setIsNFC={setIsNFC} />
          </ModalPopup>
        )
      }
      <SubHead SubBgColor={'#fff'} bottomLine={true} >
        MY 가맹정보
      </SubHead>

      <section>
        <SectionSelect SectionTit={"터치올 단말 설정 상태"}>
          <SecondCon>
            <AdminTit>
              보너스 종류, 유형, 적립기준
            </AdminTit>


            <NewServiceSelect
              earn={storeInfo.earn}
              pointUse={storeInfo.pointUse}
              stampUse={storeInfo.stampUse}
              rpsUse={storeInfo.rpsUse}
              point={storeInfo.point}
              stamp={storeInfo.stamp}
              rps={storeInfo.rps}
              grpId={storeInfo.grpId}
              setBonusEdit={setBonusEdit}
              bonusEdit={bonusEdit}
            />

            {/* <ServiceSelect 
              storeInfo={storeInfo} 
              bonusEdit={bonusEdit} 
              setBonusEdit={setBonusEdit} 
              setError={setError} 
              systemTxt={systemTxt}
            /> */}
          </SecondCon>


          <SecondCon>
            <AdminTit>
              보너스 우대
            </AdminTit>
            <BonusSpacial  
              storeInfo={storeInfo} 
              vipBonus={vipBonus} 
              setVipBonus={setVipBonus}  
            />
          </SecondCon>

          <SecondCon>
            <AdminTit>
              보너스 전송(제공) 방법
            </AdminTit>

            <AdminCon>
              <Sublabel>
                <input 
                type='radio' 
                name='bonus_send' 
                value={"0"}
                checked={sendTouch.transfer === "0"}
                onChange={(e) => handleSendTouch('transfer', e.target.value, 'string')}
                />
                <span></span>
                모두이용
              </Sublabel>

              <Sublabel>
                <input type='radio' 
                name='bonus_send'
                value={"1"}
                checked={sendTouch.transfer === "1"} 
                onChange={(e) => handleSendTouch('transfer', e.target.value, 'string')}
                />
                <span></span>
                스마트폰 NFC 태크
              </Sublabel>

              <Sublabel>
                <input type='radio' 
                name='bonus_send'
                value={"2"}
                checked={sendTouch.transfer === "2"} 
                onChange={(e) => handleSendTouch('transfer', e.target.value, 'string')}
                />
                <span></span>
                WiFi / LAN 이용서비스 전송
              </Sublabel>
            </AdminCon>
          </SecondCon>

          <SecondCon>
            <AdminTit>
              스마트폰 터치 대기시간
            </AdminTit>

            <AdminCon>
              <PhoneTime>
                30 ~ 120초 범위 : 
                <VipFloatInput 
                setPointEdit={(value) => handleSendTouch('delay', value, 'string')}
                inputValue={sendTouch.delay || ""} 
                inputName={'time'} />
                초
              </PhoneTime>
            </AdminCon>
          </SecondCon>

        </SectionSelect>
      </section>

      <FixingBTN onClick={handleDeviceEdit}>단말 정보 수정하기</FixingBTN>
    </div>
  );
};

const SecondCon = styled.div`
margin-top: 32px;
`

const AdminCon = styled.div`
background-color: #fff;
padding: 16px;
margin-top: 12px;
`

const Sublabel = styled.label`
display: flex;
justify-content: start;
align-items: center;
gap: 4px;

margin-bottom: 12px;

font-size: 18px;
color:#222;

cursor:pointer;

&:last-child {
  margin-bottom:0;
}

input[type='radio'] {
  display: none;
}

/* 체크박스 커스텀 이미지 적용 */
input[type='radio'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${checkoff});
  background-size: cover;
}

/* 체크된 상태의 이미지 적용 */
input[type='radio']:checked + span {
  background-image: url(${checkon2});
}
`

const PhoneTime = styled.p`
display: flex;
justify-content: start;
align-items: center;

font-size: 18px;

input[name="time"] {
  width: 40px;
  border: 1px solid #ddd;
  margin: 0 2px 0 4px;
  font-size: 18px;
}
`

const FixingBTN = styled.button`
display: flex;
justify-content: center;
align-items: center;

height: 40px;
font-size: 18px;
width: calc(100% - 32px);
background:#222;
color:#fff;
border-radius: 4px;

margin: 32px 0 40px 16px;
`

export default MyStoreInfo;