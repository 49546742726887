import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import { medal1 } from '../../../../assets/images';
import Grade from '../../../components/TouchOll/mygrade/Grade';
import axios from 'axios';
// import { handleHeaderToken } from '../../../components/common/function/BearerToken';

const MyGrade = () => {
  const loginUserToken = localStorage.getItem("Usertokenkey");

  // 토큰 보내기
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${loginUserToken}`;
  }, [loginUserToken])
  
  const [myGrade, setMyGrade] = useState(''); // 나의 등급
  const [month, setMonth]     = useState(''); // 달
  const [cnt, setCnt]         = useState(''); // 방문횟수

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/myPageMngr/getGradeInfo`);
        console.log("## grade ::", response?.data?.data?.message);

        // myGrade 값을 정수로 변환
        const gradeValue = parseInt(response?.data?.data?.message?.grade);
        setMyGrade(gradeValue);
        
        // month 값을 정수로 변환
        const monthValue = parseInt(response?.data?.data?.message?.month);
        setMonth(monthValue);

        // cnt 값을 정수로 변환
        const cntValue = parseInt(response?.data?.data?.message?.useCnt);
        setCnt(cntValue);

      } catch (error) {
        // 에러 핸들링
        console.log("error ::", error)
      }
    };

    fetchData();
  })

  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        MY 학년
      </SubHead>
      <section>
        <MygradeMedal>
          <h1>
            <img src={medal1} alt={`${myGrade + 1 || 0}학년`} />
          </h1>
          <h3>{myGrade + 1 || 0}학년</h3>
          <p><em>{month || 0}월 {cnt || 0}회</em> 이용하였습니다.</p>
        </MygradeMedal>

        <GradeList>
          <h1>회원등급안내</h1>
            <Grade grademedal={1} percent={5}  month={5}  credit={5} />
            <Grade grademedal={2} percent={10} month={10} credit={10} />
            <Grade grademedal={3} percent={15} month={20} credit={20} />
            <Grade grademedal={4} percent={20} month={20} credit={20} />
        </GradeList>

        <GradeTxt>
          <ul>
            <li>
              <em>·</em>
              <p>매일 24시 30분에 전일 - 최근 30일간 전체 적립 이용회수,<br/>
              금액을 학점 산출한 것을 토대로 등급(학년)갱신
              </p>
            </li>
            <li>
              <em>·</em>
              <p>
                이용회수 1점 + 이용액 1천원당(소수점 이하 절사) 0.1점 + <br/>
                전일로 부터 60일간 학점 20% (소수점 이하 절사)
              </p>
            </li>
          </ul>
        </GradeTxt>
      </section>
    </div>
  );
};

const MygradeMedal = styled.div`
padding: 20px 0 45px 0;
border-bottom: 5px solid #F6F6F6;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

h1 {
  img {
    width: 160px;
  }
}

h3 {
  font-size: 20px;
  color:#7B8531;
  font-weight:bold;
  margin-bottom: 8px;
}

p {
  font-size: 14px;
  font-weight:bold;
  
  em {
    color:#FE7A36;
  }
}
`

const GradeList = styled.div`
h1 {
  font-size: 14px;
  font-weight:bold;
  text-align:center;
  color:#7B8531;
  margin-top: 10px;
  margin-bottom: 20px;
}

padding: 20px 0 32px 0;
border-bottom: 5px solid #F6F6F6;
`

const GradeTxt = styled.div`
width: calc(100% - 32px);

font-size: 12px;

padding: 32px 16px;

li{
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 4px;

  &:first-child {
    margin-bottom: 20px;
  }

  p{
    line-height: 18px;
  }

  em {
    padding-top: 2px;
  }
}

`
export default MyGrade;