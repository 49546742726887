import React from 'react';
import {
    moor
  , pointicon
  , stampicon
  , gifticon,
  logo_txt,
  ex_icon
} from "../../../../assets/images"
import styled from 'styled-components';
import {formatCurrency} from "../../common/function/FormatNumber"
import CommonBonusBtn from '../../common/CommonBonusBtn';
import CommonIconList from '../../common/CommonIconList';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const StoreList = ({ 
    type
  , typeName
  , earn
  , date
  , cash
  , store
  , starCount
  , Icons
  , pointUse
  , stampUse
  , rpsUse
  , perCent
  , stamp
  , rps
  , pointSum
  , kind 
  , approval
  , userId
  , ex
}) => {
  /**
   * type       : 적립유형
   * typeName   : 적립 유형 이름
   * date       : 날짜
   * cash       : 금액
   * store      : 가게 이름
   * starCount  : 별점 
   * Icons      : ['hot', 'recom', 'top', 'celebs', 'new'] 리스트 형태
   * pointUse   : 포인트 사용여부
   * stampUse   : 스탬프 사용여부
   * rpsUse     : 경품 사용여부
   * perCent    : 적립 % 
   * stamp      : 스탬프
   * rps        : 경품
   * pointSum   : 포인트
   * kind       :
   * approval   :
   * userId     :
   * ex         : 비회원일 경우
   */

  const navigate = useNavigate();

  const DetailBtn = (use) => {
    const fetchData = async () => {
      try {
        const requestData = {
          approval: approval
          , userId: userId
          , type: type
        }

        console.log("requestData", requestData);

        const response = await axios.post('/api/pointMngr/inQueryPointInfo', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log("결제 조회되는 데이터: ", response?.data?.data?.message)
        const data     = response?.data?.data?.message;
        const stampUse = data?.store?.stampUse;
        const pointUse = data?.store?.pointUse;

        localStorage.setItem('pageState', JSON.stringify(data));
        const storedData = localStorage.getItem('pageState');

        if(use === 'Receipt'){
          navigate("/app/receipt")
        } else if (use === 'DetailBonus'){
          if (kind === 'R' && type !== '2' && type !== '4'){
            navigate("/app/rpsdetail")
          } else if ((kind === 'S' || kind === 'P') && (type === '2' || type === '4')){
            navigate("/app/type24detail", {state: { data: data , kind: kind}})
          } else (
            navigate("/app/detail")
          )
        } else if (use === 'StoreDetail'){
          navigate("/app/store_detail")
        }
        
        // if (kind !== "C" && stampUse === 'Y') {
        //   // stampUse 값이 'Y'인 경우 '/stamp' 페이지로 이동
        //   navigate("/common/stamp_result", { state: { data: JSON.parse(storedData)} });
        // }
        // else if (kind !== "C" && pointUse === 'Y') {
        //   // pointUse 값이 'Y'인 경우 '/point' 페이지로 이동
        //   navigate("/common/point_result", { state: { data: JSON.parse(storedData)} });
        // }
        // else {
        //   navigate("/common/point_result", { state: { data: JSON.parse(storedData) } });
        // }

      } catch (error) {
        console.log("flutter 전달 상세 오류 ::".error);

        !store && navigate('/app/no_member')
      }
    }

    fetchData()
  }



  return (
    <StoreListWrap >
      <div>
        <p className='date_count'>
          {/* 날짜만 보여주려면 (0,10) */}
          <span>{date?.substr(2, 14)}</span>

          <button onClick={() => DetailBtn('Receipt')}>
            {/* 이용내역 상세 */}
            {cash?.toLocaleString()}원 
            <em>
              <svg width="5" height="7" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.86157 5.18005C7.3951 5.71951 7.3951 6.58788 6.86157 7.12734L2.75337 11.2812C2.21564 11.8249 1.33896 11.8297 0.795257 11.292C0.251552 10.7543 0.246709 9.8776 0.784439 9.33389L3.92969 6.1537L0.784439 2.9735C0.246709 2.42979 0.251553 1.55311 0.795258 1.01538C1.33896 0.477654 2.21564 0.482498 2.75337 1.0262L6.86157 5.18005Z"
                  fill="#ffffff" />
              </svg>
            </em>
          </button>
        </p>

        <div className='store_tit'>
          <h3 onClick={() => DetailBtn('StoreDetail')}>{store ? store : <img src={logo_txt} alt='터치올' />}</h3>
          <p className={`${!starCount ? 'no_star_count' : ''}`}>
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.73438 12.8789L4.2793 8.13477L0.259766 5.22266H5.22266L6.75391 0.492188L8.29883 5.22266H13.2754L9.24219 8.13477L10.8008 12.8789L6.75391 9.95312L2.73438 12.8789Z" fill="#FE7A36"/>
            </svg>
            {starCount ? starCount.toFixed(1) : 0.0}
          </p>
        </div>

        {/* 가맹점 뱃지 */}
        <CommonIconList Icons={Icons} />

        {
          type === '5' ? 
          <p className='bank'>국민은행 000000-01-000000</p> : 
          <div className={`percent ${earn === "T" ? "type1" : earn === "G" ? "type2" : "type3"}`}>
            <em>
              {earn === "T" ? "통합" : earn === "G" ? "그룹" : "단독"}
            </em>
            {pointUse === 'Y' && <p className='pp'><img src={pointicon} alt='포인트' />{perCent.toFixed(1)}%</p>}
            {stampUse === 'Y' && <p className='ss'><img src={stampicon} alt='스탬프' />{formatCurrency(stamp)}당 1S</p>}
            {rpsUse   === 'Y' && <p className='gg'><img src={gifticon}  alt='경품'  />{formatCurrency(rps)}당 1G</p>}
          </div>
        }  
        
      </div>

      <RightBtn>
        <span onClick={() => DetailBtn('StoreDetail')}>
          <img src={moor} alt='가맹점 상세' />
        </span>

        {/* 결제 내역 버튼 */}
        <div>
          <CommonBonusBtn 
            type      ={type}
            pointSum  ={pointSum} 
            kind      ={kind} 
            DetailBtn ={() => DetailBtn('DetailBonus')}
          />
        </div>
      </RightBtn>

      {
        ex && <ExIcon><img src={ex_icon} alt='ex' /></ExIcon> 
      }
      

    </StoreListWrap>
  );
};

const ExIcon = styled.div`
position: absolute;
left: 120px;
bottom: 20px;
`

const StoreListWrap = styled.div`
  width: calc(100% - 32px);
  padding: 20px 16px;
  border-bottom:1px solid #ddd; 

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  .date_count {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;

    font-size: 13px;
    margin-bottom: 8px;

    span {
      color:#aaa;
    }

    button {
      font-size : 14px;
      font-weight: 700;
      color: #222;

      display: flex;
      justify-content: start;
      align-items: center;
      gap:4px;

      em {
        background:#555;
        color:#fff;
        width: 12px;
        height: 12px;
        display:inline-block;
        font-size: 10px;
        border-radius: 12px;
      }
    }
  }

  .store_tit {
    display: flex;
    justify-content: start;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #222;

      img {
        height: 16px;
      }
    }

    p {
      display: flex;
      justify-content: start;
      align-items: flex-start;
      margin-left: 4px;

      font-size: 14px;
      color:#FE7A36;

      &.no_star_count {
        svg {
          path {
            fill: #aaa;
          }
        }

        color:#aaa;
      }
    }
  }

  .bank {
    font-size: 14px;
    color:#444;
    margin-top: 10px;
  }

  .percent {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
    margin-top: 8px;

    &.type1{
      em {
        background: #5D0EF5;
      }
    }

    &.type2{
      em {
        background: #8E3D82;
      }
    }

    &.type3{
      em {
        background: #7F7F7F;
      }
    }


    em {
      color: #fff;
      padding: 1px 2px;
      
      border-radius: 2px;
      font-size: 14px;
    }

    p {
      font-size: 14px;

      display: flex;
      justify-content: start;
      align-items: center;
      gap: 2px;

      img {
        width: 16px;
      }

      &.pp{ color: #7B8531; }
      &.ss{ color: #EC635D; }
      &.gg{ color: #2860A7; }
    }
  }
`

const RightBtn = styled.div`
display: flex;
justify-content: start;
align-items: start;

span {
width: 30px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
}

& > div {
  width:80px;
}

`

export default StoreList;