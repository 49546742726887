import React from 'react';
import styled from 'styled-components';

const Help = () => {
  return (
    <HelpIcon>
      ?
    </HelpIcon>
  );
};

const HelpIcon = styled.em`
width: 18px;
height: 18px;
color:#fff;
background:#042F8B;
border-radius: 20px;

display: flex;
  justify-content: center;
  align-items: center;
`
export default Help;