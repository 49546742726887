import React from 'react';
import styled from 'styled-components';

const CommonIconList = ({Icons}) => {
  /**
   * 가맹점 뱃지
   */
  return (
    <IconList>
      {Icons.includes('hot')    && <Hot>HOT</Hot>}
      {Icons.includes('high')    && <High>High %</High>}
      {Icons.includes('plus')    && <Plus>Plus %</Plus>}
      {Icons.includes('recom')  && <Recom>추천</Recom>}
      {Icons.includes('top')    && <Top>TOP</Top>}
      {Icons.includes('celebs') && <Celebs>셀럽</Celebs>}
      {Icons.includes('new')    && <NEW>NEW</NEW>}
    </IconList>
  );
};

const IconList = styled.ul`
  margin-top: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;

  li {
    font-size: 10px;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    
    padding: 2px 5px;
  }
`

const Hot = styled.li`
border-color: #FFE4D7;
color: #FF5F01;
`
const High = styled.li`
border-color: #FFAF80;
color: #FC5D00;
`
const Plus = styled.li`
border-color: #8980FF;
color: #1400FF;
`
const Recom = styled.li`
border-color: #D3EADD;
color: #219653;
`
const Top = styled.li`
border-color: #FBDDDD;
color: #EB5757;
`
const Celebs = styled.li`
border-color: #EBDCF9;
color: #5D0EF5;
`
const NEW = styled.li`
border-color: #CCECFF;
color: #00A1FF;
`
export default CommonIconList;