import React from 'react';
import RpsQPopup from '../../TouchOll/RpsQPopup';
import styled from 'styled-components';
import { paper_select, paper_small, rock_select, rock_small, scissor_select, scissor_small } from '../../../../assets/images';
import EarnIcon from '../EarnIcon';
import PSGmodelPopup from '../PSGmodelPopup';

const RpsResult = ({ storeInfo, rpsinfo, pointInfo, useType }) => {
  // const rpsScore       = Number(sumBonusScore).toLocaleString();
  // const cancelrpsScore = Number(cancelSumBonusScore).toLocaleString();
 console.log("useType", useType);
  const sumBonusScore       = pointInfo?.sumBonusScore  ;    
  const cancelSumBonusScore = pointInfo?.cancelSumBonusScore  ;
  const cancelRpsScore = Number(pointInfo?.cancelRpsScore).toLocaleString();
  
  const earnType   = storeInfo?.storeEarn || 'T'; // earn 유형
  const storeName  = storeInfo?.storeName || "가맹점 이름"
  
  return (
    <RpsWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>

      <h3>
        터치올<em>-</em>
        <span>가위바위보 경품게임</span>
        <PSGmodelPopup type={'g'} />
      </h3>


      <RpsGameWrap>
        <ul>
          <li>
            <img src={'s' === rpsinfo?.show ? scissor_select : scissor_small} />
          </li>
          <li>
            <img src={'r' === rpsinfo?.show ? rock_select : rock_small} />
          </li>
          <li>
            <img src={'p' === rpsinfo?.show ? paper_select : paper_small}   />
          </li>
        </ul>

        <p>{  'w' === rpsinfo?.rpsResult ? '축하합니다! 승리!' 
            : 'd' === rpsinfo?.rpsResult ? '무승부! 아깝습니다' 
            : 'l' === rpsinfo?.rpsResult ? '패배! 아쉽습니다.' 
            : '게임 결과를 확인하세요.'}</p>

        <div>
          <p>경품승점 적립완료!</p>
          <span><em>{useType === "1" || useType === "2" ? sumBonusScore : cancelSumBonusScore}</em>점</span>
        </div> 

      </RpsGameWrap>
    </RpsWrap>
  );
};


const RpsWrap = styled.div`
  width: 100%;
  background:#fff;
  border-radius: 12px;
  // padding: 12px 16px;

   // 가게 상호
  h1{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  // 터치올-
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FF5F01;

    margin-top: 24px;

    display: flex;
    justify-content: start;
    align-items: center;

    em {
      color: #909090;
    }

    span {
      color: #2860A7;

      display: flex;
      justify-content: start;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
  }

 
`

const RpsGameWrap = styled.div`
  background-color: #F6F6F6;
  border-radius: 8px; 
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 32px 0 ;


  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-top: 16px;

    p{
      font-size: 14px;
      color:#4D6BAB;
    }

    span {
      font-size: 14px;
      color:#4D6BAB;
      display: flex;
      justify-content: flex-end;
      align-items: center;

    em {
      font-weight:26px;
      font-size: 26px;
      color:#4D6BAB;
    }
    }
    

  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    li {
      width: 60px;
      height: 60px;

      img {
        width:100%;
        height: auto;
      }
    }
  }

  &>p {
  color: #2860A7 !important;
  margin-top: 32px;
  font-size: 24px;
  font-weight:bold;
  }

  h4 {
  color: #E95513;
  font-weight: 600;
  margin-top: 12px;
  font-size: 16px;
  }

`
export default RpsResult;