import React, { useState } from 'react';
import styled from 'styled-components';
import ModalPopup from './ModalPopup';
import ReactPlayer from 'react-player/lazy';

const AdImgPopup = ({
    isVideo
  , imgUrl
  , imgName
  , img2Url
  , img2Name
  , videoUrl
  , videoName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <ImgPopupWrap>
      {
        !isVideo ? <img src={imgUrl} alt={imgName} onClick={handleIsOpen} /> :
        <div className="iframeContainer iframe4To3" onClick={handleIsOpen}>
          <ReactPlayer
            url={videoUrl}
            muted
            // controls
            width={"100%"}
            height={"100%"}
          />
   
        </div>
       
      }

      {
        isOpen && !isVideo ? 
        <ModalPopup>
          <div className='modal_content'>
            <img src={img2Url} alt={img2Name} />
            <button onClick={handleIsOpen}>닫기</button>
          </div>
          
        </ModalPopup>
        : <></>
      }
    </ImgPopupWrap>
  );
};

const ImgPopupWrap = styled.div`
width: 100%;
height: max-content;

img {
  width: 100%;
  height: auto;
}

.iframeContainer {
  position: relative;
  width: 100%;
}
.iframeContainer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.iframe4To3 {
  padding-top: 70%;
}

.modal_content {
  width: calc(100vw - 64px);
  height: content;
  max-height: 80vh;
  padding: 32px 16px;
  background:#fff;

  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 8px;
  }

  button {
    width: 100%;
    height: 40px;
    background: #FF5F01;
    font-size: 16px;
    color:#fff;
    font-weight:bold;
    border-radius: 8px;
    margin-top: 16px;
  }
}
`

export default AdImgPopup;