import React from 'react';
import styled from 'styled-components';
import {
  p_icon 
} from "../../../../assets/images";
import CashQPopup from '../../TouchOll/CashQPopup';

const WebCashTit = ({data, state, sumCash}) => {
  /**
   * data 
   * state : 0 - 입금 ・ 적립대기 | 1 - 입금 ・ 적립 완료 | 2 - 입금 ・ 적립 취소!
   * sumCash : data?.amount 외 다른 부분
   */

  const storeInfo = data?.store
  const pointInfo = data?.point; 
  const receiptInfo = data?.receipt || data?.receipt; 

  const pointStata0        = Number(data?.point).toLocaleString();
  const sumCashBonusPoint  = Number(pointInfo?.sumCashBonusPoint).toLocaleString();
  const cancelSttlePoint   = Number(pointInfo?.cancelSttlePoint).toLocaleString();

  return (
    <CashTitWrap> 
      <h1>
        {/* 스토어 이름 */}
        {storeInfo?.storeName} 

        {/* 스토어 earn */}
        <em className={`earn${
          storeInfo?.storeEarn === "통합형" || storeInfo?.storeEarn === "T"  
          ? "T" 
          : storeInfo?.storeEarn === "그룹형" || storeInfo?.storeEarn === "G" 
          ? "G" : "S" }`}>
          {storeInfo?.storeEarn === "통합형" || storeInfo?.storeEarn === "T" 
          ? "통합" 
          : storeInfo?.storeEarn === "그룹형" || storeInfo?.storeEarn === "G" 
          ? "그룹" : "단독" }
        </em>
      </h1>

      <h3>터치올<span>-</span>
      {
        receiptInfo?.stlmMethodCode !== "9" ?
          <>
            <em>현금</em>
            <img src={p_icon} />
            <CashQPopup />
          </> 
          : <em>거스름돈</em>
      }

        
      </h3>
      <BonusSU className='ppp'>
        <p className={`state${state}`}>{state === 0 ? "입금 ・ 적립대기" : state === 1 ?  "입금 ・ 적립 완료" : "입금 ・ 적립 취소!"}</p>
        <div> 
          <p className={`state${state}`}>
            <strong>
              {
                state === 0 ? pointStata0 : state === 1 ?  sumCashBonusPoint : cancelSttlePoint
              }

              {/* { 
                sumCash ? Number(sumCash).toLocaleString()
                : Number(pointInfo).toLocaleString()
              } */}
            </strong>원
          </p>
        </div>
      </BonusSU>
    </CashTitWrap>
  );
};

const CashTitWrap = styled.div`
h1 {
  color: #2860a7;
  font-weight: 800;
  font-size: 18px !important;

  margin-right: 4px;
  vertical-align: middle;

  em {
    background-color: #5d0ef5;
    color: #ffffff;
    padding: 1px 5px 1px 5px;
    font-size: 14px;
    border-radius: 3px;
    margin-left: 4px;
    
    &.earnT {
      background-color: #5d0ef5;
    }

    &.earnG {
      background-color: #8E3D82;
    }

    &.earnS {
      background-color: #7F7F7F;
    }
  }
}

h3 {
  margin-top: 26px;

  font-size: 16px;
  font-weight: 700;
  color: #222;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;

  color:#FF5F01 !important;

  span {
    color: #909090;
  }

  em {
    color: #7B8531;

  }

  img {
    width: 20px;
    height: 20px;
  }

}
`

const BonusSU = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

  }

  p {
    font-weight: 700;
    font-size: 14px;

    &.state0{
      color: #707070;

      strong{
        color: #707070;
      }
    }

    &.state1{
      color: #7B8531;

      strong{
        color: #7B8531;
      }
    }

    &.state2{
      color: #FB0E0E;

      strong{
        color: #FB0E0E;
      }
    }
    
    strong {
      font-weight: 700;
      font-size: 26px;
      margin-right: 4px;
    }
  }
`

export default WebCashTit;