import React, { useEffect, useState } from 'react';
import SystemModal from '../../common/SystemModal';

const AutoTimer = ({count, setCount, countOn, setCountOn}) => {
  const [popupTxt, setPopupTxt]     = useState(''); // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false); // 시스템 팝업

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    if(countOn){
        const id = setInterval(() => {
        setCount(count => count - 1);
      }, 1000);

      if (count === 0) {
        clearInterval(id);
        setCountOn(false);
        setPopupTxt("유효시간이 초과되었습니다.")
        setSystem(!system)
      }
      return () => clearInterval(id);
    }
    
  }, [count]);

  return (
    <>
      {
        /**
         * 시스템 모달 팝업 창
         */
        system && (
          <SystemModal system={system} setSystem={setSystem}>
            {popupTxt}
          </SystemModal>
        )
      }

    <span>{formatTime(count)}</span>
    </>
    

  );
};

export default AutoTimer;