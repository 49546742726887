import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paper, paper_small, rock, rock_small, scissor, scissor_small } from '../../../../assets/images';
import EarnIcon from '../EarnIcon';
import axios from 'axios';
import styled from 'styled-components';
import PSGmodelPopup from '../PSGmodelPopup';

const RpsStand = ({data, point}) => {
  const navigate = useNavigate();
  const images = [rock, scissor, paper];
  const storeInfo = data?.store; 

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (fileName) => {        

    let rps;

    if      (fileName === 'rock_small') {
      rps = 'r';
    } 
    else if (fileName === 'paper_small') {
      rps = 'p';
    } 
    else if (fileName === 'scissor_small') {
      rps = 's';
    }

    const getRpsResultData = async () => {
      try {
        const requestData = {
            rps     : rps
          , point   : point
        }

        let response = await axios.post('/api/pointMngr/requestPointAccml', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log("getRpsResultData :", response?.data?.data?.message);
        const { stampUse, pointUse } = response?.data?.data?.message?.store;

        if (stampUse === 'Y') {
          // stampUse 값이 'Y'인 경우 '/stamp' 페이지로 이동
          navigate("/common/stamp_result", { state: { data: response?.data?.data?.message }});
        }
        if (pointUse === 'Y') {
          // pointUse 값이 'Y'인 경우 '/point' 페이지로 이동
          navigate("/common/point_result", { state: { data: response?.data?.data?.message }});
        }
 
      } catch (error) {
        // 410 에러가 발생했을 때의 처리
        if (error.response && error.response.status === 410) {
          navigate('/error'); // 에러 페이지로 이동
        } else {
          // 기타 에러 처리
          console.error("Error getRpsResultData:", error);
        }
        
      }
    };
    
    getRpsResultData();
  }

  const storeName  = data?.store?.storeName || "가맹점 이름"
  const earnType   = data?.store?.storeEarn || 'T'; // earn 유형

  return (
    <RpsWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>

      <h3>
        <span>터치올<em>-</em></span>
        가위바위보 경품게임&nbsp;
        <PSGmodelPopup type={'g'} />
      </h3>

      <h2 className="type0">
        <p>적립대기</p>
      </h2>

      <RpsGameWrap>
        <div>
          <img src={images[currentImageIndex]} />
        </div>
        
        <ul>
          <li>
            <img
              src={scissor_small}
              onClick={() => handleImageClick('scissor_small')}
            />
          </li>
          <li>
            <img
              src={rock_small}
              onClick={() => handleImageClick('rock_small')}
            />
          </li>
          <li>
            <img
              src={paper_small}
              onClick={() => handleImageClick('paper_small')}
            />
          </li>
        </ul>

        <p>가위바위보 중 하나를 선택하세요.</p>
        <h4>승리시 경품 승점!</h4>

      </RpsGameWrap>
      
    </RpsWrap>
  );
};


const RpsWrap = styled.div`
  width: 100%;
  background:#fff;
  border-radius: 12px;

  h3 {
    color: #2860A7 !important;
    font-weight: 600;
    margin-top: 24px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:0px;

    span {
      color:#FF5F01 !important;
      margin-right: 0;

      em {
        color:#909090;
      }
    }
  }

  h2 {
    margin-top: 16px !important;
    margin-bottom: 12px !important;
  }
`

const RpsGameWrap = styled.div`
  background-color: #F6F6F6;
  border-radius: 8px; 
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 32px 0 ;


  &>div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    margin: 20px 0 32px 0;

    img {
      width: 100%;

    }
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    li {
      width: 60px;
      height: 60px;

      img {
        width:100%;
        height: auto;
      }
    }
  }

  p {
  color: #707070;
  font-weight: 500;
  margin-top: 32px;
  font-size: 16px;
  }

  h4 {
  color: #E95513;
  font-weight: 600;
  margin-top: 12px;
  font-size: 16px;
  }

`
export default RpsStand;