import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import ToggleList from '../../../components/TouchOll/board/ToggleList';


const MyHot = () => {
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        My Hot 소식
      </SubHead>

      <section>
        <ToggleList 
        title = {"My 소식 입니다."}
        text = {"긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다."}
        date = {"2023.05.05"}
        headtext={'긴급'} />
        
        <ToggleList 
        title = {"My 소식 입니다."}
        text = {"긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다."}
        date = {"2023.05.05"}
        headtext={'긴급'} />

        <ToggleList 
        title = {"My 소식 입니다."}
        text = {"긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다."}
        date = {"2023.05.05"} />
      </section>
    </div>
  );
};

export default MyHot;