import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { 
    c_txt
  , c_txt2
  , g_txt
  , g_txt2
  , p_txt
  , p_txt2
  , pp_txt
  , pp_txt2
  , s_txt
  , s_txt2
  , t2_txt
  , t3_pxt
  , t3_txt
  , t4_pxt
  , t4_txt
  , t_txt 
} from '../../../assets/images';

const CommonBonusBtn = ({
    type
  , pointSum
  , kind
  , DetailBtn
}) => {
  /**
   * type     : 적립, 취소, 사용 여부, (통장입금) 
   * pointSum : 포인트 값
   * kind     : 포인트, 스탬프, 경품 여부
   */

  return (
    <BtnPoint className={`type${type} kind${kind}`} onClick={DetailBtn}>
      {/* 각 내역 상세 */}
      <h5>
        {/* <em>{kind}</em> */}
        <span>
          <img src=
            {
              (type === "1" || type === "2") && (kind === "P" || kind === "MP") ? p_txt   : // 현금
              (type === "1" || type === "2") && (kind === "S" || kind === "MS") ? s_txt   : // 스탬프
              (type === "1" || type === "2") && (kind === "R")                  ? g_txt   : // 게임
              (type === "1" || type === "2") && (kind === "C")                  ? c_txt   : // 거스름돈
              (type === "3" || type === "4") && (kind === "P" || kind === "MP") ? p_txt2  : // 현금_취소
              (type === "3" || type === "4") && (kind === "S" || kind === "MS") ? s_txt2  : // 스탬프_취소
              (type === "3" || type === "4") && (kind === "R")                  ? g_txt2  : // 게임_취소
              (type === "3" || type === "4") && (kind === "C")                  ? c_txt2  : // 거스름돈_취소
               type === "5"                                                     ? pp_txt  : <></>
            } 
          />
        </span>
        <svg width="6" height="8" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path   
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M6.86157 5.18005C7.3951 5.71951 7.3951 6.58788 6.86157 7.12734L2.75337 11.2812C2.21564 11.8249 1.33896 11.8297 0.795257 11.292C0.251552 10.7543 0.246709 9.8776 0.784439 9.33389L3.92969 6.1537L0.784439 2.9735C0.246709 2.42979 0.251553 1.55311 0.795258 1.01538C1.33896 0.477654 2.21564 0.482498 2.75337 1.0262L6.86157 5.18005Z" 
          fill="#5D0EF5"/>
        </svg>
      </h5>
      
      <h6>
        <span>
          <img src=
            {
              (type === "0" || type === 0)                  ? t_txt : // 적립
              (type === "1" || type === 1)                  ? t_txt : // 적립
              (type === "2" || type === 2) && kind !== "P"  ? t3_txt: // 적립취소
              (type === "2" || type === 2) && kind === "P"  ? t3_pxt: // 적립취소
              (type === "3" || type === 3)                  ? t2_txt: // 사용
              (type === "4" || type === 4) && kind !== "P"  ? t4_txt: // 사용
              (type === "4" || type === 4) && kind === "P"  ? t4_pxt: // 사용취소
              (type === "5" || type === 5)                  ? pp_txt2: <></> // 사용취소
            }
          />
        </span>
        {Number(pointSum).toLocaleString()}
      </h6>
    </BtnPoint>
  );
};


const BtnPoint = styled.button`
  width: 100%;
  max-width: 92px;
  height: 84px;
  // min-width: 86px;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  overflow:hidden;

  h5 {
    width: calc(100% - 8px);
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 700;

    span {
      width : calc(100% - 12px);
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      text-align:left;

      img {
        height: 16px;
      }
    }

    em {
      border-radius:20px;
      
      font-weight: 700;
      font-size: 12px;

      min-width: 14px;
      height: 14px;
      display:block;
      margin-right: 4px;
    }

  }

  h6 {
    width: calc(100% - 8px);
    height:100%;

    font-size: 14px;
    font-weight: bold;
    // text-size-adjust: none;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    padding: 0 4px;

    span {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      height: 20px;

      img {
        height: 14px;
      }
    }
  }

  &.type0 {
    border:3px solid #595959;

    h5 {
      span {
        color:#595959;
      }
      svg path {
        fill:#595959;
      }

      em {
        color:#595959;
        border:1px solid #595959;
      }
    }
    h6 {
      background:#595959;
      color:#fff;

      span {
        color:#fff;
      }
    }
  }

  &.type1.kindP , &.type1.kindMP {
    border:3px solid #7B8531;

    h5 {
      span {
        color:#7B8531;
      }
      svg path {
        fill:#7B8531;
      }

      em {
        color:#7B8531;
        border:1px solid #7B8531;
      }
    }
    h6 {
      background:#7B8531;
      color:#fff;

      span {
        color:#fff;
      }
    }
  }

  &.type1.kindS , &.type1.kindMS{
    border:3px solid #FC1F39;

    h5 {
      span {
        color:#FC1F39;
      }
      svg path {
        fill:#FC1F39;
      }

      em {
        color:#FC1F39;
        border:1px solid #FC1F39;
      }
    }
    h6 {
      background:#FC1F39;
      color:#fff;

      span {
        color:#fff;
      }
    }
  }

  &.type1.kindR {
    border:3px solid #2860A7;

    h5 {
      span {
        color:#2860A7;
      }
      svg path {
        fill:#2860A7;
      }

      em {
        color:#2860A7;
        border:1px solid #2860A7;
      }
    }
    h6 {
      background:#2860A7;
      color:#fff;

      span {
        color:#fff;
      }
    }
  }

  &.type1.kindC {
    border:3px solid #595959;

    h5 {
      span {
        color:#595959;
      }
      svg path {
        fill:#595959;
      }

      em {
        color:#595959;
        border:1px solid #595959;
      }
    }
    h6 {
      background:#595959;
      color:#fff;

      span {
        color:#fff;
      }
    }
  }

  &.type2 {
    border:3px solid #EC664B;

    &.kindP, &.kindMP {
      h5 {
        span {
          color:#7B8531;
        }
        svg path {
          fill:#7B8531;
        }
  
        em {
          color:#7B8531;
          border:1px solid #7B8531;
        }
      }
    }

    &.kindS , &.kindMS {
      h5 {
        span {
          color:#FC1F39;
        }
        svg path {
          fill:#FC1F39;
        }
  
        em {
          color:#FC1F39;
          border:1px solid #FC1F39;
        }
      }
    }
    
    h6 {
      background:#EC664B;
      color:#fff;

      span, em {
        color:#fff;

      }
    }
  }

  &.type3, &.type4 {
    border:3px solid #AAAAAA;
    h5 {
      svg path {
        fill:#444;
      }

      span {
        color:#444;
      }

      em {
        color:#444;
        border:1px solid #444;
      }
    }
    h6 {
      background:#AAAAAA;
      color:#444;
      em {
        color:#444;
      }
    }
  }



  &.type5 {
    border:3px solid #9A65FF;
    h5 {
      
      svg path {
        fill:#9A65FF;
      }

      span {
        color:#5E813F;
      }
    }
    h6 {
      background:#9A65FF;
      color:#fff;
      em {
        color:#fff;
      }
    }
  }

  &.type7 {
    border:3px solid #5E813F;
    h5 {
      span {
        color:#5E813F;
      }

      svg path {
        fill:#5E813F;
      }
    }
    h6 {
      background:#5E813F;
      color:#fff;
      em {
        color:#5E813F;
      }
    }
  }
`

export default CommonBonusBtn;