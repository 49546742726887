import React, { useState } from 'react';
import styled from 'styled-components';

import { cgold, cicon, not_member, p_icon, pgold, slash } from '../../../../assets/images';
import CashQPopup from '../../TouchOll/CashQPopup';
import { useNavigate } from 'react-router';
import PointUseDetail from './PointUseDetail';
import EarnIcon from '../EarnIcon';
import useModalWithBackButton from '../function/HistoryModal';
import PreventGoBackComponent from '../function/GoBackMain';
import ModalPopup from '../ModalPopup';
import SystemPopup from '../SystemPopup';

const PointUseScreen = ({
    storeInfo
  , pointInfo
  , useType
  , receiptInfo
  , isApp
}) => {
  /**
   * storeInfo : 스토어 정보
   * pointInfo : 포인트 정보
   * type : 포인트 적립 | 적립 취소 | 포인트 사용 | 사용 취소
   */
  const earnType   = storeInfo?.storeEarn || 'T'; // earn 유형
  const storeName  = storeInfo?.storeName || "가맹점 이름"
  // const sumCashBonusPoint   = Number(pointInfo?.sumCashBonusPoint).toLocaleString();
  const sumCashBonusPoint         = Number(pointInfo?.sumCashBonusPoint).toLocaleString(); // 최종 포인트
  const cancelSumCashBonusPoint   = Number(pointInfo?.cancelSumCashBonusPoint).toLocaleString();

  const sttleCashBonusPoint       = Number(pointInfo?.sttlePoint + pointInfo?.cashBonusPoint).toLocaleString(); // 현금 + 추가
  const cancelSttleCashBonusPoint = Number(pointInfo?.cancelSttlePoint + pointInfo?.cancelCashBonusPoint).toLocaleString() // -(현금 + 추가)
  const sttlePoint                = Number(pointInfo?.sttlePoint).toLocaleString(); // 현금
  const cancelSttlePoint          = Number(pointInfo?.cancelSttlePoint).toLocaleString();
  const cashBonusPoint            = Number(pointInfo?.cashBonusPoint).toLocaleString(); // 추가
  const cancelCashBonusPoint      = Number(pointInfo?.cancelCashBonusPoint).toLocaleString();
  const cashGradeBonusPoint       = Number(pointInfo?.cashGradeBonusPoint).toLocaleString(); // 장학금
  const cancelCashGradeBonusPoint = Number(pointInfo?.cancelCashGradeBonusPoint).toLocaleString(); 

  const grade     = pointInfo?.grade    
  const userName  = pointInfo?.userName 
  const type      = useType
  // const type      = "1"
  const kind      = pointInfo?.kind     
  // const kind      = "C"     
   

  const navigate = useNavigate();

  const [isModal1Visible, toggleModal1] = useModalWithBackButton();
  
  const [joinPopup, setJoinPopup] = useState(false);
  const handleJoinPopup = () => {setJoinPopup(!joinPopup)}
  const handleAppDownload = () => {navigate('/app_download');};

  return (
    <>
    {/* <PreventGoBackComponent popupOpen={isModal1Visible} setOpen={toggleModal1} /> */}
    <PointWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>

      <h3 className={`kind${kind}`}>
          터치올<em>-</em>
          {
              kind === "P" ?
              <>
                <span>현금<img src={p_icon} alt='현금' /></span>
                <CashQPopup />
              </>
            : kind === "MP" ?
              <>
                <span>수동 현금<img src={p_icon} alt='현금' /></span>
                <CashQPopup />
              </>
            : <span>거스름돈<img src={cicon} alt='거스름돈' /></span>
          }

      </h3>

        <h2 className={`type${type} kind${kind}`}>
          <p>
            {
              type === "1" ? "입금 · 적립 완료" :
              type === "2" ? <><em className="pay">Pay</em> → 적립완료</> :
              type === "3" ? "적립 취소" :
              <><em className="pay">Pay</em> → 적립취소</>
            }
          </p>
        </h2>

        <PaySlash>
          <p className={`type${type} kind${kind}`}>
            <strong>
              {type === "1" || type === "2"
                ? sttleCashBonusPoint
                : cancelSttleCashBonusPoint}
            </strong>원
          </p>

          <ul className={`type${type} kind${kind}`}>
            <li>
              <span>{kind !== 'C' ? '현금' : '거스름돈'}<img src={kind !== 'C' ? p_icon : cicon} alt={kind} /></span>
              <p>
                {type === "1" || type === "2"
                  ? sttlePoint
                  : cancelSttlePoint}
                원
              </p>
            </li>
            <li>
              <span>추가적립<img src={kind !== 'C' ? p_icon : cicon} alt={kind} /></span>
              <p>
                {type === "1" || type === "2"
                  ? cashBonusPoint
                  : cancelCashBonusPoint}
                원
              </p>
            </li>
            
          </ul>
        </PaySlash>

      <div className='grade_point'>
        <span>장학금 <img src={kind !== 'C' ? pgold : cgold} alt={kind} /></span>
        <p>{type === "1" || type === "2" ? cashGradeBonusPoint : cancelCashGradeBonusPoint}원</p>
      </div>

      <h2 className={`type${type} kind${kind} sum_point`}>
        <p>합계</p>

        <p>
          <strong>
            {type === "1" || type === "2" ? sumCashBonusPoint : cancelSumCashBonusPoint}
          </strong>원
        </p>
      </h2>

      {
        userName === "고객" ? 
        <p><img src={not_member} onClick={handleJoinPopup} /></p> :
        <p>
          <em>{userName}님</em>은 현재<br/>
          터치올 대학 <em>{grade}학년</em>으로<br/><br/>
          장학금 {type === "1" || type === "2" ? cashGradeBonusPoint : cancelCashGradeBonusPoint}원 
          {
            type === "3" || type === "4" ? " 취소" : " 더 추가"
          }됨
        </p>
      }
      

      <ol>
        <li><button onClick={toggleModal1}>세부내역 확인</button></li>
        {
          isApp === "W" ? <></>
          : <li><button onClick={() => navigate('/app/useinfo', {replace: true})}>MY 이용내역 · 후기 평가</button></li>
        }
      </ol>
    </PointWrap>

    {
      isModal1Visible &&
      (
        <ModalPopup>
        <PointUseDetail 
          toggleModal       ={toggleModal1}
          earnType          ={earnType}
          storeName         ={storeName}
          sttlePoint        ={sttlePoint}
          userName          ={userName}
          grade             ={grade}
          sumCashBonusPoint ={type === "1" || type === "2" ? sumCashBonusPoint : cancelSumCashBonusPoint}
          pointInfo         ={pointInfo}
          type              ={type}
          receiptInfo       ={receiptInfo}
          kind              ={kind}
        />
        </ModalPopup>
      )
    }

    {/* 초간편 가입 권유 팝업 추가 */}
    { joinPopup &&
          <ModalPopup>
            <SystemPopup>
              <p>
                초간편 이용자에<br />
                가입하시겠습니까?
              </p>
              
              <PopupBtn>
                <li onClick={handleAppDownload}>
                  Yes
                </li>
                <li onClick={handleJoinPopup}>다음에</li>
              </PopupBtn>
            </SystemPopup>
          </ModalPopup>
        }
    </>
  );
};

const PointWrap = styled.div`
  width: calc(100% - 36px);
  padding: 16px;
  background: #fff;
  border: 2px solid #CBCBCB;
  border-radius: 12px;

  .type1, .type2{
    color: #7B8531 !important;

    em, p, strong {
      color: #7B8531 !important;
    }

 
  }

  .type3, .type4{
    color: #FB0E0E !important;

    em, p, strong {
      color: #FB0E0E !important;
    }

  }

  .kindC {
    color: #595959 !important;

    em, p, strong {
      color: #595959 !important;
    }

  }

  // 가게 상호
  h1{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  // 터치올 현금
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FE7A36 !important;

    margin-top: 24px;

    display: flex;
    justify-content: start;
    align-items: center;

    em {
      color: #909090;
    }

    span {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-right: 4px;
      color: #7B8531;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }

    &.kindP {
      span {
        color: #7B8531;
      }
    }

    &.kindC {
      color: #FE7A36 !important;
      span {
        color: #7B7878;
      }
    }
  }

  // 입금 적립 상태
  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-size: 30px;
    }
  }

  .sum_point {
    width: calc(100% - 32px);
    padding: 0 16px 8px 16px;
    margin-bottom: 32px;

    margin-top: 32px;

    border-bottom: 1px solid #707070;

    p {
      font-size: 16px;

      &:first-child{
        color:#707070 !important;
      }
    }
  }

  .grade_point {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: calc(100% - 32px);
    padding: 0 16px;
    height: 36px;
    background:#EAC234;
    border-radius: 8px;
    margin-top: 16px;

    span, p {
      color:#fff;
      font-weight:bold;
    }
    span{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:4px;
    }
    

  }

  // 포인트 사용, 취소시 
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;

    em {
      vertical-align: middle;
    }
  }

 

  // 000님 
  &>p {
    text-align: center;
    line-height: 19px;

    font-weight: bold;
    padding: 0 0 24px 0;

    em {
      color: #5D0EF5;
    }

    img {
      width: 100%;
    }
  }

  // 버튼
  ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    li {
      width: 100%;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: #9A65FF;
      border-radius: 4px;

      &:last-child{
        background: #2860A7;
      }

      button {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        width: 100%;
        height: 100%;
      }
    }
  }
`

const PaySlash = styled.div`
  width: calc(100% - 16px);
  background:url(${slash}) no-repeat;
  font-size: 14px;
  font-weight: bold;
  background-size: 100% 100%;
  padding: 0 8px 11px 8px;
  margin-top: 8px;

  strong {
    font-size: 30px;
  }

  &>p {
    height: 50px;
    line-height: 50px;
    text-align: right;
    // padding-right: 16px;
    margin-bottom: 8px;
  }

  // 현금, 장학금
  ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:stretch;
    gap: 8px;
  

    li{
      border-radius: 8px;
      border: 1px solid #707070;

      font-size: 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      line-height: 30px;

      padding:  0px 8px;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }

      span {
        display: flex;
        justify-content: start;
        align-items: center;
        color: #7B8531;
        font-weight: bold;
      }

      p{
        font-weight: bold;
      }
    }
    
  }

`


const PopupBtn = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 30px;

  li {

    &:first-child {
      background:#FF5F01;
      color:#fff;
      font-weight: 700;
    }

    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background:#eee;
    border-radius: 8px;
  }

  
`

export default PointUseScreen;