import React, { useEffect } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import MyinfoUser from '../../../components/TouchOll/mypage/MyinfoUser';
import MyinfoSelect from '../../../components/TouchOll/mypage/MyinfoSelect';
import MyinfoBonus from '../../../components/TouchOll/mypage/MyinfoBonus';
import styled from 'styled-components';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import { UserInfoResult } from '../../../../recoil/recoilState';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';


const MyInfoView = () => {
  /**
   * MY 정보조회, 수정 페이지
   */

  const [userInfo, setUserInfo] = useRecoilState(UserInfoResult);

  // Bearer Token 보내기
  useEffect(() => {
    handleHeaderToken();
  }, [])

  useEffect(()=>{
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/api/user/userInfo');
        setUserInfo(response.data.data.message);
        console.log("유저정보", response.data.data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserInfo();
  }, [])

  

  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        MY 정보 조회, 수정
      </SubHead>
      <section>
        {/* 회원 기본 정보 */}
        <MyinfoUser userInfo={userInfo}/>

        {/* 회원 선택 정보 */}
        <MyinfoSelect userInfo={userInfo} />

        {/* 보너스 사용 */}
        <MyinfoBonus userInfo={userInfo} />

        <AgreeBtn>
          <BtnBottom>조회 확인</BtnBottom>
        </AgreeBtn>
      </section>
    </div>
  );
};

const AgreeBtn = styled.div`
  margin-top: 16px;

  width: calc(100% - 32px);
  padding: 20px 16px 40px 16px;

  button {
    background: #FE7A36;
    color: #fff;

    font-size: 16px;
  }
`;

export default MyInfoView;