import React, { useEffect, useState } from 'react';
import SearchBonusSelect from './SearchBonusSelect';
import { pay_icon, qrcode, search, tooltip } from '../../../../assets/images';
import styled from 'styled-components';
import Help from '../../common/Help';
import SearchPayPopup from './SearchPayPopup';
import RightArrIcon from '../../common/RightArrIcon';
import ModalPopup from '../../common/ModalPopup';
import axios from 'axios';

const SearchPayInfo = ({ 
    handleSearchPay
  , paySearch
  , setPaySearch 
}) => {
  const [isOpen, setIsOpen] = useState(false);


const handleDetailPopup = () => {
  setIsOpen(!isOpen);
  setPaySearch(prev => ({
    ...prev,
    sid: "",
    earn: "",
    cityCode: "",
    areaCode: "",
    dongCode: "",
    sectors: "",
    storeGrp: "",
    storeLevel: "",
    storeName: "",
    rating: "",    
    kind: ""
  }));
}

  /**
   * 검색 조건 불러오기
   */
  const [searchData, setSearchData] = useState([]);
  const [codeList, setCodeList] = useState([]);

  const getCityAddress = async () => {
    const data = {
      "type" : "PAY"
    }
    try{
      const response = await axios.post('/api/codeMngr/getSearchCodeList', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('검색 pay ::' , response?.data?.data?.message);
      setSearchData(response?.data?.data?.message);

      /**
       * pcode 분류
       */
      const groupedData = response?.data?.data?.message.reduce((acc, item) => {
        if (!acc[item.pcode]) {
          acc[item.pcode] = [];
        }
        acc[item.pcode].push(item);
        return acc;
      }, {});

      console.log('groupedData', groupedData)
      setCodeList(groupedData);

    } catch (error){

    }
  }
  
  useEffect(()=>{
    getCityAddress();
  },[])

  const handlesearchList = (name, value) => {
    setPaySearch(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
    <SearchWrap>
      <h2>여기, 이 가맹점 가용 보너스 조회<Help /></h2>

      <QrStorevalue>
        <QrSection>
           <div classvalue='bubble'>
            <img src={pay_icon} />
          </div>

          <button>
            <img src={qrcode} alt='qr' /><p>QR</p>
          </button>
        </QrSection>
       

        <SearchInput>
          <div>
            <img src={search} alt='검색' />
            <input 
              type='text'
              placeholder='가맹점명 검색' 
              onChange={(e) => handlesearchList('storeName', e.target.value)}
            />
          </div>
        </SearchInput>
      </QrStorevalue>
      
      <SearchBonusSelect  
        handlesearchList = {handlesearchList}
        listEarnType  = {codeList?.EARN_TYPE?.[0]?.listCode}
        listBonusType = {codeList?.POINT_TYPE?.[0]?.listCode}
      />

      <SearchBtn onClick={handleSearchPay}>검색</SearchBtn>

      <DetailSearchBtn onClick={handleDetailPopup}>
       상세검색 <RightArrIcon />
      </DetailSearchBtn>

      {
        isOpen && 
        <SearchPayPopup 
          handlesearchList ={handlesearchList}
          setIsOpen        ={setIsOpen} 
          codeList         ={codeList}
          handleSearchPay  ={handleSearchPay}
          paySearch        ={paySearch}
          setPaySearch     ={setPaySearch}
        />
      }

    </SearchWrap>

    </>
  );
};

const SearchWrap = styled.div`
width: calc(100% - 32px);
padding: 32px 16px; 
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;

position:relative;

h2{
  background:#BDD466;
  border-radius: 50px;
  height: 35px;
  padding: 0 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap:4px;

  width: max-content;
  font-size: 14px;
  color:#356608;
  font-weight:bold;
}

.bubble {
  background:url(${tooltip}) left top no-repeat;
  background-size: contain;
  height: 33px;
  padding: 4px 10px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
  width: 60px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#FE7A36;
  font-weight:bold;
  }
}
`

const QrStorevalue = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap:8px;

width: 100%;
margin-top: 20px;
`

const QrSection = styled.div`
display: flex;
justify-content: start;
align-items: center;
gap: 4px;

width: 100%;

.buble {
  img {
    width: 100%;
    height: 100%;
  }
}

button {
  width: calc(100% - 74px);
  height: 40px;
  background:#F2F2F2;
  border:1px solid #E0E0E0;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight:bold;
}
`
const SearchInput = styled.div`
width: 100%;

div{
  width: calc(100% - 2px);
  border:1px solid #BDBDBD;
  border-radius: 8px;
  height: 38px;

  display: flex;
  justify-content: start;
  align-items: center;

  img {
    padding: 0 0 0 10px;
  }

  input {
    width: calc(100% - 12px);
    padding-left: 12px;
    font-size: 14px;
  }
}
`

const SearchBtn = styled.button`
  width: 80px;
  height: 32px;
  background:#444;
  color:#fff;
  font-size: 14px;
  border-radius:4px;
  margin-top: 16px;
`

const DetailSearchBtn = styled.button`
display: flex;
justify-content: center;
align-items: center;
gap: 4px;

background:#F2F2F2;
padding: 4px 8px;
border-radius: 20px; 

position:absolute;
right: 16px;
bottom: 32px;

font-size: 14px;
`


export default SearchPayInfo;