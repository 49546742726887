import React, { useEffect, useState } from 'react';
import { bottom_arr_select, checkoff, checkon2, search } from '../../../../assets/images';
import styled from 'styled-components';
import axios from 'axios';

const SelectInput = ({
    selectName
  , selectList
  , handlesearchList
  , setDetailArea
  , setDetailDong
  , disabled
}) => {
  /**
   * selectName : 선택 이름
   * selectList
   * handlesearchList
   * setDetailArea
   * setDetailDong
   * disabled
   */
  const [selectBool , setSelectBool ] = useState(false);
  const [selectValue, setSelectValue] = useState('');

  const codeMap = {
      EARN_TYPE    : 'earn'
    , POINT_TYPE   : 'kind'
    , AREA_CODE    : 'cityCode'
    , SECTOR       : 'sectors'
    , STORE_GRP    : 'storeGrp'
    , STORE_LEVEL  : 'storeLevel'
    , STORE_EVALUTE: 'rating'
  }

  const handleSelectValue = (e, ko) => {
    const { value, name } = e.target;
    setSelectValue(ko);
    setSelectBool(!selectBool);
    
    if(
         name === 'AREA_CODE'
      || name.includes('AREA0')
    ){
      CodeApi(value, name);
    }

    handlesearchList(
        name.includes('AREA0') ? 'areaCode' : 
        name.includes('SG0')   ? 'dongCode' : 
        codeMap[name]
      , value
    );
  }

  const CodeApi = async (codeVelue, codeName) => {
    const data = {
      "pcode" : codeVelue
    }
    try{
      const response = await axios.post('/api/codeMngr/getCodeList', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("codevalue",response)


      if(codeName==='AREA_CODE'){
        setDetailArea(response?.data?.data?.message);
      } else if(codeName.includes('AREA0')){
        setDetailDong(response?.data?.data?.message);
      }

    } catch (error){

    }
  }  


  return (
    <SelectInputWrap>
    <button 
      onClick={()=>setSelectBool(!selectBool)} 
      disabled={disabled}
      className={!selectValue ? 'placeholder' : ''}
    >
      {selectValue ? selectValue : selectName}
      <span><img src={bottom_arr_select} alt='검색' /></span>
    </button>

    {
      selectBool &&
      <ul>
        {selectList.map((item, index) => (
          <li key={index}>
            <label>
              <input 
                type='radio'
                value={item.code}
                name={item.pcode}  
                onChange={(e) => handleSelectValue(e, item.codeName)}
                checked={selectValue === item?.codeName}
                
              />
              <span></span>
              {item?.codeName}
            </label>
          </li>
        ))}
      </ul>
    }
    </SelectInputWrap>
  );
};

const SelectInputWrap = styled.div`
width: calc(100% - 17px);
height: 38px;
border:1px solid #BDBDBD;
border-radius: 8px;
padding: 0 5px 0 10px;

display: flex;
justify-content: start;
align-items: center;

position: relative;

button {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  color:#4F4F4F;

  &.placeholder {
    color:#8E8E8E;
  }
}

ul {
position: absolute;
left: -1px;
top: 32px;

background:#fff;
padding: 16px 8px;

z-index: 99;

border:1px solid #BDBDBD;
border-top: none;

width: calc(100% - 16px);
max-height: 120px;
border-radius: 0 0 8px 8px;

overflow:auto;

li {
  line-height: 1.5;
  
  label{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;

    font-size: 14px;
    color:#4F4F4F;
  }
  

  input[type='radio'] {
    display: none;
  }
  
  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }
  
  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + span {
    background-image: url(${checkon2});
  }

}
}
`

export default SelectInput;