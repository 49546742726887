import { iosnfcc } from '../../../../assets/images';
import styled from 'styled-components';
import Draggable from "react-draggable";
import { useEffect, useRef, useState } from "react";

const IosNfc = () => {
  
  
  const dragRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false); // 드래그 상태를 알려주는 state


  const handleOpenChatModal = () => {  // onClick 이벤트 핸들러
    if (isDragging) return;  // 드래그 중일 때는 함수를 실행시키지 않도록 한다.
    // eslint-disable-next-line no-undef
    IOSFluttingBtn.postMessage('true');
  };

  const handleOnDrag = (data) => { // 드래그 중일 때 이벤트 핸들러
    setIsDragging(true);  // 드래그 중일 때 flag변수를 true로 업데이트
  };
  
  const handleStopDrag = () => {  // 드래그가 끝날 때 이벤트 핸들러
    setTimeout(() => {
      setIsDragging(false);   // 드래그가 끝날 때 flag변수를 false로 업데이트 해준다.
    }, 100);
  };

  useEffect(() => {
    console.log(isDragging, "isDragging");
  }, [isDragging]);


  return (
    <Dragwrap>
   
      <Drag
        handle='.btnn'
        bounds="body"
        onDrag={(e, data) => handleOnDrag(e, data)} // onDrag 이벤트 리스너
        onStop={handleStopDrag}
      >

        <div>
          <IosBtn
            className='btnn'
            onClick={handleOpenChatModal}
            onTouchEnd={handleOpenChatModal}
          >
            <img src={iosnfcc} alt='ios' />
          </IosBtn>
        </div>
      
      </Drag>
   
    </Dragwrap>
  );
};

const Dragwrap = styled.div`
.react-draggable {
  position:fixed;
  right:16px;
  bottom: 86px;
  z-index: 9999999;
}
`
const Drag = styled(Draggable)`
// position:fixed;
//   right:16px;
//   bottom: 86px;
//   z-index: 9999999;
`
const IosBtn = styled.button`
  // background:url(${iosnfcc}) no-repeat center;
  // background-size: 35px;
  background-color:#FE7A36;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  

  img {
    width: 100%;
    height: auto;
  }
`
export default IosNfc;