import React from 'react';
import styled from 'styled-components';

const SystemPopup = ({children, btn}) => {
    /**
     * 시스템 관련 팝업
     */
    return (
        <ModalWrap>
            {children}
        </ModalWrap>
    );
};

const ModalWrap = styled.div`
    width: calc(100vw - 100px);
    background: #fff;
    border-radius: 16px;

    text-align:center;
    padding: 30px 30px 20px 30px ;

    color: #4D4D4D;
    font-size: 16px;
    line-height: 1.4;
`

export default SystemPopup;