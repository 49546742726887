import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  pointicon
, stampicon
, gifticon
, arr_bottom_gray
} from "../../../../assets/images"
import TotalBonusList from './TotalBonusList';
import TotalBonusDetail from './TotalBonusDetail';
import axios from 'axios';
import { handleHeaderToken } from '../../common/function/BearerToken';

const BonusTotal = () => {
  const [detailOpen, setDetailOpen] = useState(false);

  const handelDetailOpen = () => {
    setDetailOpen(!detailOpen)
  }

  const [userPointTotal, setUserPointTotal] = useState([]) // 유저의 총 포인트
  // Bearer Token 보내기
  useEffect(() => {
    handleHeaderToken();
  }, [])
  useEffect(() => {
    const fetchPointInfo = async () => {
      try {
        const response = await axios.get('/api/pointMngr/getPointInfo');
        setUserPointTotal(response.data.data.message.userPoint)
        console.log(response.data.data.message.userPoint)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPointInfo();
  }, []);

  const [points, setPoints] = useState({});

  useEffect(() => {
    const data = {};
    userPointTotal?.forEach(item => {
      const kind = item.earn;
      data[kind] = {};

      item.point?.forEach(point => {
        data[kind][point.kind] = {
          total: point?.totalPoint,
          available: point.availablePoint
        };
      });

    });

    setPoints(data);
  }, [userPointTotal]);

  const Ptotal  = (points["T"] && points["T"]["P"]?.total || 0) + (points["G"] && points["G"]["P"]?.total || 0) + (points["S"] && points["S"]["P"]?.total || 0); 
  const Puse    = (points["T"] && points["T"]["P"]?.available || 0) + (points["G"] && points["G"]["P"]?.available || 0) + (points["S"] && points["S"]["P"]?.available || 0); 
  
  const Stotal  = (points["T"] && points["T"]["S"]?.total || 0) + (points["G"] && points["G"]["S"]?.total || 0) + (points["S"] && points["S"]["S"]?.total || 0); 
  const Suse    = (points["T"] && points["T"]["S"]?.available || 0) + (points["G"] && points["G"]["S"]?.available || 0) + (points["S"] && points["S"]["S"]?.available || 0); 

  const Gtotal  = (points["T"] && points["T"]["R"]?.total || 0) + (points["G"] && points["G"]["R"]?.total || 0) + (points["S"] && points["S"]["R"]?.total || 0); 
  const Guse    = (points["T"] && points["T"]["R"]?.available || 0) + (points["G"] && points["G"]["R"]?.available || 0) + (points["S"] && points["S"]["R"]?.available || 0); 

  return (
    <>
      <MybonusTotal>
        <h3>My 보너스 전체</h3>
        <ul>
          <li className='point_list'>
            <TotalBonusList bonusIcon={pointicon} bonus={'현금'} total={Ptotal} use={Puse} />
          
            <TotalBonusDetail 
              detailOpen={detailOpen}
              totalT={points["T"] && points["T"]["P"]?.total || 0} useT={points["T"] && points["T"]["P"]?.available || 0} 
              totalG={points["G"] && points["G"]["P"]?.total || 0} useG={points["G"] && points["G"]["P"]?.available || 0} 
              totalS={points["S"] && points["S"]["P"]?.total || 0} useS={points["S"] && points["S"]["P"]?.available || 0} 
            />
          </li>

          <li className='stamp_list'>
            <TotalBonusList bonusIcon={stampicon} bonus={'스탬프'} total={Stotal} use={Suse} />
            <TotalBonusDetail 
              detailOpen={detailOpen}
              totalT={points["T"] && points["T"]["S"]?.total || 0} useT={points["T"] && points["T"]["S"]?.available || 0} 
              totalG={points["G"] && points["G"]["S"]?.total || 0} useG={points["G"] && points["G"]["S"]?.available || 0} 
              totalS={points["S"] && points["S"]["S"]?.total || 0} useS={points["S"] && points["S"]["S"]?.available || 0} 
            />
          </li>

          <li className='gift_list'>
            <TotalBonusList bonusIcon={gifticon} bonus={'경품승점'} total={Gtotal} use={Guse} />
            <TotalBonusDetail 
              detailOpen={detailOpen}
              totalT={points["T"] && points["T"]["R"]?.total || 0} useT={points["T"] && points["T"]["R"]?.available || 0} 
              totalG={points["G"] && points["G"]["R"]?.total || 0} useG={points["G"] && points["G"]["R"]?.available || 0} 
              totalS={points["S"] && points["S"]["R"]?.total || 0} useS={points["S"] && points["S"]["R"]?.available || 0} 
            />
          </li>
        </ul>

        <button 
          onClick={handelDetailOpen}
          className={detailOpen ? "open" : ""}
        >
          <img src={arr_bottom_gray} /></button>
      </MybonusTotal>
    </>
  );
};

const MybonusTotal = styled.div`
  border-bottom: 4px solid #ddd;

  h3 {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0 ;
    margin-top: 12px;

    img {
      transition: all .3s linear; 
      transform: rotate(0deg);
    }

    &.open {
      img {
        transform: rotate(-180deg);
        transition: all .3s linear; 
      }
      
    }
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2px;

    &>li {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      &.point_list {
        & > div{
          background-color: #F2F3EA;
        }

        h2{
          color: #7B8531;
        }
      }

      &.stamp_list {
        & > div{
          background-color: #FDEFEF;
        }

        h2{
          color: #EC635D;
        }
      }

      &.gift_list {
        & > div{
          background-color: #E9EFF6;
        }

        h2{
          color: #2860A7;
        }
      }
    }
  }
`

export default BonusTotal;