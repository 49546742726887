import React from "react";
import styled from "styled-components";
import {
  ad,
  ban1_1,
  ban2_1,
  ban3,
  ban4
} from "../../../assets/images";
import MainSlider from "../TouchOll/main/MainSlider";
import AdImgPopup from "../common/AdImgPopup";

function Ad() {
  return (
    <BorderContainer>
      {/* <HeaderImage src={ad}/> */}

      <ul>
        <li>
          <AdImgPopup
          imgUrl={ban3}
          imgName={"배너"}
          img2Url={ban1_1}
          img2Name={"자세히보기 배너"}
          />
        </li>
        <li>
          <AdImgPopup 
          imgUrl={ban4}
          imgName={"배너"}
          img2Url={ban2_1}
          img2Name={"자세히보기 배너"}
          />
          </li>
        <li>
          <AdImgPopup 
          isVideo={true}
          videoUrl={"https://www.youtube.com/embed/lXEZAc3eglk"}
          videoName={"롯데마트 한우데이, 한참은 더 따듯할 우리의 날들" }
          />
        </li>
      </ul>
    </BorderContainer>
  );
}

const BorderContainer = styled.div`  
  display: flex;
  justify-content: center; 
  padding-bottom: 32px;
  
  ul {
    margin-top: 20px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 8px; 

    width: calc(100% - 32px);
    padding: 0 16px;

    li {
      width: 100%;
      height: fit-content;
      border-radius: 8px;
      overflow:hidden;
      
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    .iframeContainer {
      position: relative;
      width: 100%;
    }
    .iframeContainer iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .iframe4To3 {
      padding-top: 70%;
    }
  }
`;

const HeaderImage = styled.img`
  width: 320px;
  height: 215px;
  margin-top: 20px;
`;

export default Ad;
