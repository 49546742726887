import React, { useEffect, useState } from 'react';
import { Map, MapMarker, MarkerClusterer } from 'react-kakao-maps-sdk';
import styled from 'styled-components';

const KakaoMap = ({
    xlat
  , ylng
  , state
}) => {
  
  
  return (
    <MapWrap>
      <Map // 지도를 표시할 Container
        center={state.center}
        isPanto={state.isPanto}
        style={{
          // 지도의 크기
          width: '100vw',
          height: 'calc(100vh - 170px)'
        }}
        level={3} // 지도의 확대 레벨
      >
        <MapMarker
          style={{ border: 'tranparent' }}
          position={state.center}
        ></MapMarker>
      </Map>
    </MapWrap>
  )
}

const MapWrap = styled.div`
width: 100vw;
height: calc(100vh - 178px);
`

export default KakaoMap;