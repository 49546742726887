import React from 'react';
import ModalPopup from './ModalPopup';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SystemModal = ({ 
    children
  , system
  , setSystem
  , linkHref
  , handleFunction 
}) => {
  const navigate = useNavigate();

  const handleNavi = () => {
    navigate(linkHref);
  };

  return (
    <ModalPopup>
      <PopupContent>
        <SystemText>{children}</SystemText>
        <SystemBtn 
          onClick={
            linkHref       ? handleNavi : 
            handleFunction ? handleFunction :
            () => setSystem(!system)
          }
        >확인</SystemBtn>
      </PopupContent>
    </ModalPopup>
  );
};


const PopupContent = styled.div`
  width: 280px;
  height: auto;

  padding: 32px 16px 16px 16px;
  background:#fff;
  border-radius: 8px;

  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: center !important;
  gap : 0 !important;
`

const SystemText = styled.p`
  margin-bottom:20px;
  font-size: 16px;
  color:#222;
  font-weight: 500 !important;
`

const SystemBtn = styled.em`
  font-size: 14px;
  color:#FE7A36;
  border-radius: 4px;
  width: 72px;
  height: 36px;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  cursor:pointer;
`


export default SystemModal;