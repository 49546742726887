import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import axios from 'axios';

const PayRequest = () => {
  /**
   * 메인에서 요청을 받았을 때 페이지
   */
  const navigate = useNavigate();
  const location = useLocation();
  const point = location.state.point || {};

  useEffect(() => {
    handleHeaderToken();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try{
        const requestData = {
              point: point
        }

        const response = await axios.post('/api/pointMngr/requestPointScrl', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log('flutter 전달 상세 데이터 ::', response?.data?.data?.message);
        handleResponse(response?.data?.data?.message);

      } catch(error){
        console.log("flutter 전달 상세 오류 ::". error);
      }
    }

    fetchData();
  }, [point])

  /**
   * api의 pointUse, stampUse에 따라서 페이지 이동
   * @param {*} response API 응답하여 받은 정보
   */
  const handleResponse = (data) => {

    if (data?.store) {
      const bonusType = data?.type;
      const rpsUsing = data?.store?.rpsUse;
      const { stampUse, pointUse } = data?.store;
      
      if (bonusType === '1' && rpsUsing === "Y"){
        if (stampUse === 'Y') {
          // stampUse 값이 'Y'인 경우 '/stamp' 페이지로 이동
          navigate("/common/stamp_type1", { state: { data: data, point:point }, replace: true});
        }
        if (pointUse === 'Y') {
          // pointUse 값이 'Y'인 경우 '/point' 페이지로 이동
          navigate("/common/point_type1", { state: { data: data, point:point }, replace: true});
        }

      }else{
        const fetchData2 = async () => {
          try{
            const requestData2 = {
                point: point
            }
    
            const response = await axios.post('/api/pointMngr/requestPointAccml', requestData2, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
    
            console.log('flutter 전달 상세 데이터 ::', response?.data?.data?.message);
            const data2     = response?.data?.data?.message;
            const stampUse2 = data2?.store?.stampUse;
            const pointUse2 = data2?.store?.pointUse;
            const stlmCode9 = data2?.receipt?.stlmMethodCode;

            if (stlmCode9 !== "9" && stampUse2 === 'Y') {
              // stampUse 값이 'Y'인 경우 '/stamp' 페이지로 이동
              navigate("/common/stamp_result", { state: { data: data2 }, replace: true});
            }
            else if (stlmCode9 !== "9" && pointUse2 === 'Y') {
              // pointUse 값이 'Y'인 경우 '/point' 페이지로 이동
              navigate("/common/point_result", { state: { data: data2 }, replace: true});
            }
            else {
              navigate("/common/point_result", { state: { data: data2 }, replace: true});
            }
            
    
          } catch(error){
            console.log("flutter 전달 상세 오류 ::". error);
          } 
        }

        fetchData2()
      }
    }
  };


  return (
    <>
      
    </>
  );
};

export default PayRequest;