import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import {
  p_icon
} from "../../../assets/images";
import ModalPopup from "../common/ModalPopup";
import CashQPopup from "../TouchOll/CashQPopup";
import WebCashTit from "./common/WebCashTit";


function CashCancel({ data }) {
  const storeInfo = data?.store;
  const reciptInfo = data?.recipt;

  console.log("CashCancel storeInfo:", storeInfo);
  console.log("CashCancel reciptInfo:", reciptInfo);

  useEffect(() => {
    console.log("CashCancel data:", data);
  }, [data]);

  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'>
        <WebCashTit data={data} state={2} sumCash={reciptInfo?.stlmPrice} />
        {/* <h1>
          {storeInfo?.storeName}
          <em>통합</em>
        </h1>
        <h3>터치올-<em>현금</em>
          <img src={p_icon} />
          <CashQPopup />
        </h3>
        <BonusSU>
          <p>입금・적립 취소!</p>
          <div>
            <p>
              <strong>
                {Number(reciptInfo?.stlmPrice).toLocaleString()}
              </strong>원
            </p>
          </div>
        </BonusSU> */}
       
      </div>
    </BonusSUWrap>
  );
}




const BonusSUWrap = styled.div`
  width: calc(100% - 32px);
  margin-top:20px;
  padding: 0 16px;

  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

  }

  h1 {
    color: #2860a7;
    font-weight: 800;

    margin-right: 4px;
    vertical-align: middle;

    em {
      background-color: #5d0ef5;
      color: #ffffff;
      padding: 1px 5px 1px 5px;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  h3 {
    margin-top: 26px;

    font-size: 16px;
    font-weight: 700;
    color: #222;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2px;

    em {
      color: #7B8531;

    }

    img {
      width: 20px;
      height: 20px;
    }

  }
`

const BonusSU = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

  }

  p {
    color: #FB0E0E;
    font-weight: 700;
    font-size: 14px;

    strong {
      color: #FB0E0E;
      font-weight: 700;
      font-size: 26px;
    }
  }
`


export default CashCancel;
