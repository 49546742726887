import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import AutoTimer from '../../TouchOll/join/AutoTimer';

const TelNumberInput = ({
    mobile
  , setMobile
  , pwState
  , setPwState
  , setPopupTxt
  , setSystem
  , setJoinType
}) => {
  /**
   * 전화번호 인증 input
   */
  const [count, setCount]           = useState(180);     // 3분 타이머 
  const [countOn, setCountOn]       = useState(false);   // 3분 타이머
  const [isBtnDis, setIsBtnDis]     = useState(true);   // 인증번호 받기 비활성화
  const [confirmNum, setConfirmNum] = useState('');     // 인증번호 입력

  /**
   * 인증번호 전송
   * after: 인증번호 input (#confirm_num_input) 보여짐
   */
  const handlePostNumRequest = async () => {
    try {
      await axios.post('/api/authUser/userCertFromMobile', {
        mobile: mobile
      });
      setCountOn(true);
      setCount(180);
      document.querySelector('#confirm_num_input').classList.add('confirm_input');
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };

  /** 
   * 전화번호 유효성 검사
   * 결과 : 인증번호 받기 버튼 상태 변경
   */
  const handleMobileChange = (e) => {
    const pattern = new RegExp("^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$");

    setMobile(e.target.value);
    setIsBtnDis(!pattern.test(e.target.value)); 
  };

  /**
   * 인증번호 확인 
   * 조건: data?result가 True 이면 인증번호 입력 input 사라짐
   * 결과: 전화번호 input 읽기 전용으로 변경되어 수정 불가.
   */
  const handleValidation = async () => {
    try {
      const response = await axios.post('/api/authUser/validationCert', {
        mobile: mobile
        , certNum: confirmNum
      });

      if (response.data.data && response.data.data.result) {
        setPwState(false);
        document.querySelector('#confirm_num_input').classList.remove('confirm_input');
        document.querySelector('#feedtxt0').classList.add('password-match');
        setJoinType('Y')
      } else {
        setPopupTxt('인증번호를 잘못 입력했습니다.')
        setSystem(true)
      }
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };

  return (
    <PassWordInputs>
    <div>
      <p>폰 No. [간편 ID]</p>
      <Feedtxt id="feedtxt0">
        {
          pwState ?
            "인증이 완료되었습니다."
            : "전화번호 인증이 필요합니다."
        }
      </Feedtxt>
      <div className="flex2">  
        <input
          placeholder=" - 없이 전화번호 입력"
          value={mobile}
          onChange={handleMobileChange} // 입력 값 변경 시 핸들러 호출
          type="text" // type을 "number"에서 "text"로 변경
          maxLength={11}
          readOnly={!pwState}
          pattern="^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$"
        />
        <button
          onClick={handlePostNumRequest}
          disabled={isBtnDis}
          style={{ backgroundColor: isBtnDis ? '#aaa' : '#2860A7' }}
        >
          인증번호 받기
        </button>
      </div>

      <div id="confirm_num_input">
        <div className="flex2">
          <input
            placeholder="인증번호 입력"
            value={confirmNum}
            onChange={(e) => setConfirmNum(e.target.value)}
            type="number"
          />

          <button
            onClick={countOn ? handleValidation : null}
            disabled={!countOn}
            style={{ backgroundColor: !countOn ? '#aaa' : '#2860A7' }}
          >확인</button>
        </div>

        <TimerWrap>
          <h4>
            <span>유효시간</span>
            <CountTimer>              
              <AutoTimer
              count={count}
              setCount={setCount}
              countOn ={countOn}
              setCountOn={setCountOn}
              />
            </CountTimer>
          </h4>
          
          <ul>
            <li>· 3분 이내 인증번호 입력!</li>
            <li>· 시간 초과시 다시 인증번호 받기</li>
          </ul>
          
        </TimerWrap>
      </div>

    </div>
    
  </PassWordInputs>
  );
};

const PassWordInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;
    position:relative;

    &.confirm_input {
      display:block !important;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    background:#fff;
  }
`;

const Feedtxt = styled.p`
  font-size: 12px !important;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const CountTimer = styled.div`
  // position:absolute;
  // right: 135px;
  // top:50%;
  // margin-top: -7px;

  span {
    font-size: 14px;
    color:#aaa;
    
  }
`


const TimerWrap = styled.div`
margin-top: 12px;
&>h4 {
  display: flex;
  justify-content: flex-end;
  align-items:center;
  gap: 4px;

  font-size: 14px;

  span {
    color: #EA5413;
  }
}

ul {
  text-align:right;
  margin-top: 8px;

  li {
    font-size: 12px;
    color:#444;
    line-height: 16px;
  }
}
`
export default TelNumberInput;