import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  checkon
  , checkoff
} from "../../../assets/images";
import ServiceCheck from './ServiceCheck';

const ServiceSelect = ({ 
    storeInfo
  , bonusEdit
  , setBonusEdit
  , earn
  }) => {


  const [isChecked, setIsChecked] = useState(0); // 체크박스 index로 컨트롤
  const [isOpen, setIsOpen]       = useState([false, false, false]); // 보너스 오픈 
  const [checkList, setCheckList] = useState([false, false, false]); // 유형

  const [storeCode, setStoreCode] = useState(''); //101025
  const [storeName, setStoreName] = useState('');

  /**
   * 통합형 T | 그룹형 G | 단독형 S 에 따라 첫 체크값 상태 변경
   */
  useEffect(()=>{
    setIsChecked(earn);
  },[])


  /**
   * 통합형 T | 그룹형 G | 단독형 S 에 따라 첫 체크값 상태 변경
   */
  useEffect(() => {
    const newCheckList      = [false, false, false];
    const earnCheck         = storeInfo.earn === 'T' ? 0 : storeInfo.earn === 'G' ? 1 : 2
    newCheckList[earnCheck] = true;

    setCheckList(newCheckList);
    setIsOpen(newCheckList);
  }, []);


  // console.log("보너스수정",bonusEdit)
  /**
   * point 값을 관리 ==> 수정하기 클릭시 bonusEdit.earn 값의 따라 저장되는 index가 달라짐 
   * */ 
  const [pointEdit, setPointEdit] = useState([0,0,0])

  useEffect(() => {
    const pointlist  = [0,0,0];
    const plen       = bonusEdit.earn === 'T' ? 0 : bonusEdit.earn === 'G' ? 1 : 2
    pointlist[plen]  = storeInfo.point;
    setPointEdit(pointlist);
  }, [storeInfo.point]);

  useEffect(() => {
    setBonusEdit({
      ...bonusEdit
      , grpId: storeCode
    });
  },[storeCode])

  /**
   * 단독형이 아닐 경우 해당 값은 0으로 변경 될 예정.
   */
  const [stypeStemp , setStypeStemp] = useState(0);
  const [stypeRps   , setStypeRps]   = useState(0);
  useEffect(() => {
    setStypeStemp(bonusEdit.stamp || 0);
    setStypeRps(bonusEdit.rps || 0);
  }, [bonusEdit]);

  useEffect(() => {
    setBonusEdit({
      ...bonusEdit,
        grpId :storeCode
      , stamp :stypeStemp
      , rps   :stypeRps
    });
  },[storeCode, stypeStemp, stypeRps])

  useEffect(() => {
    setBonusEdit({
      ...bonusEdit,
        point : pointEdit[bonusEdit.earn === 'T' ? 0 : bonusEdit.earn === 'G' ? 1 : 2]
    });
  },[pointEdit])
  

  /**
   * 체크 상태에 따라 CheckBox상태 변경 및 유형 상태 변경
   */
  const handleCheck = (index) => {
    setIsChecked(index);

    const newIste   = [false, false, false];
    newIste[index]  = true;
    setCheckList(newIste);

    // 보너스 유형 변경하기
    setBonusEdit({
      ...bonusEdit
      , earn: index === 0 ? "T" : index === 1 ? "G" : "S"
    });
  };

  /**
   * 오픈 상태 변경
   */
  const handleIsOpen = (index) => {
    const newIsOpen   = [...isOpen];
    newIsOpen[index]  = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setStoreCode(value);
    setStoreName(getStoreName(value));
  };

  const getStoreName = (code) => {
    switch (code) {
      case '101025':
        return '배스킨라빈스';
      default:
        return '';
    }
  };


  return (
    <BonusWrap>
      <BonusTit className={`${isChecked === 0 ? "open_txt" : ""}`} >
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            checked ={isChecked === 0}
            onChange={() => handleCheck(0)}
          />
          <span></span>
          통합
        </label>
        <p onClick={() => handleIsOpen(0)}>효율 36.95%
          <span className={isOpen[0] ? "open_svg" : ""}>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>
      </BonusTit>
    
      <ServiceCheck 
        pointValue    ={pointEdit[0]}
        bonusEdit     ={bonusEdit.earn === "T" ? bonusEdit : ""}
        setBonusEdit  ={setBonusEdit}
        setPointEdit  ={(newValue) => {
                          const newPointEdit = [...pointEdit]; 
                          newPointEdit[0] = parseFloat(newValue);
                          setPointEdit(newPointEdit); 
                        }}
        isChecked     ={isChecked} 
        isOpen        ={isOpen[0]} 
        checkList     ={checkList[0]}
      />

      <BonusTit className={`${isChecked === 1 ? "open_txt" : ""}`} >
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            checked ={isChecked === 1}
            onChange={() => handleCheck(1)}
          />
          <span></span>
          그룹
        </label>
        <p onClick={() => handleIsOpen(1)}>효율 4.2%
          <span className={isOpen[1] ? "open_svg" : ""}>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>

      </BonusTit>

      <ServiceCheck 
      pointValue    = {pointEdit[1]}
      bonusEdit     = {bonusEdit.earn === "G" ? bonusEdit : ""}
      setBonusEdit  = {setBonusEdit}
      setPointEdit  = {(newValue) => {
                         const newPointEdit = [...pointEdit]; 
                         newPointEdit[1] = parseFloat(newValue);
                         setPointEdit(newPointEdit); 
                       }}
      isChecked     = {isChecked} 
      isOpen        = {isOpen[1]} 
      checkList     = {checkList[1]}
      >
        <GroupCode>
          <h3>그룹코드</h3>
          <label>
            <input
              type    ='number'
              name    ='storecode'
              value   ={storeCode}
              onChange={handleInputChange} 
            />
            <p>{storeName}</p>
          </label>
        </GroupCode>
      </ServiceCheck>



      <BonusTit className={`${isChecked === 2 ? "open_txt" : ""}`} >
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            checked ={isChecked === 2}
            onChange={() => handleCheck(2)}
          />
          <span></span>
          단독
        </label>
        <p onClick={() => handleIsOpen(2)}>효율 2.2%
          <span className={isOpen[2] ? "open_svg" : ""}>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>
      </BonusTit>
  
      <ServiceCheck  
      pointValue    ={pointEdit[2]}
      bonusEdit     ={bonusEdit.earn === "S" ? bonusEdit : ""}
      setBonusEdit  ={setBonusEdit}
      setPointEdit  ={(newValue) => {
                        const newPointEdit = [...pointEdit]; 
                        newPointEdit[2] = parseFloat(newValue);
                        setPointEdit(newPointEdit); 
                      }}
      type          ={'S'} 
      isChecked     ={isChecked} 
      isOpen        ={isOpen[2]}  
      checkList     ={checkList[2]} 
      stypeStemp    ={stypeStemp}
      stypeRps      ={stypeRps}
      setStypeStemp ={setStypeStemp}
      setStypeRps   ={setStypeRps}
      />
      
    </BonusWrap>
  );
};

const BonusWrap = styled.div`
margin-top: 12px;
`

const BonusTit = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

margin-bottom: 8px;

label{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;

  font-size: 18px;
}

p {
  font-size: 18px;
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all .3s linear;
    transform: rotate(0deg);

    svg {
      path {
        stroke:#222;
      }
    }

    &.open_svg {
      transform: rotate(-180deg);
    }
  }
}

input[type='radio'] {
  display: none;
}

/* 체크박스 커스텀 이미지 적용 */
input[type='radio'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${checkoff});
  background-size: cover;
}

/* 체크된 상태의 이미지 적용 */
input[type='radio']:checked + span {
  background-image: url(${checkon});
}

p, label{
  color:#222;
}

&.open_txt {
  p, label{
    color:#FE7A36;
  }

  svg {
    path {
      stroke:#FE7A36;
    }
  }
}

`

const GroupCode = styled.label`
width:100%;
justify-content:space-between !important;
// gap:8px;

input {
  border:1px solid #ddd;
  margin: 0 2px 0 4px;
  font-size: 16px;
  height: 16px;
  color:#2860A7;
}

p {
  color:#2860A7;
}
`

export default ServiceSelect;