import React from 'react';
import { back_icon } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BackIcon = () => {
  const navigate = useNavigate();
  return (
    <BackHistory onClick={() => navigate(-1)}>
      <img src={back_icon} alt="뒤로가기" />
    </BackHistory>
  );
};

const BackHistory = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

export default BackIcon;
