import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BtnBottom from '../BtnBottom';
import UserInfoInputs from './UserInfoInputs';

const JoinTypeY = ({
    joinType
  , joinState
  , setJoinState
  , mobile
  , setPopupTxt
  , setSystem
  , page
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [passwd, setPasswd]         = useState('');   // 비밀번호 확인
  const [name, setName]             = useState('');   // 이름
  const [birthday, setBirthday]     = useState('');   // 생년월일
  
  /**
   * 회원가입 완료버튼
   */
  const handlePostUserJoinRequest = async (e) => {
    e.preventDefault();
    try {
      const { gps, useStplat, indvdlInfo, eventAdvrtsNtcn } = location.state.agreements;
      const response = await axios.post('/api/memberMngr/reqJoinMember', {
        mobile: mobile,
        passwd: passwd,
        gps: gps,
        useStplat: useStplat,
        indvdlInfo: indvdlInfo,
        eventAdvrtsNtcn: eventAdvrtsNtcn,
        name: name,
        birthday: birthday,
        fcm: ''
      });
      
      if (response.data.data.result === true) {
        navigate("/app/join_end", { state: { name }, replace: true });
      } 
      else{
        setPopupTxt(response.data.data.message)
        setSystem(true)
      }
    } catch (error) {
      setPopupTxt(error.response.data.data.message)
      setSystem(true)
    }
  };

  return (
    <JoinYWrap>
      <h2 id="wrap32">* 아래 3가지 → 정규. 안심 가입 OK!</h2>

      <UserInfoInputs 
        joinState={joinState}
        setJoinState={setJoinState}
        name={name}
        setName={setName}
        passwd={passwd}
        setPasswd={setPasswd}
        setBirthday={setBirthday}
      />

      
      <BtnJoin id="wrap32">
        <BtnBottom
          disabled={
            !(
              joinState.numberCheck
              && joinState.pwPattern
              && joinState.pwCheck
              && joinState.nameCheck
              && joinState.birthCheck
              && joinType === 'Y'
            )
          }
          onClick={handlePostUserJoinRequest}>
            정규. 안심 가입 완료
        </BtnBottom>
      </BtnJoin>
      
    </JoinYWrap>
  );
};

const JoinYWrap = styled.div`
h2 {
  margin-top: 32px;
  color:#FE7A36;
  font-weight:bold;
}
  
`

const JoinInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;

    padding: 0 16px;

    font-size: 16px;
  }
`;

const Feedtxt = styled.p`
  font-size: 14px !important;
  margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const BtnJoin = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
`;

export default JoinTypeY;