import React from 'react';
import styled from 'styled-components';
import CommonBonusBtn from '../../components/common/CommonBonusBtn';
import CommonIconList from '../../components/common/CommonIconList';
import { 
  pointicon
  , gifticon
  , stampicon
  , cicon,
  payicons,
  p_txt,
  s_txt,
  g_txt,
  c_txt
} from '../../../assets/images';

// import "../../../assets/styles/style.scss"
// import BackIcon from '../../components/TouchOll/BackIcon';
import { useNavigate } from 'react-router';

const IconGuide = () => {
  const navigate = useNavigate();
  return (
    <IconGuideWrap className='icwr'>
      <h1>아이콘, 기호 Guide</h1>
      
      <div>
        <EarnDocs>
          <li className='earnT'>
            <span>통합</span>
            <p>
              여러 통합형 가맹점끼리의<br />
              통합 입금 · 적립과<br />
              호환 Pay·사용되는 유형
            </p>
          </li>

          <li className='earnG'>
            <span>그룹</span>
            <p>
              프랜차이즈/ 지역 / 단체 등<br />
              그룹 가맹점끼리의 통합 입금 ·<br />
              적립과,호환 Pay·사용되는 유형
            </p>
          </li>

          <li className='earnS'>
            <span>단독</span>
            <p>
              보너스 적립 제공점에서만<br />
              보너스가 한정 사용되는 유형
            </p>
          </li>
        </EarnDocs>

        <BonusType>
          <ul>
            <li>
              <img src={p_txt} alt='포인트' />
            </li>
            <li>
              <img src={s_txt} alt='스탬프' />
              
            </li>
            <li>
              <img src={g_txt} alt='경품게임' />
              
            </li>
            <li>
              <img src={c_txt} alt='거스름돈' />
              
            </li>
          </ul>
        </BonusType>

        <PayIconBtn>
          <p><em>&gt;</em>아래 각 누를시 거래 상세내역 보기</p>
          <div>
            <img src={payicons} alt='결제관련 아이콘' />

            {/* 적립 결제 */}
            {/* <CommonBonusBtn type={0} pointSum={40000} kind={'C'} />
          <CommonBonusBtn type={1} pointSum={40000} kind={'P'} />
          <CommonBonusBtn type={1} pointSum={40000} kind={'S'} />
          <CommonBonusBtn type={1} pointSum={40000} kind={'G'} /> */}

            {/* 사용 */}
            {/* <CommonBonusBtn type={2} pointSum={40000} kind={'P'} />
          <CommonBonusBtn type={2} pointSum={40000} kind={'S'} />
          <CommonBonusBtn type={2} pointSum={40000} kind={'G'} /> */}

            {/* 적립 취소 */}
            {/* <CommonBonusBtn type={3} pointSum={40000} kind={'P'} />
          <CommonBonusBtn type={3} pointSum={40000} kind={'S'} />
          <CommonBonusBtn type={3} pointSum={40000} kind={'G'} /> */}

            {/* 사용 취소 */}
            {/* <CommonBonusBtn type={4} pointSum={40000} kind={'P'} />
          <CommonBonusBtn type={4} pointSum={40000} kind={'S'} />
          <CommonBonusBtn type={4} pointSum={40000} kind={'G'} /> */}

            {/* 거스름돈 */}
            {/* <CommonBonusBtn type={5} pointSum={40000} kind={'C'} /> */}


            {/* <CommonBonusBtn type={1} typeName={"결제(적립)"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={2} typeName={"보너스사용"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={7} typeName={"통장 입금"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={0} typeName={"거스름돈"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={3} typeName={"결제취소(적립취소)"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={4} typeName={"보너스사용 취소"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={6} typeName={"출금"} pointSum={40000} kind={'P'} />
            <CommonBonusBtn type={5} typeName={"거스름돈 취소"} pointSum={40000} kind={'P'} /> */}

          </div>

        </PayIconBtn>
        
        <RecipeDocs>
          <ul>
            <li><span>· 가맹점명</span> <em>&gt;</em> 가맹점 상세정보</li>
            <li><span>· 결제액(예: 9,000원)</span> <em>&gt;</em> 영수증</li>
          </ul>
        </RecipeDocs>

        <StoreBat>
          <ol>
            <li>
              <CommonIconList Icons={'hot'} />
              <p>평점 높은 가맹점</p>
            </li>
            <li>
              <CommonIconList Icons={'high'} />
              <p>적립율 높은 가맹점</p>
            </li>
            <li>
              <CommonIconList Icons={'celebs'} />
              <p>인기, 유행, Big 가맹점</p>
            </li>
            <li>
              <CommonIconList Icons={'top'} />
              <p>이용액, 이용자 많은 가맹점</p>
            </li>
            <li>
              <CommonIconList Icons={'plus'} />
              <p>이용액, 시간, 결제수단별,<br />
                2학년 이상 가맹점
                {/* 회원 추가  */}
                {/* 적립율 높은 가맹점 */}
              </p>
            </li>
            <li>
              <CommonIconList Icons={'recom'} />
              <p>적극 이용 추천점</p>
            </li>
            <li>
              <CommonIconList Icons={'new'} />
              <p>최근 신규점</p>
            </li>
          </ol>
        </StoreBat>
        

        <button onClick={() => navigate(-1)}>돌아가기</button>
      </div>
      
    </IconGuideWrap>
  );
};


const IconGuideWrap = styled.div`
position: relative;

h1 {
  background:#FF5F01;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;

  font-size: 20px;
  font-weight: bold;
}

& > div{
  width: calc(100% - 32px);
  padding: 32px 16px 16px 16px;

  & > button {
    width: 100px;
    height: 36px;
    background:#444;
    color:#fff;
    font-size: 16px;
    border-radius: 4px;

    position:absolute;
    left: 50%;
    margin-left: -50px;
    bottom : 16px;
  }
}


`

const EarnDocs = styled.ul`
  li {
    position: relative;
    margin-bottom: 28px;
    border-radius: 8px;
    
    span {
      width: 45px;
      height: 24px;

      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      font-weight: bold;
    }

    p {
      text-align: center;
      padding: 28px 0 16px 0;

      font-size: 16px;
      line-height: 26px;
    }
  }

.earnT {
  background: #E9ECFB;

  span {
    background: #5D0EF5;
  }

  p {
    color:#1C024D;
  }
}

.earnG {
  background: #FAE6FC;

  span {
    background: #8E3D82;
  }
  
  p {
    color:#34012D;
  }
}

.earnS {
  background: #EFEEEE;

  span {
    background: #7F7F7F;
  }
  
  p {
    color:#353333;
  }
}
`

const BonusType = styled.div`
padding: 12px 0;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;

ul {
  width: 60%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

li{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;

  font-size: 16px;
  font-weight: bold;

  img {
    width: auto;
    height: 25px;
  }

  // &:nth-child(1) {
  //   color: #7B8531;
  // }
  // &:nth-child(2) {
  //   color: #EC635D;
  // }
  // &:nth-child(3) {
  //   color: #2860A7;
  // }
  // &:nth-child(4) {
  //   color: #222;
  // }
}
`

const PayIconBtn = styled.div`
padding: 20px 0;

  p{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    color: #5D0EF5;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;

    em {
      width: 15px;
      height: 15px;
      color: #fff;
      background-color: #5D0EF5;
      border-radius: 50px;

      font-size: 12px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & > div {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));
    // gap: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
`

const RecipeDocs = styled.div`
padding: 12px 0;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;

ul {
  width: max-content;
  margin: 0 auto;
}

li {
  line-height: 24px;
  font-size: 16px;

  display: flex;
  justify-content: start;
  align-items: center;

  span {
    font-weight:bold;
  }

  em {
    width: 15px;
    height: 15px;
    color: #fff;
    background-color: #444;
    border-radius: 50px;

    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 8px;
  }
}
`

const StoreBat = styled.div`
padding: 20px 16px;
margin-bottom: 50px;

ol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 20px;

  & > li {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;

    ul {
      margin-top: 0;

      li {
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
`
export default IconGuide;