import React from 'react';
import styled from 'styled-components';

const EventList = ({ type }) => {
  /**
   * 이벤트 페이지 리스트
   * type : Y 진행 | N 종료
   */
  return (
    <EventType 
      className={type === "Y" ? "event_on" : ""}
    >
      <span>
        {type === "Y" ? "진행중" : "마감"}
      </span>
    </EventType>

  );
};

const EventType = styled.div` 
width: 100%;
background: #F2F2F2;
border-radius: 12px;
height: 120px;
position: relative;

span {
  position: absolute;
  left: 8px;
  top: 12px; 
  width: 48px;
  height: 24px;
  border-radius: 24px;
  background:#4D4D4D;
  color:#fff;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

&.event_on {
  span {
    background:#FE7A36;
  }
}
`

export default EventList;