import React from 'react';
import { arr_right_or, arr_right_orange, logo } from '../../../../assets/images';
import styled from 'styled-components';

const JoinSelectTxt = () => {
  return (
    <TxtWrap id='wrap32'>
      <h1>1. 축! 입학 <img src={logo} /> 대학</h1>
      <h3><img src={logo} /> 대학?</h3>
      <p>
      현존 최-초간편 100% 현금 입금.적립 & Pay<br/>
      &gt;&gt; <b>터치올 우대회원제로서</b><br/><br/>

      회원 등급제를<br/>
      <strong>대학 4학년제 처럼 구분 운영합니다.</strong><br/><br/>

      처음 1학년에서 한 학년 오를시마다<br/>
      <strong>기본 혜택에 최대 20% 추가 즉, 4학년시 최대 60% 추가혜택 제공됨</strong>
      </p>

      <div>
        특히<br/>
        <strong>
          선택회원 정보 작성, 이용시 특별 학점 가산<br/>
          <img src={arr_right_orange} /> 학년 승급 매우 유리
        </strong>
      </div>
    </TxtWrap>
  );
};

const TxtWrap = styled.div`
margin: 32px 0;

  h1 {
    font-size: 20px;
    font-weight:bold;

    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    margin-bottom: 20px;

    img {
      height: 20px;
    }
  }

  h3 {
    font-size: 16px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    margin-bottom: 20px;

    img {
      height: 20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    
    strong {
      color: #FE7A36;
    }

    b {
      font-weight:bold;
    }
  }

  div {
    width: calc(100% - 32px);
    padding: 16px;

    background:#FFE8DC;
    border:1px solid #FE7A36;
    border-radius: 8px;
    
    line-height: 20px;
    font-size: 16px;

    strong {
      color: #FE7A36;
      font-weight:bold;
    }

    img {
      vertical-align: inherit;
    }
  }
`

export default JoinSelectTxt;