import React from 'react';
import ModalPopup from '../../common/ModalPopup';
import styled from 'styled-components';
import {close_button} from '../../../../assets/images'

const BonusCan = ({toggleModal}) => {
  return (
    <ModalPopup>
      <CanWrap>
      <button onClick={toggleModal}><img src={close_button} alt='닫기' /> </button>
        <h1>가용 보너스란?</h1>
        <p>
        이용 가맹점으로부터<br />
        이미 정산되어<br /><br />

        <em>바로 현금대용 Pay 또는 <br />
        통장 입금될 수 있는 <br />
        보너스</em>를  말함
        </p>
      </CanWrap>
    </ModalPopup>
  );
};

const CanWrap = styled.div`
  position: relative;
  width: 260px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  button {
    position: absolute;
    right: 16px;
    top: 16px;
    
    img{
      width: 24px;
    height: 24px;
    }
    
  }

  h1 {
    width: 100%;
    height:74px;

    line-height: 74px;
    padding: 0 16px;

    font-size: 20px;
    color: #fff;
    background: #042F8B;
  }

  p {
    padding: 24px 16px;
    line-height: 24px;

    em{
      color: #EC635D;
    }
  }
`
export default BonusCan;