import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import ToggleList from '../../../components/TouchOll/board/ToggleList';

const QuestA = () => {
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        자주묻는 질문
      </SubHead>

      <section>
        <ToggleList />
        <ToggleList />
        <ToggleList />
      </section>
    </div>
  );
};

export default QuestA;