import React, { useState } from 'react';
import TelNumberInput from '../../../components/common/inputs/TelNumberInput';
import SystemModal from '../../../components/common/SystemModal';
import SubHead from '../../../components/TouchOll/SubHead';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import UserInfoInputs from '../../../components/TouchOll/join/UserInfoInputs';
import { formatPhoneNumber } from '../../../components/common/function/FormatNumber';

const PwTelNumber = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // const mobile = location.state;
  const mobile = '01029522222';

  // const [mobile, setMobile]         = useState('');// 핸드폰 번호
  const [pwState, setPwState]       = useState(false);// 핸드폰 번호
  const [passwd, setPasswd]         = useState('');   // 비밀번호 확인
  const [name, setName]             = useState('');   // 이름
  const [birthday, setBirthday]     = useState('');   // 생년월일

  const [popupTxt, setPopupTxt]     = useState('');     // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false);  // 시스템 팝업 

  
  const handleNextPage = () => {
    navigate('/app/join_end', {state: {name:name, mobile:mobile}})
  }

  /** 
   * 회원가입 완료 버튼
   * 조건: 아래 모두 true 표시 되어야 활성화
   */ 
  const [joinState, setJoinState] = useState({
    pwPattern   : false  // 비밀번호 패턴 확인
  , pwCheck     : false  // 비밀번호 동일 확인
  , nameCheck   : false  // 이름 유무 + 2글자 이상
  , birthCheck  : false  // 생년월일 패턴
})


  return (
    <div id="sub_common_wrap">
      {
        /**
         * 시스템 모달 팝업 창
         */
        system && (
          <SystemModal system={system} setSystem={setSystem}>
            {popupTxt}
          </SystemModal>
        )
      }

      <SubHead  SubBgColor={'#fff'} bottomLine={true}>
        비밀번호 재발급 & 정규가입
      </SubHead>

      <section>
        <NotMember>
          <h4>
            아래와 같이 새 비밀번호
            <br />재발급자 인증.확인되었습니다.
            <br />새 비밀번호 설정하세요
          </h4>

          <p>
            <span>폰 No[간편 I.D]</span>
            {formatPhoneNumber(mobile)}
          </p>
        </NotMember>
        
        <UserInfoInputs 
          joinState   ={joinState}
          setJoinState={setJoinState}
          name        ={name}
          setName     ={setName}
          passwd      ={passwd}
          setPasswd   ={setPasswd}
          setBirthday ={setBirthday}
          newpassword = {true}
        />

        <BtnPassWord id="wrap32">
          <BtnBottom 
            disabled={
              !(
                   joinState?.birthCheck
                && joinState?.nameCheck
                && joinState?.pwCheck
                && joinState?.pwPattern
               )
            }
            onClick={handleNextPage}>
            완료
          </BtnBottom>
        </BtnPassWord>
      </section>
      
    </div>
  );
};

const BtnPassWord = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  padding-bottom:40px;
`;

const NotMember = styled.div`
width: calc(100% - 32px);
padding: 0 16px;


h4 {
  text-align:center;
  font-size: 16px;
  line-height: 20px;
  font-weight:bold;
  margin: 40px 0 24px 0;
}

p{
  height: 40px;
  

  padding: 0 16px;
  background:#ccc;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    width: 50%;

  }
}
`

export default PwTelNumber;