import React from 'react';
import styled from 'styled-components';

const TotalBonusList = ({bonusIcon, bonus, total, use}) => {
  return (
    <ListBonus>
      <h2><img src={bonusIcon} alt={bonus} />{bonus}</h2>
      <div>
        <p><span>누적</span>{Number(total).toLocaleString()}</p>
        <p><span>가용</span>{Number(use).toLocaleString()}</p>
      </div>
    </ListBonus>
  );
};

const ListBonus= styled.div`
width: calc(100% - 16px);
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 0 0 16px;
height: 30px;

h2 {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: bold;

  img {
    width: 15px;
    height: 15px;
  }
}

div {
  width: calc(100% - 76px);
  display: flex;
  justify-content: end;
  align-items: center;

  p{
    width: calc(50% - 32px);
    padding: 0 16px;

    font-size: 14px;
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-weight: 500;
      min-width: 25px;
    }

    &:first-child {
      border-right: 1px solid #7B9CA3;
    }
  }
}
`

export default TotalBonusList;