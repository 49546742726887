import React, { useState } from 'react';
import { 
    close_button
  , question_btn 
  , stamp_description
} from "../../../assets/images"
import ModalPopup from '../common/ModalPopup';
import styled from 'styled-components';
import useModalWithBackButton from '../common/function/HistoryModal';

const StampQPopup = () => {
  const [isModal1Visible, toggleModal1] = useModalWithBackButton();

  return (
    <>
      <img
        src={question_btn}
        onClick={toggleModal1}
      />

      {isModal1Visible && (
        <ModalPopup>
          <img
            src={stamp_description}                  
            style={{ width: '320px', height: '600px' }}
          />
          <CloseButton
            src={close_button}
            alt="Close"
            onClick={toggleModal1}
          />
        </ModalPopup>
        )} 
    </>
  );
};

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export default StampQPopup;