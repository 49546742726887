import React, { useState } from 'react';
import styled from 'styled-components';

const UserInfoInputs = ({
  joinState
  , setJoinState
  , name
  , setName
  , passwd
  , setPasswd
  , setBirthday
  , newpassword //비밀번호 재발급 유무
}) => {
  const [passwdIput, setPasswdIput] = useState('');   // 비밀번호
  const [feedNum, setFeedNum]       = useState('영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상')   // 비밀번호 feed 텍스트

  /**
   * 비밀번호 유효성 검사 체크
   * 조건: 영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상 (한글 불가)
   */
  const passwordPattern     = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handlePWInputChange = (e) => {
    const inputValue        = e.target.value;
    setPasswdIput(inputValue);

    if (!inputValue) {
      setFeedNum("영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상");
    } 
    else if (!/^[A-Za-z\d@$!%*?&]*$/.test(inputValue) || !passwordPattern.test(inputValue)) {
      setFeedNum("비밀번호가 유효하지 않습니다.");
      setJoinState(prevState => ({ ...prevState, pwPattern: false }));
      document.querySelector('#feedtxt1').classList.remove('password-match');
    } 
    else {
      setFeedNum("비밀번호가 유효합니다.");
      setJoinState(prevState => ({ ...prevState, pwPattern: true }));
      document.querySelector('#feedtxt1').classList.add('password-match'); 
    }
  };

  /**
   * 비밀번호 확인 체크
   * 조건: 비밀번호 입력 input 창과 일치해야 함
   */
  const handlePwConfirmation = (e) => {
    const confirmPassword = e.target.value;
    setPasswd(confirmPassword)
    const password        = document.getElementById("passwordInput").value;

    if (confirmPassword === password) {
      setFeedNum("비밀번호가 일치합니다.");
      setJoinState(prevState => ({ ...prevState, pwCheck: true }));
      document.querySelector('#feedtxt1').classList.add('password-match'); 
    } 
    else {
      setFeedNum("비밀번호가 일치하지 않습니다.");
      setJoinState(prevState => ({ ...prevState, pwCheck: false }));
      document.querySelector('#feedtxt1').classList.remove('password-match'); 
    }
  };

  /**
   * 이름 확인 체크
   * 조건: 2글자 이상 작성
   */
  const handleNameInput = (e) => {
    const nameValue     = e.target.value;
    setName(nameValue)

    if (nameValue.length >= 2) {
      setJoinState(prevState => ({ ...prevState, nameCheck: true }));
      document.querySelector('#feedtxt2').classList.add('password-match'); 
    } 
    else {
      setJoinState(prevState => ({ ...prevState, nameCheck: false }));
      document.querySelector('#feedtxt2').classList.remove('password-match'); 
    }
  };

  /**
   * 생년월일 확인 체크
   * 조건: 생년월일 정규식 확인
   */
  const handleBirthInput  = (e) => {
    let birthValue        = e.target.value;
    birthValue            = birthValue.replace(/\D/g, ''); // 숫자 이외의 문자 제거
    setBirthday(birthValue)

    if (birthValue.length > 8) {
      birthValue = birthValue.slice(0, 8); // 최대 길이를 8자
    }

    if (birthValue.length >= 6) {
      birthValue = birthValue.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3'); // 형식 변환
    }

    e.target.value = birthValue; // 입력 필드에 변환 값 설정
    

    // 유효성 확인
    if (birthValue.match(/(19|20)\d{2}\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])/)) {
      setJoinState(prevState => ({ ...prevState, birthCheck: true }));
      document.getElementById('feedtxt3').classList.add('password-match'); 
    } 
    else {
      setJoinState(prevState => ({ ...prevState, birthCheck: false }));
      document.getElementById('feedtxt3').classList.remove('password-match');
    }
  };

  return (
    <>
      <JoinInputs>
        <div>
          <p>{newpassword && '새 '}비밀번호</p>

          {/* 비밀번호 관련 feed */}
          <Feedtxt id="feedtxt1">
            {feedNum}
          </Feedtxt>

          <input
            id="passwordInput"
            type="password"
            value={passwdIput}
            placeholder="비밀번호 입력"
            onChange={handlePWInputChange} />

          <input
            type="password"
            value={passwd}
            placeholder="비밀번호 확인"
            onChange={handlePwConfirmation} />
        </div>
      </JoinInputs>

      <JoinInputs>
        <div>
          <p>이름</p>

          <Feedtxt id="feedtxt2">
            {
              joinState.nameCheck ?
                "비실명시 혜택, 비밀번호 찾기 불가"
                : "이름은 2글자 이상 입력하세요."
            }
          </Feedtxt>

          <input
            type="text"
            value={name}
            placeholder="외국어도 가능"
            onChange={handleNameInput}
          />
        </div>
        
      </JoinInputs>

      <JoinInputs>
        <div>
          <p>생년월일</p>

          <Feedtxt id="feedtxt3">
            {
              joinState.birthCheck ?
                "실제와 다를시 혜택, 비밀번호 찾기 불가"
                : "정확한 생년월일을 입력해주세요."
            }
          </Feedtxt>
          
          <input
            type="text"
            placeholder="0000.00.00"
            onChange={handleBirthInput}
          />
        </div>
        
      </JoinInputs>
    </>
  );
};


const JoinInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;

    padding: 0 16px;

    font-size: 16px;
  }
`;

const Feedtxt = styled.p`
  font-size: 14px !important;
  // margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;
export default UserInfoInputs;