import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
${reset}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Variable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/montserrat:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

* {
    font-family : Pretendard !important;
    font-weight: 500;
    padding:0;
    margin:0;

    color:#222;

    margin-block-start:0; 
    margin-block-end:0; 
    margin-inline-start:0; 
    margin-inline-end:0;

    padding-block-start:0; 
    padding-block-end:0; 
    padding-inline-start:0; 
    padding-inline-end:0;
}

html, body {
  -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust : none;  /* IE */
  -moz-text-size-adjust : none;  /* 파이어폭스 */
  -o-text-size-adjust : none;  /* 오페라 구버전 */
}

.pay {
  font-family: 'Montserrat Variable' !important;
  font-weight:900;
}

.it_txt {
  font-weight: 900;
  font-style: italic;
}

button {
    background:none;
    border:none;
    cursor:pointer;
    &:focus {
      outline:none;
      -webkit-tap-highlight-color : rgba(0,0,0,0);
    }
}

a {
    text-decoration: none;
}

input {
  border:none; 
  outline:none;

  &::placeholder {
    color:#8e8e8e;
  }
}

// input type이 number일 때 기본: 화살표 버튼 삭제
input[type="number"]{
    &::-webkit-inner-spin-button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }
}

#sub_common_wrap {
    width: 100%;
    min-height: 100vh;
    background: #f5f5f5;
}

#sub_common_wrap_f {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}

#sub_head {
    width: 100%;
    height: 60px;

    position: relative;

    // 뒤로가기 아이콘
    div {
        position:absolute;
        left: 0;
        top:50%;
        margin-top: -22.5px;
    }
}

#wrap32 {
    width:calc(100% - 32px);
    padding-left:16px;
    padding-right:16px;
}

section {
    padding-top:60px;
    min-height: calc(100vh - 60px);
}
`;

export default GlobalStyle;
