import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const BottomGnb = () => {
  return (
    <BottomNavibar className='botttombar'>
      <li>
        <NavLink exact="true" to="/app/main" activeclassname="active">
          <span>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.59717 9.76664C2.59717 9.37262 2.79772 9.00296 3.13538 8.77461L11.4554 3.1481C11.8995 2.84777 12.4949 2.84777 12.939 3.1481L21.259 8.77462C21.5966 9.00296 21.7972 9.37261 21.7972 9.76664V20.2969C21.7972 21.3054 20.9376 22.1228 19.8772 22.1228H16.0372C15.4849 22.1228 15.0372 21.6751 15.0372 21.1228V16.5838C15.0372 16.0315 14.5895 15.5838 14.0372 15.5838H10.3572C9.80488 15.5838 9.35717 16.0315 9.35717 16.5838V21.1228C9.35717 21.6751 8.90945 22.1228 8.35717 22.1228H4.51717C3.45678 22.1228 2.59717 21.3054 2.59717 20.2969V9.76664Z" stroke="#222222" strokeWidth="2"/>
            </svg>
          </span>
          <p>홈</p>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/store" activeclassname="active">
          <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.36184 4.7892L19.0664 21.622M2.36184 4.7892C2.98336 4.05683 3.91165 3.59189 4.94878 3.59189H10.5958M2.36184 4.7892C1.86197 5.37824 1.56055 6.14027 1.56055 6.97254V19.3683C1.56055 21.2353 3.07751 22.7489 4.94878 22.7489H17.3723C19.2436 22.7489 20.7605 21.2353 20.7605 19.3683V14.2973M10.5958 14.2973L3.25466 21.622M18.6005 5.02867V4.95664M22.4406 4.94538C22.4406 7.44412 18.6005 10.7758 18.6005 10.7758C18.6005 10.7758 14.7605 7.44412 14.7605 4.94538C14.7605 2.87535 16.4798 1.19727 18.6005 1.19727C20.7213 1.19727 22.4406 2.87535 22.4406 4.94538Z" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <p>가맹점찾기</p>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/useinfo" activeclassname="active">
          <span>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.79922 5.8H13.1992M4.79922 10.6H13.1992M2.75922 1H15.2392C16.1008 1 16.7992 1.80589 16.7992 2.8V19L14.1992 17.2L11.5992 19L8.99922 17.2L6.39922 19L3.79922 17.2L1.19922 19V2.8C1.19922 1.80589 1.89765 1 2.75922 1Z" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <p>MY이용내역</p>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/more" activeclassname="active">
          <span>
            <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.20039 3.99961C6.20039 5.32509 5.12587 6.39961 3.80039 6.39961C2.47491 6.39961 1.40039 5.32509 1.40039 3.99961C1.40039 2.67413 2.47491 1.59961 3.80039 1.59961C5.12587 1.59961 6.20039 2.67413 6.20039 3.99961Z" stroke="#4D4D4D" strokeWidth="2" />
              <path d="M13.4004 3.99961C13.4004 5.32509 12.3259 6.39961 11.0004 6.39961C9.67491 6.39961 8.60039 5.32509 8.60039 3.99961C8.60039 2.67413 9.67491 1.59961 11.0004 1.59961C12.3259 1.59961 13.4004 2.67413 13.4004 3.99961Z" stroke="#4D4D4D" strokeWidth="2" />
              <path d="M20.6004 3.99961C20.6004 5.32509 19.5259 6.39961 18.2004 6.39961C16.8749 6.39961 15.8004 5.32509 15.8004 3.99961C15.8004 2.67413 16.8749 1.59961 18.2004 1.59961C19.5259 1.59961 20.6004 2.67413 20.6004 3.99961Z" stroke="#4D4D4D" strokeWidth="2" />
            </svg>
          </span>
          <p>더보기</p>
        </NavLink>
      </li>
    </BottomNavibar>
  );
};


const BottomNavibar = styled.ul`
  width: 100%;
  // max-width: 600px;
  height: 70px;
  background: #fff;
  z-index: 9;

  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #E0E0E0;

  li {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-size: 12px;
      color: #444;

      span {
        width: 25px;
        height: 25px;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        margin-top: 4px;
      }

      &.active {
        svg {
          stroke: #FE7A36;

          path {
            stroke: #FE7A36;
          }
        }

        p {
          color: #FE7A36;
          font-weight: 700;
        }
      }
    }

    &:first-child {
      a.active{
        svg {
          fill: #FE7A36;
        }
        path {
          fill: #FE7A36;
        }
      }
      
    }
  }
`

export default BottomGnb;