import React from 'react';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import Recipe from '../../../components/common/payuse/Recipe';
import styled from 'styled-components';
import RecipeBank from '../../../components/common/payuse/RecipeBank';

const OnlyReceipt = () => {
  const storedData = localStorage.getItem('pageState');
  const data = JSON.parse(storedData);

  const storeInfo     = data?.store;
  const pointInfo     = data?.point;
  const receiptInfo   = data?.receipt || data?.recipt;
  const useType       = data?.type;

  console.log("data", data, storeInfo, pointInfo)

  return (
    <div id="sub_common_wrap">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

      <section>
      <PointWrap>
        {
          // 통장입금 영수증 타입일 경우
          useType === '5' ? 
          <RecipeBank
          receiptInfo ={receiptInfo} 
          pointInfo   ={pointInfo} 
          storeInfo   ={storeInfo} 
          useType     ={useType} 
          />
          :
          <Recipe 
          receiptInfo ={receiptInfo} 
          pointInfo   ={pointInfo} 
          storeInfo   ={storeInfo} 
          useType     ={useType} 
          />
        }

        </PointWrap>
      </section>
    </div>
  );
};


const PointWrap = styled.div`
width: calc(100% - 32px);
padding:32px 16px;
`

export default OnlyReceipt;