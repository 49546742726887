import React from 'react';
import styled from 'styled-components';

const TotalBonusDetail = ({detailOpen, totalT, useT, totalG, useG, totalS, useS}) => {
  return (
    <BonusDetail className={detailOpen ? "open" : ""}>
      <li className='earnT'>
        <span>통합</span>
        <div>
          <p>{Number(totalT).toLocaleString()}</p>
          <p>{Number(useT).toLocaleString()}</p>
        </div>
      </li>

      <li className='earnG'>
        <span>그룹</span>
        <div>
          <p>{Number(totalG).toLocaleString()}</p>
          <p>{Number(useG).toLocaleString()}</p>
        </div>
      </li>

      <li className='earnS'>
        <span>단독</span>
        <div>
          <p>{Number(totalS).toLocaleString()}</p>
          <p>{Number(useS).toLocaleString()}</p>
        </div>
      </li>
    </BonusDetail>
  );
};

const BonusDetail = styled.ol`
width: calc(100% - 32px);
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
padding: 0px 16px;
gap: 8px;

background:#f9f9f9;

max-height:0;
overflow: hidden;
transition: all .2s linear; 

&.open {
  max-height: 100px;
  transition: all .2s linear; 
}

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-top:12px;
    }
    &:last-child {
      padding-bottom:12px;
    }

    span {
      color:#fff;
      width: 28px;
      height: 16px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      margin-left: 24px;
    }

    &.earnT span {background:#5D0EF5;}
    &.earnG span {background:#8E3D82;}
    &.earnS span {background:#7F7F7F;}

    div {
      width: calc(100% - 90px);
      // padding: 0 16px;
      display: flex;
      justify-content: end;
      align-items: center;

      p {
        width: calc(50% - 32px);
        padding: 0 16px;
        font-size: 14px;
        text-align:right;
      }
    }
  }
`

export default TotalBonusDetail;