import React, { useState } from "react";
import styled from "styled-components";
import Header from "../common/Header";
import CashResult from "../../components/Biapp/CashResult";
import GameResult from "../../components/Biapp/GameResult";
import StampResult from "../../components/Biapp/StampResult";
import Comparison from "../../components/Biapp/Comparison";
import PhoneNumberInput from "../../components/Biapp/PhoneNumberInput";
import Receipt from "../../components/Biapp/Receipt";
import Ad from "../../components/Biapp/Ad";
import { useLocation } from "react-router-dom";

import FirstUserBan from "./FirstUserBan";
import PhoneNumBan from "./PhoneNumBan";
import PointUseScreen from "../../components/common/payuse/PointUseScreen";
import StampUseScreen from "../../components/common/payuse/StampUseScreen";

function PointSaveResult({ props }) {

  const location = useLocation();
  const { data, gameResult } = location.state || {};

  // const dataInfo = data;
  const storeInfo = data?.store;


  // console.log("PointSaveResult dataInfo:", dataInfo);
  console.log('PointSaveResult storeInfo:', storeInfo);
  console.log('PointSaveResult gameResult:', gameResult);

  return (
    <div style={{ backgroundColor: '#F5F5F5' }}>
      <Header />

      {storeInfo && (
        <>
          {
            storeInfo.pointUse === "Y" && 
            <CashResult data={data} />
          }

          {
            storeInfo.stampUse === "Y" &&
            <StampResult data={data} />
          }

          { 
            data?.receipt?.stlmMethodCode !== "9" &&
            storeInfo.rpsUse === "Y" && 
            <GameResult data={data} gameResult={gameResult} />
          }

          {/* {storeInfo.pointUse === "Y" &&
            storeInfo.stampUse === "N" &&
            storeInfo.rpsUse === "Y" && (
              <>
                <CashResult data={data} />
                {
                  data?.receipt?.stlmMethodCode !== "9" && <GameResult data={data} gameResult={gameResult} />
                }
                
              </>
            )}

          {storeInfo.pointUse === "N" &&
            storeInfo.stampUse === "Y" &&
            storeInfo.rpsUse === "Y" && (
              <>
                <StampResult data={data} />
                {
                  data?.receipt?.stlmMethodCode !== "9" && <GameResult data={data} gameResult={gameResult} />
                }
              </>
            )} */}

        </>
      )}

      <FirstUserBan />

      <PhoneNumBan />

      <PhoneNumberInput data={data} gameResult={gameResult} />
      <Comparison />
      <Receipt data={data} />
      
      <Ad />
    </div>
  );
}


export default PointSaveResult;
