import React from 'react';
import { ad, ban1_1, ban2_1, ban3, ban4 } from '../../../../assets/images';
import styled from 'styled-components';
import AdImgPopup from '../AdImgPopup';

const Adimg = () => {
  return (
    <AdWrap>
      {/* <img src={ad} alt={adname ? adname : 'none'} /> */}
      <ul>
        <li>
          <AdImgPopup 
          imgUrl={ban3}
          imgName={"배너"}
          img2Url={ban1_1}
          img2Name={"자세히보기 배너"}
          />
        </li>
        <li>
          <AdImgPopup 
          imgUrl={ban4}
          imgName={"배너"}
          img2Url={ban2_1}
          img2Name={"자세히보기 배너"}
          />
          </li>
        <li>
          <AdImgPopup 
          isVideo={true}
          videoUrl={"https://www.youtube.com/embed/lXEZAc3eglk"}
          videoName={"롯데마트 한우데이, 한참은 더 따듯할 우리의 날들" }
          />
        </li>
      </ul>
    </AdWrap>
  );
};

const AdWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 32px;

width: 100%;
height: max-content;

// box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.06);

ul {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  gap: 8px; 

  width: 100%;

  li {
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    overflow:hidden;
    
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .iframeContainer {
    position: relative;
    width: 100%;
  }
  .iframeContainer iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .iframe4To3 {
    padding-top: 70%;
  }
}
`
export default Adimg;