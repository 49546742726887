import React, { useEffect, useState } from 'react';
import ModalPopup from '../../common/ModalPopup';
import styled from 'styled-components';
import { close_gray, search } from '../../../../assets/images';
import SelectInput from '../usinginfo/SelectInput';

const SearchStorePopup = ({
    setIsOpen
  , nameIs
  , codeList
  , handleSearchPay
  , handlesearchList
  , paySearch
  , setPaySearch
}) => {

  const [detailArea, setDetailArea] = useState({})
  const [detailDong, setDetailDong] = useState({})

  useEffect(()=>{
    console.log("detailArea", detailArea)
    console.log("detailDong", detailDong)
  },[detailArea, detailDong])

  
  useEffect(()=>{
    setPaySearch((prevState) => ({
      ...prevState,
      areaCode: "",
      dongCode: ""
    }));
  },[paySearch.cityCode])

  useEffect(()=>{
    setPaySearch((prevState) => ({
      ...prevState,
      dongCode: ""
    }));
  },[paySearch.areaCode])
  
  const handleSearchAndClose = () => {
    handleSearchPay();
    setIsOpen(false);
  };


  return (
    <ModalPopup setIsOpen={setIsOpen}>
      <CloseButton
        src={close_gray}
        alt="Close"
        onClick={() => setIsOpen(false)}
      />

      <SearchWrap>
        <h3>가맹점 찾기 {nameIs ? '' : '상세'} 검색</h3>
        
          <div>
          <LayoutOne>
            <label className='search_input'>
              <img src={search} alt='검색' />
              <input 
                type='text'
                placeholder='가맹점명 검색' 
                onChange={(e) => handlesearchList("storeName", e.target.value)}
              />
            </label>
          </LayoutOne>

          {
          nameIs ? <></> :
          <>
          <LayoutTwo>
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="시/도"
              selectList      ={codeList?.AREA_CODE?.[0]?.listCode}
            />
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="구/군"
              selectList      ={detailArea && detailArea}
              disabled        ={Object.keys(detailArea).length === 0}
            />
          </LayoutTwo>

          <LayoutOne>
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="동/읍/면"
              selectList      ={detailDong && detailDong}
              disabled        ={Object.keys(detailDong).length === 0}
            />
          </LayoutOne>
          
          <LayoutTwo>
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="보너스유형"
              selectList      ={codeList?.EARN_TYPE?.[0]?.listCode}
            />
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="적립유형"
              selectList      ={codeList?.POINT_TYPE?.[0]?.listCode}
            />
          </LayoutTwo>

          <LayoutTwo>
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="업종"
              selectList      ={codeList?.SECTOR?.[0]?.listCode}
            />
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="그룹"
              selectList      ={codeList?.STORE_GRP?.[0]?.listCode}
            />
          </LayoutTwo>

          {/* <LayoutTwo>
            <SelectInput 
              selectName="업종 상세"
              selectList={['보너스유형1', '보너스유형2', '보너스유형3']}
            />
            <SelectInput 
              selectName="그룹 상세"
              selectList={['보너스유형1', '보너스유형2', '보너스유형3']}
            />
          </LayoutTwo> */}

          <LayoutTwo>
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="가맹 등급"
              selectList      ={codeList?.STORE_LEVEL?.[0]?.listCode}
            />
            <SelectInput 
              setDetailArea   ={setDetailArea}
              setDetailDong   ={setDetailDong}
              handlesearchList={handlesearchList}
              selectName      ="평가 등급"
              selectList      ={codeList?.STORE_EVALUTE?.[0]?.listCode}
            />
          </LayoutTwo>
          </>
        
        }
        </div>
        
        <button onClick={handleSearchAndClose} >검색</button>
      </SearchWrap>
    </ModalPopup>
  );
};


const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SearchWrap = styled.div`
min-width: calc(320px - 32px);
background:#fff;
padding: 32px 16px 16px 16px;
border-radius: 12px;

  input {
    font-size: 14px;
    color:#4F4F4F;
  }

  h3 {
    font-size: 16px;
    font-weight:bold;
    text-align: center;

    margin-bottom: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
  }

  .search_input {
    width: calc(100% - 22px);
    height: 38px;
    border:1px solid #BDBDBD;
    border-radius: 8px;
    padding: 0 10px;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
  }

  & > button {
    width: 100%; 
    height: 32px;
    background:#4F4F4F;
    color:#fff;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 20px;

  }
`

const LayoutTwo = styled.div`
width: 100%;

display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
`

const LayoutOne = styled.div`
width: 100%;

display: flex;
justify-content: flex-start;
align-items: center;
`


export default SearchStorePopup;