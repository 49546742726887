import React from 'react';
import styled from 'styled-components';

const AdminTit = ({children}) => {
  return (
    <AdminH3tit>
      <em></em> {children}      
    </AdminH3tit>
  );
};

const AdminH3tit = styled.h3`
em {
  display:inline-block;
  width: 4px;
  height: 17px;
  background:#222;
}

font-size: 20px;
font-weight: bold;
color:#222;

display: flex;
justify-content: start;
align-items: center;
gap: 4px;

`

export default AdminTit;