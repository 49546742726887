import React from 'react';
import styled from "styled-components";
import { logo, logo_toucholl } from '../../../../assets/images';
import PSGicon from '../PSGicon';

const Recipe = ({ 
    receiptInfo
  , pointInfo
  , storeInfo
  , useType 
}) => {
  /**
   * APP 전자영수증
   * stamp : 스탬프 여부
   * storeInfo : 스토어 정보
   * useType : 타입
   */

  console.log("receiptInfo:", receiptInfo);
  console.log("storeInfo:", storeInfo);
  const supplyPrice = Number(receiptInfo?.supplyPrice).toLocaleString()
  const sttleStamp  = Number(pointInfo?.sttleStamp).toLocaleString(); // 사용
  const stlmPrice   = Number(receiptInfo?.stlmPrice).toLocaleString()
  const sttlePoint  =  Number(pointInfo?.sttlePoint).toLocaleString();

  
  return (
    <BorderContainer>
      <div className="recipe_wrap">
        <h2>전자 영수증</h2>
        
        <p>{receiptInfo?.stlmDt}</p>
        <h3>{storeInfo?.storeName}</h3>
        <h1>
          {receiptInfo?.stlmMethodCode === "9" && "거스름돈 "}
          {receiptInfo?.stlmMethodCode === "9" ? sttlePoint : stlmPrice}
          {storeInfo?.stampUse === "Y" && (useType === '2' || useType === '4') ? '개' : '원'}
        </h1>
        <ul>
          {
            receiptInfo?.stlmMethodCode !== "9" && 
            <>
              <li><span>결제수단</span>{receiptInfo?.stlmMethodName}</li>
              <li><span>{storeInfo?.stampUse === "Y" && (useType === '2'||  useType === '4') ? '스탬프 사용':'공급가액'}</span>
              {storeInfo?.stampUse === "Y" && (useType === '2'||  useType === '4') ? stlmPrice : supplyPrice}</li>
              <li><span>부가세</span>{receiptInfo?.vatPrice ? Number(receiptInfo?.vatPrice).toLocaleString() : 0}</li>
            </>
          }
          <li><span>승인번호</span>{receiptInfo?.aprvNo}</li>
          <li><span>사업자번호</span>{storeInfo?.brno}</li>
        </ul>
      </div>
    </BorderContainer>
  );
};


const BorderContainer = styled.div`
  width: 100%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.06);
 
  .recipe_wrap{
    width: calc(100% - 32px);
    padding: 30px 16px;border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 10px; 
  }

  h2{
    color: #4D4D4D;
    font-weight: 600;
    font-size: 22px;
    text-align:center;
  }

  p{
    margin-top: 28px;
    color: #8E8E8E;
    font-weight: 500;
  }

  h3 {
    margin-top: 12px;
    color: #222222;
    font-weight: bold;
  }

  h1 {
    color: #222222;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    margin-top: 18px;
    padding-bottom: 8px;
  }

  ul {
    margin-top: 20px;

    li{
      display: flex;
      justify-content: space-between;
      align-content: center;

      color: #8E8E8E;
      font-weight: 500;
      font-size: 16px;

      line-height: 24px;

      span {
      color: #8E8E8E;  
      font-weight: 500;
      font-size: 16px;
    }
    } 
  }
`;

export default Recipe;