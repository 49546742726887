import React from 'react';
import { useNavigate } from 'react-router';
import { errorimg, logo_toucholl, logo_txt } from '../../../assets/images';
import styled from 'styled-components';
import SubHead from '../../components/TouchOll/SubHead';

const NoMemberPage = () => {
  const navigate = useNavigate();

  const handelMain = () => {
    navigate('/app/join_agree')
  }

  return (
    <div id="sub_common_wrap">
      <SubHead SubBgColor={'#f5f5f5'} bottomLine={true}>
        비회원 이용불가
      </SubHead>
    <ErrorNoMember>
      <div>
        <h1><img src={errorimg} alt='터치올' /></h1>
        <h3>비회원 이용불가</h3>
        <button onClick={handelMain}>원클릭 초간편 가입하기</button>
      </div>
    </ErrorNoMember>
    </div>
  );
};

const ErrorNoMember = styled.div`
width: 100vw;
height: 100vh;
background:#f5f5f5;

display:flex;
justify-content: center;
align-items: center;

text-align:center;

h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #222;
}

button {
padding: 8px 20px;
background:#FE7A36;
font-size: 16px;
color:#fff;
font-weight: bold;
border-radius: 8px;
margin-bottom: 120px;
}
`

export default NoMemberPage;