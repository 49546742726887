import React from 'react';
import styled from 'styled-components';

const MenuIcon = ({type}) => {
  /**
   * recom : 추천
   * favor : 인기
   * best  : 대표
   * sale  : 할인
   * new   : 신상
   */
  return (
    <MenuBan className={type}>
      {
        type === 'recom' ? "추천" :
        type === 'favor' ? "인기" :
        type === 'best'  ? "대표" :
        type === 'sale'  ? "% 할인" : "신상"
      }
    </MenuBan>
  );
};

const MenuBan = styled.em`
display:inline-block;
height: 10px;
color:#fff;
padding: 2px 4px;
border-radius: 4px;

font-size: 12px;
font-weight: bold;

&.recom {
  background:#0547F0;
}
&.favor {
  background:#F41616;
}
&.best {
  background:#FE7A36;
}
&.sale {
  background:#BA0BF7;
}
&.new {
  background:#208C05;
}
`

export default MenuIcon;