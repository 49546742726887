import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import { useLocation } from 'react-router-dom';
import EarnIcon from '../../../components/common/EarnIcon';
import styled from 'styled-components';
import { logo, nfcimg, p_icon } from '../../../../assets/images';
import axios from 'axios';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import ModalPopup from '../../../components/common/ModalPopup';
import NFCpopup from '../../../components/common/NFCpopup';
import ApprovalPop from './ApprovalPop';
import IosNfc from '../../../components/common/function/IosNfc';

const UsingPointStore = ({iosNFC}) => {
  const location  = useLocation();
  const storeData = location.state.storeData  || '';
  const earn      = location.state.earn       || '';
  const useTotal  = location.state.useTotal   || '';


  const [price, setPrice]   = useState('');

  const priceChangeHandler  = (event) => {
    let price = event.target.value;
        price = Number(price.replaceAll(',', ''));

    if (isNaN(price)) {
      setPrice(0);
    } else {
      setPrice(price.toLocaleString());
    }
  }

  const [isModal, setIsModal] = useState(false);
  const [isNFC, setIsNFC] = useState(false);

  /**
   * 모바일 기기 확인 
   * isMobile : true (모바일 기기)
   */
  const [isMobile, setIsMobile]   = useState(false); // 모바일 기기인지 확인
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  useEffect(()=>{
    handleHeaderToken();
  }, [])

  const [approvalData, setApprovalData] = useState({})
  const handelApproval = () => {
    const fetchData = async() => {
      try{
        const responseData = {
            sid: storeData?.sid
          , kind: "P"
          , amount: price.replace(/,/g, "")
          , earn: earn
          , serial : storeData?.serial
        }

        const response = await axios.post('/api/pointMngr/approvePointUsage', responseData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log("approvalData :: ", response?.data?.data?.message);
        const telegramMsg = response?.data?.data?.message?.telegram;
        setApprovalData(response?.data?.data?.message);

        if (isMobile && telegramMsg) {
          // eslint-disable-next-line
          userApprovalPay.postMessage("user_point_use="+telegramMsg);
          setIsNFC(true);
        }

      } catch(error){
        console.log("사용승인 오류 ::". error);
      }
    }

    fetchData()
  }

  const [approvalPage, setApprovalPage] = useState(false)
  const [approvalJson, setApprovalJson] = useState([])
  const approvalAPI = () => {
    const fetchData = async() => {
      try{
        const responseData = {
          approval: approvalData?.approval
          , serial: storeData?.serial
          , certification: approvalData?.certification
        }

        const response = await axios.post('/api/pointMngr/approvePointUsageCfrm', responseData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log("approvePointUsageCfrm :: ", response?.data?.data?.message);
        setApprovalPage(true);
        setApprovalJson(response?.data?.data?.message)

      } catch(error){
        console.log("approvePointUsageCfrm 오류 ::". error);
      }
    }

    fetchData()
  }
  

  const locationLink = (jsonKey, jsonValue) => {
    if(jsonKey === "device_NFC"){
      if(jsonValue === 'false'){
        setIsNFC(false);
        approvalAPI();
      }
      
    }
  }

  //eslint-disable-next-line no-undef
  window.getTelegramDeviceNFC = (msg) => {
    const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, '');

    locationLink(key, value);

    
    //닫히고 nfc 가 false이면 바로 확인 호출
  }


  
  return (
    
    <div id="sub_common_wrap_f">
      {iosNFC && <IosNfc/>}

      {
        isNFC && !iosNFC && (
          <ModalPopup>
            <NFCpopup setIsNFC={setIsNFC} />
          </ModalPopup>
        )
      }

{
        approvalPage && (
          <ModalPopup>
            <ApprovalPop  
            approvalData    ={approvalData} 
            earn            ={earn} 
            setApprovalPage ={setApprovalPage} 
          />
          </ModalPopup>
        )
      }

      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        My 보너스 조회ㆍ사용
      </SubHead>

      <section>
        <EarnTit>
          {storeData?.storeName}
          <EarnIcon earn={earn} />
        </EarnTit>

        <PayAmount>
          <p>
            가용 현금 <img src={p_icon} alt='현금' />
          </p>
          <h3>{Number(useTotal).toLocaleString()}원</h3>
        </PayAmount>

        <PayInput>
          <h5><span className='pay'>Pay</span>ㆍ사용 현금<em>P</em></h5>
          <div>
            <input
              type        ="text"
              placeholder ='사용 결제액 입력'
              value       ={price}
              onChange    ={priceChangeHandler}
            />
            <img src={p_icon} alt='현금' />
          </div>
        </PayInput>

        <PayBtn>
          <a href='/app/useinfo_store'>취소</a>
          <button 
          disabled={
                       !price 
                    || parseInt(price.replace(/,/g, "")) > useTotal
                    || parseInt(price.replace(/,/g, "")) < 1000
                  }

          onClick={handelApproval}
          >
            <span className='pay'>Pay</span>ㆍ사용 결제하기
          </button>
        </PayBtn>

      </section>

      {
        isModal && (
          <ModalPopup>
            <NFCpopup />
          </ModalPopup>
        )
      }
    </div>
  );
};

const EarnTit = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  gap:4px;
  padding: 30px 0;

  font-size: 20px;
  font-weight: bold;
`

const PayAmount = styled.div`
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    color:#7B8531;
    font-size: 16px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  h3 {
    color:#7B8531;
    font-size: 26px;
    font-weight:bold;
    text-align:center;
    margin-top: 12px;
  }
`

const PayInput = styled.div`
margin-top: 52px;
padding: 0 16px;

h5 {
  color:#909090;
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    color:#909090;
  }

  em {
    width: 16px;
    height: 16px;
    background:#909090;
    border-radius: 30px;

    color:#fff;
    font-weight: bold;
    font-size: 14px;
    margin-left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

&>div {
  background:#f0f0f0;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;

  padding: 0 16px;
  height:60px;
  margin-top: 12px;

  border-radius: 8px;

  img {
    width: 26px;
  }

  input {
    background:none;
    font-size: 20px;
    text-align:right;

    width: 100%;
    color:#222;

    &::placeholder {
      color:#888;
    }
  }
}
`
const PayBtn = styled.div`
padding:0 16px;
display: flex;
justify-content: space-between;
align-items: center;
gap: 12px;
margin-top: 40px;

a {
  height: 50px;
  width: 80px;
  font-size: 16px;
  color:#4d4d4d;

  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: calc(100% - 92px);
  height: 50px;
  background:#FE7A36;
  border-radius: 25px;

  color:#fff;
  font-size: 16px;

  span {
    color:#fff;
  }

  &:disabled {
    background: #DFDFDF;
    color:#aaa;

    span {
      color:#aaa;
    }
  }
}
`
const NfcPopup = styled.div`
width: 90%;
min-width: 320px;

background:#fff;

padding: 28px 0 ;
border-radius: 12px;

h2 {
  width: 120px;

  img {
    width: 100%;
    height: auto;
  }
}

p{
  margin-top: 32px;
  font-size: 20px; 
  text-align:center;
  line-height: 28px;

  strong {
    color:#FE7A36;
    font-weight:bold;
  }

  b {
    font-weight:bold;
  }

  img {
    width: auto;
    height: 20px;
  }
}

button {
  margin-top: 28px;
  font-size: 16px;
  background:#ddd;
  width: 80px;
  height: 40px;

  border-radius: 8px;
  font-size: 16px;

}


display: flex;
justify-content: center;
align-items: center;
flex-direction:column;
`

export default UsingPointStore;