import React from 'react';
import { logo, p_icon, stamp_logo } from '../../../../assets/images';
import EarnIcon from '../../../components/common/EarnIcon';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ApprovalSop = ({
  approvalData
  , earn
  , setApprovalPage
  , loca
}) => {
  const navigate = useNavigate();
  
  const handleApprovalPage = () => {
    setApprovalPage(false)
    // navigate("/common/pay_result", 
    // {state: {approval: approvalData?.approval, type: approvalData?.type, sid:approvalData?.store?.sid}, replace: true})
    navigate("/app/main") // 사용시 적립금이 없어 메인 이동
  }

  const receiptInfo = approvalData?.receipt || approvalData?.recipt

  return (
    <ApproWrap className={`type${approvalData?.type}`}>
      <h3><img src={logo} alt='터치올' /></h3>
      <h4>가용 스탬프<EarnIcon earn={earn} /></h4>
      <div className='stamp_point'>
        <em><img src={stamp_logo} alt='스탬프 로고' /> </em>
        <div>
          <h1>{loca !== "main" ? Number(approvalData?.point).toLocaleString() : Number(receiptInfo?.stlmPrice).toLocaleString()}개</h1>
          <h2>사용 {approvalData?.type === "2" ? '완료' : '취소!'}</h2>
        </div>
      </div>

   

      <ul>
        <li>
          <span>{approvalData?.type === "2" ? '' : '취소 '}승인번호</span>                           
        : {loca !== "main" ? approvalData?.approval : receiptInfo?.aprvNo}
        </li>

        <li>
          <span><em className='pay'>Pay</em>{approvalData?.type === "2" ? '' : '취소 '} 일시</span> 
        : {receiptInfo?.stlmDt}
        </li>

        <li><span>상호명</span>                            
        : {approvalData?.store?.storeName}</li>

        <li><span>사업자번호</span>                         
        : {approvalData?.store?.brno}</li>
      </ul>

      {
        loca !== "main" && <button onClick={handleApprovalPage}>확 인</button>
      }
      
    </ApproWrap>
  );
};

const ApproWrap = styled.div`
  // 사용
  &.type2 {
    h1, h2, h2 em, p {color:#FD4D61;}
  }

  // 취소
  &.type4 {
    h1, h2, h2 em, p {color:#FB0E0E;}
  }

  width: 90vw;
  max-width: 320px;
  background:#fff;
  border-radius: 12px;

  text-align:center;
  padding: 28px 0 0 0;
  overflow: hidden;

  h3 {
    img {
      height: 25px;
    }
  }

  h4 {
    color:#FD4D61;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    
    font-size: 16px;
    font-weight:bold;
    margin-top: 28px;

    img {
      height: 16px;
      margin-right: 8px;
    }
  }

  h1 {
    font-size: 26px;
    font-weight:bold;
  }

  h2 {
    margin-top: 8px;
    font-weight:bold;
  }

  .stamp_point {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
    margin-top: 32px;

    em {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }

  ul {
    background:#f0f0f0;
    text-align:left;

    padding: 16px 32px;
    margin-top: 32px;

    li {
      color:#4D4D4D;
      line-height:20px;
      font-size: 14px;

      em {color:#4D4D4D;}

      span {
        font-weight:bold;
      }
    }

  }

  button {
    background:#FE7A36;
    width: 100%;
    height:60px;
    font-size: 20px;
    font-weight:bold;
    color:#fff;
  }
`
export default ApprovalSop;