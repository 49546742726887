import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bottom_arr_select, checkoff, checkon2 } from '../../../../assets/images';
import ModalPopup from '../../common/ModalPopup';

const SearchBonusSelect = ({
    handlesearchList
  , listBonusType
  , listEarnType}) => {

  const [bonusType, setBonusType] = useState(false); // 보너스 유형
  const [bonusList, setBonusList] = useState(false); // 적립유형

  const codeMap = {
      EARN_TYPE    : 'earn'
    , POINT_TYPE   : 'kind'
  }

  /**
   * bonusType input 클릭
   * @param {} event value 값
   */
  const [selectedBonusType, setselectedBonusType] = useState('');
  const handleTypeChange = (e, ko) => {
    const { value, name } = e.target;
    setselectedBonusType(ko);
    setBonusType(!bonusType);
    handlesearchList(codeMap[name], value);
  };

  /**
   * bonusList input 클릭
   * @param {} event value 값
   */
  const [selectedBonusList, setSelectedBonusList] = useState('');
  const handleListChange = (e, ko) => {
    const { value, name } = e.target;
    setSelectedBonusList(ko);
    setBonusList(!bonusList);
    handlesearchList(codeMap[name], value);
  };




  return (
    <>
      <SelectType>
        <div>
          <button onClick={() => setBonusType(!bonusType)}>
            {
              selectedBonusType ? selectedBonusType : "보너스 종류 전체 "
            }
            <span><img src={bottom_arr_select} alt='검색' /></span>
          </button>
        </div>

        <div>
          <button onClick={() => setBonusList(!bonusList)}>
            {
              selectedBonusList ? selectedBonusList : "적립유형 전체 "
            }
            <span><img src={bottom_arr_select} alt='검색' /></span>
          </button>
        </div>
      </SelectType>


      {
        bonusType &&
        <ModalPopup isOpen={bonusType}>
          <TypeModal >
            <ol>
              { listEarnType &&
                listEarnType.map((item, index) => {
                  return(
                    <li key={index}>
                      <label>
                        <input 
                        type    ='radio'
                        value   ={item?.code} 
                        name    ={item?.pcode}
                        onChange={(e) => handleTypeChange(e, item.codeName)} 
                        checked ={selectedBonusType === item?.codeName} />
                        <em></em>
                        {item?.codeName}
                      </label>
                    </li>
                  )
                })
              }
            </ol>

            <button onClick={() => setBonusType(!bonusType)}>취소</button>
          </TypeModal>
        </ModalPopup>
      }

      {
        bonusList &&
        <ModalPopup>
          <TypeModal>
            <ol>
              { listBonusType &&
                listBonusType.map((item, index) => {
                  return(
                    <li key={index}>
                      <label>
                        <input 
                        type    ='radio'
                        value   ={item?.code} 
                        name    ={item?.pcode}
                        onChange={(e) => handleListChange(e, item.codeName)} 
                        checked ={selectedBonusList === item?.codeName} />
                        <em></em>
                        {item?.codeName}
                      </label>
                    </li>
                  )
                })
              }
              
            </ol>

            <button onClick={() => setBonusList(!bonusList)}>취소</button>
          </TypeModal>
        </ModalPopup>
      }
    </>
  );
};

const SelectType = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  div {
    position: relative;
    width: 100%;
    height: 32px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    padding: 0 8px;

    position: relative;
  }
`


const TypeModal = styled.div`
  background: #fff;
  width: 220px;
  border-radius: 8px;
  text-align:center;
  padding: 0 0 16px 0;

  ol{
    padding: 16px 0 8px 0 ;

    li {
      display: flex;
      justify-content: start;
      align-items: center;

      height: 40px;
      font-size: 14px;
      padding: 0 16px 0 16px;
      // border-bottom: 1px solid #e0e0e0;

      label{
        display: flex;
        justify-content: start;
        align-items: center;
        gap:4px;
      }
    }
  }

  input[type='radio'] {
    display: none;
  }
  
  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + em {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }
  
  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + em {
    background-image: url(${checkon2});
  }

  button {
    width: 80px;
    height: 32px;
    background:#444;
    color:#fff;
    font-size: 14px;
    border-radius:4px;
  }
};
`

export default SearchBonusSelect;