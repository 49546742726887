import React from 'react';
import Sheet from 'react-modal-sheet';
import { useState } from 'react';

const BottomSheet = ({ termOpen, onClose, children }) => {


  return (
    <>
      <Sheet isOpen={termOpen} onClose={onClose} detent="content-height">
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>{children}</Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} />
      </Sheet>
    </>
  );
};

export default BottomSheet;
