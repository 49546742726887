import React from 'react';
import styled from 'styled-components';
import { stampicon } from '../../../../assets/images';
import PSGicon from '../../common/PSGicon';

const StampIcon = () => {
  return (
    <Stamp>
      스템프 <PSGicon type={'s'} />
    </Stamp>
  );
};

const Stamp = styled.em`
color:#FD4D61;
font-weight:bold;
line-height: 14px;
display: inline-flex;
img {
  margin-left: 2px;
  width: 14px;
  vertical-align: middle;
}
`
export default StampIcon;