import React from 'react';
import useModalWithBackButton from './function/HistoryModal';
import { 
    cash_description
  , close_button
  , game_description
  , question_btn
  , stamp_description 
} from '../../../assets/images';
import ModalPopup from './ModalPopup';
import styled from 'styled-components';
import PSGicon from './PSGicon';

const PSGmodelPopup = ({
  type 
}) => {
  const [isModal1Visible, toggleModal1] = useModalWithBackButton();
  /**
   * type : p | s | g
   */

  return (
    <>
      <IconPopup>
        <PSGicon type={type} />
         
        <img
          src={question_btn}
          onClick={toggleModal1}
        />
      </IconPopup>
      
      {isModal1Visible && (
        <ModalPopup>
          <img
            src={
              type === 'p' ? cash_description  :
              type === 's' ? stamp_description :
              game_description
            }      
            style={{ width: '320px', height: 'auto' }}          
          />
          <CloseButton
            src={close_button}
            alt="Close"
            onClick={toggleModal1}
          />
        </ModalPopup>
      )} 
    </>
  );
};

const IconPopup = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap:4px;
`


const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ModalWrap = styled(ModalPopup)`

`

export default PSGmodelPopup;