import React from 'react';
import { app_download_button, app_download_description, errorimg } from '../../../assets/images';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleAppDownloadButtonClick = () => {
    navigate('/app_download');
  };


  return (
    <ErrorWrap id="sub_common_wrap">
      <Header />

      {/* <h1><img src={errorimg} alt='터치올' /></h1> */}
      <div className='bd'>
        <h3>이미 거래된 요청입니다.</h3>
        <p>터치올을 이용해주셔서 감사합니다.<br/>
  해당 요청은 이미 승인되어 처리되었습니다.</p>
      </div>
      

      <CenterAlignContainer>
        <OverlayContainer>
          <AppDownloadDescription src={app_download_description} />
          <AppDownloadButton
            src={app_download_button}
            onClick={handleAppDownloadButtonClick}
          />
        </OverlayContainer>
      </CenterAlignContainer>
    </ErrorWrap>
  );
};

const ErrorWrap = styled.div`
text-align:center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.bd{
  width: calc(100% - 32px);
  background:#fff;
  padding: 32px 0;
  border-radius: 12px;
  margin-top: 16px;  
}

h3 {
  font-size: 20px;
  font-weight: bold;
  // margin-top: 52px;
}

p {
  font-size: 16px;
  margin-top: 16px;
  line-height: 24px;
  // padding-bottom: 20px;
}
`


const OverlayContainer = styled.div`
  position: relative;
`;

const AppDownloadButton = styled.img`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const CenterAlignContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AppDownloadDescription = styled.img`
  width: 320px;
`;



export default ErrorPage;