import React from 'react';
import { arr_w_right } from '../../../assets/images';
import styled from 'styled-components';

const RightArrIcon = () => {
  return (
    <RightArr>
      <img src={arr_w_right} alt='이동' />
    </RightArr>
  );
};

const RightArr = styled.em`
width: 15px;
height: 15px;
border-radius: 20px;
background:#4D4D4D;
display: flex;
justify-content: center;
align-items: center;

img {
  height: 8px;
}
`

export default RightArrIcon;