import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackIcon from './BackIcon';

const SubHead = ({ children, SubBgColor, bottomLine }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundColor = scrollPosition > 10 ? SubBgColor : 'transparent';

  return (
    /**
     * SubHead 서브헤더 공통
     * - 뒤로가기 기능
     * - 서브타이틀은 있으면 추가됨.
     */

    <SubHeadWrap style={{ backgroundColor }} data-bottomline={bottomLine}>
      <BackIcon />

      {children && <h2>{children}</h2>}
    </SubHeadWrap>
  );
};

const SubHeadWrap = styled.div`
  width: 100%;
  height: 60px;
  z-index:99;

  position: fixed;
  top:0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: ${(props) => (props['data-bottomline'] ? '1px solid #ddd' : 'none')};



  // 뒤로가기 아이콘
  div {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -30px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #222;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    img {
      height: 16px;
    }
  }
`;

export default SubHead;
