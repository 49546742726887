import React, { useState } from 'react';
import styled from 'styled-components';
import ModalPopup from '../../common/ModalPopup';
import {
  bottom_arr_select
, checkoff
, checkon2,
} from "../../../../assets/images"
import { useNavigate } from 'react-router';
import RightArrIcon from '../../common/RightArrIcon';

const OrderByList = () => {
  /**
   * 이용 내역 리스트 순서
   */
  const [useOrderBy, setUseOrderBy] = useState(false);
  const [selectedUseOrderBy, setselectedUseOrderBy] = useState('가용 많은 순'); 
  const handleUseOrderByChange = (event) => {
    setselectedUseOrderBy(event.target.value);
    setUseOrderBy(!useOrderBy)
  };

  const navigate = useNavigate();

  return (
    <>
     <OrderByListWrap>
      <div>
        <button onClick={() => setUseOrderBy(!useOrderBy)}>
          {
            selectedUseOrderBy ? selectedUseOrderBy : "가용 많은 순"
          }
          <span><img src={bottom_arr_select} alt='아래 화살표' /></span>
        </button>
        <p onClick={() => navigate("/app/icon_guide")}>
          아이콘 Guide <RightArrIcon />
        </p>
      </div>
    </OrderByListWrap>
    

    {
      useOrderBy &&
      <ModalPopup>
        <TypeModal className='ddd'>
          <ol>
            <li><label><input type='radio' value='가용 많은 순' onChange={handleUseOrderByChange} checked={selectedUseOrderBy === "가용 많은 순"} /><em></em>가용 많은 순</label></li>
            <li><label><input type='radio' value='거리순' onChange={handleUseOrderByChange} checked={selectedUseOrderBy === "거리순"} /><em></em>거리순</label></li>
          </ol>


          <button onClick={() => setUseOrderBy(!useOrderBy)}>취소</button>
        </TypeModal>
      </ModalPopup>
    } 
    </>
  );
};

const OrderByListWrap = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  background-color: #F6F6F6;

  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  button {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    color: #4D4D4D;
  }

  p {
    color:#31606A;
    font-weight: bold;
    cursor:pointer;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
  }
`

const TypeModal = styled.div`
  background: #fff;
  width: 220px;
  border-radius: 8px;
  text-align:center;
  padding: 0 0 16px 0;

  ol{
    padding: 16px 0 8px 0 ;

    li {
      display: flex;
      justify-content: start;
      align-items: center;

      height: 40px;
      font-size: 14px;
      padding: 0 16px 0 16px;
      // border-bottom: 1px solid #e0e0e0;

      label{
        display: flex;
        justify-content: start;
        align-items: center;
        gap:4px;
      }
    }
  }

  input[type='radio'] {
    display: none;
  }
  
  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + em {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }
  
  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + em {
    background-image: url(${checkon2});
  }

  button {
    width: 80px;
    height: 32px;
    background:#444;
    color:#fff;
    font-size: 14px;
    border-radius:4px;
  }
`

export default OrderByList;