import React, { useEffect, useRef, useState } from 'react';
import {
  logo_Login
  , qrcode
  , menubar,
  test_using
} from "../../../../assets/images"
import styled from 'styled-components';
import BonusList from '../../../components/TouchOll/main/BonusList';
import StoreList from '../../../components/TouchOll/main/StoreList';
import MainSlider from '../../../components/TouchOll/main/MainSlider';
import BottomGnb from '../../../components/TouchOll/main/BottomGnb';
import axios from 'axios';
import IconMenuList from '../../../components/TouchOll/main/IconMenuList';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserInfoResult } from "../../../../recoil/recoilState";
import Menu from './Menu';
import BonusPill from '../../../components/TouchOll/main/BonusPill';
import BonusCan from '../../../components/TouchOll/main/BonusCan';
import Emergency from '../../../components/TouchOll/popup/Emergency';
import PayTip from '../../../components/TouchOll/popup/PayTip';
import { useBodyScrollLock } from "../../../components/common/function/BodyModalScroll";
import NoticePopup from '../../../components/TouchOll/main/NoticePopup';
import { handleHeaderToken, handleLogout } from '../../../components/common/function/BearerToken';
import PayUse from '../../common/payuse/PayUse';
import useModalWithBackButton from '../../../components/common/function/HistoryModal';
import { refreshTokeyFetch } from '../../../components/common/function/RefreshToken';
import IosNfc from '../../../components/common/function/IosNfc';
import SystemModal from '../../../components/common/SystemModal';
import TestUsing from '../../../../assets/json/TestUsing.json'

const MainPage = ({iosNFC}) => {
  const [pointInfo  , setPointInfo] = useState(null);
  const [userInfo   , setUserInfo ] = useRecoilState(UserInfoResult);
  const [useDtls    , setUseDtls  ] = useState([]);

  const [isModal1Visible, toggleModal1] = useModalWithBackButton(); // 누적 보너스 팝업
  const [isModal2Visible, toggleModal2] = useModalWithBackButton(); // 가용 보너스 팝업

  const [isModal3Visible, toggleModal3] = useModalWithBackButton(); // 긴급알림 팝업
  const [isModal4Visible, toggleModal4] = useModalWithBackButton(); // 현금 사용 팁

  
  // const [emergencyIsOpen, setEmergencyIsOpen] = useState(false);// 긴급알림 팝업
  // const [tipIsOpen      , setTipIsOpen      ] = useState(false);// 현금 사용 팁
  const [alarmIsOpen    , setAlarmIsOpen    ] = useState(false);// 알립니다 팝업

  const [userPointTotal, setUserPointTotal] = useState([]) // 유저의 총 포인트

  const navigate = useNavigate();

  // Bearer Token 보내기
  useEffect(() => {
    handleHeaderToken();

    // refreshTokeyFetch();
  }, [])

  const locationLink = (jsonKey, jsonValue) => {
    if(jsonKey === "device_serial"){
      navigate("/app/useinfo_store", {state: {point: jsonValue}});
    }
    else if(/point/i.test(jsonKey)) {
      navigate("/common/pay_result_request", {state: {point:jsonValue}});
    }
  }

  //eslint-disable-next-line no-undef
  window.getTelegram = (msg) => {

    const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, '');

    locationLink(key, value);
  }


  useEffect(() => {
    const fetchPointInfo = async () => {
      try {
        const response = await axios.get('/api/pointMngr/getPointInfo');
        setPointInfo(response.data);
        console.log(response.data.data.message);
        setUserPointTotal(response.data.data.message)
        
      } catch (error) {
        console.error('Error fetching data:', error);
        console.error('Error status data:', error.response.status);

        // 412 토큰 유효값 
        if (error.response.status === 412 || error.response.status === 401) {
          setIsOpen(true)
        }
      }
    };

    fetchPointInfo();
  }, []);

  /**
   * 사용자 정보 조회
   */
  useEffect(()=>{
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/api/user/userInfo');
        setUserInfo({
          isAdmin     :response.data.data.message.isAdmin
        , agencyAt    :response.data.data.message.agencyAt
        , managerAt   :response.data.data.message.managerAt
        , userAt      :response.data.data.message.userAt
        , listStore   :response.data.data.message.listStore
        , name        :response.data.data.message.name
        , mobile      :response.data.data.message.mobile
        , birth       :response.data.data.message.birth
        , mngrLvl     :response.data.data.message.mngrLvl
        , userId      :response.data.data.message.userId
        , area        :response.data.data.message.area
        , gender      :response.data.data.message.gender
        , parea       :response.data.data.message.parea
        , userLvl     :response.data.data.message.userLvl
        , createdAt   :response.data.data.message.createdAt
        , email       :response.data.data.message.email
      })
        console.log("유저정보", response.data.data.message);
      } catch (error) {
        console.error('유저정보:', error);
      }
    };

    fetchUserInfo();
  }, [])

  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/api/myPageMngr/selectUseDtlsMain');
        setUseDtls(response.data.data.message.listUseDetails);
        console.log("이용내역 10개",response.data.data.message);
      } catch (error) {
        console.error('이용내역:', error);
      }
    };

    fetchUserInfo();
  }, []);

console.log(useDtls)

  const calculatePointByKind = (data, kind) => {
    let total = 0;
    let available = 0;

    if (data.userPoint) {
      data.userPoint.forEach(item => {
        if (item?.point) {
          item?.point.forEach(point => {
            if (point.kind === kind) {
              total += point.totalPoint;
              available += point.availablePoint;
            }
          });
        }
      });
    }
    return { total, available };
  };

  // P 종류의 총합
  const pointP = calculatePointByKind(userPointTotal, 'P');
  // S 종류의 총합
  const pointS = calculatePointByKind(userPointTotal, 'S');
  // G 종류의 총합
  const pointG = calculatePointByKind(userPointTotal, 'R');

  console.log(pointP, pointS, pointG);

  /**
   * 로그인 안 되어 있을 때 이름란의 로그인 클릭 => 
   * 로그인 페이지로 이동.
   */
  const handleNameLogin = () => {
    navigate('/')
  }

  /**
   * 메뉴 클릭시 body 스크롤 막기
   * 메뉴 Open
   */
  const [isMenu, setIsMenu] = useState(false);
  const { lockScroll }      = useBodyScrollLock();
  const handleMenu          = () => {
                              setIsMenu(!isMenu)
                              lockScroll()
                            }
                    
  const [isOpen, setIsOpen] = useState(false);
  const handleLoginUserToken = () => {
    handleLogout();
    navigate('/');
    setIsOpen(!isOpen);
  }


  return (
    <MainContentWrap>
      {iosNFC && <IosNfc />}

      {
        isOpen && 
        <SystemModal handleFunction={handleLoginUserToken}>
          <p>다시 로그인 하십시오</p>
        </SystemModal>
      }

      {/* {approval} */}

      {/* <PayUse />  */}
      {
        isModal3Visible && <Emergency toggleModal={toggleModal3} name={userInfo.name} tel={userInfo.mobile} />
      }
      {
        isModal4Visible &&  <PayTip toggleModal={toggleModal4} />
      }
      
      <HeaderTop>
        <h1><img src={logo_Login} alt="터치올" /></h1>
        <div>
          <button>
            <img src={qrcode} alt="qr코드" />
          </button>

          <button onClick={handleMenu}>
            <img src={menubar} alt="메뉴" />
          </button>
        </div>
      </HeaderTop>

      <Menu 
        className={isMenu ? 'open_menu' : ""} 
        isMenu={isMenu} 
        setIsMenu={setIsMenu} 
      />

      {/* 공지|알림  대리점|가맹점|마이페이지 아이콘 메뉴 */}
      <IconMenuList />

      <MyBonusUsing>
        <MyBonusTit className='mytit'>
          <div>
            <p>
              <span>
              {userInfo.userLvl || "0"}학년
              </span>
              {userInfo.name || <button onClick={handleNameLogin}>로그인</button>}
            </p>

            <h3>MY 보너스 사용 <strong>Pay</strong></h3>
          </div>

          <button onClick={()=>navigate('/app/useinfo_pay')}>더보기 &gt;</button>

          <em className='titbg'></em>
        </MyBonusTit>

        <MybonusWrap className="mywrap">
          <ul>
            <li onClick={toggleModal1}>누적<em>?</em></li>
            <li onClick={toggleModal2}>가용<em>?</em></li>
          </ul>

          {
            /* 누적 관련 팝업 */
            isModal1Visible && <BonusPill toggleModal={toggleModal1}/>
          }
          {
            /* 가용 관련 팝업 */
            isModal2Visible && <BonusCan toggleModal={toggleModal2} />
          }
       

          <BonusList
            type      ={'point'}
            totalPoint={userInfo.name ? pointP.total : 35000}
            usingPoint={userInfo.name ? pointP.available : 32000}
            pay       ="PAY"
          />

          <BonusList
            type      ={'stemp'}
            totalPoint={userInfo.name ? pointS.total : 3}
            usingPoint={userInfo.name ? pointS.available : 3}
            pay       ="사용"
            noName    ={!userInfo.name && true}
          />

          <BonusList
            type      ={'gift'}
            totalPoint={userInfo.name ? pointG.total : 5}
            usingPoint={userInfo.name ? pointG.available : 5}
            pay       ="신청"
          />

          <h3 onClick={toggleModal4}>내 통장 입금 ↔ <span>PAY</span> 방법 변경 <em>≫</em></h3>
        </MybonusWrap>
      </MyBonusUsing>

      <MainSlider />

      {/* 보너스 이용 내역 */}
      <StoreUsingList>
        <div className='tit_store_list'>
          <h3>My 이용내역, 후기<strong>·평가</strong></h3>
          <button onClick={()=>navigate('/app/useinfo')}>더보기 &gt;</button>
          <em className='titbg'></em>
        </div>

      
        {userInfo.name && useDtls &&
          useDtls.map((item, index) =>( 
            <StoreList
            key       ={index}
            type      ={item?.type}
            typeName  ={item?.typeName}
            earn      ={item?.earn}
            date      ={item?.payDate}
            cash      ={item?.amount}
            store     ={item?.storename}
            starCount ={item?.storeAvg}
            Icons     ={['hot']}
            pointUse  ={item?.pointUse}
            stampUse  ={item?.stampUse}
            rpsUse    ={item?.rpsUse}
            perCent   ={item?.pointRate}
            stamp     ={item?.stamp}
            rps       ={item?.rps}
            pointSum  ={item?.sumpoint} 
            kind      ={item?.kind} 
            approval  ={item?.approval} 
            userId    ={item?.userId} 
            />
          ))
        }

        {!userInfo.name && 
          TestUsing.map((item, index) =>( 
            <StoreList
            key       ={index}
            type      ={item?.type}
            typeName  ={item?.typeName}
            earn      ={item?.earn}
            date      ={item?.payDate}
            cash      ={item?.amount}
            store     ={item?.storename}
            starCount ={item?.storeAvg}
            Icons     ={['hot', 'recom', 'top', 'celebs', 'new']}
            pointUse  ={item?.pointUse}
            stampUse  ={item?.stampUse}
            rpsUse    ={item?.rpsUse}
            perCent   ={item?.pointRate}
            stamp     ={item?.stamp}
            rps       ={item?.rps}
            pointSum  ={item?.sumpoint} 
            kind      ={item?.kind} 
            approval  ={item?.approval} 
            userId    ={item?.userId} 
            ex        ={true} 
            />
          ))
        }

      </StoreUsingList>

      <BottomGnb />
    </MainContentWrap>
  );
};


const MainContentWrap = styled.div`
width: 100%;
// max-width: 600px;
margin: 0 auto;
`

const HeaderTop = styled.header`
// max-width: 600px;
width: calc(100% - 32px);
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;
  border-bottom: 1px solid #eee;

  h1 {
    height: 30px;

    img {
      height: 30px;
      width: auto;
    }
  }

  div {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;

    button {
      width: 40px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }

    }
  }
`


const MyBonusUsing = styled.div`
// max-width:600px;
`

const MyBonusTit = styled.div`
// max-width:600px;
position:relative;

.titbg {
  // max-width:600px;
  width: calc(100% - 76px);
  height: 9px;
  display: block;
  position: absolute;
  background-color: #DFEDF3;
  z-index: -9;
  right: 16px;
}
width: calc(100% - 32px);
padding: 0 16px;

display: flex;
justify-content: space-between;
align-items: center;

div{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  p {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;

    font-size: 13px;
    color: #222;

    span {
      font-size: 12px;
      color: #fff;
      background: #5D0EF5;
      width: 40px;
      height: 20px;
      border-radius: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    font-size: 16px;
    color: #3249C1;
    font-weight: 700;

    strong {
      font-weight: 700;
      color: #3249C1;
    }
  }
}

`

const MybonusWrap = styled.div`
  // max-width:600px;
  margin-top: 20px;

  ul {
    display: flex;
    align-items: center;
    justify-content: end;
    color: #717171;
    gap: 8px;
    padding-right: 16px;
    border-bottom: 1px solid #eee;
    padding-bottom: 4px;

    li {
      font-size: 12px;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 4px;

      em {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #717171;
        border-radius: 18px;
        color:#fff;
      }

      &:last-child {
        color: #042F8B;

        em {
          background-color: #042F8B;
        }
      }
    }
  }

  h3 {
    width: calc(100% - 32px);
    margin: 8px 16px 0 16px;
    height: 40px;
    background:#fff0db;

    font-weight: 700;
    color:#222;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    span {
      color:#FE7A36;
      margin:0 4px;
    }

    em {
      width: 25px;
      height: 25px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const StoreUsingList = styled.div`
  // max-width:600px;
  margin-top: 32px;
  position: relative;
  padding-bottom: 70px;

  .titbg {
    width: calc(100% - 32px);
    height: 9px;
    display: block;
    position: absolute;
    background-color: #DFEDF3;
    z-index: -9;
  }

  .tit_store_list {
    width: calc(100% - 32px);
    padding: 0 16px 8px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom:1px solid #ddd;

    h3 {
      color: #3249C1;
      font-size: 16px;
      font-weight: 700;

      strong{
        color: #3249C1;
      }
    }

    button {
      font-size: 12px;
      color:#313030;
      font-weight: 700;
    }
  }
`

export default MainPage;