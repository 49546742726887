import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  checkoff
  , checkon2
} from "../../../assets/images"
import AmountInput from './AmountInput';
import VipFloatInput from './VipFloatInput';
import { parseTime } from '../common/function/FormatNumber';

const BonusSpacial = ({storeInfo, vipBonus, setVipBonus}) => {
  /**
   * stime, etime 시간 관련
   */
  const [sHour, setSHour] = useState("")
  const [smin,  setSmin]   = useState("")
  const [eHour, setEHour] = useState("")
  const [emin,  setEmin]   = useState("")

  useEffect(() => {

    const parsedStime = parseTime(storeInfo.stime);
    const parsedEtime = parseTime(storeInfo.etime);

    if (parsedStime) {
      setSHour(parsedStime.hour.toString().padStart(2, '0'));
      setSmin(parsedStime.minute.toString().padStart(2, '0'));
    }

    if (parsedEtime) {
      setEHour(parsedEtime.hour.toString().padStart(2, '0'));
      setEmin(parsedEtime.minute.toString().padStart(2, '0'));
    }
  }, [storeInfo.stime, storeInfo.etime]);

  /**
   * stime, etime 문자열로 시간 나타내기
   * 수정하기를 누르면 stime보다 etime이 나중이 되면 오류가 떠야함. 
   */
  useEffect(() => {
    let stimeEdit = tenTime(sHour) + tenTime(smin);
    let etimeEdit = tenTime(eHour) + tenTime(emin);

    handleVipBonus('stime', stimeEdit, 'string');
    handleVipBonus('etime', etimeEdit, 'string');

  },[sHour, smin, eHour, emin])
  
  /**
   * 10미만의 숫자는 01, 00 두자리 수로 저장
   * @param {*} time : set 안의 숫자
   * @returns 
   */
  const tenTime = (time) => {
    const intTime = parseInt(time);
    let changeTime;

    if (intTime < 10) {
        changeTime = `0${intTime}`;
    } else {
        changeTime = time;
    }

    return changeTime;
  };
  
  /**
   * 지정시간 상태관리 코드
   * @param {*} e : 지정시간
   */
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    let numericValue = parseInt(value);

    if (name === "startHour" || name === "endHour") {
        numericValue = Math.min(Math.max(numericValue, 0), 23); // 최소값은 0, 최대값은 23로 제한
    } else if (name === "startMinute" || name === "endMinute") {
        numericValue = Math.min(Math.max(numericValue, 0), 59); // 최소값은 0, 최대값은 59로 제한
    }

    // 문자열 변경
    const paddedValue =  numericValue.toString();

    if (name === "startHour") {
        setSHour(paddedValue);
    } else if (name === "startMinute") {
        setSmin(paddedValue);
    } else if (name === "endHour") {
        setEHour(paddedValue);
    } else if (name === "endMinute") {
        setEmin(paddedValue);
    }
  };
  

  /**
   * 타입 변경 
   * @param {*} value 
   * @param {*} type 
   * @returns 변경된 타입
   */
  const TypeChange = (value, type) => {
    let processedValue;
    switch (type) {
      case 'float':
        processedValue = parseFloat(value);
        break;
      case 'string':
        processedValue = String(value);
        break;
      case 'int':
        processedValue = parseInt(value);
        break;
      default:
        processedValue = value;
    }
    return processedValue;
  }

  const handleVipBonus = (name, value, type) => {
    setVipBonus(prevState => ({
      ...prevState,
      [name]: TypeChange(value, type)
    }));
  };
  
  return (
    <SpecialWrap>
      <ul>
        <li>
          <span>단골우대</span>
          <p>
            2학년부터 한 학년 승급 시
            <VipFloatInput 
              setPointEdit={(value) => handleVipBonus('advancement', value, 'float')}
              inputValue={vipBonus.advancement || 0}
              inputName={'percent'}
            />
            % 추가
          </p>
        </li>

        <li>
          <span>결제금액</span>
          <p>
            <AmountInput
            setValue={(value) => handleVipBonus('amount', value, 'string')}
            value={vipBonus.amount || ''}
            />
            원 이상
            <VipFloatInput 
              setPointEdit={(value) => handleVipBonus('amountRatio', value, 'float')}
              inputValue={vipBonus.amountRatio || 0}
              inputName={'percent'}  
            />
            % 추가
          </p>
        </li>

        <li>
          <span>결제수단</span>
          <PayTypeWrap>
            <div className='pay_select'>
              <label>
                <input 
                type='radio' 
                name='paylist' 
                value={"0"}
                checked={vipBonus.method === "0"}
                onChange={(e) => handleVipBonus('method', e.target.value, 'string')}
                />
                <em></em>
                현금, 계좌이체
              </label>
              <label>
                <input type='radio' 
                name='paylist' 
                value={"1"}
                checked={vipBonus.method === "1"}
                onChange={(e) => handleVipBonus('method', e.target.value, 'string')}
                />
                <em></em>
                신용카드
              </label>
            </div>

            <div className='pay_select2'>
              <label>
                <input type='radio' 
                name='paylist' 
                value={"2"}
                checked={vipBonus.method === "2"}
                onChange={(e) => handleVipBonus('method', e.target.value, 'string')}
                />
                <em></em>
                포인트
              </label>

              <VipFloatInput
                setPointEdit={(value) => handleVipBonus('methodRatio', value, 'float')}
                inputValue={vipBonus.methodRatio || 0} 
                inputName={'percent'}  
              />
              % 추가
            </div>

          </PayTypeWrap>
        </li>

        <li>
          <span>지정시간</span>
          <BonusTime>
            <div>
              <input
                type="number"
                name="startHour"
                value={sHour || ""}
                onChange={handleTimeChange}
              />
              시
              <input
                type="number"
                name="startMinute"
                value={smin || ""}
                onChange={handleTimeChange}
              />
              분 ~ 
        
              <input
                type="number"
                name="endHour"
                value={eHour || ""}
                onChange={handleTimeChange}
              />
              시
              <input
                type="number"
                name="endMinute"
                value={emin || ""}
                onChange={handleTimeChange}
              />
              분
            </div>
            <div>
            
             <VipFloatInput 
              setPointEdit={(value) => handleVipBonus('time', value, 'float')}
              inputValue={vipBonus.time || ''}
              inputName={'percent'}  
            />
            % 추가
            </div>
          </BonusTime>
        </li>
      </ul>
    </SpecialWrap>
  );
};

const SpecialWrap = styled.div`
margin-top: 16px;
ul {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 16px;

  li {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    padding: 16px;
    background-color: #fff;
    font-size: 18px;
  

    span {
      margin-bottom: 12px;
      font-weight: bold;
    }
    
    input[name="percent"] {
      width: 40px;
      border: 1px solid #ddd;
      margin: 0 2px 0 4px;
      font-size: 18px;
    }

    input[name="pay"] {
      width: 80px;
      border: 1px solid #ddd;
      margin: 0 2px 0 4px;
      font-size: 16px;
      padding: 0 4px;
    }

    p {
      display: flex;
      justify-content: end;
      align-items: center;
    }
    
  }
}

`

const PayTypeWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
gap:12px;

.pay_select {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.pay_select2 {
  display: flex;
  justify-content: start;
  align-items: center;
}

label {
  display: flex;
  justify-content: start;
  align-items: center;
  gap:4px;

  input[type='radio'] {
    display: none;
  }
  
  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + em {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }
  
  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + em {
    background-image: url(${checkon2});
  }
}

`

const BonusTime = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
gap: 8px;

input {
  width: 40px;
  border: 1px solid #ddd;
  margin: 0 2px 0 4px;
  padding: 0 2px;
  text-align:right;
  font-size: 18px;
}

`

export default BonusSpacial;