import styled from "styled-components";

function ReceiptCancle({ data }) {

  var payType = data?.recipt?.stlmMethodCode;
  const reciptData = data?.recipt || data?.receipt; 

  //console.log("reciptData data:", data);
  
  return (
    <>
    <BorderContainer>
      <div className="recipe_wrap">
        <h2>전자 영수증</h2>
        <p>{reciptData?.stlmDt}</p>
        <h3>{data?.store?.storeName}</h3>
        <h1>
          {reciptData?.stlmMethodCode === "9" && "거스름돈 "}
          {Number(reciptData?.stlmPrice).toLocaleString()}원
        </h1>
        <ul>
          {
          reciptData?.stlmMethodCode !== "9" && 
          <>
          <li><span>결제수단</span>{payType === '0' ? '현금' : payType === '1' ? '신용,체크카드' : payType === '2' ? '터치올 포인트' : '거스름돈'}</li>
          <li><span>공급가액</span>{Number(reciptData?.supplyPrice).toLocaleString()}</li>
          <li><span>부가세</span>{Number(reciptData?.vatPrice).toLocaleString()}</li>
          </>
          }
          <li><span>승인번호</span>{reciptData?.aprvNo}</li>
          <li><span>사업자번호</span>{data?.store?.brno}</li>
        </ul>
      </div>
    </BorderContainer>        
    </>  
  );
}


const BorderContainer = styled.div`
  margin-top: 20px;
  width: calc(100% - 32px);
  padding: 0 16px;
 
  .recipe_wrap{
    width: calc(100% - 32px);
    padding: 30px 16px;border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 10px; 
  }

  h2{
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
    text-align:center;
  }

  p{
    margin-top: 28px;
    color: #8E8E8E;
    font-weight: 500;
  }

  h3 {
    margin-top: 12px;
    color: #222222;
    font-weight: 0;
  }

  h1 {
    color: #222222;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    margin-top: 18px;
    padding-bottom: 8px;
  }

  ul {
    margin-top: 20px;

    li{
      display: flex;
      justify-content: space-between;
      align-content: center;

      color: #8E8E8E;
      font-weight: 500;
      font-size: 16px;

      line-height: 24px;

      span {
      color: #8E8E8E;  
      font-weight: 500;
      font-size: 16px;
    }
    } 
  }
`;

export default ReceiptCancle;
