import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import { ediya1, ediya2, ediya3, ediya4, ediya5, ediya6, gifticon, heart, iconbtn1, iconbtn2, iconbtn3, logo, logo_toucholl, pointicon, sd, stampicon, telicon } from '../../../../assets/images';
import { formatCurrency } from '../../../components/common/function/FormatNumber';
import EarnIcon from '../../../components/common/EarnIcon';
import MenuIcon from '../../../components/TouchOll/store/MenuIcon';
import { useNavigate } from 'react-router';

const StoreDetail = ({}) => {
  /**
   * 가맹정 상세보기
   */
  const navigate = useNavigate();

  const starCount = 4.5;
  const pointUse = 'Y';
  const stampUse = 'Y';
  const rpsUse = 'N';

  const perCent = 3.5;
  const stamp = 3000;
  const rps = 3000;

  const storeTxt = "로스팅 공장 직영 운영입니다.원두의 신선도를 최상의 하고 있습니다. 과일청과 바닐라 시럽도 수제로 만들어 제공해 드리고 있고, 시그니처 원두의 신선도를 최상의 하고 있습니다. 로스팅 공장 직영 운영입니다.원두의 신선도를 최상의 하고 있습니다. 과일청과 바닐라 시럽도 수제로 만들어 제공해 드리고 있고, 시그니처 원두의 신선도를 최상의 하고 있습니다."
  
  return (
    <div id="sub_common_wrap">
      <SubHead SubBgColor={'#f5f5f5'} bottomLine={true}>
        이디야 가산포휴점
        <StarSu className={`${!starCount ? 'no_star_count' : ''}`}>
          <svg width="14" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.73438 12.8789L4.2793 8.13477L0.259766 5.22266H5.22266L6.75391 0.492188L8.29883 5.22266H13.2754L9.24219 8.13477L10.8008 12.8789L6.75391 9.95312L2.73438 12.8789Z" fill="#FE7A36"/>
          </svg>
          {starCount ? starCount.toFixed(1) : 0}
        </StarSu>
      </SubHead>

      <section>
        <BonusEarn>
          <h3><img src={logo} alt='로고' /> 보너스 우대</h3>
          <div className={`percent`}>
            <EarnIcon earn={'T'} />
            {pointUse === 'Y' && <p className='pp'><img src={pointicon} alt='포인트' />{perCent.toFixed(1)}%</p>}
            {stampUse === 'Y' && <p className='ss'><img src={stampicon} alt='스탬프' />{formatCurrency(stamp)}당 1S</p>}
            {rpsUse   === 'Y' && <p className='gg'><img src={gifticon}  alt='경품'  />{formatCurrency(rps)}당 1G</p>}
          </div>

          <ul>
            <li>
              <span>우수회원</span>
              <div>
                1학년 → 한 학년씩 승급당 <em>5%</em> 추가 <br/>
                <p>(예) 4학년 → 기본 적립 <em>+15%</em></p>
              </div>
            </li>
            <li>
              <span>이용금액</span>
              <div>
                50,000원 이상시 기본적립 <em>+5%</em>
              </div>
            </li>
            <li>
              <span>결제수단</span>
              <div>
                현금 or Pay시 기본적립 <em>+5%</em> <br/>
                제휴 신용카드시 <em>+5%</em>
              </div>
            </li>
            <li>
              <span>우대시간</span>
              <div>
              12:00 ~ 13:30 이용시 <em>+10%</em> 
              </div>
            </li>
          </ul>
        </BonusEarn>

        <ImgList>
          <Tith2><em></em>실내・외 전경사진</Tith2>
          <ul>
            <li><img src={ediya1} alt='ediya1' /></li>
            <li><img src={ediya2} alt='ediya2' /></li>
            <li><img src={ediya3} alt='ediya3' /></li>
            <li><img src={ediya3} alt='ediya3' /></li>
          </ul>
        </ImgList>

        <ImgList>
          <Tith2><em></em>매장 로고 및 관련사진 </Tith2>
          <ul>
            <li><img src={ediya4} alt='ediya4' /></li>
            <li><img src={ediya5} alt='ediya5' /></li>
            <li><img src={ediya6} alt='ediya6' /></li>
            <li><img src={ediya6} alt='ediya6' /></li>
          </ul>
        </ImgList>

        <StoreAbout>
          <div className='Icon_btn'>
            <button>
              <img src={iconbtn1} alt='iconbtn1' />
              <p>지도</p>
            </button>
            
            <button>
              <img src={iconbtn2} alt='iconbtn2' />
              <p>길찾기</p>
            </button>

            <button>
              <img src={iconbtn3} alt='iconbtn3' />
              <p>공유</p>
            </button>
          </div>

          <Tith2><em></em>매장 안내 </Tith2>

          <div className='about'>
            <h5><img src={telicon} /> 052-1234-1234</h5>
            <ol>
              <li><span>홈페이지 </span>www.</li>
              <li><span>주소    </span>금천구 가산 디지털로1로 25</li>
              <li>가산디지털역 3번 출구에서 300m</li>
              <li><span>영업시간 </span>매일 09:00 ~ 21:00 (주말, 공휴일 제외)</li>
              <li><span>주차    </span>가능 (00대)</li>
              <li><span>예약    </span>불가</li>
            </ol>

            <AboutBox>
              <h3><span>소개</span> <button>상세보기 →</button></h3>
              <div className='store_txt'>
                <p>
                {storeTxt}
                </p>
              </div>
            </AboutBox>

            <AboutBox>
              <h3><span>알림, 홍보</span> <button>더보기 →</button></h3>
              <div className='img_notice'>
                <img src={ediya6} />
              </div>
            </AboutBox>

            <AboutBox>
              <h3><span>상품, 서비스, 메뉴</span> <button>상세보기 →</button></h3>
              <ul className='menulist'>
                <li><h2>아메리카노           &nbsp; <MenuIcon type={"favor"} /><MenuIcon type={"recom"} /></h2>5,000원</li>
                <li><h2>돌체라떼            &nbsp; <MenuIcon type={"best"} /><MenuIcon type={"sale"} /></h2>5,000원</li>
                <li><h2>그린티 프라프치노     &nbsp; <MenuIcon type={"new"} /></h2>5,000원</li>
                <li><h2>화이트 초콜릿 프라프치노&nbsp;</h2>5,000원</li>
                <li><h2>얼그레이            &nbsp;</h2>5,000원</li>
              </ul>
            </AboutBox>
          </div>
        </StoreAbout>

        <Review>
          <Tith2><em></em>리뷰 평가 </Tith2>
          <div>
            <h3><img src={heart} alt='하트' /><em> 250명</em> 중 203명 참여</h3>
            <StarSvg className={`${!starCount ? 'no_star_count' : ''}`}>
              <svg width="14" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.73438 12.8789L4.2793 8.13477L0.259766 5.22266H5.22266L6.75391 0.492188L8.29883 5.22266H13.2754L9.24219 8.13477L10.8008 12.8789L6.75391 9.95312L2.73438 12.8789Z" fill="#FE7A36"/>
              </svg>
              {starCount ? starCount.toFixed(1) : 0}
            </StarSvg>
          </div>
          
          <ol>
            <li>
              <p>"음식이 맛있어요."<em>83</em></p>
              <div style={{width: "30%"}}></div>
            </li>
            <li>
              <p>"친절해요."<em>50</em></p>
              <div style={{width: "20%"}}></div>
            </li>
            <li>
              <p>“매장이 깨끗해요”<em>30</em></p>
              <div style={{width: "15%"}}></div>
            </li>
            <li>
              <p>“인테리어가 멋져요”<em>25</em></p>
              <div style={{width: "10%"}}></div>
            </li>
            <li>
              <p>“메뉴가 다양해요”<em>15</em></p>
              <div style={{width: "5%"}}></div>
            </li>
          </ol>
        </Review>

        <Btns>
          <button>My 매장 소개 변경</button>
          <button onClick={() => navigate(-1)}>마침</button>
        </Btns>
        
      </section>
    </div>
  );
};

const StarSu = styled.p`
position: absolute;
right: 16px;
top: 50%;
transform: translate(0, -50%);

display:flex;
justify-content: flex-end;
align-items: center;
gap: 2px;

font-size: 14px;
color:#FE7A36;

&.no_star_count {
  svg {
    path {
      fill: #aaa;
    }
  }

  color:#aaa;
}
`

const BonusEarn = styled.div`
width: calc(100% - 64px);
padding: 16px;
margin-left: 16px;
background:#EEEEEE;
border-radius: 8px;
margin-top: 16px;

h3 {
  img {
    height: 20px;
  }
  font-size: 16px;
  font-weight: bold;

  display:flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.percent {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  margin-top: 8px;

  &.type1{
    em {
      background: #5D0EF5;
    }
  }

  &.type2{
    em {
      background: #8E3D82;
    }
  }

  &.type3{
    em {
      background: #7F7F7F;
    }
  }


  em {
    color: #fff;
    padding: 1px 2px;
    
    border-radius: 2px;
    font-size: 14px;
  }

  p {
    font-size: 14px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;

    img {
      width: 16px;
    }

    &.pp{ color: #7B8531; }
    &.ss{ color: #EC635D; }
    &.gg{ color: #2860A7; }
  }
}

ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  margin-top: 12px;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    font-size: 14px;
    line-height: 20px;

    em {
      color:#FF5F01;
    }

    p {
      font-size: 12px;
      color:#888;
    }
  }
}
`

const ImgList = styled.div`
padding: 0 0 0 16px;
margin-top: 20px;

ul {
  overflow-x:auto; 
  white-space:nowrap;

  &::-webkit-scrollbar {
    display: none !important; 
  }

  li {
    width: 120px;
    height: 80px;
    overflow:hidden;

    display:inline-block;
    margin-right: 8px;
    // justify-content: center;
    // align-items: center;

    border-radius: 8px;

    img {
      width: 100%;
      height: 80px;
      object-fit: cover;
    }
  }
}
`
const Tith2 = styled.h2`
display:flex;
justify-content: flex-start;
align-items: center;
gap: 4px;

font-size: 16px;
font-weight: bold;
margin-bottom: 8px;

em {
  width :4px;
  height: 4px;
  display: inline-block;
  background: #FF5F01;
}
`
const StoreAbout = styled.div`
position: relative;

padding: 32px 0 0 0;

&>h2 {
  margin-left: 16px;
}

.Icon_btn {
  position: absolute;
  right: 16px;  
  top: 12px;

  display:flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  img {
    width: 25px;
  }

  p {
    font-size: 10px;
    text-align: center;
  }
}

.about {
  width: calc(100% - 32px);
  padding: 20px 16px;
  background:#EEEEEE;

  h5 {
    margin-bottom: 12px;
    font-size: 14px;

    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  ol {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;

    li{
      display:flex;
      justify-content: flex-start;
      align-items: center;

      font-size: 14px;

      span { 
        width: 60px;
        font-size: 14px;
        color:#042F8B;

        display: inline-block;
      }

      &:before {
        content: "・";
        margin-right: 4px;
      }
    }
  }
}
`

const AboutBox = styled.div`
margin-top: 12px;

  h3 {
    font-size: 14px;
    color:#042F8B;

    display:flex;
    justify-content: space-between;
    align-items: center;

    span { 
      width: 120px;
      font-size: 14px;
      color:#042F8B;

      display: inline-block;

      &:before {
        content: "・";
        margin-right: 4px;
      }
    }

    button {
      color:#FF5F01;
    }
  }

  &>div {
    
    background:#fff;
    border-radius: 8px;
    margin-top: 8px;

    font-size: 14px;
    line-height: 20px;

  }

  .store_txt {
    width: calc(100% - 32px);
    height: 60px;
    padding: 16px;

    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;  //해당 영역을 box 형태로 관리되도록 한다.
      -webkit-line-clamp: 3; // 영역 내의 콘텐츠의 최대 라인수를 결정한다.
      -webkit-box-orient: vertical; // 영역 박스의 내의 정렬을 수직으로 하도록 한다.
    }
    
  }

  .img_notice {
    height: 140px;
    overflow: hidden;

    img {
      width: auto;
      height: 140px;
    }
  }

  .menulist {
    margin-top: 8px;

    padding: 12px 10px;
    background:#F5F5F5;
    border-radius: 8px;

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    li {
      width: 100%;

      font-size: 14px; 

      display:flex;
      justify-content: space-between;
      align-items: center;

      h2{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
      }
    }
  }
`

const Review = styled.div`
width: calc(100% - 32px);
padding: 0 16px;
margin-top: 20px;

&>div{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  h3 {
    color:#888;

    em {
      color:#FF5F01;
      font-weight: bold;
    }
  }
}

ol {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin-top: 8px;

  li {
    width: 100%;
    height: 30px;

    position: relative;

    background:#EEEEEE;
    border-radius: 4px;

    overflow: hidden;

    p {
      position:absolute;
      left: 16px;
      top: 0;

      width: calc(100% - 32px);
      height: 30px; 

      display:flex;
      justify-content: space-between;
      align-items: center;

      font-size: 14px;

      z-index: 3;

      em {
        color:#2860A7;
      }
    }

    div {
      position:absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 30px; 
      background:#C2D6FE;

      border-radius: 4px;
    }
  }
}
`

const StarSvg = styled.p`
display:flex;
justify-content: flex-end;
align-items: center;
gap: 2px;

font-size: 14px;
color:#FE7A36;

&.no_star_count {
  svg {
    path {
      fill: #aaa;
    }
  }

  color:#aaa;
}
`

const Btns = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 12px;

padding: 32px 0;

button {
  padding: 0 20px;
  height: 35px;

  background: #FF5F01;
  color:#fff;

  border-radius: 4px;

  font-size: 16px;

  &:last-child {
    background:#444;
  }
}
`
export default StoreDetail;