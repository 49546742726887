import React, { useState } from 'react';
import RpsUseDetail from './RpsUseDetail';
import styled from 'styled-components';
import useModalWithBackButton from '../function/HistoryModal';
import ModalPopup from '../ModalPopup';
import EarnIcon from '../EarnIcon';
import PSGmodelPopup from '../PSGmodelPopup';
import { ggold, sgold, slash } from '../../../../assets/images';
import PSGicon from '../PSGicon';
import { useNavigate } from 'react-router';

const RpsResultList = ({data, useType, receiptInfo, isApp}) => {
  const [isModal1Visible, toggleModal1] = useModalWithBackButton(); 

  const pointInfo  = data?.point;
  const storeInfo  = data?.store;
  const rpsInfo    = data?.rps;
  const type       = useType;

  const rpsSumScroe         = Number(pointInfo?.rpsScore + pointInfo?.bonusScore).toLocaleString();
  const cancelRpsSumScroe   = Number(pointInfo?.cancelRpsScore + pointInfo?.cancelRpsAddPoint).toLocaleString();
  const rpsScore            = Number(pointInfo?.rpsScore).toLocaleString();
  const cancelRpsScore      = Number(pointInfo?.cancelRpsScore).toLocaleString();
  const bonusScore          = Number(pointInfo?.bonusScore).toLocaleString();
  const cancelRpsAddPoint   = Number(pointInfo?.cancelRpsAddPoint).toLocaleString();
  const gradeBonusScore     = Number(pointInfo?.gradeBonusScore).toLocaleString();
  const cancelRpsGradePoint = Number(pointInfo?.cancelRpsGradePoint).toLocaleString();
  const sumBonusScore       = Number(pointInfo?.sumBonusScore).toLocaleString();        
  const cancelSumBonusScore = Number(pointInfo?.cancelSumBonusScore).toLocaleString();  

  const earnType   = storeInfo?.storeEarn || 'T'; // earn 유형
  const storeName  = storeInfo?.storeName || "가맹점 이름"
  const navigate = useNavigate();
  return (
    <>
    <RpsWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>

      <h3>
        터치올<em>-</em>
        <span>가위바위보 경품게임</span>
        <PSGmodelPopup type={'g'} />
      </h3>

      <h2 className={`type${type}`}>
        <p>
          {type === "1" || type === "2" ? "적립 완료" : "적립 취소"}
        </p>

      </h2>

      <PaySlash>
        <p className={`type${type}`}>
          <strong>
          {type === "1" || type === "2" ? rpsSumScroe : cancelRpsSumScroe}
          </strong>점
        </p>

        <ul className={`type${type}`}>
          <li>
            <span>경품승점 <PSGicon type={'g'} /></span>
            <p>
              {type === "1" || type === "2" ? rpsScore : cancelRpsScore}
              점
            </p>
          </li>
          <li>
            <span>추가적립 <PSGicon type={'g'} /></span>
            <p>
              {type === "1" || type === "2" ? bonusScore : cancelRpsAddPoint}
              점
            </p>
          </li>

        </ul>
      </PaySlash>

      <div className='grade_point'>
        <span>장학금 <img src={ggold} alt='g' /></span>
        <p>{type === "1" || type === "2" ? gradeBonusScore : cancelRpsGradePoint}점</p>
      </div>

      <h2 className={`type${type} sum_point`}>
        <p>합계</p>
        <p>
          <em>
            <strong>
            {type === "1" || type === "2" ? sumBonusScore : cancelSumBonusScore}
            </strong>
            점
          </em>
        </p>
      </h2>
    </RpsWrap>


     <DetailGameWrap>

      <h3>
        <em>{pointInfo?.userName}님은</em> 현재<br/>
        터치올 대학 <em>{pointInfo?.grade}학년</em>으로<br/><br/>
        장학금 {type === "1" || type === "2" ? gradeBonusScore : cancelRpsGradePoint}점 더 추가됨
      </h3>


      <ol>
      <li><button onClick={toggleModal1}>세부내역 확인</button></li>

        {
          isApp === "W" ? <></>
          : <li><button onClick={() => navigate('/app/useinfo', {replace: true})}>MY 이용내역 · 후기 평가</button></li>
        }
      </ol>
    </DetailGameWrap>

    {
      isModal1Visible && 
      <ModalPopup>
        <RpsUseDetail
          toggleModal       ={toggleModal1}
          earnType          ={storeInfo?.storeEarn}
          storeName         ={storeInfo?.storeName}
          rpsScore          ={rpsScore}
          userName          ={pointInfo?.userName}
          grade             ={pointInfo?.grade}
          pointInfo         ={pointInfo}
          type              ={type}
          receiptInfo       ={receiptInfo}
          rpsInfo           ={rpsInfo}
        />
      </ModalPopup>
    } 
    </>
  );
};


const RpsWrap = styled.div`
  width: 100%;
  background:#fff;
  border-radius: 12px;

  .type1, .type2{
    color:#2860A7 !important;
    p, strong, em{
    color:#2860A7 !important;
    }
  }

  .type3, .type4{
    color:#FB0E0E !important;
    p, strong, em{
    color:#FB0E0E !important;
    }
  }

   // 가게 상호
  h1{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  // 터치올-
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FF5F01;

    margin-top: 24px;

    display: flex;
    justify-content: start;
    align-items: center;

    em {
      color: #909090;
    }

    span {
      color: #2860A7;

      display: flex;
      justify-content: start;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
  }

 h2{
  margin-top: 18px;

  p{
    font-size: 14px;
    font-weight: bold;
  }

  strong {
    font-size: 30px;
  }
 }

.sum_point {
  width: calc(100% - 32px);
  padding: 0 16px 8px 16px;
  // margin-bottom: 32px;

  margin-top: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #707070;

  p {
    color:#707070 !important;
    font-size: 16px;

  }

}

 .grade_point {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: calc(100% - 32px);
  padding: 0 16px;
  height: 36px;
  background:#EAC234;
  border-radius: 8px;
  margin-top: 16px;

  span, p {
    color:#fff;
    font-weight:bold;
  }
  span{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:4px;
  }

}
`

const PaySlash = styled.div`

  background:url(${slash}) no-repeat;
  font-size: 14px;
  font-weight: bold;
  background-size: 100% 100%;
  padding: 0 8px 11px 8px;
  margin-top: 8px;

  strong {
    font-size: 30px;
  }

  &>p {
    height: 50px;
    line-height: 50px;
    text-align: right;
    // padding-right: 16px;
    margin-bottom: 8px;
  }

  // 현금, 장학금
  ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:stretch;
    gap: 4px;
  

    li{
      border-radius: 8px;
      border: 1px solid #707070;

      font-size: 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      line-height: 30px;

      padding:  0px 8px;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }

      span {
        display: flex;
        justify-content: start;
        align-items: center;
        color: #2860A7;
        font-weight: bold;
      }

      p{
        font-weight: bold;
      }
    }
    
  }

`

const DetailGameWrap = styled.div`
width: 100%;

ul {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;

  li {
    width: calc(100% - 32px);
    height: 35px;
    padding: 0 16px;
    border: 1px solid #7B8531;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #7B8531;
      font-weight: bold;
      font-size: 16px;

    }

    p {
      color: #7B8531;
      font-weight: bold;
      font-size: 16px;

    }
  }
}

h3 {
  font-size: 16px !important;
  font-weight: bold;
  // padding: 24px 0;
  text-align: center;
  line-height: 22px;
  margin-bottom: 32px;

  em {
    color: #8B53F5;
  }
}

// button {
//   font-size: 16px;
//   font-weight: bold;
//   color: #fff;
//   background-color: #A982F5;
//   height: 40px;
//   width: 100%;
//   border-radius: 4px;
// }

ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    li {
      width: 100%;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: #9A65FF;
      border-radius: 4px;

      &:last-child{
        background: #2860A7;
      }

      button {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        width: 100%;
        height: 100%;
      }
    }
  }
`

const CenteredImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 290px;
    height: 150px;
    margin-top: 10px;
  }
`



export default RpsResultList;