import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { checkoff, checkon } from '../../../assets/images';
import NewServiceCheck from './NewServiceCheck';

const NewServiceSelect = ({
    earn
  , pointUse
  , stampUse
  , rpsUse
  , point
  , stamp
  , rps
  , grpId
  , setBonusEdit
  , bonusEdit
}) => {

  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(earn)
  }, [earn])

  const handleCheck = (event) => {
    setSelected(event.target.value);

    setBonusEdit(prev => {
      // 함수 내부에서 이전 상태값 참조
      return {
        ...prev,
        earn: event.target.value  
        , pointUse: 'N'
        , stampUse: 'N'
        , rpsUse  : 'N'
        , point   : 0
        , rps     : 0
        , stamp   : 0
      }
    });

  }


  const [storeCode, setStoreCode] = useState(''); //101025
  const [storeName, setStoreName] = useState('');
  
  const handleInputChange = (event) => {
    const value = event.target.value;
    setStoreCode(value);
    setStoreName(getStoreName(value));
  };

  const getStoreName = (code) => {
    switch (code) {
      case '101025':
        return '배스킨라빈스';
      default:
        return '';
    }
  };

  return (
    <BonusWrap>
      <BonusTit >
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            value   ='T'
            checked ={selected === 'T'}
            onChange={handleCheck}
          />
          <span></span>
          통합
        </label>
        <p>
          효율 36.9%
          <span >
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>
      </BonusTit>

      {
        selected === 'T' &&
        <NewServiceCheck 
        earnType='T'
        selected={selected}
        bonusEdit={bonusEdit}
        setBonusEdit={setBonusEdit}
        />
      }

      <BonusTit>
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            value   ={'G'}
            checked ={selected === 'G'}
            onChange={handleCheck}
          />
          <span></span>
          그룹
        </label>
        <p>
          효율 4.3%
          <span >
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>
      </BonusTit>

      {
        selected === 'G' &&
        <NewServiceCheck 
        earnType='G'
        selected={selected}
        bonusEdit={bonusEdit}
        setBonusEdit={setBonusEdit}
        >
          <GroupCode>
          <h3>그룹코드</h3>
          <label>
            <input
              type    ='number'
              name    ='storecode'
              value   ={storeCode}
              onChange={handleInputChange} 
            />
            <p>{storeName}</p>
          </label>
        </GroupCode>

        </NewServiceCheck>
      }

      <BonusTit>
        <label>
          <input
            type    ='radio'
            name    ='bonus_type'
            value   ={'S'}
            checked ={selected === 'S'}
            onChange={handleCheck}
          />
          <span></span>
          단독
        </label>
        <p>
          효율 2.2%
          <span >
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5 5L1 0.999999" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </p>
      </BonusTit>

      {
        selected === 'S' &&
        <NewServiceCheck 
        earnType='S'
        selected={selected}
        bonusEdit={bonusEdit}
        setBonusEdit={setBonusEdit}
        />
      }

    </BonusWrap>
  );
};

const BonusWrap = styled.div`
margin-top: 12px;
`

const BonusTit = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

margin-bottom: 8px;
line-height: 30px;

&:has(input[type='radio']:checked) {
  p, label{
    color:#FE7A36;
  }

  svg {
    path {
      stroke:#FE7A36;
    }
  }
}

label{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;

  font-size: 20px;
}

p {
  font-size: 20px;
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all .3s linear;
    transform: rotate(0deg);

    svg {
      path {
        stroke:#222;
      }
    }

    &.open_svg {
      transform: rotate(-180deg);
    }
  }
}

input[type='radio'] {
  display: none;
}

/* 체크박스 커스텀 이미지 적용 */
input[type='radio'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${checkoff});
  background-size: cover;
}

/* 체크된 상태의 이미지 적용 */
input[type='radio']:checked + span {
  background-image: url(${checkon});
}

p, label{
  color:#222;
}


`

const GroupCode = styled.label`
width:100%;
justify-content:space-between !important;
// gap:8px;

input {
  border:1px solid #ddd;
  margin: 0 2px 0 4px;
  font-size: 16px;
  height: 16px;
  color:#2860A7;
}

p {
  color:#2860A7;
}
`
export default NewServiceSelect;