import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import SubHead from '../../../components/TouchOll/SubHead';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import SystemModal from '../../../components/common/SystemModal';


const PwNewReissue = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [popupTxt, setPopupTxt]  = useState('');    // 시스템 팝업 텍스트
  const [system, setSystem]      = useState(false); // 시스템 팝업

  /**
   * 비밀번호 재발급 페이지의 유저 정보 가져오기
   */
  const { mobile, name, birthday, confirmNum } = location.state.userInfoPW;
  const userBirthday  = birthday.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3'); //생년월일 
  const userMobile    =  mobile.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3'); //핸드폰번호
  
  
  const [passwdIput, setPasswdIput] = useState('');   // 비밀번호
  const [passwd, setPasswd]         = useState('');   // 비밀번호 확인
  const [feedNum, setFeedNum]       = useState('영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상')   // 비밀번호 feed 텍스트

  /** 
   * 새 비밀번호 완료 버튼
   * 조건: 아래 모두 true 표시 되어야 활성화
   */ 
  const [PassWordState, setPassWordState] = useState({
      pwPattern   : false  // 비밀번호 패턴 확인
    , pwCheck     : false  // 비밀번호 동일 확인
  })

  /**
   * 비밀번호 유효성 검사 체크
   * 조건: 영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상 (한글 불가)
   */
  const passwordPattern     = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handlePWInputChange = (e) => {
    const inputValue        = e.target.value;
    setPasswdIput(inputValue);

    if (!inputValue) {
      setFeedNum("영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상");
    } 
    else if (!/^[A-Za-z\d@$!%*?&]*$/.test(inputValue) || !passwordPattern.test(inputValue)) {
      setFeedNum("비밀번호가 유효하지 않습니다.");
      setPassWordState(prevState => ({ ...prevState, pwPattern: false }));
      document.querySelector('#feedtxt1').classList.remove('password-match');
    } 
    else {
      setFeedNum("비밀번호가 유효합니다.");
      setPassWordState(prevState => ({ ...prevState, pwPattern: true }));
      document.querySelector('#feedtxt1').classList.add('password-match'); 
    }
  };

  /**
   * 비밀번호 확인 체크
   * 조건: 비밀번호 입력 input 창과 일치해야 함
   */
  const handlePwConfirmation = (e) => {
    const confirmPassword = e.target.value;
    setPasswd(confirmPassword)

    const password = document.getElementById("passwordInput").value;

    if (confirmPassword === password) {
      setFeedNum("비밀번호가 일치합니다.");
      setPassWordState(prevState => ({ ...prevState, pwCheck: true }));
      document.querySelector('#feedtxt1').classList.add('password-match'); 
    } 
    else {
      setFeedNum("비밀번호가 일치하지 않습니다.");
      setPassWordState(prevState => ({ ...prevState, pwCheck: false }));
      document.querySelector('#feedtxt1').classList.remove('password-match'); 
    }
  };

  /**
   * 회원가입 완료버튼
   */
  const handleNewPassword = async (e) => {
    e.preventDefault();
    try {
      
      const response = await axios.put('/api/memberMngr/resetPasswd',  {
          passwd       : passwdIput
        , repeatPasswd : passwd
        , cert         : confirmNum
        , mobile       : mobile
      });

      console.log(response.data);
      if (response.data.data.result === true) {
        setPopupTxt("비밀번호가 변경되었습니다.")
        setSystem(true)
      } 
      else {
        setPopupTxt(response.data.data.message)
        setSystem(true)
      }
    } catch (error) {
      console.log('비밀번호 변경 실패: ' + error.response.data.data.message);
    }
  };

  return (
    <div id="sub_common_wrap_f">
      {
        /**
         * 시스템 모달 팝업 창
         */
        system && (
          <SystemModal system={system} setSystem={setSystem} linkHref={'/app/main'}>
            {popupTxt}
          </SystemModal>
        )
      }

      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        비밀번호 재발급 & 정규가입
      </SubHead>

      <section>
        <UserInfo>
          <p>
            비밀번호 재발급자의 정보가 인증 되었습니다.
            <br />정보 확인 후 새 비밀번호를 설정하세요.
          </p>

          <ul>
            <li>
              <span>성명</span>
              <h3>{name}</h3>
            </li>

            <li>
              <span>생년월일</span>
              <h3>{userBirthday}</h3>
            </li>

            <li>
              <span>전화번호</span>
              <h3>{userMobile}</h3>
            </li>
          </ul>

        </UserInfo>
        
        {/* 비밀번호 input */}
      <PassWordInputs>
        <div>
          <p>새 비밀번호 발급</p>
          <input  
            id          ="passwordInput"  
            type        ="password" 
            value       ={passwdIput}
            placeholder ="새 비밀번호 입력" 
            onChange    ={handlePWInputChange} />

          <input 
            type        ="password" 
            value       ={passwd}
            placeholder ="새 비밀번호 확인"
            onChange    ={handlePwConfirmation} />
        </div>

        {/* 비밀번호 관련 feed */}
        <Feedtxt id="feedtxt1">
          {feedNum}
        </Feedtxt>
      </PassWordInputs>

      <BtnPassWord id="wrap32">
        <BtnBottom 
          disabled={
            !(
                PassWordState.pwPattern 
              && PassWordState.pwCheck 
            )
          }
          onClick={handleNewPassword}
          >
          완료
        </BtnBottom>
      </BtnPassWord>
      </section>
    </div>
  );
};

const UserInfo = styled.div`
  width: calc(100% - 32px);
  padding: 32px 16px 32px 16px;
  background:#f5f5f5;

  p {
        font-size: 16px;
        color: #222;
        line-height: 26px;
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 16px;

        padding: 20px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;

        margin-top: 20px;
        color:#222;

        li {
            display: flex;
            justify-content: start;
            align-items: center;
            
            font-size: 18px;

            span {
                width: 80px;
                font-weight: 700;
            }
        }
    }
`

const PassWordInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;

    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #444;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    background:#f5f5f5;
  }
`;

const Feedtxt = styled.p`
  font-size: 12px !important;
  margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const BtnPassWord = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  padding-bottom:40px;
`;

export default PwNewReissue;