import React, { useEffect, useMemo, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import EarnIcon from '../../../components/common/EarnIcon';
import StorePRS from '../../../components/TouchOll/usinginfo/StorePRS';
import axios from 'axios';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import { useLocation, useNavigate } from 'react-router-dom';
import IosNfc from '../../../components/common/function/IosNfc';

const UsingStore = ({iosNFC}) => {
  const navigate = useNavigate();
  const location = useLocation();  
  const deviceSerial = location.state.point || '';
  const storeSid     = location.state.sid || '';
  

  const [storeData, setStoreData] = useState([])

  useEffect(()=>{
    handleHeaderToken();
  }, [])

  /**
   * Point 전문 이용한 사용 가능 보너스
   */
  // const point = deviceSerial.replace(/[^a-zA-Z0-9]/g, '');
  // const point = "090cd6adaa9c7471d8308512866d7904ca0002931cd6ac1779239cb25599da313e21b9d0563f096326a332cd4f610c0aae21b9d0563f096326a332cd4f610c0aae21b9d0563f096326a332cd4f610c0aae21b9d0563f096326a332cd4f610c0aae21b9d0563f096326a332cd4f610c0aa"
  const fetchData = async() => {
    try{
      const responseData = {
          point : deviceSerial
      }

      const response = await axios.post('/api/pointMngr/selectAccmlPoint', responseData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log("가맹점 사용가능 보너스 :: ", response?.data?.data?.message)
      setStoreData(response?.data?.data?.message);
    }catch(error){
      console.log("가맹점 사용가능 보너스 오류 ::". error);
    }
  }

  /**
   * Sid 이용한 사용 가능 보너스
   */
  const fetchDataSid = async() => {
    try{
      const responseData = {
          sid   : storeSid
      }

      const response = await axios.post('/api/pointMngr/selectAccmlPointFromSid', responseData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log("sid 가맹점 사용가능 보너스 :: ", response?.data?.data?.message)
      setStoreData(response?.data?.data?.message);
    }catch(error){
      console.log("sid 가맹점 사용가능 보너스 오류 ::". error);
    }
  }

  useEffect(() => {
    // deviceSerial 값이 있으면 fetchData 실행
    if (deviceSerial === '') {
      fetchDataSid();
    } else {
      fetchData();
    }
  }, [deviceSerial, storeSid])

  const BASE_IMG_URL = "https://dev1.touchall.co.kr"; 

  
  /**
   * summedByEarn는 각 earn의 값을 total 하는 값.
   * 이전 내역 중에 포인트가 있다면 사용이 가능하게 하기 위해 화면을 출력할 예정.
   */
  const summedByEarn = {}
  useEffect(() => {
    if(storeData && storeData.total) {
      storeData?.total.map(item => {
        if(!summedByEarn[item.earn]) {
          summedByEarn[item.earn] = 0; 
        }
        summedByEarn[item.earn] += item.available;
      });
    }
  }, [storeData])

  useEffect(()=>{
    console.log("summedByEarn", summedByEarn, summedByEarn['G'])
  },[summedByEarn])
  
  return (
    <div id="sub_common_wrap_f">
      {iosNFC && <IosNfc />}
      
      <SubHead  SubBgColor={'#fff'} bottomLine={true}>
        My 보너스 조회ㆍ사용
      </SubHead>

      <section>
        {/* 가맹점 로고 이미지 */}
        <StoreTop>
            <div>
              <img src={BASE_IMG_URL + storeData?.logo} alt={storeData?.storeName} />
            </div>
        </StoreTop>

        {/* 가맹점 이름 */}
        <EarnTit>
          {storeData?.storeName}
          <EarnIcon earn={storeData?.earnCode} />
        </EarnTit>

         {/* 가맹점 유형 출력 */}
        <StoreRPSWrap>
        {/* {
          storeData?.total && 
          storeData?.total  
          .filter(item => item.earn === storeData?.earnCode)
          .map((item, index) => (
            <StorePRS 
              key={index}
              kind={item?.kind}
              total={item?.total} 
              useTotal={item?.available}  
              storeData={storeData}
              earn={storeData?.earnCode}
            />
          ))
        } */}
        {
          storeData?.total &&
          storeData?.total
            .filter(item => item.earn === storeData?.earnCode)
            .map((item, index) => {
              // storePoint에서 kind와 earn이 일치하는 항목을 찾기
              const matchingStorePoint = storeData?.storePoint?.find(point => point.kind === item.kind && point.earn === item.earn);
              // 만약 일치하는 항목이 있다면 해당 point 값을 사용하고, 그렇지 않으면 item.available 값을 사용
              const availableValue = matchingStorePoint ? matchingStorePoint.point : item.available;

              return (
                <StorePRS 
                  key       ={index}
                  kind      ={item.kind}
                  total     ={item.total} 
                  useTotal  ={availableValue}  
                  storeData ={storeData}
                  earn      ={storeData.earnCode}
                />
              );
            })
        }
        </StoreRPSWrap>

        {/* 
        ** 과거 유형별 보너스 출력 **
        - 아래 조건
        - summedByEarn 유효값
        - summedByEarn의 key 값 : ['T', 'G', 'S'] 각각 값이 0이 아닐 것
        - storeData?.earnCode의 값이 아닐 것.
        - 통합 > 그룹 > 단독 순서대로 나오게 하기 위해 유형별로 출력

        + 현재 유형이 'T'이면,         'G'는 사용 가능한 값이 있어도 출력 되지 않음.
        + 현재 유형이 'G'이면,         'T'는 사용 가능한 값이 있어도 출력 되지 않음.
        + 현재 유형이 'S'이면,         'S'만 출력 됨.
        + 현재 유형이 'T' 또는 'G'이면, 'S'는 값이 있으면 출력 됨.

        >> result: 과거유형 'S' 만 출력 유무 확인 하면 됨.
        */}

        {/* 유형 'S'*/}
        {
          summedByEarn 
          && 'S' !== storeData?.earnCode
          && summedByEarn['S'] > 0
          && storeData?.total && (
            <>
              <EarnTit>
                {storeData?.storeName}
                <EarnIcon earn={'S'} />
              </EarnTit>

              <StoreRPSWrap>
              {storeData?.total
                .filter(item => item.earn === 'S' && item.available > 0)
                .map((item, index) => (
                  <StorePRS 
                    key       ={index}
                    kind      ={item?.kind}
                    total     ={item?.total} 
                    useTotal  ={item?.available}  
                    storeData ={storeData}
                    earn      ={'S'}  
                  />
                ))}
              </StoreRPSWrap>
            </>
          )
        }

 

        {/* 과거 유형의 T, G가 필요하다면 아래 로직 사용하면 됨. */}
        {/* 유형 'T'*/}
        {/* { 
          summedByEarn 
          && 'G' !== storeData?.earnCode // G 가 아닐 때
          && 'T' !== storeData?.earnCode // T 가 아닐 때
          && summedByEarn['T'] > 0       // T 의 가용 보너스가 0보다 클 때
          &&
          <EarnTit>
            {storeData?.storeName}
            <EarnIcon earn={'T'} />
          </EarnTit>
        }
        <StoreRPSWrap>
        {
          summedByEarn 
          && 'G' !== storeData?.earnCode 
          && 'T' !== storeData?.earnCode
          && summedByEarn['T'] > 0
          && storeData?.total 
          && storeData?.total
          .filter(item => item.earn === 'T' && item.available > 0)
          .map((item, index) => (
            <StorePRS 
              key={index}
              kind={item?.kind}
              total={item?.total} 
              useTotal={item?.available}  
              storeData={storeData}
              earn={'T'}  
            />
          ))
        }
        </StoreRPSWrap> */}

        {/* 유형 'G'*/}
        {/* {
          summedByEarn 
          && 'T' !== storeData?.earnCode 
          && 'G' !== storeData?.earnCode
          && summedByEarn['G'] != 0
          &&
          <EarnTit>
            {storeData?.storeName}
            <EarnIcon earn={'G'} />
          </EarnTit>
        }
        <StoreRPSWrap>
        {
          summedByEarn 
          && 'T' !== storeData?.earnCode
          && 'G' !== storeData?.earnCode
          && summedByEarn['G'] != 0
          && storeData?.total 
          && storeData?.total.filter(item => item.earn === 'G' && item.available > 0)
          .map((item, index) => (
            <StorePRS 
              key={index}
              kind={item?.kind}
              total={item?.total} 
              useTotal={item?.available}  
              storeData={storeData}
              earn={'G'}  
            />
          ))
        }
        </StoreRPSWrap> */}


        <BtnEnd onClick={() => navigate(-1)}>확인</BtnEnd>
      </section>
    </div>
  );
};

const StoreTop = styled.div`
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;
gap: 30px;
padding: 20px 0 0 0;

&>div {
  width: 120px;
  height:120px;
  border-radius: 8px;
  border:1px solid #E0E0E0;
  overflow:hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}
`

const EarnTit = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  gap:4px;
  border-bottom: 1px solid #DFDFDF;
  padding: 30px 0;

  font-size: 20px;
  font-weight: bold;
`

const BtnEnd = styled.button`
font-size:16px;
color:#fff;
width: 120px;
height:40px;
border-radius: 8px;
background:#4D4D4D;

display: flex;
justify-content: center;
align-items: center;
margin:30px auto 40px auto;


`

const StoreRPSWrap = styled.div`
margin-bottom: 30px;
`
export default UsingStore;