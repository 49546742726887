import React from 'react';
import {
  tip
, close_button
, p_icon} from "../../../../assets/images"
import ModalPopup from '../../common/ModalPopup';
import styled from 'styled-components';
import PopupModal from '../../common/PopupModal';

const PayTip = ({toggleModal}) => {
  /**
   * 현금 사용팁 
   */
  return (
    <ModalPopup >
      <PayTipPop>
        <CloseButton
          src={close_button}
          alt="Close"
          onClick={toggleModal}
        />

        <h1>
          터치올 - 현금<em>P</em><br />
          사용 TIP
        </h1>

        <p>
          <strong>
            터치올-<b>현금</b><img src={p_icon} />는 포인트가 아닙니다. <br/>
            <em>100%  진짜 현금입니다!</em> <br /><br />
          </strong>

          1. 최초가입시 <b>현금</b><img src={p_icon} /> 사용방법<br />
          &gt; 가맹점 현금대용 <em>초간편 PAY</em><br /><br />

          <span>2. 통장 입금 방법으로 변경</span><br />
          My 터치올 &gt; My 정보조회.수정<br />
          &gt; ‘터치올 보너스 사용’ 에서 변경<br />
          &gt; PAY 미사용액 <em>통장 자동입금!</em>
        </p>
      </PayTipPop>
    </ModalPopup>
  );
};

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const PayTipPop = styled.div`
width: 300px;
border-radius: 12px;
overflow:hidden;

h1 {
  font-size: 24px;
  color:#fff;
  padding: 30px 16px;
  line-height: 28px;
  background: url(${tip}) #FE7A36;

  em {
    color:#FE7A36;
    background:#fff;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display:inline-block;
    text-align:center;
    font-weight:bold;
    margin-left: 4px;
  }
}

p {
  background:#fff;
  font-size: 16px;
  padding: 32px 16px;
  color:#707070;
  font-weight:bold;
  line-height: 24px;
  text-align:Center;

  em {
    color:#FE7A36;
  }

  img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  strong {
    color:#042F8B;

    em {
      font-size: 20px;
    }
  }

  span {
    color:#FE7A36;
  }

  b {
    color:#545F03;
  }
}

`
export default PayTip;