import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { phoneNumberState } from '../../../recoil/recoilState';
import { useRecoilState } from 'recoil';
import ModalPopup from '../common/ModalPopup';

function PhoneNumberInput({ data, gameResult }) {

  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useRecoilState(phoneNumberState);
  const [prevPhoneNumber, setPrevPhoneNumber] = useState('');

  useEffect(() => {
    setPrevPhoneNumber(phoneNumber);
  }, []);

  const handlePhoneNumberChange = e => { setPhoneNumber(e.target.value); };

  const handleConfirmButtonClick = () => {
    navigate('/point_save_result_detail', {
      state: { data: data, phoneNumber: phoneNumber, gameResult: gameResult }
      , replace: true
    });
    window.scrollTo(0, 0); // 가장 상단으로 이동
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <PhoneNumInputWrap>
      <BorderContainer>
        <TextWrap>
          <p>폰 No. 입력</p>
          <SubTitleText>
            한번 입력이후, 가맹점 이용시 그냥 폰 터치만!
          </SubTitleText>

          <PhoneNumberInputField
            placeholder="- 없이 번호만 입력"
            value={phoneNumber || prevPhoneNumber}
            onChange={handlePhoneNumberChange}
            name="tel"
            type="number"
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
            }}
            maxLength={11}
            pattern="^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$"
          />
        </TextWrap>

        <ConfirmButtonContainer>
          <ConfirmButton
            disabled={!phoneNumber.match(/^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$/)}
            onClick={handleIsOpen}
          >
            확 인
          </ConfirmButton>
        </ConfirmButtonContainer>
      </BorderContainer>

      {
        isOpen && 
        <ModalPopup>
          <NumberPopup>
            <p>번호를 잘못 입력하면 혜택이 유실될 수 있습니다.<br />
              <span>{phoneNumber}</span>이(가) 맞습니까?</p>

            <ul>
              <li onClick={handleConfirmButtonClick}>계속 진행</li>
              <li onClick={handleIsOpen}>다시 입력</li>
            </ul>
          </NumberPopup>
        </ModalPopup>
      }
    </PhoneNumInputWrap>
  );
}

const PhoneNumInputWrap = styled.div`
width: calc(100% - 32px);
padding: 0 16px;
margin-top: 20px;
`;

const ConfirmButtonContainer = styled.div`
  margin-top: 30px;
`;

const ConfirmButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: ${props => (props.disabled ? '#DDDDDD' : '#FE7A36')};
  color: #ffffff;
  padding: 3px 15px;
  border: none;
  border-radius: 0 0 10px 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

const TextWrap = styled.div`
width: calc(100% - 32px);
  padding: 0 16px;
`;

const SubTitleText = styled.div`
  margin-right: 10px;
  color: #707070;
  font-weight: 400;
  margin-top: 5px;
  font-size: 15px;
`;

const PhoneNumberInputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 16px;
  border: 1px solid #8e8e8e;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #e5f4ff;


  &::-webkit-inner-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

const BorderContainer = styled.div`
    width: 100%;
    padding: 20px 0 0 0;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

    p {
      margin-right: 10px;
      color: #ec635d;
      font-weight: 600;
    }
`;

const NumberPopup = styled.div`
width: calc(80vw - 32px);
max-width: 320px;
background: #fff;
padding: 32px 16px; 

p {
  font-size :16px;
  line-height: 1.5;
  margin-bottom: 32px;

  span {
    font-weight: bold;
    color:#FE7A36;
    font-size : 18px;
  }
}

ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:8px;

  li {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background:#444;
    color:#fff;
    border-radius: 8px;
    

    &:first-child {
      background: #FE7A36;
      font-weight: bold;
    }
  }
}
`

export default PhoneNumberInput;
