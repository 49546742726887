import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import "../../../../assets/styles/style.scss"
import {
    calendar
  , search
} from "../../../../assets/images"
import ModalPopup from '../../common/ModalPopup';
import DataPicker from './DataPicker';
import {formatDate} from "../../../components/common/function/FormatNumber"
import SearchBonusSelect from './SearchBonusSelect';
import axios from 'axios';
import RightArrIcon from '../../common/RightArrIcon';
import SearchUsingPopup from './SearchUsingPopup';

const SearchInfo = ({
    dataResponse
  , setDataResponse
  , searchList
  , setSearchList
  , setIsLoading
  , setUseList
  , setPage
}) => {

  const searchFetchData = async () => {
    setIsLoading(true);
    try{
      const response = await axios.post('/api/myPageMngr/selectUseDtls', searchList, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log('이용내역 상세 데이터 ::', response?.data?.data?.message);

      setDataResponse(response?.data?.data?.message);
      setUseList(response?.data?.data?.message?.listUseDetails); 
      
    } catch(error) {
      console.log("이용내역 오류 ::", error)
    }
    setIsLoading(false);
  }


  /**
   * 일 계산
   * @param {*} date 
   * @param {*} days 
   * @returns 
   */
  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  /**
   * 달 계산
   */
  const addMonths = (date, months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  };

  /**
   * 년 계산
   * @param {*} date 
   * @param {*} years 
   * @returns 
   */
  const addYears = (date, years) => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  };

  /**
   * 기간 검색 관련
   */
  const today = new Date();
  const currentDate = formatDate(today);

  const [startDate, setStartDate]           = useState(currentDate);
  const [endDate, setEndDate]               = useState(currentDate);
  const [selectedPeriod, setSelectedPeriod] = useState('1month');
  const [datePick, setDatePick]             = useState(false);
 
  /**
   * 날짜 계산 식
   * @param {*} period 기간 
   */
  const handleDateChange = (period) => {
    setEndDate(currentDate);
    setSelectedPeriod(period);

    switch (period) {
      case 'today':
        setStartDate(currentDate);
        break;
      case '15days':
        setStartDate(formatDate(addDays(today, -15)));
        break;
      case '1month':
        setStartDate(formatDate(addMonths(today, -1)));
        break;
      case '3months':
        setStartDate(formatDate(addMonths(today, -3)));
        break;
      case '6months':
        setStartDate(formatDate(addMonths(today, -6)));
        break;
      case '1year':
        setStartDate(formatDate(addYears(today, -1)));
        break;
      default:
        break;
    }
  };


  useEffect(()=>{
    handleDateChange('1month')
  },[])

  /**
   * 일반 업데이트
   * @param {*} name  : key
   * @param {*} value : value
   */
  const handlesearchList = (name, value) => {
    setSearchList(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  /**
   * 날짜가 변경되면 업데이트 
   */
  useEffect(() => {
    handlesearchList('sdate', startDate)
    handlesearchList('edate', endDate)
  },[startDate, endDate])
  

  /**
   * input 형태의 value 값을 업데이트 
   * @param {} e : input Value
   */
  const handleinputData = (e) => {
    const {name, value}= e.target;
    handlesearchList(name, value);
  }

  /**
   * 검색버튼 클릭시 작동
   */
  const handleSearchBtn = () => {
    handlesearchList("offset", 0);
    setPage(1)
    searchFetchData();
  }

  const getCityAddress = async () => {
    const data = {
      "type" : "PAY"
    }
    try{
      const response = await axios.post('/api/codeMngr/getSearchCodeList', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('검색 pay ::' , response?.data?.data?.message);

      /**
       * pcode 분류
       */
      const groupedData = response?.data?.data?.message.reduce((acc, item) => {
        if (!acc[item.pcode]) {
          acc[item.pcode] = [];
        }
        acc[item.pcode].push(item);
        return acc;
      }, {});

      console.log('groupedData', groupedData)
      setCodeList(groupedData);

    } catch (error){

    }
  }

  useEffect(()=>{
    getCityAddress();
  },[])

  const [isOpen, setIsOpen] = useState(false);
  const [codeList, setCodeList] = useState([]);

  return (
    <>
    <SearchWrap className='sww'>
      <ul>
        <li className={selectedPeriod === 'today' ? 'active' : ''}>
          <button onClick={() => handleDateChange('today')}>오늘</button>
        </li>
        <li className={selectedPeriod === '15days' ? 'active' : ''}>
          <button onClick={() => handleDateChange('15days')}>15일</button>
        </li>
        <li className={selectedPeriod === '1month' ? 'active' : ''}>
          <button onClick={() => handleDateChange('1month')}>1개월</button>
        </li>
        <li className={selectedPeriod === '3months' ? 'active' : ''}>
          <button onClick={() => handleDateChange('3months')}>3개월</button>
        </li>
        <li className={selectedPeriod === '6months' ? 'active' : ''}>
          <button onClick={() => handleDateChange('6months')}>6개월</button>
        </li>
        <li className={selectedPeriod === '1year' ? 'active' : ''}>
          <button onClick={() => handleDateChange('1year')}>1년</button>
        </li>
      </ul>

      <DateSelect className='ds'>
        <div onClick={() => setDatePick(!datePick)}>
          <span>
            <img src={calendar} alt='달력' />
          </span>
          <input
            type    ='text'
            name    ='sdate'
            value   ={startDate}
            readOnly
          />
        </div>
        ~
        <div onClick={() => setDatePick(!datePick)}>
          <span>
            <img src={calendar} alt='달력' />
          </span>
          <input
            type    ='text'
            name    ='edate'
            value   ={endDate}
            readOnly
          />
        </div>

      </DateSelect>

      <NameDetail>
        <NameSearch>
          <span><img src={search} alt='이름검색' /></span>
          <input 
            type='text' 
            placeholder='가맹점 상호 검색'
            name='storeName' 
            onChange={handleinputData}
          />
        </NameSearch>
        
        <button onClick={()=> setIsOpen(!isOpen)}>상세검색 <RightArrIcon /></button>
      </NameDetail>

      {/* 보너스 유형, 종류 select */}
      <Wrap32>
        <SearchBonusSelect 
          setSearchList={setSearchList}
          listBonusType={dataResponse?.listBonusType}
          listEarnType ={dataResponse?.listEarnType} 
        />
      </Wrap32>
      

      <SearchBtn onClick={handleSearchBtn}>검색</SearchBtn>
    </SearchWrap>

    {
      datePick && 
      <ModalPopup>
        <DataPicker 
          setState={setDatePick} 
          setStartDatepick={setStartDate} 
          setEndDatepick={setEndDate} 
          setSelectedPeriod={setSelectedPeriod}
        />
      </ModalPopup>
    }

    {
      isOpen && 
      <SearchUsingPopup 
        handlesearchList ={handlesearchList}
        setIsOpen        ={setIsOpen} 
        codeList         ={codeList}
        // handleSearchPay  ={handleSearchPay}
        // paySearch        ={paySearch}
        // setPaySearch     ={setPaySearch}
      />
    }

    </>
  );
};

const SearchWrap = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    width: calc(100% - 32px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-gap: 4px;

    li {
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      height: 28px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      
      button {
        color: #4D4D4D;
        width: 100%;
      }
      
      &.active {
        border: 1px solid #FE7A36;

        button {
          color: #FE7A36;
        }
      }
    }
  }

`
const Wrap32 = styled.div`
width:calc(100% - 32px);
`
const DateSelect = styled.div`
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  div {
    width: calc(50% - 16px);
    border: 1px solid #E0E0E0;
    height: 30px;
    border-radius: 8px;

    display: flex;
    justify-content: start;
    align-items: center;

    // padding: 0 8px;

    span {
      margin: 0 8px;
    }

    input {
      width: calc(100% - 24px);
      font-size: 14px;
    }
  } 
`

const NameSearch = styled.div`
  width: 50%; 
  border: 1px solid #E0E0E0;
  height: 30px;
  border-radius: 8px;

  display: flex;
  justify-content: start;
  align-items: center;


  span {
    padding: 0 8px;
  }

  input {
    width: 100%;
    font-size: 14px;
  }
`

const SearchBtn = styled.button`
  width: 80px;
  height: 32px;
  background:#444;
  color:#fff;
  font-size: 14px;
  border-radius:4px;
  margin-top: 16px;
`

const NameDetail = styled.div`  
width: calc(100% - 32px);
height: 32px;

margin-top:8px;
padding: 0 16px;
display: flex;
justify-content: space-between;
align-items: center;
gap: 8px;

&>button {
  width: 50%;
  height: 100%;
  background:#F2F2F2;
  border-radius: 8px;
  padding: 0 10px;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
`


export default SearchInfo;