import React, { useState } from 'react';
import ModalPopup from '../../common/ModalPopup';
import {close_button} from "../../../../assets/images"
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const Emergency = ({toggleModal, name, tel}) => {
  /**
   * 긴급 알림 
   */
  const formattedPhoneNumber = tel.replace(/(\d{3})(\d{4})(\d{4})/, '010-$2-$3');
  const navigate = useNavigate();

  return (
      <ModalPopup>
        <EmergencyPop>
          <CloseButton
            src={close_button}
            alt="Close"
            onClick={toggleModal}
          />
          <h1>긴급알림</h1>

          <p>
            {name}님의 폰 No - I.D<br />
            {formattedPhoneNumber}가<br />
            <em>다른 가입 폰 No와 중복입니다.</em><br /><br />

            지금 사용중 폰No가<br />
            가입 이후 변경되셨다면<br />
            <em>지금 바로 수정하셔야 합니다.</em><br /><br />

            그렇지 않으면<br />
            귀하 소중한 보너스가<br />
            유실될 수 있으며<br />
            <em>당사는 이에 면책입니다.</em>
          </p>

          <button onClick={() => navigate('/app/mypage_view')}>
            My 정보 조회 수정하기
          </button>
        </EmergencyPop>
      </ModalPopup>
  );
};

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const EmergencyPop = styled.div`
width: 300px;
border-radius: 12px;
overflow:hidden;

h1 {
  font-size: 24px;
  color:#fff;
  background:#FF5F01;
  padding: 30px 16px;

}

p {
  background:#fff;
  font-size: 16px;
  padding: 32px 16px;
  color:#042F8B;
  font-weight:bold;
  line-height: 24px;
  text-align:Center;

  em {
    color:#E81414;
  }
}

button {
  color:#fff;
  background:#FF5F01;
  width: 100%;
  font-size: 20px;
  padding: 20px 0;
}
`

export default Emergency;