import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const AmountInput = ({ value, setValue,  disabled, onChange }) => {
  // 입력값을 쉼표로 구분된 형식으로 형식화하는 함수
  const formatValue = (value) => {
    if (value !== undefined) {
      // 숫자로 변환 후 쉼표로 구분된 형식으로 변환
      return Number(value).toLocaleString();
    } else {
      return ''; // 값이 없는 경우 빈 문자열 반환
    }
  };

  useEffect(()=>{
    setEnteredNum(formatValue(value))
  },[value])

  // 형식화된 입력값을 상태로 관리
  const [enteredNum, setEnteredNum] = useState(0);
  // 입력값이 변경될 때 호출되는 함수
  const changeEnteredNum = (e) => {
    const inputValue = e.target.value;
    // 쉼표로 구분된 숫자 형식을 숫자 형식으로 변환
    const numericValue = parseFloat(inputValue.replaceAll(",", ""));
    // 입력된 값이 숫자가 아닌 경우 무시
    if (!isNaN(numericValue)) {
      setEnteredNum(inputValue); // 입력값 변경
      setValue(numericValue); // 실제 값 업데이트
    }
  };


  return (
    <AmountNumInput
      type="text" 
      name="pay"
      value={enteredNum}
      disabled={disabled}
      onChange={changeEnteredNum}
    />
  );
};

const AmountNumInput = styled.input`
width:60px !important;
border: 1px solid #ddd;
margin: 0 2px 0 4px;
font-size: 18px !important;
outline:none;
text-align:right;
padding: 2px 4px !important;
`

export default AmountInput;