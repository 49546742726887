import React from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import ToggleList from '../../../components/TouchOll/board/ToggleList';

const Notice = () => {
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        공지사항
      </SubHead>

      <section>
        <ToggleList 
          title = {"공지사항 입니다."}
          text = {"긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다.긴급 소식입니다."}
          date = {"2023.05.05"}
        />
      </section>
    </div>
  );
};

export default Notice;