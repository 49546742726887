import React, { useState } from "react";
import Header from "../common/Header";
import CashCancel from "../../components/Biapp/CashCancel";
import StampCancel from "../../components/Biapp/StampCancel";
import GameCancel from "../../components/Biapp/GameCancel";
import Receipt from "../../components/Biapp/Receipt";
import { useLocation } from "react-router-dom";
import Ad from "../../components/Biapp/Ad";
import ReceiptCancle from "../../components/Biapp/ReceiptCancel";
import styled from "styled-components";
import PointUseScreen from "../../components/common/payuse/PointUseScreen";
import StampUseScreen from "../../components/common/payuse/StampUseScreen";
import RpsResult from "../../components/common/payuse/RpsResult";
import RpsResultList from "../../components/common/payuse/RpsResultList";

function PointCancel() {

  const [isPopupVisible, setPopupVisible] = useState(false);
  const location = useLocation();
  const { cancelData } = location.state || {};

  console.log("cancelData :: ", cancelData);

  const dataInfo    = cancelData?.data?.message;
  const storeInfo   = cancelData?.data?.message?.store;
  const receiptInfo = cancelData?.data?.message?.recipt;
  const useType     = cancelData?.data?.message?.type;
  const isApp       = cancelData?.data?.message?.isAPP;
  const pointInfo   = cancelData?.data?.message?.point;
  

  //console.log("cancelData storeInfo :: ", storeInfo);
  //console.log("cancelData receiptInfo :: ", receiptInfo);

  const handleQuestionClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div id="sub_common_wrap">
      <Header />    
      <Wrap32>
        {
        'Y' === storeInfo?.pointUse ? 
          <PointUseScreen
            storeInfo={storeInfo} 
            pointInfo={pointInfo} 
            useType={useType} 
            receiptInfo={receiptInfo} 
            isApp={isApp}
          />
        : 
          <StampUseScreen 
            storeInfo={storeInfo} 
            pointInfo={pointInfo} 
            useType={useType} 
            receiptInfo={receiptInfo} 
            isApp={isApp}
          />
        }
      </Wrap32>

        {/* 경품증정 결과 */}
        {
          'N' !== storeInfo?.rpsUse && receiptInfo?.stlmMethodCode !== "9" 
          ? <GameCancel data={dataInfo} />   : <></>
        }
      <ReceiptCancle data={dataInfo} />
      <Ad />     
    </div>  
  );
}

const Wrap32 = styled.div`
width: calc(100% - 32px);
padding : 0 16px;
margin-top: 20px;
`

export default PointCancel;
