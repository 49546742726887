import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import axios from 'axios';
import AutoTimer from '../../../components/TouchOll/join/AutoTimer';
import SystemPopup from '../../../components/common/SystemPopup';
import SystemModal from '../../../components/common/SystemModal';
import JoinTypeY from '../../../components/TouchOll/join/JoinTypeY';
import JoinTypeN from '../../../components/TouchOll/join/JoinTypeN';
import { logo_txt } from '../../../../assets/images';

const Join = () => {
  const [mobile, setMobile]         = useState('');   // 핸드폰 번호
  const [isBtnDis, setIsBtnDis]     = useState(true); // 인증번호 받기 비활성화
  const [confirmNum, setConfirmNum] = useState('');   // 인증번호 입력
  // const [feedNum, setFeedNum]       = useState('영문 대소문자 구분없이, 숫자, 특수문자 포함 최소 8자 이상')   // 비밀번호 feed 텍스트
  const [count, setCount]           = useState(180);  // 3분 타이머 
  const [countOn, setCountOn]       = useState(false);  // 3분 타이머 
  const [popupTxt, setPopupTxt]     = useState(''); // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false); // 시스템 팝업

  const location = useLocation();
  const navigate = useNavigate();

  /** 
   * 회원가입 완료 버튼
   * 조건: 아래 모두 true 표시 되어야 활성화
   */ 
  const [joinState, setJoinState] = useState({
      numberCheck : false  // 인증번호 확인
    , pwPattern   : false  // 비밀번호 패턴 확인
    , pwCheck     : false  // 비밀번호 동일 확인
    , nameCheck   : false  // 이름 유무 + 2글자 이상
    , birthCheck  : false  // 생년월일 패턴
  })


  /**
   * 인증번호 전송
   * after: 인증번호 input (#confirm_num_input) 보여짐
   */
  const handlePostNumRequest = async () => {
    try {
      await axios.post('/api/authUser/userCertFromMobile', {
        mobile: mobile
      });
      setCountOn(true);
      setCount(180);
      document.querySelector('#confirm_num_input').classList.add('confirm_input');
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };

  /** 
   * 전화번호 유효성 검사
   * 결과 : 인증번호 받기 버튼 상태 변경
   */
  const handleMobileChange = (e) => {
    const pattern = new RegExp("^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$");

    setMobile(e.target.value);
    setIsBtnDis(!pattern.test(e.target.value)); 
  };

  /**
   * 인증번호 확인 
   * 조건: data?result가 True 이면 인증번호 입력 input 사라짐
   * 결과: 전화번호 input 읽기 전용으로 변경되어 수정 불가.
   */
  const handleValidation = async () => {
    try {
      const response = await axios.post('/api/authUser/validationCert', {
          mobile  : mobile
        , certNum : confirmNum
      });

      if (response.data.data && response.data.data.result) {
        setCount('');
        setJoinState(prevState => ({ ...prevState, numberCheck: true }));
        document.querySelector('#confirm_num_input').classList.remove('confirm_input');
        document.querySelector('#feedtxt0').classList.add('password-match');
      } else {
        setPopupTxt('인증번호를 잘못 입력했습니다.');
        setSystem(!system);
      }
    } catch (error) {
      console.error('Error while making the POST request:', error);
    }
  };

  // 정규 가입 'Y' | 임시가입 'N' 
  const [joinType, setJoinType] = useState('Y');
  const handleJoinType = (e) => {
    setJoinType(e.target.value);
    setJoinState(prev => {
      return{
        ...prev,
        pwPattern   : false  // 비밀번호 패턴 확인
      , pwCheck     : false  // 비밀번호 동일 확인
      , nameCheck   : false  // 이름 유무 + 2글자 이상
      , birthCheck  : false  // 생년월일 패턴
      }
     
    })
  }
  

 
  
  return (
    <div id="sub_common_wrap">
      {
        /**
         * 가입하기 버튼 눌렀을 때 시스템 모달 팝업 창
         */
        system && (
           <SystemModal system={system} setSystem={setSystem}>
            {popupTxt}
          </SystemModal>
        )
      }
     
     <SubHead  SubBgColor={'#f5f5f5'} bottomLine={true}>
        One 클릭 <img src={logo_txt} />가입
      </SubHead>

      <section>
        <JoinTypeTab className={`type${joinType}`}>
          <li>
            <label>
              <input 
                type='radio' 
                name='join_type' 
                value={'Y'} 
                checked={joinType === 'Y'} 
                onChange={handleJoinType}
              />
              정규. 안심 가입
            </label>
          </li>
          <li>
            <label>
              <input 
                type='radio' 
                name='join_type' 
                value={'N'} 
                checked={joinType === 'N'} 
                onChange={handleJoinType}
              />
              임시가입
            </label>
          </li>
        </JoinTypeTab>

        <Subtit>
          <ul>
            <li>※ 휴대폰 명의자가 아니어도 가입 可</li>
            <li>※ 미성년자 및 외국인도 가입 可</li>
          </ul>
        </Subtit>
        

        {/* 전화번호 아이디 input */}
        <JoinInputs>
          <div>
            <PhoneTit>
              <p>폰 No. [간편 ID]</p>
              <span>※ 사용 폰 No 변경시 변경 可</span>
              
            </PhoneTit>
            
            <Feedtxt id="feedtxt0">
              {
              joinState.numberCheck ?
                "인증이 완료되었습니다."
                : "전화번호 인증이 필요합니다."
              }
            </Feedtxt>

            <div className="flex2">
              <input
                placeholder =" - 없이 전화번호 입력"
                value       ={mobile}
                onChange    ={handleMobileChange} // 입력 값 변경 시 핸들러 호출
                type        ="number" 
                maxLength   ={11}
                onInput      ={e => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                readOnly    ={joinState.numberCheck}
                pattern     ="^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$"
              />

              <button 
                onClick   ={handlePostNumRequest} 
                disabled  ={isBtnDis}
                style     ={{ backgroundColor: isBtnDis ? '#aaa' : '#2860A7' }}
              >
                {joinType === 'N' && <>임시비번&<br/></>}인증번호 받기
                </button>
            </div>

            <div id="confirm_num_input">
              <div className="flex2">
                <input
                  placeholder ="인증번호 입력"
                  value       ={confirmNum}
                  onChange    ={(e) => setConfirmNum(e.target.value)}
                  type        ="number"
                />
                

                <button 
                  onClick   ={countOn ? handleValidation : null}
                  disabled  ={!countOn}
                  style     ={{ backgroundColor: !countOn ? '#aaa' : '#2860A7' }}
                >확인</button>
              </div>

              <TimerWrap>
                <h4>
                  <span>유효시간</span>
                  <CountTimer>              
                    <AutoTimer
                    count={count}
                    setCount={setCount}
                    countOn ={countOn}
                    setCountOn={setCountOn}
                    />
                  </CountTimer>
                </h4>
                
                <ul>
                  <li>· 3분 이내 인증번호 입력!</li>
                  <li>· 시간 초과시 다시 인증번호 받기</li>
                </ul>
                
              </TimerWrap>
            </div>

            
          </div>
          
        </JoinInputs>

        {
          joinType === 'Y' ? 
          <JoinTypeY 
            joinType    ={joinType} 
            joinState   ={joinState} 
            setJoinState={setJoinState} 
            mobile      ={mobile}
            setPopupTxt ={setPopupTxt}
            setSystem   ={setSystem}
          /> : 
          <JoinTypeN 
            setJoinType ={setJoinType}
          />
        }
      
      </section>

    </div>
  );
};

const Subtit = styled.div`
  padding: 20px 16px 0 16px;

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #fe7a36;
  }

  ul {
    li {
      font-size: 14px;
      color: #444;
      line-height: 20px;
    }
    margin-top: 12px;
  }
`;

const PhoneTit = styled.div`
display: flex;
flex-direction: row !important;

justify-content: flex-start !important;
align-items: center !important;

span {
  color:#FF5F01;
  font-size: 12px;
}
`

const JoinInputs = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 32px;

  #confirm_num_input {
    display:none;
    position:relative;
    
    &.confirm_input {
      display:block !important;
      position:relative;
    }
  }
  
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
    }

    p {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }
  }
  .flex2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    gap: 8px !important;

    button {
      min-width: 110px !important;
      height: 50px;
      background: #2860A7;
      color: #fff;
      border-radius: 8px;

      font-size: 16px;
    }
  }
  input {
    width: calc(100% - 32px);
    height: 50px;
    border-radius: 8px;

    padding: 0 16px;

    font-size: 16px;

  }
`;

const Feedtxt = styled.p`
  font-size: 14px !important;
  // margin-top: 8px;
  color: #fe7a36 !important;
  font-weight: 500 !important;

  &.password-match {
    color:#46bf16 !important;
  }
`;

const JoinTypeTab = styled.ul`
width: calc(100% - 32px);
// padding: 0 16px; 
margin: 0 16px;
margin-top: 32px;

display: flex;
justify-content: center;
align-items: center;

li {
  width: 100%;
  height: 40px;

  background:#5D0EF5;
  font-weight: bold;

  border-radius: 12px 12px 0 0;

  label {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    color:#fff;

    input[type='radio'] {
      display:none;
    }

    
  }
  
  &:last-child {
    background:#FFC121;
  }
}

&.typeY {
  border-bottom: 5px solid #5D0EF5;
}

&.typeN {
  border-bottom: 5px solid #FFC121;
}
`

const TimerWrap = styled.div`
margin-top: 12px;
&>h4 {
  display: flex;
  justify-content: flex-end;
  align-items:center;
  gap: 4px;

  font-size: 14px;

  span {
    color: #EA5413;
  }
}

ul {
  text-align:right;
  margin-top: 8px;

  li {
    font-size: 12px;
    color:#444;
    line-height: 16px;
  }
}
`

const CountTimer = styled.div`
  // position:absolute;
  // right: 135px;
  // top:50%;
  // margin-top: -7px;

  span {
    font-size: 14px;
    color:#aaa;
    
  }
`

export default Join;
