import React from 'react';
import styled from 'styled-components';

const EarnIcon = ({earn}) => {
  return (
    <EarnType className={`earn${earn}`}>
      {
        earn === 'T' ? '통합' : 
        earn === 'G' ? '그룹' :
        '단독'
      }
    </EarnType>
  );
};

const EarnType = styled.div`
width: 32px;
height: 22px;
border-radius: 2px;

color: #fff;
font-weight: 500;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;

&.earnT {
  background: #5D0EF5;
}
&.earnG {
  background: #8E3D82;
}
&.earnS {
  background: #7F7F7F;
}
`
export default EarnIcon;