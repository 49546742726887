import React from 'react';
import styled from 'styled-components';

const FirstUserBan = () => {
  return (
    <FirstUserImg>
      <div>
          <p><em></em>처음이신 분 / 앱 미설치자</p>
          <h2>touch·oll 보너스<br />
            <span>현금 입금, 적립 & Pay 이용</span></h2>
        </div>
    </FirstUserImg>
  );
};


const FirstUserImg = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
  margin-top: 20px;

  div {
    width: calc(100% - 32px);
    padding: 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

    p {
      font-size: 14px; 
      color:#777;
      em {
        width: 5px;
        height: 12px;
        background:#45E04B;
        display:inline-block;
        margin-right: 7px;

      }
    }

    h2 {
      margin-top: 18px;
       font-size: 22px;
       font-weight:700;
       color:#FE7A36;
       line-height: 28px;
       padding-left:10px;

       span {
        color: #5D0EF5;
       }
    }
  }

`

export default FirstUserBan;