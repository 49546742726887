import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';

const PayUse = () => {
  /**
   * api로 POST 하여 정보 조회
   * 
   * 보너스를 사용 했을 때 페이지
   */

  const location = useLocation();
  const { approval, type, sid } = location.state || {};

  const navigate = useNavigate();

  useEffect(() => {
    handleHeaderToken();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try{
        const requestData = {
            approval: approval
          , type    : type
          , sid     : sid
        }

        // const requestData = {
        //   approval : "A80851624767727"
        //   , type   :  "2"
        //   , sid    : "S962594319"
        // }

        const response = await axios.post('/api/pointMngr/selectAccmlPointInQuery', requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log('이용내역 상세 데이터 ::', response?.data?.data?.message);
        handleResponse(response?.data?.data?.message);

      } catch(error){
        console.log("이용내역 상세 오류 ::". error);
      }
    }

    fetchData();
  }, [approval, type, sid])

  
  /**
   * api의 pointUse, stampUse에 따라서 페이지 이동
   * @param {*} response API 응답하여 받은 정보
   */
  const handleResponse = (data) => {
    if (data?.store) {
      const { stampUse, pointUse } = data?.store;

      if (stampUse === 'Y') {
        // stampUse 값이 'Y'인 경우 '/stamp' 페이지로 이동
        navigate("/common/stamp_result", { state: { data: data} , replace: true});
      }
      if (pointUse === 'Y') {
        // pointUse 값이 'Y'인 경우 '/point' 페이지로 이동
        navigate("/common/point_result", { state: { data: data} , replace: true});
      }
    }
  };
  

  return (
    <>
      
    </>
  );
};

export default PayUse;