import React from 'react';
import JoinSelectTxt from '../../../components/TouchOll/join/JoinSelectTxt';
import JoinSelectInputs from '../../../components/TouchOll/join/JoinSelectInputs';
import styled from 'styled-components';
import PointIcon from '../../../components/TouchOll/join/PointIcon';
import { Link } from 'react-router-dom';
import { point_tip } from '../../../../assets/images';
import useModalWithBackButton from '../../../components/common/function/HistoryModal';
import PayTip from '../../../components/TouchOll/popup/PayTip';

const JoinSelect = () => {
  const [isModal1Visible, toggleModal1] = useModalWithBackButton(); // 현금 사용 팁

  return (
    <>
    {/* 축입학 터치올 대학 */}
    <JoinSelectTxt />

    {/* 특별 학점 가산 회원 정보 */}
    <JoinSelectInputs />

    <PaySelect>
      <h1>3. <PointIcon /> 입금 · Pay 사용방법 선택</h1>
      
      {/* 현금 사용 팁 버튼 */}
      <button onClick={toggleModal1}><img src={point_tip} /></button>

      <Link to="/app/main">홈으로</Link>
    </PaySelect>

    {
      // 현금 사용 팁 팝업
      isModal1Visible && <PayTip toggleModal={toggleModal1} />
    }

    </>
  );
};

const PaySelect = styled.div`
width: calc(100% - 32px);
padding: 32px 16px;
h1 {
  font-size: 20px;
  font-weight:bold;

  margin-bottom: 20px;

  img {
    width: 18px;
  }
}
button {
  img {
    height: 40px;
  }
}

&>a {
  display:block;
  width: 135px;
  height: 40px;

  background:#444;
  border-radius: 8px;

  display:flex;
  justify-content: center;
  align-items: center;

  color:#fff;
  font-weight:bold;
  font-size: 18px;

  margin: 40px auto 0 auto;

}

`
export default JoinSelect;