import React from 'react';
import styled from 'styled-components';
import { gold, logo_toucholl } from '../../../../assets/images';

const LoginTopTxt = () => {
  return (
    <>
      <GoldBanner>
        <img src={gold} alt="금메달 배너" />
      </GoldBanner>

      <LoginTitle>
        <p>세계최초. 유일 최초-간편</p>

        <h3>
          100%
          <ul>
            <li>
              진<em></em>
            </li>
            <li>
              짜<em></em>
            </li>
            <li>
              현<em></em>
            </li>
            <li>
              금<em></em>
            </li>
          </ul>
          보너스 & Pay
        </h3>

        <h1>
          <img src={logo_toucholl} alt="터치올" />
        </h1>
      </LoginTitle>
    </>
  );
};



const GoldBanner = styled.div`
  width: 100%;
  height: auto;

  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    max-width: 360px;
  }
`;

const LoginTitle = styled.div`
  text-align: center;
  margin-top: 40px;

  p {
    color: #222;
    font-size: 16px;
  }

  h3 {
    font-size: 20px;
    color: #7b8531;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 12px;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1px;

      li {
        position: relative;
        color:#FF5F01;

        &:nth-child(2) {
          margin-right: 4px;
        }

        &:nth-child(4) {
          margin-right: 4px;
        }

        &:nth-child(1) {
          margin-left: 4px;
        }

        em {
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: #FE7A36;

          left: 50%;
          top: 0;

          margin-left: -2px;
          margin-top: -6px;
        }
      }
    }
  }

  h1 {
    margin-top: 20px;

    img {
      width: 100%;
      max-width: 260px;
    }
  }
`;

export default LoginTopTxt;