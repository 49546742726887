import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SystemModal from '../common/SystemModal';
import { useRecoilState } from 'recoil';
import { DeviceInfoResult } from "../../../recoil/recoilState";

const FloatInput = ({setPointEdit, inputValue, inputName, inputDisabled }) => {
  const [value, setValue]       = useState(0);
  const [popupTxt, setPopupTxt] = useState(''); // 시스템 팝업 텍스트
  const [system, setSystem]     = useState(false); // 시스템 팝업
 
  useEffect(()=> {
    if(inputValue){
      setValue(inputValue)
    }
  },[inputValue])

  const handleFloatChange = (e) => {
    const inputValue = e.target.value;
    const isValidFloat = /^(\d+)?(\.\d{0,2})?$/.test(inputValue);

    if (isValidFloat) {
      setValue(inputValue);
      setPointEdit(inputValue);
      
    } else {
      setPopupTxt('소수점 둘째 자리까지만 입력할 수 있습니다.');
      setSystem(true);
    }

    
  };



  return (
    <>
    <FloatNumInput
      type='number'
      name={inputName}
      value={value}
      onChange={handleFloatChange}
      disabled={inputDisabled}
    />

    {
      /**
       * 시스템 모달 팝업 창
       */
      system && (
        <SystemModal system={system} setSystem={setSystem}>
          {popupTxt}
        </SystemModal>
      )
    }
    </>
  );
};

const FloatNumInput = styled.input`
width:50px !important;
border: 1px solid #ddd;
margin: 0 2px 0 4px;
font-size: 18px;
outline:none;
text-align:right;
padding: 2px 4px;
`

export default FloatInput;