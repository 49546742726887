import React from 'react';
import styled from 'styled-components';
import PSGmodelPopup from '../../common/PSGmodelPopup';

const WebGameTit = ({data, state}) => {
  const storeInfo = data?.store;
  const pointInfo = data?.point; 
  const type      = data?.type;

  const pointStata0        = Number(data?.point).toLocaleString();
  const sumCashBonusStamp  = Number(pointInfo?.sumCashBonusStamp).toLocaleString();
  const cancelSttleStamp   = Number(pointInfo?.cancelSttleStamp).toLocaleString();

  return (
    <CashTitWrap> 
      <h1>
        {/* 스토어 이름 */}
        {storeInfo?.storeName} 

        {/* 스토어 earn */}
        <em className={`earn${
          storeInfo?.storeEarn === "통합형" || storeInfo?.storeEarn === "T"  
          ? "T" 
          : storeInfo?.storeEarn === "그룹형" || storeInfo?.storeEarn === "G" 
          ? "G" : "S" }`}>
          {storeInfo?.storeEarn === "통합형" || storeInfo?.storeEarn === "T" 
          ? "통합" 
          : storeInfo?.storeEarn === "그룹형" || storeInfo?.storeEarn === "G" 
          ? "그룹" : "단독" }
        </em>
      </h1>

      <h3>터치올<span>-</span><em>가위바위보 경품게임</em> 
        <PSGmodelPopup type={'g'} />
      </h3>
      <BonusSU className='ppp'>
        <p className={`state${state}`}>{state === 0 ? "적립대기" : state === 1 ?  "적립 완료" : "적립 취소"} </p>
       
      </BonusSU>
    </CashTitWrap>
  );
};

const CashTitWrap = styled.div`
h1 {
  color: #2860a7;
  font-weight: 800;
  font-size: 18px !important;

  margin-right: 4px;
  vertical-align: middle;

  em {
    color: #ffffff;
    padding: 1px 5px 1px 5px;
    font-size: 15px;
    border-radius: 3px;
    margin-left: 4px;

    &.earnT {
      background-color: #5d0ef5;
    }

    &.earnG {
      background-color: #8E3D82;
    }

    &.earnS {
      background-color: #7F7F7F;
    }
  }
}

h3 {
  margin-top: 26px;

  font-size: 16px;
  font-weight: 700;
  color: #222;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;

  color:#FF5F01 !important;

  span {
    color: #909090;
  }

  em {
    color: #2860A7;

  }

  img {
    width: 20px;
    height: 20px;
  }

}
`

const BonusSU = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

  }

  p {
    font-weight: 700;
    font-size: 14px;

    &.state0{
      color: #707070;

      strong{
        color: #707070;
      }
    }

    &.state1{
      color: #FD4D61;

      strong{
        color: #FD4D61;
      }
    }

    &.state2{
      color: #FB0E0E;

      strong{
        color: #FB0E0E;
      }
    }
    
    strong {
      font-weight: 700;
      font-size: 26px;
      margin-right: 4px;
    }
  }
`

export default WebGameTit;