import { atom } from 'recoil';

export const pointState = atom({
  key: 'pointState',
  default: null, // point 상태의 초기값 설정
});

export const phoneNumberState = atom({
  key: 'phoneNumberState',
  default: '', // phoneNumber 상태의 초기값 설정
});

export const UserInfoResult = atom({
  key: 'UserInfoResult',
  default:
  {
    isAdmin:''
    , agencyAt:''
    , managerAt:''
    , userAt:''
    , listStore:[]
    , name: ''
    , mobile: ''
    , birth:''
    , mngrLvl:''
    , userId:''
    , area: ''
    , gender: ''
    , parea:''
    , userLvl:''
    , createdAt:''
    , email: ''
    , bank:''
    , bankName:''
    , holder:''
    , cityCode:''
    , areaCode:''
    , dongCode:''
  }
});


export const DeviceInfoResult = atom({
  key: 'DeviceInfoResult',
  default: {
      deviceSnl      :''
    , obDeviceSeq    :''
    , vat            :''
    , transfer       :''
    , delay          :''
    , author         :''
    , pointUse       :''
    , stampUse       :''
    , rpsUse         :''
    , remnant        :''
    , receipt        :''
    , leaflet        :''
    , earn           :''
    , point          :''
    , stamp          :''
    , rps            :''
    , advancement    :''
    , amount         :''
    , amountRatio    :''
    , method         :''
    , methodRatio    :''
    , time           :''
    , stime          :''
    , etime          :''
    , applyDow       :''
  }
})

