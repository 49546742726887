import React from 'react';
import { useNavigate } from 'react-router';
import SubHead from '../../../components/TouchOll/SubHead';
import "../../../../assets/styles/style.scss"
import SwitchToggle from '../../../components/TouchOll/more/SwitchToggle';
import styled from 'styled-components';
import { handleLogout } from '../../../components/common/function/BearerToken';
const SettingPage = () => {
  const navigate = useNavigate();

  const handleLoginUserToken = () => {
    handleLogout();
    navigate('/');
  }

  
  return (
    <div id="sub_common_wrap_f">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        설정
      </SubHead>
      <section>
        <SettingList>
          <ul>
            <li>
              <span>버전정보</span>
              <p>V1.0 <em>업데이트</em></p>
            </li>
            <li>
              <span>PUSH 설정</span>
              <SwitchToggle />
            </li>
            <li><button onClick={handleLoginUserToken}>로그아웃</button></li>
          </ul>
        <p>회원 탈퇴는 홈페이지에서 가능합니다.</p>
        </SettingList>
      </section>
    </div>
  );
};

const SettingList = styled.div`
  width: 100%;

  li {
    height: 54px;
    border-bottom: 5px solid #f6f6f6;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 16px;

    span {
      font-size: 12px;
      color:#4D4D4D;
    }

    p {
      color:#7B8531;
      font-size: 14px;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 4px;

      em {
        font-size: 12px;
        color:#FF5F01;
        text-decoration: underline;
      }
    }

    &:last-child {
      justify-content: end;
    }

    button {
      color:#4D4D4D;
      text-decoration: underline;
      font-size: 12px;
    }
  }

  & > p {
    color:#8E8E8E;
    font-size :12px;
    text-align:right;
    margin-right: 16px;
    margin-top: 10px;
  }
`

export default SettingPage;