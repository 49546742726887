import React, { useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import styled from 'styled-components';
import { bottom_arr_select, checkoff, checkon } from '../../../../assets/images';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import { useRecoilState } from 'recoil';
import { UserInfoResult } from '../../../../recoil/recoilState';

const MyinfoSelectEdit = () => {

  const [userInfo, setUserInfo] = useRecoilState(UserInfoResult);
  const [selectInfo, setSelectInfo] = useState({
    ...userInfo
  })

  const handleSelectChange = (key, value) => {
    setSelectInfo({
      ...selectInfo,
      [key]: value
    });
  };


  return (
    <div id="sub_common_wrap">
      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        회원 선택 정보 수정
      </SubHead>

      <section>
        <JoinInputs>
          <div>
            <p>성별</p>
            <ul> 
              <li>
                <label>
                  <input 
                    type    ='radio' 
                    name    ='gender' 
                    value   ='F' 
                    checked ={selectInfo.gender === 'F'}  
                    onChange={(e)=>handleSelectChange('gender', e.target.value)} 
                  />
                  <em></em>
                  <p>여자</p>
                </label>
              </li>

              <li>
                <label>
                  <input 
                    type    ='radio' 
                    name    ='gender' 
                    value   ='M' 
                    checked ={selectInfo.gender === 'M'} 
                    onChange={(e)=>handleSelectChange('gender', e.target.value)} 
                  />
                  <em></em>
                  <p>남자</p>
                </label>
              </li>
              </ul>
          </div>
        </JoinInputs>

        <JoinInputs>
          <div>
            <p>주요 소재지</p>
            
            <ol>
              <li>
                <input 
                  type        ='text' 
                  name        ='loca1' 
                  placeholder ='시/도'
                  value       ={selectInfo.parea}
                  readOnly 
                /> 
                <em><img src={bottom_arr_select} /></em>
              </li>

              <li>
                <input 
                  type        ='text' 
                  name        ='loca2' 
                  placeholder ='구/군'
                  value       ={selectInfo.area}
                  readOnly
                /> 
                <em><img src={bottom_arr_select} /></em>
              </li>

              <li>
                <input 
                  type        ='text' 
                  name        ='loca3' 
                  placeholder ='읍/면/동'
                  readOnly
                /> 
                <em><img src={bottom_arr_select} /></em>
              </li>

              {/* <li><input 
                type='text' 
                name='loca4' 
                readOnly 
                placeholder='동/읍/
              면/리'/> 
                <em><img src={bottom_arr_select} /></em>
              </li> */}
            </ol>
          </div>
        </JoinInputs>

        <JoinInputs>
          <div>
            <p>이메일</p>
            <input 
              type        ='text' 
              name        ='email' 
              placeholder ='이메일 주소'  
              value       ={selectInfo.email}
              onChange    ={(e)=>handleSelectChange('email', e.target.value)}
            />
          </div>
        </JoinInputs>

        <AgreeBtn>
          <BtnBottom>수정하기</BtnBottom>
        </AgreeBtn>
      </section>
      
    </div>
  );
};

const JoinInputs = styled.div`
width: calc(100% - 32px);
padding: 0 16px;
margin-top: 32px;

&>div > p {
  font-size: 16px;
  color: #222;
  font-weight: 700;
  margin-bottom: 12px;
}

ul{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  input[type='radio'] {
    display: none;
  }

  /* 체크박스 커스텀 이미지 적용 */
  input[type='radio'] + em {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(${checkoff});
    background-size: cover;
  }

  /* 체크된 상태의 이미지 적용 */
  input[type='radio']:checked + em {
    background-image: url(${checkon});
  }

  li {
    width: 100%;
    background:#fff;
    border-radius: 8px;
    padding: 12px 16px;

    label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      &:has(input[type='radio']:checked ){
        background:#fff;

        p {
          color:#FE7A36;
          font-weight:bold;
        }
      }
    }
    
  }
}

input[name='email'] {
  width: calc(100% - 32px);

  font-size: 16px;
  background:#fff;
  padding: 12px 16px;

  border-radius: 8px;
}

ol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  li {
    width: calc(50% - 4px - 32px);
    height: 16px;

    border-radius: 8px;
    background:#fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 16px;

    input {
      font-size: 16px;
    }
  }
}
`
const AgreeBtn = styled.div`
  margin-top: 16px;

  width: calc(100% - 32px);
  padding: 20px 16px 40px 16px;

`;
export default MyinfoSelectEdit;