import React, { useState, useEffect } from "react";  
import axios from "axios";

import Header from "../common/Header";
import CashStanby from "../../components/Biapp/CashStanby";
import StampStanby from "../../components/Biapp/StampStanby";
import GameStanby from "../../components/Biapp/GameStanby";
import CashGameStanby from "../../components/Biapp/CashGameStanby";
import StampGameStanby from "../../components/Biapp/StampGameStanby";
import Receipt from "../../components/Biapp/Receipt";
import Ad from "../../components/Biapp/Ad";
import { useLocation } from "react-router-dom";

function PointSaveRequestScreen() {
  
  const location = useLocation();  
  const { data } = location.state || {};
	const [error, setError] = useState(null);  
  const dataInfo = data?.data;
  const storeInfo = data?.data?.store;  
  
  console.log("PointSaveRequestScreen  data :: ", data);
  console.log("PointSaveRequestScreen  storeInfo :: ", storeInfo);
  
  return (
    <div id="sub_common_wrap">
      <Header />
      {storeInfo && (
        <>
          {
            storeInfo.pointUse === "Y" &&
            <CashStanby data={dataInfo} rpsUse={storeInfo.rpsUse} />
          }

          {
            storeInfo.stampUse === "Y" &&
            <StampStanby data={dataInfo} rpsUse={storeInfo.rpsUse} />
          }

          {
            storeInfo.rpsUse === "Y" && 
            <GameStanby data={dataInfo} />
          }

          {/* {storeInfo.pointUse === "Y" &&
          storeInfo.stampUse === "N" &&
          storeInfo.rpsUse === "Y" && (
            <>
              <CashGameStanby data={dataInfo} />
            </>
          )}

          {storeInfo.pointUse === "N" &&
          storeInfo.stampUse === "Y" &&
          storeInfo.rpsUse === "Y" && (
            <>
              <StampGameStanby data={dataInfo} />
            </>
          )} */}
          
        </>
      )}
      
      <Receipt data={dataInfo} />
      <Ad />
    </div>
  );
}

export default PointSaveRequestScreen;
