import React from 'react';
import { tail_spin } from '../../../assets/images';
import ModalPopup from './ModalPopup';

const LoadingBar = () => {
  return (
    <ModalPopup>
      <img src={tail_spin} alt='loading' />
    </ModalPopup>
  );
};

export default LoadingBar;