import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  checkoff
  , checkon2
} from "../../../assets/images"
import FloatInput from './FloatInput';
import AmountInput from './AmountInput';

const ServiceCheck = ({ 
    pointValue
  , bonusEdit
  , setBonusEdit
  , setPointEdit
  , type
  , isChecked
  , isOpen
  , checkList
  , stypeStemp
  , stypeRps
  , setStypeStemp
  , setStypeRps
  , children }) => {
  const [pointChecked, setPointChecked] = useState(false);
  const [stampChecked, setStampChecked] = useState(false);
  const [gameChecked, setGameChecked] = useState(false);

  useEffect(() => {
    if(bonusEdit){
      setPointChecked(bonusEdit.pointUse === 'Y' ? true : false);
      setStampChecked(bonusEdit.stampUse === 'Y' ? true : false);
      setGameChecked(bonusEdit.rpsUse === 'Y' ? true : false);
    }
  }, [bonusEdit])
  /**
   * 보너스 종류가 변경될 때마다 유형 Check 비활성화
   * 단, 처음에는 초기값 세팅을 위해 실행하지 않게 하였음. 
   */
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      setPointChecked(false);
      setStampChecked(false);
      setGameChecked(false);
    }
  }, [isChecked]);


  // /**
  //  * 보너스 변경하기
  //  * 포인트, 스탬프, 경품 사용여부
  //  */
  useEffect(() => {
    setBonusEdit((prevStoreInfo) => ({
      ...prevStoreInfo
      , pointUse: pointChecked  ? "Y" : "N"
      , stampUse: stampChecked  ? "Y" : "N"
      , rpsUse  : gameChecked   ? "Y" : "N"
    }));
  }, [ pointChecked, stampChecked, gameChecked, setBonusEdit]);


  return (
    <BonusCon className={`bcccc ${isOpen ? "open_content" : ""}`}>
      <ul>
        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={pointChecked}
              disabled={!checkList}
              onChange={(e) => setPointChecked(e.target.checked)} />
            <span></span>
            포인트
          </Sublabel>

          <p>
            <FloatInput 
              setPointEdit={setPointEdit}
              inputValue={pointValue}
              inputName={'point'}
              inputDisabled={!pointChecked}
            />
            %
          </p>
        </li>

        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={stampChecked}
              disabled={!checkList}
              onChange={(e) => setStampChecked(e.target.checked)}
            />
            <span></span>
            스탬프
          </Sublabel>
       
          {
            type === "S" ?
            <p>기준금액 <AmountInput 
            value={stypeStemp} 
            setValue={setStypeStemp} 
            disabled={!stampChecked} />당 1개</p> 
            :  
            <p>기준금액당 1개</p>
          }

        </li>

        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={gameChecked}
              disabled={!checkList}
              onChange={(e) => setGameChecked(e.target.checked)}
            />
            <span></span>
            가위바위보
          </Sublabel>

            {
              type === "S" ?
              <TypeS>기본승점 없음<br /> 
              <span>
                기준금액 
                <AmountInput 
                value={stypeRps} 
                setValue={setStypeRps} 
                disabled={!gameChecked}  
                />
                당 1게임 승점
              </span>
              </TypeS> 
              :  
              <p>기본승점 없음<br />기준금액당 1게임 승점</p>
            }
            
          
        </li>
        <li>
          {children}
        </li>
      </ul>
    </BonusCon>
  );
};

const Sublabel = styled.label`
input[type='checkbox'] {
  display: none;
}

/* 체크박스 커스텀 이미지 적용 */
input[type='checkbox'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${checkoff});
  background-size: cover;
}

/* 체크된 상태의 이미지 적용 */
input[type='checkbox']:checked + span {
  background-image: url(${checkon2});
}
`
const BonusCon = styled.div`

  transition: all .3s linear;
  max-height: 0;
  overflow: hidden;

  &.open_content {
    max-height: 500px;
    margin-bottom: 12px;

    input {
      
    }
  }


  ul {
    padding: 16px;
  background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;

    li {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: start;

      input {
        border: 1px solid #ddd;
        width: 80px;
        height: 16px;
      }

      label {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;
      }

      p {
        text-align: right;
        line-height: 20px;
        font-size: 16px;

        display: flex;
        justify-content: end;
        align-items: center;
        gap: 4px;
      }
    }
  }
`
const TypeS = styled.p`
  flex-direction:column;
  align-items: flex-end !important;
`

export default ServiceCheck;