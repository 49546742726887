import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  p_icon 
} from "../../../assets/images";
import ModalPopup from "../common/ModalPopup";
import CashQPopup from "../TouchOll/CashQPopup";
import WebCashTit from "./common/WebCashTit";


function CashStanby({ data, rpsUse }) {
  // useEffect(() => {
  //   console.log("Received data:", data);
  // }, [data]);

  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'>
        {/* 현금 타이틀 */}
        <WebCashTit data={data} state={0} />
        {
          rpsUse === 'Y' && <h6>가위바위보 미진행시 적립불가</h6>
        }
      </div>
      
    </BonusSUWrap>      
  );
}

const BonusSUWrap = styled.div`
  width: calc(100% - 32px);
  margin-top:20px;
  padding: 0 16px;
  
  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
    
    h6 {
      color: #E95513;  
      font-weight: 500;
      margin-top: 5px;
      font-size: 18px;
      text-align:right;
    }
  }
`


export default CashStanby;
