import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { UserInfoResult } from "../../../../recoil/recoilState";
import { close_icon } from "../../../../assets/images"
import { formatPhoneNumber } from "../../../components/common/function/FormatNumber";
import { useBodyScrollLock } from "../../../components/common/function/BodyModalScroll";

const Menu = ({ className, isMenu, setIsMenu }) => {
  const userInfo = useRecoilValue(UserInfoResult);

  /**
   * 메뉴 닫기
   */
  const { openScroll } = useBodyScrollLock();
  const handleMenu = () => {
    setIsMenu(!isMenu)
    openScroll()
  }

  return (
    <>
      <MenuWrap className={className}>
        <button onClick={handleMenu}><img src={close_icon} alt='닫기' /></button>

        <MenuTit>
          <h1>{userInfo.name}</h1>
          <p>{formatPhoneNumber(userInfo.mobile)}</p>
        </MenuTit>


        <ul>
          <li>
            <p>소개</p>
            <ol>
              <li>회사 소개</li>
              <li>터치올 소개</li>
            </ol>
          </li>

          <li>
            <p>가맹점 찾기</p>
          </li>

          <li>
            <p><a href='/app/useinfo_pay'>MY 보너스 사용·PAY</a></p>
          </li>

          <li>
            <p>MY 터치올</p>
            <ol>
              <li><a href="/app/mypage_view">MY 정보조회, 수정</a></li>
              <li><a href="/app/useinfo">MY 이용내역, 후기, 평가</a></li>
              <li><a href="/app/mygrade">MY 학점, 학년</a></li>
              <li><a href="/app/board/myhot">MY HOT 소식</a></li>
            </ol>
          </li>

          <li>
            <p><a href="/app/board/event">이벤트</a></p>
          </li>

          <li>
            <p><a href="/app/board/notice">공지사항</a></p>
          </li>

          <li>
            <p><a href="/app/board/qa">자주 묻는 질문</a></p>
          </li>

        </ul>

        <CompanyList>
          <li>가맹점</li>
          <li>지원사</li>
          <li>파트너</li>
        </CompanyList>
      </MenuWrap>

      <BgMenu className={isMenu && 'open'} onClick={handleMenu}></BgMenu>
    </>
  );
};

const MenuTit = styled.div`
padding-top: 48px;
border-bottom: 1px solid #ddd;
padding-bottom: 24px;

h1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

p {
 font-size: 14px;
 color:#888;
}
`

const BgMenu = styled.div`
display:none;
width: 100vw;
height: 100vh;
z-index:999;
background:rgba(0,0,0,0.5);
position:fixed;
top:0;

&.open {
  display:block;
}
`

const MenuWrap = styled.div`
width: calc(100vw - 40px);
height: -webkit-fill-available;
position:fixed; 
right:-100vw;
top:0;
background:#fff;
z-index: 9999;
overflow: auto;

padding: 40px 16px 0px 16px;

transition: all .2s linear;

&.open_menu {
  right:-20vw;
}

button {
  position: absolute;
  left: 16px;
  top: 32px;
}

ul{
  margin-top: 32px;
  border-bottom: 1px solid #ddd;
  
  &>li{
    margin-bottom: 32px;

    p{
      font-size: 16px;
      font-weight: 700;
    }

    ol {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 8px;
      margin-left: 12px;
      margin-top: 12px;

      li{
        font-size: 14px;
        font-weight: 500;
        color:#555;
      }
    }
  }
}
`

const CompanyList = styled.ol`
  margin-top: 24px;

  font-size: 14px;
  color:#555;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 24px;

  padding-bottom :40px;
`

export default Menu;