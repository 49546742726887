import React, { useState } from 'react';
import styled from 'styled-components';

import EarnIcon from '../EarnIcon';
import RpsStand from './RpsStand';
import PSGmodelPopup from '../PSGmodelPopup';

const PointStandScreen = ({
    storeInfo
  , data
  , point
  , kind
}) => {
  /**
   * storeInfo : 스토어 정보
   * pointInfo : 포인트 정보
   * type : 포인트 적립 | 적립 취소 | 포인트 사용 | 사용 취소
   */
  const earnType   = storeInfo?.storeEarn || 'T'; // earn 유형
  const storeName  = storeInfo?.storeName || "가맹점 이름"
  const sttlePoint = Number(data?.point).toLocaleString(); // 최종 포인트

  return (
    <>
    <PointWrap>
      <h1>
        {storeName}
        <EarnIcon earn={earnType} />
      </h1>

      <h3>
        터치올<em>-</em>
        <span>{kind === "G" ? "수동적립" : "현금"}</span>
        <PSGmodelPopup type={'p'} />
      </h3>

      <h2 className="type0">
        <p>입금·적립대기</p>

        <p>
          <strong>
            {sttlePoint}
          </strong>원
        </p>
      </h2>
      
      <h6>가위바위보 미진행시 적립불가</h6>
    </PointWrap>

    <PointWrap>
      <RpsStand data={data} point={point} />
    </PointWrap>
    
    </>
  );
};

const PointWrap = styled.div`
  width: calc(100% - 36px);
  padding: 16px;
  background: #fff;
  border: 2px solid #CBCBCB;
  border-radius: 12px;

  .type0{
    color: #707070 !important;

    em, p, strong {
      color: #707070 !important;
    }
  }

  // 가게 상호
  h1{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  // 터치올 현금
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FE7A36;

    margin-top: 24px;

    display: flex;
    justify-content: start;
    align-items: center;

    em {
      color: #909090;
    }

    span {
      color: #7B8531;

      display: flex;
      justify-content: start;
      align-items: center;
      margin-right: 4px;

      img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
  }

  // 입금 적립 상태
  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 32px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-size: 30px;
    }
  }

  // 포인트 사용, 취소시 
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;

    em {
      vertical-align: middle;
    }
  }

 h6 {
    color: #E95513;  
    font-weight: 500;
    margin-top: 5px;
    font-size: 18px;
    text-align:right;
  }

`


export default PointStandScreen;