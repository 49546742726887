import React, { useState } from 'react';
import {bottom_arr_select} from "../../../assets/images"
import styled from 'styled-components';

const SectionSelect = ({children, SectionTit}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  }
  return (
    <SectionSelectWrap>
      <TitleH onClick={handleIsOpen}>
        <h1>{SectionTit}</h1>
        <span className={`${isOpen ? "open_svg" : ""}`}>
          <img src={bottom_arr_select} />
        </span>
      </TitleH>
      
      <SectionCon className={`${isOpen ? "open_content" : ""}`}>
        {children}
      </SectionCon>
    </SectionSelectWrap>
  );
};

const SectionSelectWrap = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px;
`

const TitleH = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 12px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #222;

  span {
    transition: all .3s linear;
    transform: rotate(0deg);
    
    &.open_svg {
      transform: rotate(-180deg);
    }
  }
`

const SectionCon = styled.div`
  transition: all .2s ease-in-out;
  max-height: 0;
  overflow: hidden;

  &.open_content {
    max-height: 300vh;
  }
`

export default SectionSelect;