import React, { useState } from 'react';
import styled from 'styled-components';
import SystemModal from '../common/SystemModal';

const VipFloatInput = ({setPointEdit, inputValue, inputName, inputDisabled }) => {
  const [popupTxt, setPopupTxt]     = useState(''); // 시스템 팝업 텍스트
  const [system, setSystem]         = useState(false); // 시스템 팝업
 

  const handleFloatChange = (e) => {
    const inputValue = e.target.value;

    // 입력된 값이 숫자이거나 빈 문자열인 경우에만 값을 업데이트
    if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
      setPointEdit(inputValue);
    } else {
      setPopupTxt('소수점 둘째 자리까지만 입력할 수 있습니다.');
      setSystem(true);
    }
  };

  return (
    <>
    <FloatNumInput
        type='number'
        name={inputName}
        value={inputValue}
        onChange={handleFloatChange}
        disabled={inputDisabled}
      />

    {
      /**
       * 시스템 모달 팝업 창
       */
      system && (
        <SystemModal system={system} setSystem={setSystem}>
          {popupTxt}
        </SystemModal>
      )
    }
    </>
  );
};

const FloatNumInput = styled.input`
width: 40px !important;
border: 1px solid #ddd;
margin: 0 2px 0 4px;
font-size: 18px;
outline:none;
text-align:right;
padding: 0px 4px;
`
export default VipFloatInput;