import React from 'react';
import { ban1, ban2, ban3, ban4 } from "../../../../assets/images"
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const MainSlider = () => {

  return (
    <MainBanSlider 
    pagination={{ clickable: true }}
    autoplay={false}
    loop={true}
    modules={[Pagination, Autoplay]}
    >
      <SwiperSlide className='main_wrap_slide'><img src={ban3} alt='배너' /></SwiperSlide>
      <SwiperSlide className='main_wrap_slide'><img src={ban4} alt='배너' /></SwiperSlide>
      <SwiperSlide className='main_wrap_slide'><iframe width="auto" height="100%" src="https://www.youtube.com/embed/lXEZAc3eglk" title="롯데마트 한우데이, 한참은 더 따듯할 우리의 날들" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe></SwiperSlide>
    </MainBanSlider>
  );
};

const MainBanSlider = styled(Swiper)`
max-width:600px;
margin: 8px auto 32px auto;
position:relative;
overflow:hidden;

.main_wrap_slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background:#000;

  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.swiper-pagination-bullet-active{
  background: #fff !important;
}

.swiper-pagination-bullet {
  background:rgba(255,255,255,0.8);
}

`
export default MainSlider;