import React from 'react';
import styled from 'styled-components';

const SkeletonUi = () => {
  return (
    <SkeletonWrap>
      <div>
        <ul>
          <li className='skeleton_loading'></li>
          <li className='skeleton_loading'></li>
          <li className='skeleton_loading'></li>
          <li className='skeleton_loading'></li>
        </ul>
      </div>

      <CommonBtn className='skeleton_loading'></CommonBtn>
    </SkeletonWrap>
  );
};

const SkeletonWrap = styled.div`
width: calc(100% - 32px);
padding: 20px 16px;
border-bottom:1px solid #ddd;

display: flex;
justify-content: space-between;
align-items: flex-start;
gap: 40px;

&>div {
  width: 100%;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  width: 100%;

  li {
    width: 100%;
    
    background:#eee;

    &:first-child {
      width: 80%;
      height: 16px;
    }

    &:nth-child(2) {
      height: 19px;
    }

    &:nth-child(3) {
      width: 50px;
      height: 17px;
    }

    &:nth-child(4) {
      width: 90%;
      height: 17px;
    }
  }
}

.skeleton_loading {
  background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
  border-radius: 1rem;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load 1s infinite;
}

@keyframes load {
  100% {
      background-position: -100% 0;
  }
}
`

const CommonBtn = styled.div`
max-width: 92px;
height: 84px;

border-radius: 8px;

background:#eee;
display: block;
`
export default SkeletonUi;