import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';

const MoreList = ({link, target, icon, nav}) => {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'icon'}>
      <MoreMenu icon={icon} href={link} target={ target === 'new' ? "_blank" : "_self"}>
        <p>{nav}</p>
      </MoreMenu>
    </StyleSheetManager>
  );
};

const MoreMenu = styled.a`
width: calc(100% - 42px);
height: calc(100px - 34px);
padding: 16px 20px;
background: url(${(props) => props.icon}) no-repeat right 20px bottom 20px #f9f9f9;
border:1px solid #F2F2F2;
border-radius: 8px;

p {
  font-weight: bold;
  font-size: 14px;
}
` 
export default MoreList;