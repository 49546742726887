 /**
   * 
   * @param {*} phoneNumber : 전화번호
   * @returns 010-0000-0000 형식의 전화번호
   */
 export const formatPhoneNumber = (phoneNumber) => {
  // 입력된 전화번호에서 숫자만 추출
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  // 정규식을 사용하여 전화번호 형식에 맞게 변환
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  // 형식에 맞지 않는 경우 원본 전화번호 반환
  return phoneNumber;
};


/**
   * 
   * @param {*} birthday : 생년월일
   * @returns : 0000.00.00 형식의 생년월일
   */
export const formatBirth = (birthday) => {
  // 입력된 생년월일 문자열에서 연, 월, 일 추출
  const year = birthday.substr(0, 4);
  const month = birthday.substr(4, 2);
  const day = birthday.substr(6, 2);

  // 원하는 형식으로 연결하여 반환
  const formattedBirthday = `${year}.${month}.${day}`;
  return formattedBirthday;
};


/**
 * 숫자를 만원, 천원, 원 단위로 표시하는 함수
 * @param {*} amount 금액
 * @returns 
 */
export const formatCurrency = (amount) => {
  if (amount >= 10000) {
    return `${Math.floor(amount / 10000)}만원`;
  } else if (amount >= 1000) {
    return `${Math.floor(amount / 1000)}천원`;
  } else {
    return `${amount}원`;
  }
}


/**
   * 시간-날짜 0000.00.00으로 형식변경
   * @param {*} date 
   * @returns 
   */
export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 10미만은 앞에 0추가
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
};

/**
 * 1200, 2351 와 같이 온 시간을 hour, miute으로 변경
 * @param {*} timeStr 
 * @returns 
 */
export const parseTime = (timeStr) => {
  if (typeof timeStr === 'string' && timeStr.length === 4) {
    const hour = parseInt(timeStr.slice(0, 2));
    const minute = parseInt(timeStr.slice(2, 4));
    return { hour, minute };
  }
  return null;
};


// 입력값을 쉼표로 구분된 형식으로 형식화하는 함수
export const formattoLocaleString = (value) => {
  if (value !== undefined) {
    // 숫자로 변환 후 쉼표로 구분된 형식으로 변환
    return Number(value).toLocaleString();
  } else {
    return ''; // 값이 없는 경우 빈 문자열 반환
  }
};