import React from 'react';
import { useLocation } from 'react-router';
import ApprovalPop from './ApprovalPop';
import ApprovalSop from './ApprovalSop';
import styled from 'styled-components';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';

const OnlyType24 = () => {
  /**
   * type 2 (사용) , type 4 (사용취소) 일 때 페이지
   */
  const location = useLocation();  
  const data = location.state.data;
  const kind = location.state.kind;

  return (
    <div id="sub_common_wrap">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>


      <ApprovalMain>
        {
          kind === 'P' ?
          <ApprovalPop
            approvalData={data} 
            earn        ={data?.store?.storeEarn} 
            loca        = {'main'}
          /> :
          <ApprovalSop 
            approvalData={data} 
            earn        ={data?.store?.storeEarn} 
            loca        = {'main'}
          />
        
        }
      </ApprovalMain>
    </div>
  );
};

const ApprovalMain = styled.div`
width: 100%;
height: 100vh;
 
display: flex;
justify-content: center;
align-items: center;
`
export default OnlyType24;