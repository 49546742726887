import React from 'react';
import { logo, p_icon } from '../../../../assets/images';
import EarnIcon from '../../../components/common/EarnIcon';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ApprovalPop = ({
  approvalData
  , earn
  , setApprovalPage
  , loca
}) => {
  const navigate = useNavigate();
  const handleApprovalPage = () => {
    setApprovalPage(false)
    navigate("/common/pay_result", 
    {state: {approval: approvalData?.approval, type: approvalData?.type, sid:approvalData?.store?.sid}, replace: true})
  }

  const receiptInfo = approvalData?.receipt || approvalData?.recipt
  

  return (
    <ApproWrap className={`type${approvalData?.type}`}>
      <h3><img src={logo} alt='터치올' /></h3>
      <h4>터치올 현금 <img src={p_icon} alt='p' /><EarnIcon earn={earn} /></h4>
      <h1>{
      loca !== "main" ? 
      Number(approvalData?.point).toLocaleString() 
      : Number(receiptInfo?.stlmPrice).toLocaleString()
      }원</h1>
      <h2><em className='pay'>Pay</em>ㆍ사용 {approvalData?.type === "2" ? '완료' : '취소!'}</h2>

      <div>
        {approvalData?.type === "2" ? (<span><em className='pay'>Pay</em>와 동시</span>) : ''}
        <p>{
        loca !== "main" ? 
        Number(approvalData?.sumCashBonusPoint).toLocaleString() :  
        approvalData?.type === "4" ? 
        Number(approvalData?.point?.cancelSumCashBonusPoint).toLocaleString() :
        Number(approvalData?.point?.sumCashBonusPoint).toLocaleString()
        }원
        <img src={p_icon} alt='p' />입금.적립{approvalData?.type === "2" ? '완료' : '취소'}</p>
      </div>

      <ul>
        <li>
          <span>{approvalData?.type === "2" ? '' : '취소 '}승인번호</span>                           
        : {loca !== "main" ? approvalData?.approval : receiptInfo?.aprvNo}
        </li>

        <li>
          <span><em className='pay'>Pay</em>{approvalData?.type === "2" ? '' : '취소 '} 일시</span> 
        : {receiptInfo?.stlmDt}
        </li>

        <li><span>상호명</span>                            
        : {approvalData?.store?.storeName}</li>

        <li><span>사업자번호</span>                         
        : {approvalData?.store?.brno}</li>
      </ul>

      {
        loca !== "main" && <button onClick={handleApprovalPage}>확 인</button>
      }
      
      
    </ApproWrap>
  );
};

const ApproWrap = styled.div`
  // 사용
  &.type2 {
    h1, h2, h2 em, p {color:#7B8531;}
  }

  // 취소
  &.type4 {
    h1, h2, h2 em, p {color:#FB0E0E;}
  }

  width: 90vw;
  max-width: 320px;
  background:#fff;
  border-radius: 12px;

  text-align:center;
  padding: 28px 0 0 0;
  overflow: hidden;

  h3 {
    img {
      height: 25px;
    }
  }

  h4 {
    color:#7B8531;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    
    font-size: 16px;
    font-weight:bold;
    margin-top: 28px;

    img {
      height: 16px;
      margin-right: 8px;
    }
  }

  h1 {
    font-size: 26px;
    font-weight:bold;
    margin-top: 32px;
  }

  h2 {
    margin-top: 8px;
    font-weight:bold;
  }

  &>div {
    padding: 0 16px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;

        color:#5E6162; 
        margin-top: 32px;

        em {
          color:#5E6162; 
        }
        font-weight:bold;
      }

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        padding: 12px 0;
        border:4px solid #FFE4D7;
        border-radius: 8px;

        font-size: 18px;
        font-weight:bold;
        margin-top: 8px;
        
        img {
          height:20px;
          margin-right: 8px;
        }
      }
  }

  ul {
    background:#f0f0f0;
    text-align:left;

    padding: 16px 32px;
    margin-top: 32px;

    li {
      color:#4D4D4D;
      line-height:20px;
      font-size: 14px;

      em {color:#4D4D4D;}

      span {
        font-weight:bold;
      }
    }

  }

  button {
    background:#FE7A36;
    width: 100%;
    height:60px;
    font-size: 20px;
    font-weight:bold;
    color:#fff;
  }
`
export default ApprovalPop;