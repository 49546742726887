import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { search } from '../../../../assets/images';
import SearchStorePopup from './SearchStorePopup';
import axios from 'axios';

const StoreSearch = ({
      mapType
    , paySearch
    , setPaySearch
    , handleSearchPay
}) => {
  const [isOpen , setIsOpen ] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleDetailPopup = () => {
    setIsOpen(!isOpen);
    setPaySearch(prev => ({
      ...prev,
      sid: "",
      earn: "",
      cityCode: "",
      areaCode: "",
      dongCode: "",
      sectors: "",
      storeGrp: "",
      storeLevel: "",
      storeName: "",
      rating: "",    
      kind: ""
    }));
  }

  const handleDetailPopup2 = () => {
    setIsOpen2(!isOpen2);
    setPaySearch(prev => ({
      ...prev,
      sid: "",
      earn: "",
      cityCode: "",
      areaCode: "",
      dongCode: "",
      sectors: "",
      storeGrp: "",
      storeLevel: "",
      storeName: "",
      rating: "",    
      kind: ""
    }));
  }

  /**
   * 검색 조건 불러오기
   */
  const [searchData, setSearchData] = useState([]);
  const [codeList, setCodeList] = useState([]);

  const getCityAddress = async () => {
    const data = {
      "type" : "FIND"
    }
    try{
      const response = await axios.post('/api/codeMngr/getSearchCodeList', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('검색 pay ::' , response?.data?.data?.message);
      setSearchData(response?.data?.data?.message);

      /**
       * pcode 분류
       */
      const groupedData = response?.data?.data?.message.reduce((acc, item) => {
        if (!acc[item.pcode]) {
          acc[item.pcode] = [];
        }
        acc[item.pcode].push(item);
        return acc;
      }, {});

      console.log('groupedData', groupedData)
      setCodeList(groupedData);

    } catch (error){

    }
  }
  
  useEffect(()=>{
    getCityAddress();
  },[])

  const handlesearchList = (name, value) => {
    setPaySearch(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
     <SearchWrap className={`map_search${mapType}`}>
      {
        mapType === 2 &&
        <h3>주요 소재지 <em>서울, 금천</em> 중심 &nbsp; | &nbsp; <span>내 위치 주변 찾기</span></h3>
      }
        
        <ul>
          <li><button onClick={(handleDetailPopup2)}>가맹점 이름 검색 <img src={search} /></button></li>
          <li><button onClick={handleDetailPopup}>상세 검색 <img src={search} /></button></li>
        </ul>
      </SearchWrap> 

      {
        isOpen &&
        <SearchStorePopup 
          setIsOpen       ={setIsOpen} 
          paySearch       ={paySearch}
          setPaySearch    ={setPaySearch}
          codeList        ={codeList}
          handleSearchPay ={handleSearchPay} 
          handlesearchList={handlesearchList}
        />
      }
      {
        isOpen2 &&
        <SearchStorePopup 
          nameIs          ={true} 
          setIsOpen       ={setIsOpen2} 
          paySearch       ={paySearch}
          setPaySearch    ={setPaySearch}
          codeList        ={codeList}
          handleSearchPay ={handleSearchPay} 
          handlesearchList={handlesearchList}
        />
      }
    </>
  );
};

const SearchWrap = styled.div`
position: absolute;
top:0;
left: 0;

width: calc(100% - 32px);
padding: 0 16px;
margin-top: 12px;

z-index: 7;

&.map_search2 {
  width: calc(100% - 64px);
  padding: 16px;

  border-radius: 8px;
  background:#fff;
  left: 16px;

  box-shadow: 0px 4px 14px -7px #5d5d5d;

  h3 {
    color:#4D4d4d;
    font-size: 14px;
    margin-bottom: 8px;

    em {
      color:#2F80ED;
      border-bottom: 1px solid #2F80ED;
      display: inline-block;
      padding-bottom: 2px;
    }

    span {
      color:#2F80ED;
      border-bottom: 1px solid #2F80ED;
      display: inline-block;
      padding-bottom: 2px;
    }
  }
}

ul {
  display:flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  li {
    width: 100%;
    height: 32px;
    border-radius: 8px;

    background:#E1F0F4;

    button {
      width: 100%;
      height: 100%;

      display:flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      font-size: 14px;
      padding: 0 16px;
    }
  }
}
`

export default StoreSearch;