import React, { useState } from "react";
import styled from "styled-components";

import {
  close_button
  , stamp_description
  , stamp_logo
  , question_btn
} from "../../../assets/images";
import ModalPopup from "../common/ModalPopup";
import WebStampTit from "./common/WebStampTit";


function StampStanby({ data }) {
  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'>
        <WebStampTit data={data} state={0} />
      </div>
    </BonusSUWrap>

  );
}

const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
  padding: 0 16px;

  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

  }

`

export default StampStanby;
