import React from 'react';
import { g_icon, pointicon, stampicon } from '../../../assets/images';

const PSGicon = ({
  type
}) => {
  /**
   * type : p | s | g
   */

  return (
    <>
      <img
        src={
          type === 'p' ? pointicon :
          type === 's' ? stampicon :
          g_icon
        }
        style={{width: '20px'}}

        alt={
          type === 'p' ? '포인트' :
          type === 's' ? '스탬프' :
          '경품승점'
        }
      />
    </>
  );
};

export default PSGicon;