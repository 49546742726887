import React, { useState } from 'react';
import styled from 'styled-components';
import SubHead from '../../../components/TouchOll/SubHead';
import { bottom_arr, checkon, checkoff, logo_txt } from '../../../../assets/images';
import BtnBottom from '../../../components/TouchOll/BtnBottom';
import BottomSheet from '../../../components/TouchOll/BottomSheet';
import { useNavigate } from 'react-router-dom';
import PointIcon from '../../../components/TouchOll/join/PointIcon';
import StampIcon from '../../../components/TouchOll/join/StampIcon';

const JoinAgree = () => {
  const [termOpen, setTermOpen]     = useState(false);

  // 동의 관련 
  const [allAgreed, setAllAgreed]   = useState(true);
  const [agreements, setAgreements] = useState({
      useStplat       : true  //이용약관
    , indvdlInfo      : true  //개인정보 수집
    , gps             : true  // 위치기반
    , eventAdvrtsNtcn : true  //이벤트
  });

  const handleAllAgreedChange = event => {
    const checked = event.target.checked;

    setAllAgreed(checked);
    setAgreements(prevAgreements => ({
        useStplat       : checked
      , indvdlInfo      : checked
      , gps             : checked
      , eventAdvrtsNtcn : checked
    }));
  };

  const handleAgreementChange = event => {
    const { name, checked } = event.target;
    setAgreements(prevAgreements => ({
      ...prevAgreements
      , [name]: checked
    }));
  
    const anyAgreementFalse = Object.values({ ...agreements, [name]: checked }).some(agreed => agreed === false);
    setAllAgreed(!anyAgreementFalse);
  };

  const navigate = useNavigate();

  const handleNextClick = () => {
    const agreementsResult = {
        useStplat       : agreements.useStplat        ? "Y" : "N"
      , indvdlInfo      : agreements.indvdlInfo       ? "Y" : "N"
      , gps             : agreements.gps              ? "Y" : "N"
      , eventAdvrtsNtcn : agreements.eventAdvrtsNtcn  ? "Y" : "N"
    };
  
    if (agreements.useStplat && agreements.indvdlInfo && agreements.gps) {
      navigate(
          "/app/join"
        , { state: { agreements: agreementsResult } }
        );
    }

  };


  return (
    <div id="sub_common_wrap">
      <SubHead  SubBgColor={'#f5f5f5'} bottomLine={true}>
        One 클릭 <img src={logo_txt} />가입
      </SubHead>

      <section>
        <JoinTop>
          <h3>약관동의 가입 전 TIP<em className='it_txt'>!</em></h3>

          <ul>
            <li>
              <div>
                <strong>아래 초간편 가입 약관 동의 직후부터</strong>
              </div>
            </li>

            <li>
              <div>
                폰 No입력, 앱 호출, 보너스 제공 요청 등 <br />
                절차 일체 없이, 가맹점 단말에<br /><br />
                <strong>그냥 초간편 폰 터치만으로</strong>  <br />
                <PointIcon /> 통장입금 확인, <StampIcon /> 등 보너스<br />
                확인, <em className='pay'>Pay</em> 세상이 시작됩니다<em className='it_txt'>!</em>
              </div>
            </li>
          </ul>
        </JoinTop>

        <AgreeBtn>
          <BtnBottom onClick={() => setTermOpen(true)}>동의</BtnBottom>
        </AgreeBtn>

        <BottomSheet 
        termOpen  ={termOpen} 
        onClose   ={() => setTermOpen(false)}
        >
          <AgreeWrap>
            <Agreetit>
              <h1>약관동의</h1>
              <p>필수 항목 및 선택 항목 약관에 동의해주세요.</p>
            </Agreetit>

            <TotalInput>
              전체동의
              <input
                type    ="checkbox"
                checked ={allAgreed}
                onChange={handleAllAgreedChange}
              />
              <span></span>
            </TotalInput>

            <ul>
              <li>
                <label>
                  <div>
                    이용약관
                    <p>자세히보기</p>
                  </div>
                  <input
                    type    ="checkbox"
                    name    ="useStplat"
                    checked ={agreements.useStplat}
                    onChange={handleAgreementChange}
                  />
                  <span></span>
                </label>
              </li>
              <li>
                <label>
                  <div>
                    개인정보 수집 및 이용동의
                    <p>자세히보기</p>
                  </div>
                  <input
                    type    ="checkbox"
                    name    ="indvdlInfo"
                    checked ={agreements.indvdlInfo}
                    onChange={handleAgreementChange}
                  />
                  <span></span>
                </label>
              </li>
              <li>
                <label>
                  <div>
                    위치기반 서비스 이용약관
                    <p>자세히보기</p>
                  </div>
                  <input
                    type    ="checkbox"
                    name    ="gps"
                    checked ={agreements.gps}
                    onChange={handleAgreementChange}
                  />
                  <span></span>
                </label>
              </li>
              <li>
                <label>
                  <div>
                    이벤트 · 혜택 알림 동의<em>(선택)</em>
                    {/* <p>자세히보기</p> */}
                  </div>
                  <input
                    type    ="checkbox"
                    name    ="eventAdvrtsNtcn"
                    checked ={agreements.eventAdvrtsNtcn}
                    onChange={handleAgreementChange}
                  />
                  <span></span>
                </label>
              </li>
            </ul>

            <BtnBottom
              disabled={
                !agreements.useStplat
                || !agreements.indvdlInfo
                || !agreements.gps
              }
              onClick={handleNextClick}
            >
            <span>다음</span>
            </BtnBottom>

          </AgreeWrap>
        </BottomSheet>
      </section>
    </div>
  );
};

const JoinTop = styled.div`
  width: calc(100% - 32px);
  padding: 20px 16px 0 16px;

  h3 {
    width: max-content;
    height: 24px;
    font-size: 14px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #f7e7dd;
    border-radius: 50px;

    padding: 2px 10px;

    color: #FE7A36;

    margin: 0 auto 20px auto;

    .it_txt{
      color: #FE7A36;
    }
  }

  ul {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      div {
        width: calc(100% - 42px);
        font-size: 14px;
        line-height: 22px;
        color: #444;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background: #fff;

        padding: 15px 20px;

        strong {
          color: #5d0ef5;
          font-weight: 700;
        }

        img {
        width :14px !important;
        }

        .pay, .it_txt {
          color: #444;
        }

        
      }

      &::after {
        content: url(${bottom_arr});
        margin: 5px 0;
      }

      &:last-child::after {
        content: none;
      }
    }
  }
`;

const AgreeBtn = styled.div`
  position: fixed;
  bottom: 0;

  width: calc(100% - 32px);
  padding: 20px 16px 40px 16px;

  button {
    background: #FE7A36;
    color: #fff;
  }
`;

const AgreeWrap = styled.div`
  width: calc(100% - 40px);

  border-radius: 16px 16px 0 0;
  background: #fff;
  padding: 8px 20px 40px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  ul {
    width: calc(100% - 40px);
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    gap: 8px;

    margin-bottom: 32px;

    li {
      width: 100%;
      height: 40px;

      display: flex;
      justify-content: start;
      align-items: center;
    }
  }

  label {
    width: 100%;

    cursor: pointer;
    font-size: 16px;
    color: #222;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      text-decoration: underline;
      font-size: 12px;
      color: #444;
      margin-left: 8px;

      display: inline-block;
    }

    input[type='checkbox'] {
      display: none;
    }

    /* 체크박스 커스텀 이미지 적용 */
    input[type='checkbox'] + span {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(${checkoff});
      background-size: cover;
    }

    /* 체크된 상태의 이미지 적용 */
    input[type='checkbox']:checked + span {
      background-image: url(${checkon});
    }
  }
`;

const Agreetit = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #FE7A36;
  }

  p {
    font-size: 14px;
    color: #444;
    margin-top: 12px;
    line-height: 18px;
  }
`;

const TotalInput = styled.label`
  width: calc(100% - 42px) !important;
  height: 50px;

  border: 2px solid #aaa;
  border-radius: 8px;

  padding: 0 20px;

  margin-bottom: 12px;
  font-weight:700;
`;

export default JoinAgree;
