import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FloatInput from './FloatInput';
import AmountInput from './AmountInput';
import { checkoff, checkon2 } from '../../../assets/images';

const NewServiceCheck = ({
    earnType
  , selected
  , point
  , stamp
  , rps
  , grpId
  , setBonusEdit
  , bonusEdit
  , children}) => {

  const [pointChecked, setPointChecked] = useState(false);
  const [stampChecked, setStampChecked] = useState(false);
  const [gameChecked, setGameChecked] = useState(false);

  const [pointEdit, setPointEdit] = useState(0)
  const [stampEdit, setStampEdit] = useState(0)
  const [rpsEdit, setRpsEdit] = useState(0)

  useEffect(() => {
    bonusEdit && 
      setPointChecked(bonusEdit?.pointUse === 'Y' ? true : false);
      setStampChecked(bonusEdit?.stampUse === 'Y' ? true : false);
      setGameChecked (bonusEdit?.rpsUse   === 'Y' ? true : false);
  }, [])

  useEffect(() => {
    point && 
    setPointEdit(bonusEdit?.point);
    setStampEdit(bonusEdit?.stamp);
    setRpsEdit(bonusEdit?.rps);
  }, [])

  useEffect(() => {
    setBonusEdit(prev => {
      // 함수 내부에서 이전 상태값 참조
      return {
        ...prev,
        point: parseFloat(pointEdit)
      }
    });
  }, [pointEdit])

  useEffect(() => {
    setBonusEdit(prev => {
      // 함수 내부에서 이전 상태값 참조
      return {
        ...prev,
        stamp: stampEdit
      }
    });
  }, [stampEdit])

  useEffect(() => {
    setBonusEdit(prev => {
      // 함수 내부에서 이전 상태값 참조
      return {
        ...prev,
        rps: rpsEdit
      }
    });
  }, [rpsEdit])


  /**
   * 보너스 변경하기
   * 포인트, 스탬프, 경품 사용여부
   */
  useEffect(() => {
    setBonusEdit((prevStoreInfo) => ({
      ...prevStoreInfo
      , pointUse: pointChecked  ? "Y" : "N"
      , stampUse: stampChecked  ? "Y" : "N"
      , rpsUse  : gameChecked   ? "Y" : "N"
    }));
  }, [ pointChecked, stampChecked, gameChecked]);

  const handlePointChange = () => {
    setPointChecked(!pointChecked);
    if(!pointChecked){
      setStampChecked(false);
    }
  };
  
  const handleStampChange = () => {
    setStampChecked(!stampChecked);
    if(!stampChecked){
      setPointChecked(false);
    }
  };



  return (
    <BonusCon className={`${earnType === selected ? "open_content" : ""}`}>
      <ul>
        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={pointChecked}
              // disabled={!checkList}
              onChange={handlePointChange} />
            <span></span>
            포인트
          </Sublabel>

          <p>
            <FloatInput 
              setPointEdit={setPointEdit}
              inputValue={bonusEdit.point}
              inputName={'point'}
              inputDisabled={!pointChecked}
            />
            %
          </p>
        </li>

        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={stampChecked}
              // disabled={!checkList}
              onChange={handleStampChange}
            />
            <span></span>
            스탬프
          </Sublabel>
       
          {
            selected === "S" ?
            <p>기준금액 <AmountInput 
            value={stampEdit} 
            setValue={setStampEdit} 
            disabled={!stampChecked} />당 1개</p> 
            :  
            <p>기준금액당 1개</p>
          }

        </li>

        <li>
          <Sublabel>
            <input
              type='checkbox'
              name='bonus_product'
              checked={gameChecked}
              // disabled={!checkList}
              onChange={(e) => setGameChecked(e.target.checked)}
            />
            <span></span>
            가위바위보
          </Sublabel>

            {
              selected === "S" ?
              <TypeS>기본승점 없음<br /> 
              <span>
                기준금액 
                <AmountInput 
                value={rpsEdit} 
                setValue={setRpsEdit} 
                disabled={!gameChecked}  
                />
                당 1게임 승점
              </span>
              </TypeS> 
              :  
              <p>기본승점 없음<br />기준금액당 1게임 승점</p>
            }
            
          
        </li>
        <li>
          {children}
        </li>
      </ul>
    </BonusCon>
  );
};

const Sublabel = styled.label`
input[type='checkbox'] , input[type='radio'] {
  display: none;
}

/* 체크박스 커스텀 이미지 적용 */
input[type='checkbox'] + span , input[type='radio'] + span{
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${checkoff});
  background-size: cover;
}

/* 체크된 상태의 이미지 적용 */
input[type='checkbox']:checked + span , input[type='radio']:checked + span {
  background-image: url(${checkon2});
}
`
const BonusCon = styled.div`
  transition: all .3s linear;
  max-height: 0;
  overflow: hidden;

  &.open_content {
    max-height: 500px;
    margin-bottom: 12px;

    input {
      
    }
  }


  ul {
    padding: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 12px;

    li {
      width: 100%;
      line-height: 25px;

      display: flex;
      justify-content: space-between;
      align-items: start;

      input {
        border: 1px solid #ddd;
        width: 80px;
        height: 16px;
      }

      label {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;

        font-size: 18px;
      }

      p {
        text-align: right;
        line-height:25px;
        font-size: 18px;

        display: flex;
        justify-content: end;
        align-items: center;
        gap: 4px;
      }
    }
  }
`
const TypeS = styled.p`
  flex-direction:column;
  align-items: flex-end !important;
`
export default NewServiceCheck;