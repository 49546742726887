import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {  
    stamp_description
  , game_description
  , close_button
  , stamp_logo
  , question_btn
  , scissor_small
  , rock_small
  , paper_small
  , rock
  , scissor
  , paper
} from "../../../assets/images";
import { useRecoilValue } from 'recoil';
import { pointState } from "../../../recoil/recoilState";
import ModalPopup from "../common/ModalPopup";
import WebStampTit from "./common/WebStampTit";

function StampGameStanby({ data }) {
  const [isGamePopup, setGamePopupVisible]= useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const images = [rock, scissor, paper];

  const storeInfo   = data?.store;
  const pointInfo   = data?.point;
  const amount      = data?.amount;
  const pointValue  = useRecoilValue(pointState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  const handleGameQuestionClick = () => {
    setGamePopupVisible(!isGamePopup);
  };

  const handleImageClick = (fileName) => {

    let rps;

    if      (fileName === 'rock_small') {
      rps = 'r';
    } 
    else if (fileName === 'paper_small') {
      rps = 'p';
    } 
    else if (fileName === 'scissor_small') {
      rps = 's';
    }

    const getRpsResultData = async () => {
      try {
        let serial = data?.serial;
        let approval = data?.approval;
        let _URL = '/api/pointMngr/requestRpsResult';
        console.log("requestRpsResult _URL :", _URL);

        const requestData = {
            rps     : rps
          , serial  : serial
          , approval: approval
          , point   : pointValue
        }

        let response = await axios.post(_URL, requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log("point_save_rps :", response.data);

        let gameResult = response?.data?.data;

        navigate('/point_save_result', { state: { data: data, gameResult: gameResult }, replace: true });
      } catch (error) {
 
        // 410 에러가 발생했을 때의 처리
        if (error.response && error.response.status === 410) {
          navigate('/error', {replace: true}); // 에러 페이지로 이동
        } else {
          // 기타 에러 처리
          console.error("Error occurred:", error);
        }
        
      }
    };
    
    getRpsResultData();
    
  };


  console.log("data", data)

  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'> 
        <WebStampTit data={data} state={0} />

        <GameWrap>
        <h6>가위바위보 미진행시 적립불가</h6>
        
      <GameTypeContainer>
        <p>가위바위보 경품게임</p>
        <img
          src={question_btn}
          onClick={handleGameQuestionClick}
        />
      </GameTypeContainer>

      <GrayBackGroundContainer>
        <GameShuffleContainer>
          <GameShuffleImg
            src={images[currentImageIndex]}
          />
        </GameShuffleContainer>

        <GameSelectContainer>
          <GameShuffleImg
            src={scissor_small}
            onClick={() => handleImageClick('scissor_small')}
          />
          <GameShuffleImg
            src={rock_small}
            onClick={() => handleImageClick('rock_small')}
          />
          <GameShuffleImg
            src={paper_small}
            onClick={() => handleImageClick('paper_small')}
          />
        </GameSelectContainer>

        <DescriptionContainer>
          <h5>가위바위보 중 하나를 선택하세요</h5>
          <h2>승리시 경품 승점!</h2>
        </DescriptionContainer>
      </GrayBackGroundContainer>

      {isGamePopup && (
          <ModalPopup>
            <img
              src     ={game_description}
              style   ={{ width: '320px', height: '600px' }}
            />
            <CloseButton
              src     ={close_button}
              alt     ="Close"
              onClick ={() => setGamePopupVisible(false)}
            />
          </ModalPopup>
        )}
    </GameWrap>

      </div>
    </BonusSUWrap>      
  );
}


const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
  padding: 0 16px;

  .cash_stamp_wrap {
    width: calc(100% - 36px);
    background-color: #fff;
    border-radius: 12px;
    padding: 22px 18px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);

  }

  h1 {
    color: #2860a7;
    font-weight: 800;

    margin-right: 4px;
    vertical-align: middle;

    em {
      background-color: #5d0ef5;
      color: #ffffff;
      padding: 1px 5px 1px 5px;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  h3 {
    margin-top: 26px;

    font-size: 16px;
    font-weight: 700;
    color: #222;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2px;

    em {
      color: #FD4D61;

    }

    img {
      width: 20px;
      height: 20px;
    }

  }
`

const BonusSU = styled.div`
margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

  }

  p {
    color: #707070;
    font-weight: 700;
    font-size: 14px;

    strong {
      color: #707070;
      font-weight: 700;
      font-size: 26px;
    }
  }
`


const GameWrap = styled.div`
  width: 100%;

  h6 {
    color: #E95513;  
    font-weight: 500;
    margin-top: 5px;
    font-size: 18px;
    text-align:right;
  }
`

const GrayBackGroundContainer = styled.div`
  width: 100%; 
  height: 300px; 
  background-color: #F6F6F6;
  border-radius: 10px;  
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 10px;
   
`;

const GameShuffleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const GameSelectContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const GameShuffleImg = styled.img`
  width: 60px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;  
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px; 
  
  h5 {
    margin-right: 10px;
  color: #707070;
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
  }

  h2 {
    color: #E95513;  
    font-weight: 500;
    margin-top: 5px;
    font-size: 18px;
  }
`;

const GameTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  
  p{
    margin-right: 5px;
    color: #2860A7;
    font-weight: 600;
    font-size: 18px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;


export default StampGameStanby;
