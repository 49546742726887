import React from 'react';
import styled from 'styled-components';
import StampUseScreen from '../../../components/common/payuse/StampUseScreen';
import TopImg from '../../../components/common/payuse/TopImg';
import BlankBtns from '../../../components/common/payuse/BlankBtns';
import Recipe from '../../../components/common/payuse/Recipe';
import Adimg from '../../../components/common/payuse/Adimg';
import { useLocation } from 'react-router';
import SubHeadHome from '../../../components/TouchOll/SubHeadHome';
import StampStandScreen from '../../../components/common/payuse/StampStandScreen';

const StampTypeOne = () => {
  /**
   * 스탬프 적립 대기 페이지
   */
  const location = useLocation();  
  const data = location.state.data || {};
  const point = location.state.point;

  const storeInfo   = data?.store;
  const pointInfo   = data?.point;
  const receiptInfo = data?.receipt || data?.recipt;
  const useType     = data?.type;

  console.log("받아온 데이터::",data)
  console.log("receiptInfo 데이터::",receiptInfo)

  return (
    <div id="sub_common_wrap_f">
      <SubHeadHome SubBgColor={'#fff'} bottomLine={true}>
        보너스 이용 내역
      </SubHeadHome>

      <section>
        <StampWrap>
          <StampResultWrap>
            {/* 터치올 공통 배너 */}
            <TopImg />

            {/* 스탬프 상태 Type에 따른 화면 */}
            <StampStandScreen storeInfo={storeInfo} pointInfo={pointInfo} data={data} point={point} />

            {/* 전자영수증 */}
            <Recipe stamp={"Y"} receiptInfo={receiptInfo} storeInfo={storeInfo} useType={useType} />

            {/* 광고 */}
            <Adimg />
          </StampResultWrap>

        </StampWrap>
      </section>
    </div>
  );
};


const StampWrap = styled.div`
background:#F5F5F5; 
padding:32px 0;
`

const StampResultWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
gap: 32px;

width: calc(100% - 32px);
padding: 0 16px;

`

export default StampTypeOne;