import React, { useState } from 'react';
import {
    notice_icon
  , bell
  , store
  , store2
  , mypage
} from "../../../../assets/images"
import styled from 'styled-components';
import ModalPopup from '../../common/ModalPopup';
import { useRecoilValue } from 'recoil';
import { UserInfoResult } from "../../../../recoil/recoilState";
import { Link, useNavigate } from 'react-router-dom';
import useModalWithBackButton from '../../common/function/HistoryModal';
import { useBodyScrollLock } from '../../common/function/BodyModalScroll';

const IconMenuList = () => {
  const [isModal1Visible, toggleModal1] = useModalWithBackButton();
  const { openScroll } = useBodyScrollLock(); 
  // const [storeList, setStoreList] = useState(false);
  const userInfo = useRecoilValue(UserInfoResult);

  const listlen = userInfo.listStore.length; // 가맹점 수


  const navigate = useNavigate()

  const handleMyStorePage = (sid) => {
    openScroll();
    navigate("/admin/mystore_info", { state: { sid } });
  };

  console.log("listlen", listlen)
  return (
    <>
      <IconMenu>
        <ul>
          <li><a href='/app/board/notice'><img src={notice_icon} alt="공지" /></a></li>
          <li><a href='/app/board/myhot'><img src={bell} alt="알림" /></a></li>
        </ul>

        <ol>
          {
            userInfo.agencyAt === "Y" && 
            <li><img src={store} alt="대리점" /></li>
          }
          {
            /**
             * 가맹점이 1개 이상: Modal 팝업으로 가맹점 리스트 보여줌
             * 가맹점이 1개 : 바로 가맹점 페이지로 이동됨
             */
            userInfo.managerAt === "Y" && 
            <li onClick={listlen > 1 ? toggleModal1 : () => handleMyStorePage(userInfo.listStore.sid)}>
              <img src={store2} alt="가맹점" />
            </li>
          }
          {/* <li><img src={partner} alt="파트너" /></li> */}
          {/* <li><img src={mypage} alt="파트너" /></li> */}
        </ol>
      </IconMenu>

      {
        isModal1Visible &&
        (
          <ModalPopup>
            <StoreModalPopup>
              <h3>나의 가맹점 리스트</h3>
              <ul>
                {
                  userInfo.listStore.map((item, index) => {
                    return(
                      <li key={index} onClick={() => handleMyStorePage(item.sid)}>
                        {item.storeName}
                      </li>
                    )
                  })
                }
              </ul>

              <button onClick={toggleModal1}>
                닫기
              </button>
            </StoreModalPopup>

          </ModalPopup>
        )
      }
    </>
  );
};

const IconMenu = styled.div`
  // max-width:600px;
  width: calc(100% - 32px);
  padding: 8px 16px 38px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  

  ul{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
  }

  ol{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
  }

  li {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 25px;
      height: auto;
    }
  }
`

const StoreModalPopup = styled.div`
background:#fff;
border-radius: 16px;
width: calc(320px - 32px);
min-height: 300px;
padding: 20px 16px 58px 16px;
position:relative;

h3 {
  font-size: 20px;
  font-weight:bold;
  text-align:center;
  margin-top: 16px;
  margin-bottom: 20px;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 12px;
}

li {
  font-size: 18px;
  line-height: 24px;
  list-style: circle;
  margin-left: 20px;
  cursor: pointer;
}

button {
  width: 100px;
  height: 40px;
  font-size: 16px;
  position:absolute;
  left: 50%;
  bottom:16px;
  margin-left: -50px;
  color:#fff;
  border-radius: 4px;
  background:#FE7A36;
}

`

export default IconMenuList;