import React from 'react';
import styled from 'styled-components';
import { ediya, gifticon, mapicon, moor, no_store, p_icon, pointicon, stampicon, telicon, tooltip_tail } from '../../../../assets/images';
import { formatCurrency } from '../../common/function/FormatNumber';
import CommonIconList from '../../common/CommonIconList';
import Help from '../../common/Help';
import { useNavigate } from 'react-router';

const StoreFind = ({
  sid
, earn
, store
, storeImg
, starCount
, Icons
, pointUse
, stampUse
, rpsUse
, perCent
, stamp
, rps
, city
, gungu
, dong
, sectorName
, phone
, xpoint
, ypoint
, onClick
}) => {
/**
   * earn       : 유형
   * store      : 가게 이름
   * storeImg   : 스토어 이미지
   * starCount  : 별점 
   * Icons      : ['hot', 'recom', 'top', 'celebs', 'new'] 리스트 형태
   * pointUse   : 포인트 사용여부
   * stampUse   : 스탬프 사용여부
   * rpsUse     : 경품 사용여부
   * perCent    : 적립 % 
   * stamp      : 스탬프
   * rps        : 경품
   * phone      : 전화번호
   */

const BASE_IMG_URL = "https://dev1.touchall.co.kr";
  
const navigate = useNavigate();
const handleUsing = () => {
  navigate("/app/useinfo_store", {state: {sid: sid}});
}

const storeDetail = () => {
  navigate("/app/store_detail");
}

  return (
<StoreWrap>
      <StoreListWrap>
        <div>
          <div className='store_tit'>
            <h3 onClick={storeDetail}>{store}</h3>
            <p className={`${starCount === 0 ? 'no_star_count' : ''}`}>
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.73438 12.8789L4.2793 8.13477L0.259766 5.22266H5.22266L6.75391 0.492188L8.29883 5.22266H13.2754L9.24219 8.13477L10.8008 12.8789L6.75391 9.95312L2.73438 12.8789Z" fill="#FE7A36"/>
              </svg>
              {starCount.toFixed(1)}
            </p>
          </div>

          {/* 가맹점 뱃지 */}
          <IconMap>
            <CommonIconList Icons={Icons} />
          </IconMap>
          
          
          <div className={`percent ${earn === "T" ? "type1" : earn === "G" ? "type2" : "type3"}`}>
            <em>{earn === "T" ? "통합" : earn === "G" ? "그룹" : "단독"}</em>
            
            {pointUse === 'Y' && <p className='pp'><img src={pointicon} alt='포인트' />{perCent.toFixed(1)}%</p>}
            {stampUse === 'Y' && <p className='ss'><img src={stampicon} alt='스탬프' />{formatCurrency(stamp)}당 1S</p>}
            {rpsUse === 'Y' && <p className='gg'><img src={gifticon} alt='경품' />{formatCurrency(rps)}당 1G</p>}
          </div>

          <p className='adress'>{city?.substr(0,2)} - {gungu} - {dong}  &nbsp;|&nbsp;  {sectorName}</p>

          {/* 전화 지도 평가후기 */}
          <IconMap>
            <p><a href={`tel:${phone}`}><em><img src={telicon} alt='전화' /></em>전화</a></p>
            <button onClick={onClick}><p><em><img src={mapicon} alt='지도' /></em>지도</p></button>
            <p>평가 | 후기</p>
          </IconMap>

        </div>

        <RightBtn>
          <button onClick={storeDetail}><img src={moor} alt='매장상세' /></button>
          <div className='storeimg'>
            <img src={storeImg ? BASE_IMG_URL + storeImg : no_store} alt={store} />
          </div>
        </RightBtn>
      </StoreListWrap>
    </StoreWrap>
  );
};


const StoreWrap = styled.div`
width: calc(100% - 32px);
padding: 32px 16px;
border-bottom:1px solid #ddd; 
`
const StoreListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .store_tit {
    display: flex;
    justify-content: start;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #222;
    }

    p {
      display: flex;
      justify-content: start;
      align-items: flex-start;
      margin-left: 4px;

      font-size: 14px;
      color:#FE7A36;

      &.no_star_count {
        svg {
          path {
            fill: #aaa;
          }
        }

        color:#aaa;
      }
    }
  }

  .percent {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
    margin-top: 8px;

    &.type1{
      em {
        background: #5D0EF5;
      }
    }

    &.type2{
      em {
        background: #8E3D82;
      }
    }

    &.type3{
      em {
        background: #7F7F7F;
      }
    }


    em {
      color: #fff;
      padding: 1px 2px;
      
      border-radius: 2px;
      font-size: 14px;
    }

    p {
      font-size: 12px;

      display: flex;
      justify-content: start;
      align-items: center;
      gap: 2px;

      img {
        width: 16px;
      }

      &.pp{ color: #7B8531; }
      &.ss{ color: #EC635D; }
      &.gg{ color: #2860A7; }
    }
  }

  .adress {
    color:#8e8e8e;
    font-size: 14px;
    margin-top: 8px;
  }

  
`

const RightBtn = styled.div`
display: flex;
justify-content: start;
align-items: start;

.storeimg {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  overflow:hidden;
  width: 90px;
  // min-height:115px;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

`

const IconMap = styled.div`
display: flex;
justify-content: start;
align-items: center;
gap:8px;

button {
  cursor:point;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;

  p {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2px;
  }
}

&>p {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;
  margin-top: 8px;

  font-size: 14px;
  
  color:#4D4D4D;

  &:last-child {
    border: 1px solid #8E8E8E;
    padding: 2px 5px;
    border-radius: 4px;
  }

  a {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2px;
  }
}
`

export default StoreFind;