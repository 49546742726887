import React, { useState } from "react";
import styled from "styled-components";

import {
    rock_small
  , scissor_small
  , paper_small
  , rock_select
  , scissor_select
  , paper_select
  , question_btn
  , close_button
  , game_description
} from "../../../assets/images";
import ModalPopup from "../common/ModalPopup";
import WebGameTit from "./common/WebGameTit";


function GameResult({ data, gameResult }) {
  
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleQuestionClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  console.log("GameResult data:", data);
  console.log("GameResult:", gameResult);

  return (
    <BonusSUWrap>
      <div className='cash_stamp_wrap'>        
        <WebGameTit data={data} state={0} />

        <GrayBackGroundContainer>
          <GameSelectContainer>
            <GameShuffleImg
              src={gameResult?.show === 's' ? scissor_select : scissor_small}
            />
            <GameShuffleImg
              src={gameResult?.show === 'r' ? rock_select : rock_small}
            />
            <GameShuffleImg
              src={gameResult?.show === 'p' ? paper_select : paper_small}                
            />
          </GameSelectContainer>

          <DescriptionContainer>              
            <SelectSubTitleText>{gameResult?.result === 'w' ? '축하합니다! 승리!' : gameResult?.result === 'd' ? '무승부! 아깝습니다' : gameResult?.result === 'l' ? '패배! 아쉽습니다.' : '게임 결과를 확인하세요.'}</SelectSubTitleText>
          {gameResult?.result === 'w' && (
            <GameResultDescriptionContainer>
              <SaveStatus>경품승점 적립대기!</SaveStatus>        
              <CashAmount>{gameResult?.rpsPoint} 점</CashAmount>
            </GameResultDescriptionContainer>
          )}
          {gameResult?.result === 'd' && (
            <GameResultDescriptionContainer>
              <SaveStatus>경품승점 적립대기!</SaveStatus>        
              <CashAmount>{gameResult?.rpsPoint} 점</CashAmount>
            </GameResultDescriptionContainer>
          )}
          {gameResult?.result === 'l' && (
            <GameResultDescription>{gameResult?.result === 'l' ? '다음 기회를 이용하세요.' : '다음 기회를 이용하세요.'}</GameResultDescription>
          )}
          </DescriptionContainer>
        </GrayBackGroundContainer>
        </div>
      </BonusSUWrap>
  );
}

const BonusSUWrap = styled.div`
width: calc(100% - 32px);
margin-top:20px;
padding: 0 16px;

.cash_stamp_wrap {
  width: calc(100% - 36px);
  background-color: #fff;
  border-radius: 12px;
  padding: 22px 18px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
}
`

const GrayBackGroundContainer = styled.div`
  width: calc(100% - 32px); 
  background-color: #F6F6F6;
  border-radius: 10px;   
  padding: 30px 16px 26px 16px;
  margin-top: 12px;
`;

const GameResultDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;  
`;

const GameResultDescription = styled.div`
  color: #222222;
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;    
`;

const SaveStatus = styled.div`      
  color: #4D6BAB;  
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  margin-left: 20px;
`;

const CashAmount = styled.div`
  color: #4D6BAB;
  font-weight: 600;
  font-size: 26px;
  margin-right: 20px;
`;

const DescriptionContainer = styled.div`
  display: flex;  
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const SelectSubTitleText = styled.div`  
  margin-right: 10px;
  color: #4D6BAB;
  font-weight: 600;  
  font-size: 22px;
`;

const GameSelectContainer = styled.div`
  margin-bottom: 33px;
  display: flex;
  justify-content: center;
`;

const GameShuffleImg = styled.img`
  width: 60px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
`;

export default GameResult;
