import React, { useEffect, useState } from 'react';
import ModalPopup from '../ModalPopup';
import styled from 'styled-components';
import { arr_right_or, arr_w_right, close_btn, logo, logo_txt, medal, p_icon, stamp_logo } from '../../../../assets/images';
import EarnIcon from '../EarnIcon';
import { parseTime } from '../function/FormatNumber';
import PSGicon from '../PSGicon';

const StampUseDetail = ({
    toggleModal
  , earnType
  , storeName
  , sttleStamp
  , userName
  , grade
  , sumCashBonusStamp
  , pointInfo
  , type
  , receiptInfo
  , kind
  , show // 결제마킹 클릭 여부
}) => {
  const payMethodRatio            =        pointInfo?.payMethodRatio || 0                          // 추가적립 : 현금결제
  const sttleRate                 =        pointInfo?.sttleRate      || 0                          // 추가적립 : 50,000원 이상
  const timeRatio                 =        pointInfo?.timeRatio      || 0                          // 추가적립 : 우대시간
  const sumRatio                  =        pointInfo?.sumRatio       || 0                          // 추가적립 : 소계
  const cashBonusStamp            = Number(pointInfo?.cashBonusStamp            ).toLocaleString() // 정규보너스 추가적립금
  const cancelCashBonusStamp      = Number(pointInfo?.cancelCashBonusStamp      ).toLocaleString()
  const gradeRate                 =        pointInfo?.gradeRate      || 0                          // 우대률
  const cashGradeBonusStamp       = Number(pointInfo?.cashGradeBonusStamp       ).toLocaleString() // 우대률 합한 적립금
  const cancelCashGradeBonusStamp = Number(pointInfo?.cancelCashGradeBonusStamp ).toLocaleString() // 우대률 합한 적립금
  const baseGradeRate             =        pointInfo?.baseGradeRate  || 0                          // 학년 승급시 
  const baseAmountRatio           =        pointInfo?.baseAmountRatio || 0;
  const cancelSumCashBonusStamp   = Number(pointInfo?.cancelSumCashBonusStamp   ).toLocaleString();
  const cancelSttleStamp          = Number(pointInfo?.cancelSttleStamp          ).toLocaleString();
  /**
   * stime, etime 시간 관련
   */
  const [sHour, setSHour] = useState("")
  const [smin,  setSmin]  = useState("")
  const [eHour, setEHour] = useState("")
  const [emin,  setEmin]  = useState("")

  useEffect(() => {
    const parsedStime = parseTime(pointInfo?.startTime);
    const parsedEtime = parseTime(pointInfo?.endTime);

    if (parsedStime) {
      setSHour(parsedStime.hour.toString().padStart(2, '0'));
      setSmin(parsedStime.minute.toString().padStart(2, '0'));
    }

    if (parsedEtime) {
      setEHour(parsedEtime.hour.toString().padStart(2, '0'));
      setEmin(parsedEtime.minute.toString().padStart(2, '0'));
    }
  }, [pointInfo?.startTime, pointInfo?.endTime]);

  // 산출확인 버튼
  const [baseBonusSum, setBaseBonusSum] = useState(false);
  
  return (
    <>
      <DetailWrap className={show === 'use' ? "showuse" : ""}>
        <h1>
          <img src={logo} alt='터치올' />
        </h1>

        <h2>
          {storeName} <EarnIcon earn={earnType} />
        </h2>

        <p>보너스 제공 세부 내역</p>

        <ul>
          <li>
            <h3>
              <em>1</em>
              기본 보너스
              <a onClick={() => setBaseBonusSum(!baseBonusSum)}>
                산출확인
                <img src={arr_w_right} alt='이동' />
              </a>
            </h3>

            {/* 산출확인 버튼 클릭시 팝업 */}
            {
              baseBonusSum &&
              <>
                <BaseBonusPop>
                  <h6>기본 보너스 산출내역</h6>
                  <button onClick={()=>setBaseBonusSum(!baseBonusSum)}><img src={close_btn} alt='close' /></button>

                  <p>①결제 금액 / ②금액 당 적립</p>

                  <ol>
                    <li><em><b>{Number(receiptInfo?.stlmPrice).toLocaleString()}</b>원</em></li>
                    <li><span>/</span><em><b>{pointInfo?.baseStampPrice || 0}</b>원</em></li>
                  </ol>
                  <h4><em>{type === "1" || type === "2" ? sttleStamp : cancelSttleStamp}</em>개</h4>

                </BaseBonusPop>
                <DimBG className={show === 'use' ? "showuse" : ""} onClick={() => setBaseBonusSum(!baseBonusSum)}></DimBG>
              </>
            }

            <h5>
              <p>
                {/* <img src={logo_txt} alt='터치올' />  */}
                 &nbsp;{kind === "S" ? "스탬프" : "수동 스탬프"}  <PSGicon type={'s'} /> 
                 {type === '3' && <em className='type3'> 취소</em>}
              </p>
              <div>
                <img src={stamp_logo} alt='스탬프'/>
                <span className={`type${type}`}>
                <em>{sttleStamp}</em> 개
                </span>
              </div>
            </h5>
          </li>

          <li>
            <h3><em>2</em>추가 적립 {type === '3' && <span className='type3'> &nbsp;취소</span>}</h3>
            <ol>
              <li>
                <span>현금 결제</span> {payMethodRatio}%
              </li>
              
              <li>
                <span>{Number(pointInfo?.baseStampPrice).toLocaleString()}개 이상 결제</span> {baseAmountRatio}%
              </li>
            
              <li><span>우대시간({sHour}:{smin}~{eHour}:{emin}) 이용</span> {timeRatio}%</li>
              
              <li className='small_total'>
                <span>소계</span> {sumRatio}%
              </li>
              <li className='total_ratio'>
                <span>① 기본 보너스 x ② 추가 적립 {sumRatio}%</span> 
                <strong className={`type${type}`}>
                  { type === "1" || type === "2" ? cashBonusStamp : cancelCashBonusStamp } 
                  <em> 개</em>
                </strong>
              </li>
            </ol>
          </li>

          <li>
            <h3><em>3</em>터치올 대학-학년별 장학금{type === '3' && <span className='type3'> &nbsp;취소</span>}</h3>
            <div className='amount'>
              <div>
              (① 기본 보너스  +  ② 추가적립)<br/>
              x  <em><p>{grade}학년</p> {userName}</em> <span>우대율 <p>{gradeRate}%</p></span>
              </div>

              <h4 className={`type${type}`}>
                {type === "1" || type === "2" ? cashGradeBonusStamp : cancelCashGradeBonusStamp}<em> 개</em>
              </h4>
            </div>

            <div className='oowdea'>
              <h6><em>{storeName}</em> 학년별 우대</h6>
              <p>1학년 <img src={arr_right_or} /> 매 한학년 승급시 <em>{baseGradeRate}%</em>씩 추가</p>
            </div>
          </li>
        </ul>

        <DetailSum>
          <p>총</p>
          <h6 className={`type${type}`}>{sumCashBonusStamp}<em> 개</em></h6>
        </DetailSum>

        {/* <DetailNotice>
          <p>
          <em>매장이 통합, 그룹형 일시 기준 적용 시간차로 </em><br/>
          확정수량은 단말 안내 수량과<br/>
          다소 차이가 있을 수 있습니다.
          </p>
        </DetailNotice> */}

        {
          show !== 'use' && <button onClick={toggleModal}>확인</button>
        }
        
      </DetailWrap>
    </>
  );
};

const DetailWrap = styled.div`
.type1, .type2{
  color: #FD4D61 !important;

  em, p, strong {
    color: #FD4D61 !important;
  }
}

.type3, .type4{
  color: #FB0E0E !important;

  em, p, strong {
    color: #FB0E0E !important;
  }
}

 width: calc(100vw - 72px);
  max-width: 340px;
  height: calc(100vh - 128px);

  padding: 32px 20px;
  background:#fff;

  overflow: auto;

  h1 {
    width: auto;
    height: 30px;

    img {
      height: 30px;
    }
  }

  h2{
    font-weight: bold;
    font-size: 18px;
    color: #2860A7;
    margin-top: 16px;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

  }

  &>p {
    font-size: 14px;
    margin-top: 8px;
  }

  ul {
    margin-top: 32px;

    & > li {
      padding-bottom: 24px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
      position: relative;

      h3 {
        display: flex;
        justify-content: start;
        align-items: center;
        color: #8F20E7;
        font-weight: bold;

        em {
          background: #8F20E7;
          width: 18px;
          height: 18px;
          border-radius: 18px;

          display: flex;
          justify-content: center;
          align-items: center;

          color: #fff;
          font-weight: bold;
          margin-right: 2px;
        }

        a {
          font-weight: 500;
          font-size: 14px;
          color: #fff;
          background: #FD4D61;
          border-radius: 2px;
          padding: 2px 4px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;

          margin-left: 6px;
        }
      }

      h5 {
        margin-top: 8px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
          font-size: 18px;
          font-weight: bold;
          color: #FD4D61;

          display: flex;
          justify-content: start;
          align-items: center;
          gap: 2px;
          
          img {
            height: 18px;
          }
        }

        div {
          display: flex;
          justify-content: start;
          align-items: center;
          gap:4px;
        }
        span {
          font-size: 16px;
          color: #FD4D61;
          font-weight: bold;

          em {
            font-size: 25px;
            font-weight: bold;
            color: #FD4D61;
          }
        }
      }

      &:nth-child(3) {
        background: url(${medal}) right top no-repeat;
        background-size: 70px;

        .amount{
          display: flex;
          justify-content: space-between;
          align-items: end;

          & > div{
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            margin-top: 50px;

            em{
              color: #325C90;
              display: inline-block;
              
              p {
                background-color: #325C90;
              }
            }

            p {
              color: #fff !important;
              border-radius: 2px;
              display: inline-block;
              padding: 1px 4px;
            }

            span {
              color: #FE7A36;
              
              p {
                background-color: #FE7A36;
              }
            }


            
          }
          

          h4 {
            font-size: 25px;
            color: #FD4D61;
            font-weight: bold;

            em {
              font-size: 16px;
              color: #FD4D61;
            }
          }
        }
        
        .oowdea {
          background: #F3F2F2;
          border-radius: 4px;
          padding: 12px 8px;
          margin-top: 28px;

          h6 {
            font-size: 16px;
            font-weight: bold;

            em {
              color: #325C90;
            }
          }

          p {
            font-size: 14px;
            font-weight: bold;
            margin-top: 16px;

            display: flex;
            justify-content: start;
            align-items: center;
            gap: 4px;

            em {
              color: #fff;
              background: #FE7A36;
              padding: 2px 4px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  
  ol {
    margin-top: 12px;
    padding-left: 16px;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    gap: 8px;

    li {
      color: #8E8E8E;

      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #8E8E8E;
      }

      &.small_total {
        span {
          font-weight: bold;
        }
        color: #222;
        font-weight: bold;
      }

      &.total_ratio {
        padding-top: 10px;

        span {
          font-weight: bold;
          color: #222;
        }
        
        font-weight: bold;

        strong{
          font-size: 25px;
          font-weight: bold;
          color: #FD4D61;

          em {
            font-size: 16px;
            color: #FD4D61;
          }
        }
        
      }
    }
  }

  &>button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#FE7A36;
    border-radius: 8px;
    font-size: 20px;
    color:#fff;
    font-weight:bold;
    margin-top: 28px;
  }

  &.showuse {
    // width: 100%;
    max-width: unset;
    height: max-content;
  }
`

const DetailSum = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p{
    font-size: 25px;
    color: #8F20E7;
    font-weight: 900;
  }

  h6 {
    font-weight: bold;
    font-size: 40px;
    color: #FD4D61;

    em {
      font-weight: bold;
      font-size: 16px;
      color: #FD4D61;
    }
  }


`
const DetailNotice = styled.div`
  margin-top: 32px;
  background: #F5F5F5;
  padding: 20px 16px;
  font-size: 16px;
  line-height: 21px;

  text-align: center;

  em{
    color: #8E8E8E;
  }
`

const BaseBonusPop = styled.div`
position:absolute;

background:#fff;
border:1px solid #7B8531;
border-radius: 0 8px 8px 8px;
left: 99px;
z-index: 99;

padding: 16px;

button {
  position: absolute;
  right: 5px;
  top: 5px;
}

h6 {
  font-size: 14px;
  font-weight: bold;
  color:#7B8531;
}

p {
  font-size: 14px;
  margin-top: 8px;
}

h4 {
  text-align:right;
  margin-right: 8px;
  margin-top: 4px;

  em {
    font-size: 18px;
    margin-right: 4px;
    color:#7B8531;
    font-weight: bold;
  }

  font-size: 12px;
  color:#7B8531;
  font-weight: bold;
}

ol {
  border-bottom: 1px solid #7B8531;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;

  padding:0 0 4px 0;

  li {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    width: calc(100% - 16px);

    position:relative;
    padding: 0 8px;

    span {
      position:absolute;
      left: 8px;
      font-size: 16px;
    }

    em {
      font-size: 12px;
      
      b{
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }
}
`

const DimBG = styled.div`
  width: calc(100vw - 32px);
  height: 100vh;

  position: fixed;
  left:0;
  top:0;

  background: rgba(0,0,0,0.2);
  z-index: 9;
  
  &.showuse {
   width: 100%;
  }
`
export default StampUseDetail;