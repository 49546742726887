import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'
import ko  from 'date-fns/locale/ko'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
// import "./datepickerstyle.scss"
import styled from 'styled-components';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DataPicker = ({
    setState
  , setStartDatepick
  , setEndDatepick
  , setSelectedPeriod}) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  // const [focus, setFocus] = useState(START_DATE)

  useEffect(() => {
    if (startDate) {
      setStartDatepick(format(startDate, 'yyyy.MM.dd', { locale: ko }));
    }
    if (endDate) {
      setEndDatepick(format(endDate, 'yyyy.MM.dd', { locale: ko }));
    }
  }, [startDate, endDate, setStartDatepick, setEndDatepick])


  const [rangeDate, setrRangeDate] = useState([
    {
      startDate:startDate,
      endDate: endDate,
      key: "selection",
    },
  ])

  const handleDatePicker = () => {
    setState(false)
    setSelectedPeriod('')
  }

  return (
    <div>
      <DataPickerWrap >
      <DateRange
          editableDateInputs={true}
          onChange={item => {
            setrRangeDate([item.selection])
            setStartDate(item.selection.startDate);
            setEndDate(item.selection.endDate);
          }}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={false}
          showSelectionPreview={false}
          ranges={rangeDate}
          rangeColors={['#FEBC9A']}
          months={1}
          locale={ko}
        />

      <button className='exit' onClick={handleDatePicker}>확인</button>
      </DataPickerWrap>
      
    </div>
  )
};

const DataPickerWrap = styled.div`
background:#fff;
padding: 32px 16px;
width: 256px;
text-align: center;

button.exit {
  font-size: 14px;
  font-weight:bold;
  background: #444;
  color:#fff;
  width: 100px;
  height: 32px;
  border-radius: 4px;
}

.rdrDateRangeWrapper {
  width: calc(32px * 7);
  margin-bottom: 20px;
}
.rdrDateDisplayWrapper {
  display:none;
}

.rdrMonthAndYearWrapper{
  padding:0;
  height: 40px;
}

.rdrNextPrevButton {
  margin: 0;
}

.rdrMonth {
  padding:0;
}

.rdrDay{
  width: 32px;
  height: 32px;
  position:relative;
  margin: 2px 0;

  &:has(.rdrStartEdge) { 
    background:#FEBC9A;    
    border-radius: 50% 0 0 50%;
  }

  &:has(.rdrEndEdge){ 
    background:#FEBC9A;    
    border-radius: 0 50% 50% 0 ;
  }

  &:has(.rdrStartEdge.rdrEndEdge){
    border-radius: 50% ;
  }
}

.rdrDayStartPreview, .rdrDayEndPreview , .rdrDayInPreview{
// display:none;
top: 0 !important;
height :30px;
}

.rdrStartEdge, .rdrEndEdge {
  background:#FE7A36;
  border-radius: 50%;
  width: 32px;
  height :32px;

  top: 0 !important;
  left:0 !important;
}

.rdrMonth {
  width: calc(32px * 7);
}

.rdrInRange {
  width: 100%;
  height: 100%;
  top:0;
  bottom: 0;
  border-radius: 0 !important;
}

.rdrDays {
  width: calc(32px * 7);

}

.rdrDayHovered {
  width: 32px;
  height: 32px !important;
  top: 0 !important;
}
`

export default DataPicker;