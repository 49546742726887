import React, { useEffect, useState } from 'react';
import SubHead from '../../../components/TouchOll/SubHead';
import { useLocation } from 'react-router-dom';
import EarnIcon from '../../../components/common/EarnIcon';
import styled from 'styled-components';
import { logo, nfcimg, p_icon, stamp_logo, stampicon } from '../../../../assets/images';
import { handleHeaderToken } from '../../../components/common/function/BearerToken';
import axios from 'axios';
import ModalPopup from '../../../components/common/ModalPopup';
import NFCpopup from '../../../components/common/NFCpopup';
import ApprovalPop from './ApprovalPop';
import ApprovalSop from './ApprovalSop';
import IosNfc from '../../../components/common/function/IosNfc';

const UsingStampStore = ({iosNFC}) => {
  const location  = useLocation();
  const storeData = location.state.storeData  || '';
  const earn      = location.state.earn       || '';
  const useTotal  = location.state.useTotal   || '';


  const [price, setPrice]   = useState('');

  const priceChangeHandler  = (event) => {
    let price = event.target.value;
        price = Number(price.replaceAll(',', ''));

    if (isNaN(price)) {
      setPrice(0);
    } else {
      setPrice(price.toLocaleString());
    }
  }

  /**
   * 모바일 기기 확인 
   * isMobile : true (모바일 기기)
   */
  const [isMobile, setIsMobile]   = useState(false); // 모바일 기기인지 확인
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);


  useEffect(()=>{
    handleHeaderToken();
  }, [])

  const [isNFC, setIsNFC] = useState(false);
  const [approvalData, setApprovalData] = useState({})
  
  const handelApproval = () => {
    const fetchData = async() => {
      try{
        const responseData = {
            sid: storeData?.sid
          , kind: "S"
          , amount: price.replace(/,/g, "")
          , earn: earn
          , serial : storeData?.serial
        }

        const response = await axios.post('/api/pointMngr/approvePointUsage', responseData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        
        console.log("approvalData :: ", response?.data?.data?.message);
        const telegramMsg = response?.data?.data?.message?.telegram;
        setApprovalData(response?.data?.data?.message);

        if (isMobile && telegramMsg) {
          // eslint-disable-next-line no-undef
          userApprovalPay.postMessage("user_point_use="+telegramMsg);
          setIsNFC(true);
        }

      } catch(error){
        console.log("사용승인 오류 ::". error);
      }
    }

    fetchData()
  }

  const [approvalPage, setApprovalPage] = useState(false)
  const [approvalJson, setApprovalJson] = useState([])
  const approvalAPI = () => {
    const fetchData = async() => {
      try{
        const responseData = {
          approval: approvalData?.approval
          , serial: storeData?.serial
          , certification: approvalData?.certification
        }

       console.log("responseData:: ", responseData)

        const response = await axios.post('/api/pointMngr/approvePointUsageCfrm', responseData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        console.log("approvePointUsageCfrm :: ", response?.data?.data?.message);
        setApprovalPage(true);
        setApprovalJson(response?.data?.data?.message)

      } catch(error){
        console.log("approvePointUsageCfrm 오류 ::". error);
      }
    }

    fetchData()
  }
  

  const locationLink = (jsonKey, jsonValue) => {
    if(jsonKey === "device_NFC"){
      if(jsonValue === 'false'){
        setIsNFC(false);
        approvalAPI();
      }
      
    }
  }

  //eslint-disable-next-line no-undef
  window.getTelegramDeviceNFC = (msg) => {
    const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, '');

    locationLink(key, value);
  }


  return (
    <div id="sub_common_wrap_f">
      {iosNFC && <IosNfc />}

      {
        isNFC && !iosNFC && (
          <ModalPopup>
            <NFCpopup setIsNFC={setIsNFC}/>
          </ModalPopup>
        )
      }

      {
        approvalPage && (
          <ModalPopup>
            <ApprovalSop  
            approvalData    ={approvalData} 
            earn            ={earn} 
            setApprovalPage ={setApprovalPage} 
          />
          </ModalPopup>
        )
      }

      <SubHead SubBgColor={'#fff'} bottomLine={true}>
        My 보너스 조회ㆍ사용
      </SubHead>

      <section>
        <EarnTit>
          {storeData?.storeName}
          <EarnIcon earn={earn} />
        </EarnTit>

        <PayAmount>
          <div>
            <img src={stamp_logo} alt='스탬프' />
            <div>
              <p>
                가용 스탬프
              </p>
              <h3>{Number(storeData?.storePoint[0]?.point).toLocaleString()}개</h3>
            </div>
            
          </div>
        </PayAmount>

        <PayInput>
          <h5>사용 스탬프<em>S</em></h5>
          <div>
            <input
              type        ="text"
              placeholder ='사용할 스탬프 개수 입력'
              value       ={price}
              onChange    ={priceChangeHandler}
            />
            <img src={stampicon} alt='스탬프' />
          </div>
        </PayInput>

        <PayBtn>
          <a href='/app/useinfo_store'>취소</a>
          <button 
          onClick={handelApproval}
          disabled={
                       !price 
                    || parseInt(price.replace(/,/g, "")) > storeData?.storePoint[0]?.point
                    || parseInt(price.replace(/,/g, "")) < 10
                  }
          >
            사용 결제하기
          </button>
        </PayBtn>

      </section>

    </div>
  );
};

const EarnTit = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  gap:4px;
  padding: 30px 0;

  font-size: 20px;
  font-weight: bold;
`

const PayAmount = styled.div`
&>div {
  display: flex;
    justify-content: center;
    align-items: center;
    gap:12px;
} 
  p {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;

    color:#FD4D61;
    font-size: 16px;

  }

  h3 {
    color:#FD4D61;
    font-size: 26px;
    font-weight:bold;
    text-align:left;
    margin-top: 12px;
  }
`

const PayInput = styled.div`
margin-top: 52px;
padding: 0 16px;

h5 {
  color:#909090;
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    color:#909090;
  }

  em {
    width: 16px;
    height: 16px;
    background:#909090;
    border-radius: 30px;

    color:#fff;
    font-weight: bold;
    font-size: 14px;
    margin-left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

&>div {
  background:#f0f0f0;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;

  padding: 0 16px;
  height:60px;
  margin-top: 12px;

  border-radius: 8px;

  img {
    width: 26px;
  }

  input {
    background:none;
    font-size: 20px;
    text-align:right;

    width: 100%;
    color:#222;

    &::placeholder {
      color:#888;
    }
  }
}
`
const PayBtn = styled.div`
padding:0 16px;
display: flex;
justify-content: space-between;
align-items: center;
gap: 12px;
margin-top: 40px;

a {
  height: 50px;
  width: 80px;
  font-size: 16px;
  color:#4d4d4d;

  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: calc(100% - 92px);
  height: 50px;
  background:#FE7A36;
  border-radius: 25px;

  color:#fff;
  font-size: 16px;

  span {
    color:#fff;
  }

  &:disabled {
    background: #DFDFDF;
    color:#aaa;

    span {
      color:#aaa;
    }
  }
}
`

const NfcPopup = styled.div`
width: 90%;
min-width: 320px;

background:#fff;

padding: 28px 0 ;
border-radius: 12px;

h2 {
  width: 120px;

  img {
    width: 100%;
    height: auto;
  }
}

p{
  margin-top: 32px;
  font-size: 20px; 
  text-align:center;
  line-height: 28px;

  strong {
    color:#FE7A36;
    font-weight:bold;
  }

  b {
    font-weight:bold;
  }

  img {
    width: auto;
    height: 20px;
  }
}

button {
  margin-top: 28px;
  font-size: 16px;
  background:#ddd;
  width: 80px;
  height: 40px;

  border-radius: 8px;
  font-size: 16px;

}


display: flex;
justify-content: center;
align-items: center;
flex-direction:column;
`

export default UsingStampStore;