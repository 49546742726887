import React from 'react';
import { medal1 } from '../../../../assets/images';
import { medal2 } from '../../../../assets/images';
import { medal3 } from '../../../../assets/images';
import { medal4 } from '../../../../assets/images';
import styled from 'styled-components';

const Grade = ({grademedal, percent, month, credit}) => {
  /**
   * medal : 1 | 2 | 3 | 4
   * percent : 추가 적립률
   * month: 월 ~회
   * credit : 학점
   */

  const getMedalImage = (grademedal) => {
    switch (grademedal) {
      case 1:
        return medal1;
      case 2:
        return medal2;
      case 3:
        return medal3;
      case 4:
        return medal4;
      default:
        return null;
    }
  }
  return (
    <Medalwrap>
      <h2><img src={getMedalImage(grademedal)} alt={`${grademedal}학년`} /></h2>
      <div>
        <h4>적립금의 <em>{percent}% 추가 적립</em></h4>
        <span>등급조건</span>
        <p>
          월 {month}회 미만 이용,<br />
          {credit}학점 미만 적립 이용자
        </p>
      </div>
    </Medalwrap>
  );
};

const Medalwrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 28px;

  h2 {
    img {
      width: 90px;
      padding-top: 12px;
    }
  }

  div {
    h4 {
      font-size: 14px;

      em {
        color:#FE7A36;
        font-weight:bold;
      }
    }

    span {
      width: 55px;
      height: 20px;
      background:#EDEDED;
      border-radius: 50px;
      font-size: 12px;  
      margin: 12px 0 8px 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 12px;
      color:#7B8531;
      line-height: 15px;
    }
  }
`

export default Grade;