import React from 'react';
import { p_icon } from '../../../../assets/images';
import styled from 'styled-components';
import PSGicon from '../../common/PSGicon';

const PointIcon = () => {
  return (
    <Point>
      현금 <PSGicon type={'p'} />
    </Point>
  );
};

const Point = styled.em`
color:#7B8531;
font-weight:bold;
line-height: 14px;
display: inline-flex;
justify-content: flex-start;
align-items:center;

img {
  margin-left: 2px;
  width: 14px;
  vertical-align: middle;
}
`
export default PointIcon;